
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import { fn } from '../../../Functions';
import './Styles/PlatformSurgingTopics.scss';

const PlatformSurgingTopics = ({ 
    topic, type,
    data
}:{
    topic: string, type: string,
    data: {
        increasedTopic: {
            [topic: string]: number,
        },
        decreasedTopic: {
            [topic: string]: number,
        }
    }
}) => {
    
    return <div className={'PlatformSurgingTopicsArea'}>
        <div className={'Title'} >
            {`${topic} 급상승/급하강 토픽 TOP5`}
        </div>
        <div className={'SubTitle'}>
            {`지난 한달 전 대비 ${type === 'rank' && topic === "네이버웹툰"?"관심등록순위":type === 'rank'?"인기순위":"조회수"}가 상승한 토픽`}
        </div>
        <div className={'TopicRankPlace'} >
            <div className={'TopicSurgingList'} >
                <div className={'TopicBox'} >
                    {Object.entries(data['increasedTopic']).map( ([topic, percent], idx) => (
                        <div className={'TopicRow'} key={`${topic}:${percent}`} >
                            <span className={'RankNumber'} >{idx + 1}</span>
                            <a className={'TopicText'} href={`/market/trendsearch?topic=${topic}`} onClick={fn.gotoByAnchor} >
                                {topic}
                            </a>
                            <div className={'DirectionIcon'} >
                                <ArrowDropUp style={{color: 'rgb(255, 114, 114)', fontSize: '2rem'}} />
                            </div>
                            <span className={'PercentText'} > {percent + "%"}</span>
                        </div>
                    ))}
                </div>
                <div className={'TopicBox'} >
                    {Object.entries(data['decreasedTopic']).map( ([topic, percent], idx) => (
                        <div className={'TopicRow'} key={`${topic}:${percent}`} >
                            <span className={'RankNumber'} >{idx + 1}</span>
                            <a className={'TopicText'} href={`/market/trendsearch?topic=${topic}`} onClick={fn.gotoByAnchor} >
                                {topic}
                            </a>
                            <div className={'DirectionIcon'} >
                                <ArrowDropDown style={{color: 'rgb(91, 133, 253)', fontSize: '2rem'}} />
                            </div>
                            <span className={'PercentText'} >{percent + "%"}</span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    </div>
}

export default PlatformSurgingTopics;