import { useEffect, useState } from 'react';
import './Styles/EmptyResult.scss';
import { fn } from '../../../Functions';
import { platformAndTopicRank } from '../Util/PlatformTopicRank';
import PopularMaterials from './PopularMaterials';
import { getTopicList } from '../../../Data/MarketKeyword';

const platformList: {[platform: string]: string} = {
    naver: '네이버',
    series: '네이버시리즈',
    lezhin: '레진',
    ridi: '리디북스',
    bomtoon: '봄툰',
    daum: '카카오웹툰',
    kakao: '카카오페이지',
    comico: '코미코',
    toomics: '투믹스',
    toptoon: '탑툰',
};

const EmptyResult = ({ 
    searchText
}:{
    searchText: string,
}) => {

    const [ similarTopicList, setSimilarTopicList ] = useState<string[]>([]);
    const [ rankTopics, setRankTopics ] = useState<string[]>([]);
    const [ platform, setPlatform ] = useState<string>('');
    const [ topicList, setTopicList ] = useState<string[] | null>(null);
    useEffect(()=>{
        (async() => {
            let topics = await getTopicList();
            setTopicList(topics);
        })();
    },[])

    useEffect(() => {
        const similarTopics: string[] = Array.from(new Set(topicList)).filter( topic => topic.includes(searchText.toUpperCase() || searchText.toLowerCase()));
        setSimilarTopicList(similarTopics);

        let randomTopic = Object.entries(platformAndTopicRank)[Math.floor(Math.random() * Object.keys(platformAndTopicRank).length)]
        
        setPlatform(randomTopic[0]);
        setRankTopics(randomTopic[1]);
    },[searchText, topicList])

    return <div className={'EmptyResultArea'}>
        <div className={'EmptyResultMessage'}>
            <span >{searchText}</span>
            {'에 대한 검색 결과가 없습니다.'}
        </div>
        {similarTopicList.length>0
            ?<div className={'EmptyResultChartContainer'}>
                <div className={'ContainerTitle'}>
                    {'추천 토픽'}
                </div>
                <div className={'SimilarTopicItems'}>
                    {similarTopicList.map( keyword => 
                    <a className={'Topic'} href={`/market/trendsearch?topic=${keyword}`} onClick={fn.gotoByAnchor} key={keyword} >
                        {keyword}
                    </a>)}
                </div>
            </div>:<></>}
        <div className={'EmptyResultChartContainer'}>
            <div className={'PlatformTitle'} >
                {`${platformList[platform]}의 인기 토픽`}
            </div>
            <PopularMaterials {...{topics: rankTopics}} />
        </div>
    </div>
}

export default EmptyResult;