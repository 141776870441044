import { useEffect, useRef, useState } from 'react';
import { Button, Checkbox, Dialog, FormControlLabel } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import RadioGroup from '../Analysis/Components/RadioGroup';
import { currentTermsofuseVersion } from '../../Page/Analytics/AnalysisApplicationConstants';
import ChannelService from '../../Component/ChannelService';
import LoadingCircle from '../../Component/LoadingCircleAnimated';
import { GuideTextBoxForPairedVerification, GuideTextBoxForStandAloneVerification, validationStatusType } from '../../Component/TextBox';
import { useSession, useSignIn } from '../../Recoil/Session/Session';
import { useEpisodes } from '../../Recoil/Title/Episodes';
import { approvePayment, createPayment } from '../../Data/Payment';
import { verifyEmail, verifyOtp } from '../../Data/Email';
import { getProductInfo } from '../../Data/Product';
import { logBehavior, logPurchase } from '../../Data/Behavior';
import { fn, validateEmailForm } from '../../Functions';
import { useToastAlert } from '@webtoontoday/toast';
import "./Styles/ContentsPayment.scss";

const necessaryConsents = [
    '개인정보 수집 및 이용 동의',
    '결제대행 서비스 이용약관 동의'
];

const consentToUrl = {
    '개인정보 수집 및 이용 동의': 'https://webtoon.today/privacypolicy/kr',
    '결제대행 서비스 이용약관 동의': currentTermsofuseVersion,
};

const ContentsPayment = ({serviceId, titleId, episodeId}: {serviceId: string, titleId: string, episodeId?: string}) => {
    const { toastAlert } = useToastAlert();
    const { session, sessionRefresh } = useSession();
    const { logout, EmailTokenSignin, EmailSignupWithOtp } = useSignIn();
    const {episodeObject, refresh, fetch} = useEpisodes();

    const textBoxMontorTimer: React.MutableRefObject<ReturnType<typeof setTimeout> | null> = useRef(null);

    useEffect(()=>{
        fetch({serviceId,titleId});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[serviceId, titleId, session.memberState]);

    const [ orderInformations, setOrderInformations ] = useState<{orderId:string, amount:number}>({orderId: "", amount: 0});
    const [ isPopupClosed, setIsPopupClosed ] = useState(false);    

    const [ productAllInfo, setProductAllInfo ] = useState<{name: string, detail: string, image: string, amount: number, finalAmount: number}>({name: "", detail: "", image: "", amount: 0, finalAmount: 0});
    const [ productPerEPInfo, setProductPerEPInfo ] = useState<{name: string, detail: string, image: string, amount: number, finalAmount: number}>({name: "", detail: "", image: "", amount: 0, finalAmount: 0});

    const [selectedProduct, setSelectedProduct] = useState<'all'|'episode'>('all');

    const [ consents, setConsents ] = useState<string[]>([]);
    const [ modalContents, setModalContents ] = useState<string|null>(null);

    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ isBlockingScreen, setIsBlockingScreen ] = useState<boolean>(false);

    const [isEpisodeListOpen, setIsEpisodeListOpen] = useState<boolean>(false);

    const [email, setEmail] = useState<string>("");
    const [emailValidationStatus, setEmailValidationStatus] = useState<validationStatusType>('undone');

    const [ isVisibleoOtpForm, setIsVisibleOtpForm ] = useState(false);
    const [ otp, setOtp ] = useState('');
    const [ otpValidationStatus, setotpValidationStatus ] = useState<validationStatusType>('undone');

    useEffect(()=>{
        if (session.memberState === 'regular'){
            if (session.email === null){
                logout();
            }else{
                setEmail(session.email);
                setEmailValidationStatus('success');
                setotpValidationStatus('success');
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[session])

    useEffect(() => {
        if (!session.name){
            return;
        }
        (async () => {
            const result = await getProductInfo(`${serviceId}:${titleId}`);
            if(result){
                setProductAllInfo(result);
            }else{
                toastAlert('상품을 불러올 수 없습니다.', 5000, 'error');
                ChannelService.ChannelIO()('openChat');
            }
        })();
        (async () => {
            if (!episodeId){
                return;
            }
            const result = await getProductInfo(`${serviceId}:${titleId}:${episodeId}`);
            if(result){
                setProductPerEPInfo(result);
            }else{
                toastAlert('상품을 불러올 수 없습니다.', 5000, 'error');
                ChannelService.ChannelIO()('openChat');
            }
        })();
    },[episodeId, serviceId, titleId, session, toastAlert])

    const productInfo = selectedProduct === 'all' ? productAllInfo : productPerEPInfo;

    useEffect(() => {
        if (!isPopupClosed) { 
            return;
        }
        ( async () => {
                const res = await approvePayment(orderInformations);

                if (res && res.orderId === orderInformations.orderId && res.amount === orderInformations.amount) {
                    logBehavior('purchase-success-approve-tosspayment', res);
                    logPurchase({
                        transaction_id: res.orderId,
                        value: res.amount,
                        currency: "KRW",
                        items: [{
                            item_id: `${serviceId}:${titleId}`,
                            item_name: productInfo.name,
                            quantity: 1,
                        }]
                    })
                    setIsBlockingScreen(true);
                    setTimeout(()=>{
                        setIsBlockingScreen(false);
                        setIsLoading(true);
                    },2000);

                } else {
                    logBehavior('purchase-fail-approve-tosspayment', res)
                    toastAlert('결제 인증에 실패했습니다.', 5000, 'error');
                    ChannelService.ChannelIO()('openChat');
                }
            }
        )();
    }, [isPopupClosed, orderInformations, productInfo.name, serviceId, titleId, toastAlert]);
    
    useEffect(()=>{
        (async ()=>{
            let episodes = episodeObject[`${serviceId}:${titleId}`] || [];

            if (episodes.length === 0){
                return;
            }

            const purchaseIsNeeded = episodes.map(({isFree, purchase}:{isFree: boolean, purchase: boolean}) => !isFree && !purchase).reduce( (a:boolean,b:boolean) => a || b );

            if (! purchaseIsNeeded){
                const firstPaiedEpisodeId = episodes.filter(row => !row.isFree).sort((a,b) => Number(a.episodeId) - Number(b.episodeId))[0]?.episodeId || "4";

                fn.goto(`/contents/${serviceId}/${titleId}/${firstPaiedEpisodeId}`);
            }
        })();
    },[episodeObject, serviceId, titleId]);

    return (
        <div className={'ContentsPaymentArea'}>
            <div className={'ContentesPaymentInner'}>
                <div className={'OrderedProductList'}>
                    <div className={'Title'}>
                        {'주문 상품'}
                    </div>
                    <div className={'DividingLine'} style={{borderTop: '1px solid rgb(51,51,51)'}} />

                    <RadioGroup<'all'|'episode'>
                        style={{ width: '100%', marginBottom: 16 }}
                        options={episodeId?[
                            {value: 'all', label: <div className='LabelRow' >
                                <div className={'Image'}>
                                    <img src={productAllInfo.image} alt={productAllInfo.detail}/>
                                </div>
                                <div>
                                    <div className='ProductTitle'>{productAllInfo.detail}</div>
                                    <div>
                                        {`회차당 ₩${Math.floor(productAllInfo.finalAmount / (episodeObject[`${serviceId}:${titleId}`] || []).filter(row => !row.isFree).length)}`}
                                    </div>
                                    <div>
                                        {"구매일로부터 소장기간 1년"}
                                    </div>
                                </div>
                            </div>},
                            {value: 'episode', label: <div className='LabelRow' >
                                <div className={'Image'}>
                                    <img src={productPerEPInfo.image} alt={productPerEPInfo.detail}/>
                                </div>
                                <div>
                                    <div className='ProductTitle'>{productPerEPInfo.detail}</div>
                                    <div>
                                        {`회차당 ₩${productPerEPInfo.finalAmount}`}
                                    </div>
                                    <div>
                                        {"구매일로부터 소장기간 1년"}
                                    </div>
                                </div>
                            </div>},
                        ]:[ {value: 'all', label: <div className='LabelRow' >
                                <div className={'Image'}>
                                    <img src={productAllInfo.image} alt={productAllInfo.detail}/>
                                </div>
                                <div>
                                    <div className='ProductTitle'>{productAllInfo.detail}</div>
                                    <div>
                                        {`회차당 ₩${Math.floor(productAllInfo.finalAmount / (episodeObject[`${serviceId}:${titleId}`] || []).filter(row => !row.isFree).length)}`}
                                    </div>
                                    <div>
                                        {"구매일로부터 소장기간 1년"}
                                    </div>
                                </div>
                            </div>},
                        ]} value={selectedProduct}
                        onChange={ (newValue)=>{ setSelectedProduct(newValue) } }
                    />

                    {selectedProduct === 'all' && episodeObject[`${serviceId}:${titleId}`] && <div className={`EpisodeList ${isEpisodeListOpen?"":"Fold"}`}>
                        <div>
                            {episodeObject[`${serviceId}:${titleId}`].filter(row => !row.isFree && !row.purchase).map(row => <div key={row.episodeId} className={"EpisodeRow"}>
                                <img src={row.thumbnail} alt={row.title}/>
                                <div>
                                    <span>{row.title}</span><br/>
                                    <span className={"Date"}>{new Date(row.createdAt * 1000).toLocaleDateString("ko-KR")}</span>
                                </div>
                            </div>)}
                        </div>
                    </div>}
                    {selectedProduct === 'all' && <Button
                        className='FoldEpisodeListButton' fullWidth
                        variant='contained' disableElevation
                        onClick={()=>{
                            if (isEpisodeListOpen) {
                                setIsEpisodeListOpen(false);
                                logBehavior('purchase-fold-eplist', {})
                            }else{
                                setIsEpisodeListOpen(true);
                                logBehavior('purchase-unfold-eplist', {})
                            }
                        }}>
                        {isEpisodeListOpen
                            ?`접기▲`
                            :`결제 대상 확인하기▼`
                        }
                    </Button>}
                    <div className={'OrderedServicePrices'}>
                        <div className={'Title'}>
                            {'주문 금액'}
                        </div>
                        <div className={'DividingLine'} />
                        <div className={'PriceRow'}>
                            <span>{'상품 금액'}</span>
                            <span>{`${productInfo.amount.toLocaleString()}원`}</span>
                        </div>
                        <div className={'InnerDividingLine'} />
                        <div className={'PriceRow'}>
                            <span>{'할인 금액'}</span>
                            <span className={'Discounts'} style={{textDecoration:"line-through"}}>
                                {`${(productInfo.amount-productInfo.finalAmount).toLocaleString()}원`}
                                <span className={'DiscountPercent'}>
                                    {`${Math.floor((productInfo.amount-productInfo.finalAmount) / productInfo.amount * 100)}% 할인`}
                                </span>
                            </span>
                        </div>
                        <div className={'InnerDividingLine'} />
                        <div className={'PriceRow'}>
                            <span>{'결제 금액'}</span>
                            <span style={{color: 'rgb(0,176,240)'}}>{`${productInfo.finalAmount.toLocaleString()}원`}</span>
                        </div>
                        <div className={'CheckAndConsentOfOrder'}>
                            <div className={'Title'}>
                            <FormControlLabel 
                                labelPlacement='start'
                                control={
                                    <Checkbox 
                                        style={{padding: 0, color: 'rgb(51,51,51)'}}
                                        checked={JSON.stringify(necessaryConsents.sort()) === JSON.stringify(consents.sort())}
                                        onChange={ (e) => {
                                            if (e.target.checked) {
                                                logBehavior('purchase-approve-agreement', {})
                                                setConsents(necessaryConsents);
                                            } else {
                                                setConsents([]);
                                            }
                                        }}
                                    />
                                }
                                label={'주문 내용 확인 및 동의　'}
                                />  
                            </div>
                            <div className={'DividingLine'} />
                            {(necessaryConsents as ('개인정보 수집 및 이용 동의'|'결제대행 서비스 이용약관 동의')[]).map(consent => (
                                <div key={consent}  style={{display: 'flex', justifyContent: 'space-between', margin: '10px 0', color: 'rgb(117, 117, 117)', fontSize: '0.75rem'}}>
                                    <div 
                                        style={{
                                            display: 'flex', alignItems: 'center', cursor:'pointer',
                                            color: `${consents.indexOf(consent) >= 0?'rgb(0,176,240)':''}`
                                        }}
                                        onClick={ (e) => {
                                            if ( consents.indexOf(consent) >= 0 ) {
                                                setConsents(consents.filter( ele => ele !== consent ))
                                            } else {
                                                setConsents(consents.concat(consent))
                                            }
                                        }}
                                    >
                                        <Check 
                                            fontSize={'small'} 
                                            style={{
                                                marginRight: 15,
                                            }} 
                                        />
                                        <div>
                                            {`[필수] ${consent}`}
                                        </div>
                                    </div>
                                    <div className={'DetailsOfTerms'} onClick={ () => setModalContents(consentToUrl[consent]) }>
                                        {'자세히'}
                                    </div>
                                    <Dialog 
                                        PaperProps={{ style: {
                                            width: '100%', height: '100%'
                                        }}}
                                        open={Boolean(modalContents)} onClose={ () => setModalContents(null) } 
                                    >
                                        <div style={{display: 'flex', flexDirection: 'column', width: '100%',height:'100%'}}>
                                            <iframe 
                                                src={modalContents||""} width='100%'  height='100%' title={modalContents||""}
                                                style={{border: 0}}
                                            ></iframe>
                                            <div 
                                                style={{
                                                    display: 'flex', justifyContent: 'flex-end', backgroundColor: 'transparent',
                                                    padding: '5px 30px'
                                                }}
                                            >
                                                <Button
                                                    disableElevation variant={'contained'} color={'primary'}
                                                    onClick={() => setModalContents(null)}
                                                    size={'small'}
                                                >
                                                    {'확인'}
                                                </Button>
                                            </div>
                                        </div>
                                    </Dialog>
                                </div>
                            ))}
                        </div>
                        
                        <div className={'Title'}>
                            {'주문자 이메일 정보'}
                        </div>
                        <div className={'DividingLine'} />

                        <GuideTextBoxForPairedVerification
                            purpose={'send'}
                            text={email}
                            onChange={(event: any) => {
                                if (textBoxMontorTimer.current) {
                                    clearTimeout(textBoxMontorTimer.current);
                                }
                                textBoxMontorTimer.current = setTimeout(() => {
                                    logBehavior('purchase-enter-email', {});
                                }, 300);

                                setEmail(event.target.value);
                            }}
                            placeholder={''}
                            isDisabled={emailValidationStatus === 'success'}
                            guideTexts={{
                                initial: "",
                                normal: '',
                                success: '인증되었어요.',
                                fail: '',
                                required: '',
                            }}
                            validationStatus={emailValidationStatus}
                            secondStepValidationStatus={otpValidationStatus}
                            onClick={async () => {
                                if (!validateEmailForm(email)) {
                                    logBehavior('purchase-invalid-email', {});
                                    setEmailValidationStatus('fail');
                                    return;
                                }

                                setIsBlockingScreen(true);

                                const verifyEmailRes = await verifyEmail(email);
                                logBehavior('verify-email', {});

                                if (verifyEmailRes) {
                                    setIsVisibleOtpForm(true);

                                    logBehavior('verify-email(success)', {});
                            } else {
                                    toastAlert('이메일에 문제가 있어요.', 3000, 'error');
                                    logBehavior('verify-email(fail)', {});
                                    setEmailValidationStatus('fail');
                                }
                                setIsBlockingScreen(false);
                            }}                     
                        />
                        { isVisibleoOtpForm && <GuideTextBoxForStandAloneVerification
                            purpose={'verification'}
                            text={otp}
                            onChange={(event : any) =>{
                                if (textBoxMontorTimer.current){
                                    clearTimeout(textBoxMontorTimer.current);
                                }
                                textBoxMontorTimer.current = setTimeout(()=>{}, 300);
                            
                                setOtp(event.target.value);
                            }}
                            placeholder={''}
                            guideTexts={{
                                initial: '',
                                normal: '',
                                success: '',
                                fail: 'otp 인증에 실패했어요.',
                                required: '',
                            }}
                            validationStatus={otpValidationStatus}
                            onClick={async ()=>{
                                setIsBlockingScreen(true);
                                const emailTokenSigninRes = await EmailTokenSignin(email, otp);
                                
                                if ( emailTokenSigninRes.email ) {
                                    await sessionRefresh();
                                    
                                    logBehavior('email-token-login(success)', {});
                                    setotpValidationStatus('success');
                                    setEmailValidationStatus('success');
                                    setIsVisibleOtpForm(false);                                    
                                } else {
                                    logBehavior('email-token-login(fail)', {});
                                    
                                    const verifyOtpRes = await verifyOtp(email, otp);
                                    
                                    if ( verifyOtpRes ) {
                                        const name = email.split('@')[0];
                                        const emailSignupRes = await EmailSignupWithOtp(email, otp, name);

                                        if ( emailSignupRes ) {
                                            await sessionRefresh();
        
                                            logBehavior('email-signup-with-otp(success)', {});
                                            setotpValidationStatus('success');
                                            setEmailValidationStatus('success');
                                            setIsVisibleOtpForm(false);        
                                        } else {
                                            toastAlert('otp 인증에 실패했어요.', 3000, 'error');
                                            logBehavior('email-signup-with-otp(fail)', {});
                                            setEmailValidationStatus('undone');
                                            setotpValidationStatus('fail');                                
                                        }
                                    } else {
                                        toastAlert('otp 인증에 실패했어요.', 3000, 'error');
                                        logBehavior('verify-otp(fail)', {});
                                        setEmailValidationStatus('undone');
                                        setotpValidationStatus('fail');                                
                                    }

                                }

                                setIsBlockingScreen(false);
                            }}
                        />}
                        <div className={'ButtonBox'}>
                            <Button 
                                className={'PayButton'} fullWidth variant={'contained'} color={'primary'}
                                disabled={(
                                    JSON.stringify(necessaryConsents.sort()) !== JSON.stringify(consents.sort())
                                    || emailValidationStatus !== 'success'
                                )}
                                onClick={ async () => {
                                    logBehavior('purchase-tosspayment-start', {})
                                    let popup = window.open(undefined, "팝업창", "width = 1000, height = 800, top = 50, left = 50, location = no");
                                    if (popup?.document?.body){
                                        popup.document.body.innerHTML = "토스 페이먼츠를 로딩중입니다..";
                                    }

                                    const orderId = (session.userid || "") + Math.round(new Date().getTime()/1000);
                                    const res = await createPayment({
                                        method: '카드', amount: productInfo.finalAmount, orderId, 
                                        orderName: `${productInfo.name}: ${productInfo.detail}`, 
                                        orderDetail: [{productId: selectedProduct === 'episode'?`${serviceId}:${titleId}:${episodeId}`:`${serviceId}:${titleId}`, quantity: 1, totalAmount: productInfo.finalAmount}] });
                                    
                                    if (popup && res) {
                                        popup.location.href = res;
                                        setOrderInformations({amount: productInfo.finalAmount, orderId});

                                        let timer = setInterval( () => {
                                            if (popup?.closed) {
                                                logBehavior('purchase-popup-closed', {})
                                                clearInterval(timer);
                                                setIsPopupClosed(true);
                                            }
                                        })

                                    } else {
                                        logBehavior('purchase-fail-tosspayment', res)
                                        if(popup){
                                            popup.close();
                                        }   
                                        toastAlert('결제가 실패했어요.', 5000, 'error');
                                        ChannelService.ChannelIO()('openChat');
                                    }
                                }}
                            >
                                {'결제하기'}
                            </Button>
                        </div>
                        <Dialog open={isLoading} onClose={()=>setIsLoading(false)}>
                            <div style={{display: 'flex', flexDirection: 'column', minWidth: 300, fontFamily: 'inherit'}}>
                                <div style={{ padding: '15px 20px'}} >
                                    <div>
                                        {"결제가 완료되었어요! 작품을 감상하러 가실래요?"}
                                    </div>
                                </div>
                                <div style={{padding: '15px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                    <Button
                                        onClick={()=>{
                                            refresh({serviceId, titleId});
                                            fn.goto(`/contents/${serviceId}/${titleId}`)
                                            setIsLoading(false);
                                        }} 
                                        style={{  color: 'rgb(51,51,51)', padding: '7px 20px'}}>
                                        {'작품 홈으로'}
                                    </Button>
                                    <Button
                                        onClick={()=>{
                                            refresh({serviceId, titleId});

                                            if (!episodeId){
                                                const episodes = episodeObject[`${serviceId}:${titleId}`] || [];
                                                const firstPaiedEpisodeId = episodes.filter(row => !row.isFree).sort((a,b) => Number(a.episodeId) - Number(b.episodeId))[0]?.episodeId || "4";
                                                fn.goto(`/contents/${serviceId}/${titleId}/${firstPaiedEpisodeId}`);
                                            }else {
                                                fn.goto(`/contents/${serviceId}/${titleId}/${episodeId}`);
                                            }
                                            setIsLoading(false);
                                        }} 
                                        style={{ backgroundColor: 'rgb(0,176,240)', color: 'rgb(255,255,255)', padding: '7px 20px'}}>
                                        {'작품 감상하기'}
                                    </Button>
                                </div>
                            </div>
                        </Dialog>
                    </div>
                </div>
            </div>
            <LoadingCircle show={isBlockingScreen}/>
        </div>
    )
}

export default ContentsPayment;