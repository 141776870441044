import React from 'react';
import './NoticeWrite.scss';
import { createImageWithS3 } from '../../Data/Image';
import 'react-quill/dist/quill.snow.css';
import { Button, MenuItem, Select, TextField } from '@material-ui/core';
import { fn } from '../../Functions';
import { createPost, readPost, updatePost } from '../../Data/Post';
import LoadingCircle from '../../Component/LoadingCircle';
import moment from 'moment-timezone';
import { useSession } from '../../Recoil/Session/Session';

const ReactQuill = (global.Node && global.HTMLElement) ? require('react-quill') : () => false;
const Quill = ReactQuill.Quill;

let Block, Image;
if (Quill){
    const ResizeModule = require('@botom/quill-resize-module');
    Block = Quill.import('blots/block');

    Block.tagName = 'div';
    Quill.register(Block)

    Quill.register('modules/resize', ResizeModule);

    Image = Quill.import('formats/image');
    Image.className = 'InsertImages';
    Quill.register(Image,true)
}

const NoticeWrite = ({updatePostId}: {updatePostId: number}) => {
    const { isManager } = useSession();

    //let queryString = window.location.search.replace('?','');
    //let queries = Object.fromEntries(queryString.split('&').map(pair => pair.split("=").map(uri => decodeURIComponent(uri))))
    
    const [title, setTitle] = React.useState<string>('');
    const [type, setType] = React.useState<string>('notice');
    const [body, setBody] = React.useState<string>('');
    const [exposure, setExposure] = React.useState<string>('public');

    const [significantFrom, setSignificantFrom] = React.useState<number>(0);
    const [significantTo, setSignificantTo] = React.useState<number>(0);

    const [managerAuthority, setManagerAuthority] = React.useState<boolean>(false);
    
    React.useEffect (()=>{

        (async()=>{
            if (updatePostId) {
    
                const result = await readPost(updatePostId);
                const post = result[0];

                if (result && result.length === 1) {
                    setTitle(post.title);
                    setBody(post.body);
                    setType(post.type);
                    setExposure(post.exposure);
                    setSignificantFrom(post.significantFrom * 1000);
                    setSignificantTo(post.significantTo * 1000);
                } else {
                    alert('실패했습니다');
                }
            }

            const authority = await isManager();
            if( authority ){
                setManagerAuthority(true);
            }
            
        })()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    
    const [disabled, setDisabled] = React.useState(false);

    const quillRef = React.useRef();
    
    const imageHandler = () => {
        const input = document.createElement('input');
        input.setAttribute('type','file');
        input.setAttribute('accept','image/*');
        input.click();
    
        input.addEventListener('change',async(e)=>{
            let fileUrl = await createImageWithS3({name: input.files![0].name, files: [input.files![0]]});
            
            const editor = (quillRef.current as any).getEditor();
            const range = editor.getSelection();
            editor.insertEmbed(range.index,'image',fileUrl)
            editor.setSelection(range.index + 1)
        })
    }
    
    const modules = React.useMemo(()=>{
        return {
            toolbar: {
                container: [
                    ['bold', 'italic', 'underline', 'strike'], 
                    ['blockquote', 'code-block'],
                  
                    [{ 'header': 1 }, { 'header': 2 }],
                    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                    [{ 'script': 'sub'}, { 'script': 'super' }],
                    [{ 'indent': '-1'}, { 'indent': '+1' }], 
                    [{ 'direction': 'rtl' }],
                  
                    [{ 'size': ['small', false, 'large', 'huge'] }], 
                    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                  
                    [{ 'color': [] }, { 'background': [] }],
                    [{ 'font': [] }],
                    [{ 'align': [] }],

                    ['link','image'],
                  
                    ['clean']
                ],
                handlers:{
                image: imageHandler
                },
            },
            resize: {
                toolbar: {
                    alingTools: true,
                },
                locale: {
                    altTip: "Hold down the alt key to zoom",
                    floatLeft: "Left",
                    floatRight: "Right",
                    center: "Center",
                    restore: "Restore",
                }
            }
        }
    },[])


    const postSubmit = async () => {
        setDisabled(true);
        
        if (updatePostId) {
            
            const FormJson = { 
                postId: updatePostId, type, exposure, title, body,
                significantFrom: significantFrom === 0 ? null : significantFrom / 1000,
                significantTo: significantTo === 0 ? null : significantTo / 1000,
            }
            const resultKey = await updatePost(FormJson);

            if (resultKey) {
                fn.goto(`/notice/${updatePostId}`)
            } else {
                alert('수정 요청이 실패했습니다.')
            }

        } else if (!updatePostId) {
            
            const FormJson = { 
                type, exposure, title, body, 
                significantFrom: significantFrom === 0 ? null : significantFrom / 1000,
                significantTo: significantTo === 0 ? null : significantTo / 1000,
            }
            
            const resultKey = await createPost(FormJson);
            
            if (resultKey){
                fn.goto('/notice')
                alert('게시글을 추가하였습니다')
            } else {
                alert('게시글을 추가하지 못했습니다.')
            }
        }

        setDisabled(false);
    }
        
    return (
        <div className={'NoticeWrite'}>
            <div className={'WriteContainer'}>
                <div className={'WriteInner'}>
                    <div className={'Title'}>
                        {'공지사항'}
                    </div>
                    <div className={'WriteTitle'}>
                            {'공지글 작성하기'}
                    </div>
                    <div className={'Form'}>
                        <div className={'FormRow'}>
                            <label className={"Attribution"}>{"제목"}
                            </label>
                            <TextField className={'TextField'} placeholder={''} value={title}
                                onChange={ (event) => setTitle(event.target.value)} 
                            />
                        </div>
                        <div className={'FormRow'}>
                            <label className={"Attribution"}>{"타입"}
                            </label>
                            <Select
                                className={'SelectField'}
                                variant={'outlined'}
                                value={type}
                                onChange={ (e) => setType(e.target.value as 'notice') }
                            >
                                <MenuItem value={'notice'}>{'공지'}</MenuItem>
                            </Select>
                        </div>
                        <div className={'FormRow'}>
                            <label className={"Attribution"}>{"공개여부"}
                            </label>
                            <Select
                                className={'SelectField'}
                                variant={'outlined'}
                                value={exposure}
                                onChange={ (e) => setExposure(e.target.value as 'public' | 'private') }
                            >
                                <MenuItem value={'public'}>{'공개'}</MenuItem>
                                <MenuItem value={'private'}>{'비공개'}</MenuItem>
                            </Select>
                        </div>
                        <div className={'FormRow'}>
                            <label className={'Attribution'}>{'공지기간'}</label>
                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                <form noValidate>
                                    <TextField
                                        id="datetime-local"
                                        type="datetime-local"
                                        value={moment(significantFrom).format('YYYY-MM-DDTHH:mm')}
                                        onChange={(e)=>setSignificantFrom(Date.parse(e.target.value))}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </form>
                                <div style={{display: 'flex', alignItems: 'center', padding: '0 10px', fontSize: '1.3rem', fontWeight: 'bold'}}>{'~'}</div>
                                <form noValidate>
                                    <TextField
                                        id="datetime-local"
                                        type="datetime-local"
                                        value={moment(significantTo).format('YYYY-MM-DDTHH:mm')}
                                        onChange={(e)=> setSignificantTo(Date.parse(e.target.value))}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </form>
                            </div>
                        </div>
                        <div className={'DescriptionContainer'}>
                            <ReactQuill
                                ref={quillRef}
                                theme={"snow"}
                                placeholder={"본문을 입력해 주세요."}
                                value={body}
                                onChange={setBody}
                                modules={modules}
                            />
                        </div>
                    </div>
                    <div className={'ButtonArea'}>
                        <Button variant={'contained'} color={'primary'} 
                            disabled={!body || !title}
                            onClick={()=> {
                                setDisabled(true)
                                postSubmit()
                                setDisabled(false)
                            }}
                        >
                            {!updatePostId?'등록':'수정'}</Button>
                        <Button variant={'contained'} color={'primary'} 
                            onClick={()=>{
                                if (managerAuthority){
                                    if (updatePostId) {
                                        fn.goto(`/notice/${updatePostId}`)
                                    } else {
                                        fn.goto('/notice')
                                    }
                                } else {
                                    alert('권한이 없습니다.')
                                }
                            }}>
                            {'취소'}
                        </Button>
                    </div>
                </div>
            </div>
            <LoadingCircle show={disabled} />
        </div>
    );
}

export default NoticeWrite;