
import { fn } from '../../../Functions';
import { keyToPlatformIcon, proxy } from '../../../Page/MarketAnalysis/MarketAnalysisFunctions';
import './Styles//PlatformRepresentatives.scss';


const PlatformRepresentatives = ({ 
    topic: searchedTopic, data
}:{
    topic: string,
    data: {
        platform: string,
        thumbnail: string,
        title: string,
        description: string,
        topic: string[],
        href: string
    }[]
}) => {

    return <div className={'PlatformRepresentativesArea'}>
        <div className={'Title'} >
            {'플랫폼 간판 '}
            <span className={'SearchText'} >{searchedTopic}</span>
            {' 웹툰'}
        </div>
        <div className={'RepresentativeWebtoons'}>
            {data.map(({thumbnail, title, topic: titleTopics, description, platform, href}) => 
            <div key={href} className={'WebtoonRow'}>
                <a className={'VisibleInfomation'} onClick={fn.gotoByAnchor} href={href} >
                    <div className={'Thumbnail'}>
                        <img src={proxy(thumbnail.replace('jpg;','jpg'), 200)}
                            alt={'Thumbnail'} style={{width: 100, height: 100, objectFit: 'cover', objectPosition: '50% 0%', borderRadius: '4px', border: '1px solid rgb(207, 207, 207)'}}
                            onError={(event: any)=>{
                                if (event.target && typeof event.target.getAttribute === 'function'
                                && event.target.getAttribute('src') === proxy(thumbnail.replace('jpg;','jpg'), 200) ){
                                    event.target.setAttribute('src', thumbnail);
                                }
                            }}
                        />
                        <img src={keyToPlatformIcon(platform)} alt={'platformIcon'}
                            style={{width: '25%', position: 'absolute', left: 0, top: 0, borderRadius: '3px 0 3px 0', border: '1px solid rgb(207,207,207)', borderWidth: '1px 0 0 1px'}} />
                    </div>
                    <div className={'WebtoonInformations'} >
                        <div className={'TitleInfo'} >
                            {title}
                        </div>
                        <div className={'DescriptionInfo'}>
                            {description}
                        </div>
                    </div>
                </a>
                <div className={'KeywordsInfomation'}>
                    {[
                        searchedTopic, 
                        ...titleTopics.filter( titleTopic => titleTopic !== searchedTopic ).slice(0,4)
                    ].map( topic => 
                        <a className={'Keyword'} href={`/market/trendsearch?topic=${topic}`} key={topic} onClick={ (e) => {
                            if ( searchedTopic === topic ) {
                                e.preventDefault();
                                window.scrollTo({top: 0})
                            }
                            fn.gotoByAnchor(e);
                        }} >
                            {topic}
                        </a>)}
                </div>
            </div>)}
        </div>
    </div>
}

export default PlatformRepresentatives;