import React from 'react'
import { logBehavior } from '../Data/Behavior';
import './Share.scss'
import { useToastAlert } from '@webtoontoday/toast';

const toastShareComment = 
    <div style={{fontSize: '1rem', color:'rgb(255,255,255)', textAlign: 'center', lineHeight: '20px'}} >
        <div>
            <span style={{color: 'rgb(0,176,240)'}}>{'링크'}</span>{`를 `}<span style={{color: 'rgb(0,176,240)'}}>{'복사'}</span>{'했어요.'}
        </div>
        <div>
            {'공유하고 싶은 곳에 붙여 넣어 주세요.'}
        </div>
    </div>

const Share = ({ title = '', contents = <></>, pcStyle = {}, mobileStyle = {}, givenUrl: givenPath = "", log = {what: '', detail: {}} }) => {
    const { toastAlert } = useToastAlert();

    const handleSharing = async () => {
        try{
            await navigator.share({
                title: `${title}`,
                url: window.location.protocol+"//"+window.location.host+(givenPath || window.location.pathname)
            });

            if (log.what) {
                logBehavior(log.what, log.detail);
            };
        } catch (error) {
            if (!error.includes("AbortError")){
                alert(`Error: ${error}`);
            };
        };
    };

    const handleCopying = async (e) => {
        if (log.what) {
            logBehavior(log.what, log.detail);
        };
        
        const url = window.location.protocol+"//"+window.location.host+(givenPath || window.location.pathname);

        const $textarea = document.createElement("textarea");

        document.body.appendChild($textarea);
        
        $textarea.value = url;
        $textarea.select();
        
        document.execCommand('copy');
        document.body.removeChild($textarea);

        toastAlert(toastShareComment, 2000)
    }

    return (
        <div className={'ShareArea'} >
            <div className={'PCShare'} style={{...pcStyle}} onClick={handleCopying}>
                {contents}
            </div>
            <div className={'MobileShare'} style={{...mobileStyle}} onClick={navigator.share?handleSharing:handleCopying}>
                {contents}
            </div>
        </div>
    )
};

export default Share;