import { Favorite } from '@material-ui/icons';
import React from 'react';
import './Styles/SearchResult.scss';
import moment from 'moment';
import { fn } from '../../../Functions';
import LoadingCircle from '../../../Component/LoadingCircle';


const SearchResult = ({
    titles, isLoading
}:{
    titles: (TitleType & TitleAugmentedData)[], isLoading: boolean
}) => {
    
    return (
        <div className={'SearchResultMainArea'}>
            {titles.length > 0 ?
                titles.map(title => (
                    <div
                        className={'SearchResult'} key={`SearchResult:${title.serviceId}:${title.titleId}`}
                        onClick={()=>fn.goto(`/contents/${title.serviceId}/${title.titleId}`)}
                    >
                        <div className={'ThumbnailImageBox'} >
                            <img 
                                src={title.thumbnail} alt={'thumnailImage'} 
                                style={{width: 90, height: 90}} 
                            />
                        </div>
                        <div className={'ResultContainer'}>
                            <div className={'Body'}>
                                <div className={'Title'}>
                                    {title.title}
                                </div>
                                <div className={'Description'}>
                                    {title.description}
                                </div>
                            </div>
                            <div className={'SearchResultFooter'} >
                                <span className={'Favorites'} >
                                    <Favorite className={'Icon'} />
                                    <span>{title.subscriptionCount || 0}</span>
                                </span>
                                <span className={'DividingLine'} >{'l'}</span>
                                <span>
                                    {moment(title.lastEpisodeUpdatedAt * 1000).format("YY.MM.DD")}
                                </span>
                                <span className={'DividingLine'} >{'l'}</span>
                                <span>
                                    {title.name || ''}
                                </span>
                            </div>
                        </div>
                    </div>
                ))
            :!isLoading
                ?<div className={'NoSearchResultBox'} >
                    <img 
                        src={'https://static.webtoon.today/ddah/logo-not-found.png'} 
                        style={{width: 44, height: 44, objectFit: 'contain'}}
                        alt={'NotFoundImage'} 
                    />
                    <div className={'ResultText'} >
                        {'검색 결과가 없어요.'}
                    </div>
                    <div className={'SuggestionText'} >
                        {'다른 단어로 검색해보시겠어요?'}
                    </div>
                </div>
                :<></>
            }
            <LoadingCircle show={isLoading} />
        </div>
    )
}

export default SearchResult;