
import { ParentSize } from '@visx/responsive';
import BarChart from './BarChart';
import { quarterDataKeyExtractor } from '../Util/Functions';
import './Styles/PlatformSerializationTrend.scss';

const keyToLabel: {[Key: string]: string} = {
    naver: '네이버',
    series: '네이버시리즈',
    lezhin: '레진',
    ridi: '리디북스',
    bomtoon: '봄툰',
    daum: '카카오웹툰',
    kakao: '카카오페이지',
    comico: '코미코',
    toomics: '투믹스',
    toptoon: '탑툰',
};


const PlatformSerializationTrend = ({ 
    topic, data
}:{
    topic: string
    data: {
        [platform: string] : {
            [date: string]: number
        }
    }
}) => {
    
    const [ platformKey, platformData ] = Object.entries(data)[0]

    const latestQuartersArray: string[] = quarterDataKeyExtractor(Object.keys(platformData).sort().reverse()[0] + 'q', 4 * 3 + 1)
    
    const BarData: {name: string, matchCount: number}[] = []

    for ( const quarter of latestQuartersArray ) {
        const key = quarter.replace(/q/g, '');
        BarData.push({
            name: quarter,
            matchCount: platformData[key] || 0
        })
    }

    BarData.sort().reverse()
    
    return <div className={'PlatformSerializationTrendArea'}>
        <div className={'Title'} >
            {keyToLabel[platformKey] + ' '}
            <span className={'SearchText'} >{topic}</span>
            {' 웹툰 연재 추이'}
        </div>
        <div className={'ChartBox'} >
            <ParentSize>
                {({width, height}: {width: number, height: number})  => 
                    <BarChart {...{width, height, data: BarData}} />}
            </ParentSize>
        </div>
    </div>
}

export default PlatformSerializationTrend;