import React from 'react';
import { fn } from '../../../Functions';

import "./Styles/Tabs.scss"

const tag2eng: Map<string, string> = new Map([
    ['추천 작품','global'],
    ['일상','daily'],
    ['스릴러','thriller'],
    ['무협/사극','history'],
    ['드라마','drama'],
    ['개그','gag'],
    ['판타지','fantasy'],
    ['스포츠','sport'],
    ['액션','action'],
    ['감성','emotional'],
    ['로맨스','romance']
]);

const tagsArray: string[] = Array.from(tag2eng.keys());

const Tab = ({tabName, isSelected}: {tabName: string, isSelected: boolean}) => {

    let engTabName = tag2eng.get(tabName);

    return (
        <div className={`Tab ${isSelected?`Selected`:``}`} onClick={(e)=>{
            fn.goto(`/?tab=${engTabName}`)
        }}>
            {tabName}
        </div>
    );

}


const Tabs = ({selectedTab, handleRefresh, className = ""}: {
    selectedTab: string, handleRefresh: Function, className?: string
}) => {
    return (
        <div className={`Tabs ${className}`}>
            {tagsArray.length>0?tagsArray.map(tag => <Tab key={tag} tabName={tag} isSelected={selectedTab === tag2eng.get(tag)} />):<></>}
            <div className={'RefreshButton'} onClick={() => handleRefresh()}>
                <img src={'https://static.webtoon.today/ddah/refresh.svg'} alt='refresh'/>
            </div>
        </div>
    );
};

export default Tabs;