import React from 'react';
import './Header.scss';
import { fn } from '../../../../Functions';

const Header = () => {
    return (
        <div className={'Univ2023Header'}>
            <div className={'HeaderContentContainer'}>
                <a className={'WebtoonTodayLogo'} href={'/'} onClick={fn.gotoByAnchor} >
                    <img src={'https://static.webtoon.today/ddah/logo-03.png'} alt={'logo'} style={{height: 24, objectFit: 'contain'}} />
                </a>
            </div>
        </div>
    );
};

export default Header;