
import { Close } from '@material-ui/icons';
import './Styles/Dropdown.scss';
import { useState, useEffect, useRef,  } from 'react';
import TextCheckBox from '../../../Component/TextCheckBox';

const Dropdown = ({ 
    itemList, itemFullWidth, value, onClick
}:{
    itemList: React.ReactNode[], itemFullWidth?: boolean,
    value: React.ReactNode[], onClick: (item: React.ReactNode) => void
}) => {
    const [ isDropdownOpen, setIsDropdownOpen ] = useState<boolean>(false);
    
    const dropDownRef = useRef<HTMLDivElement>(null);

    useEffect( () => {
        const clickHandler = (e: MouseEvent) => {
            if ( (dropDownRef.current && !(dropDownRef.current).contains(e.target as Node)) ) {
                setIsDropdownOpen(false);
            }
        }

        document.addEventListener('mousedown', clickHandler);
        return () => {
            document.removeEventListener('mousedown', clickHandler);
        }
    }, [])

    useEffect( () => {
        if (value.length === 0){
            setIsDropdownOpen(false);
        }
    },[value])
    
    return(<div className={`BasicDropdownContainer ${isDropdownOpen?'Focused':''}`} id={itemList.join(',')} ref={dropDownRef} >
        <div className={'DropdownBox'} onClick={ () => setIsDropdownOpen(!isDropdownOpen) } >
            <div className={'DropdownRow'}>{value.length < 1
                ?'장르를 선택해주세요.'
                :value.map( (genre,idx) => (<div className={'SelectedBox'} key={`selected:${genre}:${idx}`} >
                    <div className={'SelectedItems'} > {genre} </div>
                    <Close className={'DeleteTagButton'} onClick={ (e) => {
                        e.stopPropagation();
                        onClick(`${genre}`);
                    }}  />
                </div>))}
            </div>
            <img src={`https://static.webtoon.today/ddah/icon/icon_shevronRight.svg`} className={`DropdownButton ${isDropdownOpen?'Open':'Close'}`} width={20} height={20} alt={'icon_shevronRight'} />
        </div>
        <div className={`DropdownContent ${isDropdownOpen?"Open":"Close"}`} >
            {itemList.map( item => (
                <div 
                    className={'DropdownItemBox'}
                    key={`DropdownItem:${item}`}
                    onClick={ () => onClick(`${item}`) }
                >
                    <TextCheckBox
                        className={`DropdownItem ${itemFullWidth?'Row':'Item'} ${value.includes(`${item}`)?'Selected':""}`}
                        text={`${item}`}
                        isChecked={value.includes(`${item}`)}
                        onChange={ () =>  onClick(`${item}`) }
                        color={'rgba(61, 106, 255, 1)'}
                        style={{
                            justifyContent: 'flex-end',
                            flexDirection: 'row-reverse',
                            gap: 14, width: '100%', pointerEvents: 'none',
                            height: 'auto'
                        }}
                        key={`TextCheckbox:${item}`}
                    />
                </div>
                ))}
        </div>
    </div>)
}

export default Dropdown;