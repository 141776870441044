import React from 'react'
import { AddPhotoAlternate, Add, Delete, Loop} from '@material-ui/icons';
import { randomString } from '../Functions';
import { createImageWithS3, getThumbnail } from '../Data/Image';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@material-ui/core';

import './DragAndDropForAds.scss';
import LoadingCircle from './LoadingCircle';
import { logBehavior } from '../Data/Behavior';


const DragAndDrop = ({file, setFile, setIsImagesLoading, isEditable, placeholder, disabled = false, maximum}) => {

    const [isDragging, setIsDragging] = React.useState(false);

    const [uniqueId, ] = React.useState(randomString(32));
    
    const [cover, setCover] = React.useState(false);
    const [collapse, setCollapse] = React.useState(null);
    const [loadingImages, setLoadingImages] = React.useState([])

    const [currentCursorIndex, setCurrentCursorIndex] = React.useState(null);
    const [insertIndex, setInsertIndex] = React.useState(null);
    const [isDropped, setIsDropped] = React.useState(false);

    const [isMaximumLengthMessage, setIsMaximumLengthMessage] = React.useState(false);

    const disableBoolean = disabled

    const [ isWarningDialogue, setIsWarningDialogue ] = React.useState(false);
    
    const [ warningMessage, setWarningMessage ] = React.useState(null);

    const [ orgFile, setOrgFile ] = React.useState([]);
    const [ isCanceled, setIsCanceled ] = React.useState(false);

    const handleUploadFiles = async (files) => {
        setIsFileLoading(true);
        
        let newOrg = []
        
        for (let ele of file ) {
            newOrg.push(ele);
        }
        setOrgFile([...newOrg]);
        
        for (let element of files) {
            
            if (element.type in ['image/jpeg', 'image/png']) {
                setIsFileLoading(false);
                setIsWarningDialogue(true);
                setWarningMessage('JPG나 PNG파일을 올려주세요');
                return;
            }
            
            if (element.size > 5048576) {
                setIsFileLoading(false);
                setIsWarningDialogue(true);
                setWarningMessage('파일의 크기를 5MB 이하로 올려주세요.')
                return;
            }
        }
        
        if (files.length + file.length > maximum) {

            setIsMaximumLengthMessage(true);

        } else {
            let temporaryFile
            let filesLength = files.length;
            let newFile = [...file]

            for (let i = 0; i < filesLength; i++) {

                temporaryFile = files[i];
                let name = temporaryFile.name;
                let reader = new FileReader();

                reader.onload = () => {

                    newFile.push({
                        name,
                        image: reader.result,
                        createdAt: Math.round(new Date().getTime() / 1000)
                    });
                    setFile([...newFile]);
                }
                reader.readAsDataURL(temporaryFile);
            }
            createServerImage(files);
        }
    }
    
    const createServerImage = async (targets) => {

        setIsImagesLoading(true);
        let updatedNewFile = [...file];
        let waitingImages = [];
        let cutAndImage = [];

        const uploadFiles = async () => {
            for (const {target, needCut} of cutAndImage) {
                try {
    
                    let filename = await createImageWithS3({name: target.name, files:[target]});
                    let urlArray = [];
                    if (needCut) {
                        let resizedFileArray = await getThumbnail([filename], 600);

                        if ( !resizedFileArray[0] ) {
                            setIsCanceled(true);
                            return;
                        };

                        urlArray.push(resizedFileArray[0]);
                    } else {
                        urlArray.push(filename);
                    };

                    updatedNewFile.push({
                        name: target.name,
                        image: urlArray[0],
                        createdAt: Math.round(new Date().getTime() / 1000)
                    });

                    const waitingLoadingImages = loadingImages.filter(image => image !== target.name)
                    setLoadingImages(waitingLoadingImages);
                } catch(e){
                    console.error(e);
                };
            };
            
            if (!isCanceled) {
                setFile(updatedNewFile);
                logBehavior('add-adscut(success)', {adsCuts: updatedNewFile});
            } else {
                logBehavior('add-adscut(fail)');
            };
    
            if (loadingImages.length === 0){
                setIsImagesLoading(false);
            };
            
            setIsFileLoading(false);
        };
        
        for (const target of targets) {
            waitingImages.push(target.name);

            let reader = new FileReader();

            reader.onload = () => {

                let image = new Image();
                image.src = reader.result;
                image.onload = () => {
                    cutAndImage.push({
                        target,
                        needCut: image.width > 600 || image.height > 600,
                    })
                    
                    if (cutAndImage.length === targets.length && targets.length > 0) {
                        uploadFiles()
                    };
                };
            };
            reader.readAsDataURL(target)
        };
        setLoadingImages(waitingImages);
    };
    
    const moveFile = (removeIndex, insertIndex) => {
        
        if (insertIndex !== currentCursorIndex) {
            if(currentCursorIndex < insertIndex){

                const modifiedFiles = [
                    ...file.slice(0,removeIndex),
                    ...file.slice(removeIndex + 1, insertIndex),
                    file[removeIndex],
                    ...file.slice(insertIndex),
                ];

                setFile(modifiedFiles);

            } else {
                
                const modifiedFiles = [
                    ...file.slice(0, insertIndex),
                    file[removeIndex],
                    ...file.slice(insertIndex, removeIndex),
                    ...file.slice(removeIndex + 1),
                ];

                setFile(modifiedFiles);
            }
        }
    }

    const isLastInsertIndex = (eleIndex) => {
        if (file.length === eleIndex + 1 && file.length === insertIndex) {
            return true;
        } else {
            return false;
        }
    }
    
    const [ isDraggingUploadFile, setIsDraggingUploadFile ] = React.useState(false);

    const [ isMobile, setIsMobile ] = React.useState(false);

    React.useEffect(()=>{
        setIsMobile(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
    },[])

    const warningClose = () => {
        setFile([...orgFile])
        setIsWarningDialogue(false)
        setWarningMessage(false);
    }

    const [ isFileLoading, setIsFileLoading ] = React.useState(false);
    
    return (
        <div className={'DragAndDropForAds'}>
            {!isEditable?
            <div className={`DragAndDropContainer`} >
                {(file || []).map((element,eleIndex) => 
                <div className={'FileFrame'} key={`${element.name}:${element.image}`}>
                    <img className={'FilePreview'} src={element.image} alt={`${element}`} />
                    
                    <IconButton size={'small'}
                        disabled
                        style={{position: 'absolute', top:10, right:0, width: 30, height: 30, border:'1px lightgray solid', backgroundColor: 'rgba(255,255,255,0.5)'}}
                    >
                        <Delete style={{fontSize: '1rem'}}/>
                    </IconButton>
                </div>)}
            </div>
            :<div className={`DragAndDropContainer ${disableBoolean?'DragAndDropContainerDisabled':''} ${cover && isDraggingUploadFile?'CoveredContainer':''}`}
                onMouseEnter={(event)=>{
                    event.stopPropagation();
                    event.preventDefault();
                    if(!isDragging && file.length === 0 && !isDropped && !isMobile){
                        setCover(true);
                        setCollapse('DragAndDropContainer');
                    }
                }}
                onMouseLeave={(event)=>{
                    event.stopPropagation();
                    event.preventDefault();
                    if(!isDragging && file.length === 0){
                        setCover(false);
                        setCollapse(null);
                    }
                    setIsDropped(false);
                }}
                onDrop={(event)=>{
                    if(!isDragging){
                        event.stopPropagation();
                        event.preventDefault();
                        if(file.length < maximum){
                            handleUploadFiles(event.dataTransfer.files);
                        } else {
                            setIsMaximumLengthMessage(true);
                        }
                        setCover(false);
                        setCollapse(null);
                        setIsDropped(true);
                    } else {
                        const lastIndex = file.length
                        
                        event.stopPropagation();
                        event.preventDefault();
                        setIsDragging(false);

                        moveFile(currentCursorIndex, lastIndex);
                    }
                    setInsertIndex(null);
                    setCurrentCursorIndex(null);
                    setIsDraggingUploadFile(false);
                }}
                onDragLeave={()=>{
                    if(collapse === 'DragAndDropContainer' && cover){
                        setCover(false)
                        if(file.length === 0){
                            setCollapse(null);
                        }
                    }
                    setIsDraggingUploadFile(false);
                }}
                onDragOver={(event)=>{
                    if (!isDraggingUploadFile){
                        setIsDraggingUploadFile(true);
                    }
                    event.stopPropagation();
                    event.preventDefault();
                    if(!isDragging){
                        if (!cover && !isMobile){
                            setCover(true)
                        }
                        if(collapse !== 'DragAndDropContainer'){
                            setCollapse('DragAndDropContainer')
                        }
                    }
                }}
                onClick={(event)=>{
                    setIsDropped(false)
                }}
            >
                {file.length === 0 && (!cover && !collapse) && placeholder?<div className={'PlaceholderText'}>{placeholder}</div>:<></>}
                {file.map((element,eleIndex) =>
                <div draggable className={`FileFrame ${cover?'Hide':''} ${isLastInsertIndex(eleIndex)?'VacantLast':''} ${insertIndex === eleIndex?'VacantSpace':''} ${loadingImages.length > 0?'OnLoading':''}`}
                    key={`${element.name}:${element.image}`}
                    id={eleIndex}
                    onDragStart={(event)=>{
                        event.dataTransfer.effectAllowed = 'move';  
                        if(file.filter(each => each.name === element.name)){
                            setCurrentCursorIndex(eleIndex)
                            setIsDragging(true)
                        }
                        event.dataTransfer.setDragImage(event.target, 70, 70)
                    }}
                    onDragOver={(event)=>{
                        event.stopPropagation();
                        event.preventDefault();

                        if (isDragging){

                            const draggingLocationX = event.clientX
                            const standardLocationX = event.target.getBoundingClientRect().x
                            
                            if(draggingLocationX - 1 <= standardLocationX + 35){
                                setInsertIndex(eleIndex)
                            } else if (draggingLocationX > standardLocationX + 35){
                                setInsertIndex(eleIndex + 1)
                            }

                        } else {
                            if(!cover && !isMobile){
                                setCover(true)
                            };
                            setCollapse('FileFrame')
                        }
                    }}
                    onDrop={(event)=>{
                        event.stopPropagation();
                        event.preventDefault();

                        if (isDragging) {
                            const draggingLocationX = event.clientX
                            const standardLocationX = event.target.getBoundingClientRect().x

                            if(draggingLocationX - 1 <= standardLocationX + 35){
                                moveFile(currentCursorIndex,eleIndex)
                            } else if (draggingLocationX > standardLocationX + 35){
                                moveFile(currentCursorIndex,eleIndex + 1)
                            }
                        }
                        setCurrentCursorIndex(null);
                        setInsertIndex(null);
                    }}
                    onDragEnd={()=>{
                        if (isDragging){
                            setIsDragging(false);
                            setInsertIndex(null);
                            setCurrentCursorIndex(null);
                        }
                    }}
                >
                    <img className={'FilePreview'} src={`${element.image}`} alt={`${element.name}`} />
                    
                    {!isDragging?<IconButton
                        size={'small'}
                        style={{position: 'absolute', top:10, right:0, width: 30, height: 30, border:'1px lightgray solid', backgroundColor: 'rgba(255,255,255,0.5)'}}
                        onClick={(event)=>{
                            event.preventDefault();
                            event.stopPropagation();
                            const newFile = [...file].filter((eachFile, idx)=> idx !== eleIndex);
                            setFile(newFile)
                            if (!cover && newFile.length === 0) {
                                setCover(true);
                            }
                        }}
                    >
                        <Delete style={{fontSize: '1rem'}}/>
                    </IconButton>:<></>}
                    {loadingImages.includes(element.name)
                    ?<Loop
                        style={{position: 'absolute', top:'calc(50% - 15px)', right:'calc(50% - 15px)', width: 30, height: 30, border:'1px lightgray solid', backgroundColor: 'rgba(255,255,255,0.5)'}} />
                    :<></>}
                </div>
                )}

                <div className={`FileFrame ${cover && isDraggingUploadFile?'Hide':''}`}
                    onDragOver={(event)=>{
                        event.stopPropagation();
                        event.preventDefault();

                        if (isDragging){
                            setInsertIndex(file.length)
                        }
                    }}
                    onDrop={(event)=>{
                        event.stopPropagation();
                        event.preventDefault();
                        if (isDragging) {
                            moveFile(currentCursorIndex, file.length)
                        }
                        setCurrentCursorIndex(null);
                        setInsertIndex(null);
                    }}
                >
                    {file.length < maximum?<div className={'FilePreview'} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer'}}
                        onClick={()=>{
                            if (!isDragging && file.length >= 0){
                                const input = document.getElementById(uniqueId);
                                input.value = null;
                                input.click();
                            }
                        }}
                    >
                        <Add color={'primary'} fontSize={'large'} />
                    </div>
                    :<></>}
                </div>

                {!isMobile && isDraggingUploadFile?<div className={(!cover || !collapse)?'Hide':'AddArea'}>
                    <AddPhotoAlternate style={{pointerEvents: 'none'}} fontSize={'large'} color={'primary'} />
                </div>:<></>}

                <div style={{display: 'none'}}>
                    <input type={'file'} multiple id={uniqueId} accept={'.jpg,.jpeg,.png'}
                        onChange={(event) => {
                            if (event.target.files && event.target.files.length > 0 ){
                                handleUploadFiles(event.target.files);
                            }
                            setCover(false);
                        }}
                    />
                </div>
            </div>}
            <Dialog className={"Dialog"} open={isMaximumLengthMessage} onClose={() => setIsMaximumLengthMessage(false)}>
                <DialogTitle style={{paddingBottom: 0}}>
                    {'파일 개수 초과'}
                </DialogTitle>
                <DialogContent>
                    <div style={{whiteSpace: 'pre-line'}}>
                        {`최대 업로드 가능 한 파일 갯수는 ${maximum}개 입니다.
                        새로운 파일을 업로드 하는 경우에는 기존 파일을 제거하여 추가하거나
                        최대 파일 갯수를 넘지 않게 업로드해 주세요.`}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button variant={"contained"} disableElevation className={"PopupButton"}
                        onClick={()=>setIsMaximumLengthMessage(false)} color={"primary"} >
                        {"확인"}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={isWarningDialogue} onClose={warningClose} disabled={isFileLoading} >
                <DialogContent style={{width: 310, height: 100, boxSizing: 'border-box', whiteSpace: 'pre-line'}} >
                        {warningMessage}
                </DialogContent>
                <DialogActions style={{display: 'flex', justifyContent: 'center'}}>
                    <Button color={'primary'} onClick={warningClose} disabled={isFileLoading} >
                        {'확인'}
                    </Button>
                </DialogActions>
            </Dialog>
            <LoadingCircle disabled={isFileLoading} />
        </div>
    )
}


export default DragAndDrop;
