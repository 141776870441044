import React, { useEffect } from 'react';

import MotherBoard from './Section/MotherBoard';

import MenuBar from './Component/MenuBar';
import WebtoonScore from './Section/WebtoonScore';
import PostScript from './Section/PostScript';
import Member from './Section/Member';
import QnA from './Section/QnA';
import Copyright from './Section/Copyright';


import './Landing.scss';

import { pageHeaderOptions } from '../../Recoil/PageHeader/PageHeader';
import { useSetRecoilState } from 'recoil';
import Apply from './Section/Apply';
import ChannelService from '../../Component/ChannelService';

const Landing = () => {
    const setPageHeader = useSetRecoilState(pageHeaderOptions);

    React.useEffect(()=>{
        setPageHeader({
            titleText: '웹툰 데이터 분석',
            isHiddenForMobile: true,
            isHiddenForPC: true,
            isAppbarHidden: true,
        });
        
        ChannelService.ChannelIO();
    },[setPageHeader])

    useEffect(()=>{
        
        const handleScroll = ()=>{
            document.getElementsByTagName('body')[0]?.setAttribute("data-scroll", `${window.scrollY}`);
        };

        window.addEventListener('scroll', handleScroll);

        return ()=>{
            window.removeEventListener('scroll', handleScroll);
            document.getElementsByTagName('body')[0]?.removeAttribute("data-scroll");
        }
    },[])
    
    return (
        <div className={"AnalyticsLandingPage"}>
            <MenuBar />
            <MotherBoard />
            <WebtoonScore />
            <PostScript />
            <Member />
            <Apply />
            <QnA />
            <Copyright />
        </div>
    );

}

export default Landing;


