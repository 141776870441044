import axios from 'axios';
import ss from './Session';

export const createPayment = async ({method, amount, orderId, orderName, orderDetail}) => {
    let res = await axios.put('https://challenge-api.webtoon.today/payment', { method, amount, orderId, orderName, orderDetail}, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    });
    
    if (res.data && res.data.code === 200){
        return res.data.checkoutUrl;
    } else {
        return res.data;
    };
};


export const approvePayment = async ({orderId, amount}) => {
    let res = await axios.post('https://challenge-api.webtoon.today/payment/confirm', {orderId, amount}, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    });

    if (res.data && res.data.code === 200){
        return res.data.data;
    } else {
        return res.data;
    };
};


export const cancelPayment = async ({paymentKey, cancelReason, cancelAmount}) => {
    let res = await axios.post('https://challenge-api.webtoon.today/payment/cancel', {paymentKey, cancelReason, cancelAmount}, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    });

    if (res.data && res.data.code === 200){
        return res.data.data;
    } else {
        return res.data;
    };
};
