import React from 'react';
import { Button, } from '@material-ui/core';

const GenderChange = ({gender: givenGender, callback=(property)=>{}, }) => {
    
    let [gender, setGender] = React.useState(givenGender);

    return(
        <div className={'UserPageArea'}>
            <div className={'BodyContainer'} >
                <div className={'InnerInputBox'}>
                    <div className={'BoxLabel'}>
                        {'성별'}
                    </div>
                    <div className={'GenderGroup'} >
                        {Object.entries({M: '남자',W: '여자',N: '알리지 않음'}).map(([key, genderElement],index) => (
                            <Button className={`${key === gender?'FocusedButton':''}`} disableElevation style={{borderRadius: 0, width: '33%', padding: '5px 8px'}} variant={'outlined'} 
                                key={`${key}:${genderElement}`} onClick={()=> setGender(key)} >
                                {genderElement}
                            </Button>
                        ))}
                    </div>
                </div>
            </div>
            <div className={'Footer'}>
                <Button fullWidth className={'SaveButton'} variant={'contained'} color={'primary'} onClick={()=>{
                    if (givenGender !== gender) {
                        callback(gender);
                    }
                }} >
                    {'저장'}
                </Button>
            </div>
        </div>
    );
    
}

export default GenderChange;