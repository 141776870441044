import { useEffect } from 'react';
import { Button } from '@material-ui/core';
import { useTitles } from '../../../../Recoil/Title/Titles';
import { fn } from '../../../../Functions';
import './Complete.scss';
import { useSetRecoilState } from 'recoil';
import { pageHeaderOptions } from '../../../../Recoil/PageHeader/PageHeader';

const Complete = ({serviceId, titleId}:{serviceId:string, titleId:string}) => {
    const {titleObject, fetch} = useTitles();

    const setPageHeader = useSetRecoilState(pageHeaderOptions);

    useEffect(() => {
        setPageHeader({
            isHiddenForPC: true,
            isHiddenForMobile: true,
            isAppbarHidden: true,
        })
    }, [setPageHeader])

    useEffect(()=>{
        fetch([{serviceId, titleId}]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[serviceId, titleId]);

    const title = titleObject[`${serviceId}:${titleId}`];

    return (
        <div className={'SubmitDoneArea'}>
            <div className={'SubmitDoneInner'}>
                <div className={'SubmitDoneHeader'}>
                    <img className={'CheckCircle'} src={'https://static.webtoon.today/ddah/icon/icon_success.svg'} alt={'success'}/>
                    <div className={'HeaderText'}>{'작품 제출을 완료했어요.'}</div>
                </div>
                <div className={'TitleCard'}>
                    <img className={'TitleThumbnail'} src={title?.thumbnail||'https://static.webtoon.today/noimage.jpg'} alt={'title_thumbnail'} />
                    <div className={'TitleInfo'}>
                        <div className={'Title'}>{title?.title || "작품명"}</div>
                        <div className={'Author'}>{title?.author || "저자"}</div>
                    </div>
                    <Button className={'GotoTitleButton'} href={'/analysis/univ2023/mypage'} onClick={fn.gotoByAnchor}>
                        <span>{'등록한 작품 확인하기'}</span>
                        <img className={'RightArrow'} src={'https://static.webtoon.today/ddah/icon/chevron_right_bold.svg'} alt={'right_arrow'} />
                    </Button>
                </div>
                <div className={'LaterButtonWrapper'}>
                    <Button className={'LaterButton'} href={'/analysis/univ2023'} onClick={fn.gotoByAnchor}>{'나중에 확인할게요'}</Button>
                </div>
                <div className={'SubmitDoneFooter'}>
                    <div className={'FooterTextWrapper'}>
                        <span>{'공모전 결과는 '}</span>
                        <span className={'Bold'}>{'2023년 1월 15일'}</span>
                        <span>{'에 발표됩니다.'}</span>
                    </div>
                    <div className={'FooterTextWrapper'}>
                        <span>{'작가님의 소중한 작품에 멋진 결과 있기를 응원할게요!'}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Complete