import { useEffect, useState } from 'react';
import {Paper, Button,} from '@material-ui/core';

import './Styles/index.scss';
import LoadingCircle from '../../Component/LoadingCircle';
import { getSurveyFormat, questionType, questionaireType, sendSurveyResponse } from '../../Data/Survey';

import RangeChoice from '../../Component/Survey/RangeChoice';
import MultipleChoice from '../../Component/Survey/MultipleChoice';
import Subjective from '../../Component/Survey/Subjective';
import Notice from '../../Component/Survey/NoticeBox';
import SingleChoice from '../../Component/Survey/SingleChoice';
import { fn } from '../../Functions';


const SurveyLogo = 'https://static.webtoon.today/ddah/logo-01.png';


const hasMeaningfulResponse = (responseOfThisQuestion: any): boolean => {
    if(!responseOfThisQuestion){
        return false;
    } else if(typeof responseOfThisQuestion === 'string' && !(responseOfThisQuestion.trim())){
        return false;
    } else if(typeof responseOfThisQuestion === 'object' && Object.keys(responseOfThisQuestion).length === 0){
        return false;
    }

    return true;
}

const isConditionMet = (question: questionType, response: {[questionKey: string]: any}): boolean => {
    if(!question.optional_clause){
        return true;
    }

    const conditionOfThisQuestion = question.optional_clause.values;
    const responseOfRelatedQuestion = response[question.optional_clause.key];

    const responseType = typeof responseOfRelatedQuestion;
    if(responseType === 'string' || responseType === 'number'){
        return conditionOfThisQuestion.includes(responseOfRelatedQuestion);
    } else if(Array.isArray(responseOfRelatedQuestion)){
        return responseOfRelatedQuestion.some(value => conditionOfThisQuestion.includes(value));
    } else if(responseType === 'object') {
        return Object.keys(responseOfRelatedQuestion).some(key => responseOfRelatedQuestion[key] && conditionOfThisQuestion.includes(key));
    } else{
        return false;
    }
}

const Survey = ({surveyId}: {surveyId: string}) => {

    useEffect(()=>{
        document.title = "데이터 분석 평가설문";
    },[])

    const [questionaire, setQuestionaire] = useState<questionaireType>([]);
    useEffect(()=>{
        (async()=>{
            const questionaire = await getSurveyFormat({surveyId});

            if(questionaire){
                setQuestionaire(questionaire);
            } else{
                window.history.back();
            }
        })();
    },[surveyId])

    const [response, setResponse] = useState<{[questionKey: string]: any}>({});
    const [disabled, setDisabled] = useState<boolean>(false);
    const [submmited, setSubmmited] = useState<boolean>(false);

    const surveySubmit = async () => {
        setDisabled(true)

        if( response ){

            const result = await sendSurveyResponse({
                surveyId,
                data:{
                    response
                }
            });
            
            if (result) {
                setSubmmited(true)
                fn.goto(`/`);
    
            } else{
                setSubmmited(false);
                alert("요청이 실패하였습니다.");
            }
        
        } else {
            setSubmmited(false);
            alert("* 필수 항목을 입력해주세요. *")
        }

        setDisabled(false)
    }

    const notAnsweredRequriedQuestions = questionaire.filter(question => question.isRequired && !hasMeaningfulResponse(response[question.key]));

    return (
        <>
            <div>
                {!submmited?
                    <div className={"SurveyBody"}>
                        <div className={"SurveyContainer"}>
                            <div className={"Section SurveyLogo"}>
                                <div className={"Inner"}>
                                    <img className={"WebtoonToday"} src={SurveyLogo} alt="logo"/>
                                </div>
                            </div>
                            {questionaire.filter(question => isConditionMet(question, response)).map(question => <Question key={question.key} question={question} response={response} setResponse={setResponse} />)}
                            <div
                                className={"ButtonBox"}
                                onClick={()=>{
                                    if(notAnsweredRequriedQuestions.length > 0){
                                        window.location.hash = notAnsweredRequriedQuestions[0].key;
                                    }
                                }}
                            >
                                <Button 
                                    className={"Submit"}
                                    variant={"contained"}
                                    color={"primary"}
                                    onClick={()=>surveySubmit()}
                                    disabled={notAnsweredRequriedQuestions.length > 0}
                                >
                                    {" 제출하기 "}
                                </Button>
                            </div>
                        </div>
                    </div>
                :<> </>}
                <LoadingCircle show={disabled} />
            </div>
        </>
    );
}

const Question = ({question, response, setResponse}: {question: questionType, response: {[questionKey: string]: any}, setResponse: React.Dispatch<React.SetStateAction<{[questionKey: string]: any;}>>}) => {
    let content;

    switch(question.type){
        case 'notice':{
            content = (
                <Notice
                    title={question.title}
                    subtitle={question.subtitle}
                    isRequired={question.isRequired}
                />
            )
            break;
        }
        case 'subjective':{
            content = (
                <Subjective
                    title={question.title}
                    subtitle={question.subtitle}
                    isRequired={question.isRequired}
                    response={response[question.key]}
                    setResponse={(value: any) => setResponse({...response, [question.key]: value})}
                />
            )
            break;
        }
        case 'range':{
            content = (
                <RangeChoice
                    title={question.title}
                    subtitle={question.subtitle}
                    isRequired={question.isRequired}
                    response={response[question.key]}
                    setResponse={(value) => setResponse({...response, [question.key]: value})}
                    caption_max={question.caption_max}
                    caption_min={question.caption_min}
                />
            )
            break;
        }
        case 'single':{
            content = (
                <SingleChoice
                    title={question.title}
                    options={question.options}
                    response={response[question.key]}
                    setResponse={(value) => setResponse({...response, [question.key]: value})}
                    isRequired={question.isRequired}
                    isToggle={question.isToggle}
                    allowOtherOption={question.isToggle && question.allowOtherOption}
                />
            )
            break;
        }
        case 'multiple':{
            content = (
                <MultipleChoice
                    title={question.title}
                    options={question.options}
                    response={response[question.key] || {}}
                    setResponse={(value) => setResponse({...response, [question.key]: { ...(response[question.key] || {}), ...value}})}
                    isRequired={question.isRequired}
                    isToggle={question.isToggle}
                    allowOtherOption={question.isToggle && question.allowOtherOption}
                />
            )
            break;
        }
        default:
            content = <></>;
            break;
    }

    return (
        <Paper
            elevation={0}
            className={"Section"}
            id={question.key}
            style={question.indent?{marginLeft: question.indent * 30}:{}}
        >
            {content}
        </Paper>
    );
}

export default Survey;