import './User.scss';
import React from 'react';
import { Button, Tooltip, } from '@material-ui/core';
import { AddCircle, DeleteForever, DragHandleOutlined, HelpOutline, KeyboardArrowRight, } from '@material-ui/icons';
import { AskMDLToken, AskCertificate, FetchCertificationResult } from '../../Data/PhoneCertification';
import { fn } from '../../Functions';
import { readProfile, updateProfile } from '../../Data/Profile';
import moment from 'moment-timezone';
import { fileToBase64 } from '../../Data/Image';
import NameChange from './UserSubpage/NameChange';
import AddressidChange from './UserSubpage/AddressidChange';
import GenderChange from './UserSubpage/GenderChange';
import BirthdayChange from './UserSubpage/BirthdayChange';
import Slideup from '../../Component/Slideup';
import CheckLogout from '../CheckLogout';
import LoadingCircle from '../../Component/LoadingCircle';
import { logBehavior } from '../../Data/Behavior';
import ChannelService from '../../Component/ChannelService';
import { useSession } from '../../Recoil/Session/Session';
import { pageHeaderOptions } from '../../Recoil/PageHeader/PageHeader';
import { useSetRecoilState } from 'recoil';

// 정적인 값 / 함수는 react 함수컴포넌트 밖으로 빼서 선언. 퍼포먼스에 영향이 있더라구요

const UserPage = () => {
    const { session, sessionRefresh } = useSession();

    const [ user, setUser ] = React.useState([])

    const [ changeTarget, setChangeTarget ] = React.useState(null)

    const [ image, setImage ] = React.useState(null);

    const [ isImageLoading, setIsImageLoading ] = React.useState(false);

    const [ isLoading, setIsLoading ] = React.useState(true);

    const setPageHeader = useSetRecoilState(pageHeaderOptions);
    React.useEffect(()=>{
        setPageHeader({
            titleText: '회원 정보',
            headerStyle: {borderBottom: '1px rgb(231,231,231) solid'}
        })
    },[setPageHeader])

    React.useEffect(()=>{
        ( async () => {
            setIsLoading(true);
            
            const res = await readProfile(session.userid);

            if (session.memberState !== 'regular'){
                fn.goto('/signin')
            };

            setUser(res)

            setIsLoading(false);
        })();
    },[session.memberState, session.userid])

    React.useEffect(()=>{
        setIsLoading(true);

        if (user.image && user.image !== 'https://static.webtoon.today/noprofile.jpg') {
            setHasImageFile(true);
        };

        setImage(user.image);

        setIsLoading(false);
    },[user]);

    const [ hasImageFile, setHasImageFile] = React.useState(false);

    const profileImageHandler = async () => {
        setIsImageLoading(true);

        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();

        input.addEventListener('change', async (e) => {
            const data = await fileToBase64(input.files[0]);
            
            const newProfileImage = await updateProfile({image: data});

            if (newProfileImage.image) {
                setImage(newProfileImage.image);
                setHasImageFile(true);
                
                sessionRefresh();
            } else {
                alert('이미지 업로드에 실패했습니다.')
            };
        });

        setIsImageLoading(false);
    }
    
    const [ isLogout, setIsLogout ] = React.useState(false);
    
    return(
        <div className={'UserPageArea'}>
            <div className={'BodyContainer'} >
                <div style={{margin: '42px 0 36px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div style={{position: 'relative'}}>
                        <img src={image?image:'https://static.webtoon.today/noprofile.jpg'} onClick={profileImageHandler} alt={'noimage'} style={{width: 86, height: 86, borderRadius: '50%', cursor: 'pointer'}} />
                        {!hasImageFile
                            ?<AddCircle style={{position: 'absolute', right: 0, bottom: 0, cursor: 'pointer'}} 
                                onClick={profileImageHandler}
                            />
                            :<DeleteForever
                                style={{position: 'absolute', right: 0, bottom: 0, color: 'white', cursor: 'pointer', borderRadius: '50%', backgroundColor: 'red'}} 
                                onClick={ async () => {
                                    
                                    const res = await updateProfile({image: 'https://static.webtoon.today/noprofile.jpg'});
                                    
                                    if ( res.addressid ) {
                                        logBehavior('change-addressid(success)');
                                        if (!res.image) {
                                            res.image = 'https://static.webtoon.today/noprofile.jpg'
                                            setImage('https://static.webtoon.today/noprofile.jpg')
                                            setHasImageFile(false);

                                            sessionRefresh();
                                        } else {
                                            setImage(res.image);
                                            setHasImageFile(false);

                                            sessionRefresh()
                                        }

                                    } else {
                                        logBehavior('change-addressid(fail)');
                                        alert('프로필 수정이 실패했습니다.')
                                    }
                            }}
                            />
                        }
                    </div>
                </div>
                <div className={'UserRow'}>
                    <div className={'AttributeCell'}>
                        {'닉네임'}
                    </div>
                    <div className={'ValueCell'}>
                        {user.name}
                        <Button className={'ModifyButton'} onClick={() => setChangeTarget('name')} >
                            {'변경'}<KeyboardArrowRight className={'Icon'} />
                        </Button>
                    </div>
                </div>
                <div className={'UserRow'}>
                    <div className={'AttributeCell'}>
                        {'영어작가명'}
                    </div>
                    <div className={'ValueCell'}>
                        {user.addressid}
                        <Button className={'ModifyButton'} onClick={() => setChangeTarget('addressid')} >
                            {'변경'}<KeyboardArrowRight className={'Icon'} />
                        </Button>
                    </div>
                </div>
                <div className={'UserRow'}>
                    <div className={'AttributeCell'}>
                        {'이메일'}
                    </div>
                    <div className={'ValueCell'}>
                        {user.email}
                    </div>
                </div>
                <div className={'UserRow'}>
                    <div className={'AttributeCell'}>
                        {'비밀번호'}
                    </div>
                    <div className={'ValueCell'}>
                        {'-'}
                        <Button className={'ModifyButton'} onClick={() => fn.goto("/account/start-reset-password")} >
                            {'변경'}<KeyboardArrowRight className={'Icon'} />
                        </Button>
                    </div>
                </div>
                { <div className={'UserRow'}>
                    <div className={'AttributeCell'}>
                        {'실명 인증'} 
                        <Tooltip
                            title={`작품을 올리시려면 실명 인증이 필요해요.`}
                            TransitionProps={{style:{
                                borderRadius: 20, backgroundColor: 'rgb(210, 243, 255)', 
                                color: 'rgb(33,33,33)', padding: '6px 10px 6px 10px', fontSize: '0.75rem'
                            }}}
                        >
                            <HelpOutline style={{color:'rgb(117, 117, 117)', fontSize:'1.1rem'}} />
                        </Tooltip>
                    </div>
                    <div className={'ValueCell'}>
                        {user.certificatedName && user.certificatedBirthday && user.certificatedPhoneNumber
                            ?<div>{'인증 완료'}</div>
                            :<div style={{cursor: 'pointer'}} onClick={()=>{
                                (async()=>{
                                    let mdl_tkn = await AskMDLToken();

                                    try{
                                        await AskCertificate(mdl_tkn);
                                    }catch(e){
                                        alert("팝업 차단을 해제해 주세요.");
                                        return;
                                    }

                                    const result = await FetchCertificationResult(mdl_tkn);

                                    if (result && result.code === 200) {
                                        const newProfile = {...user}
                                        newProfile['certificatedName'] = result.user.RSLT_NAME
                                        newProfile['certificatedBirthday'] = result.user.RSLT_BIRTHDAY
                                        newProfile['certificatedPhoneNumber'] = result.user.TEL_NO

                                        await updateProfile(newProfile)
                                        
                                        sessionRefresh();
                                    } else {
                                        alert('인증 실패')
                                    }
                                })()
                            }}>{'인증하기'}</div>    
                        }
                    </div>
                </div>}
                <div className={'UserRow'}>
                    <div className={'AttributeCell'}>
                        {'성별'}
                    </div>
                    <div className={'ValueCell'}>
                        {user.gender === 'M'?'남성':user.gender === 'W'?'여성':user.gender === 'N'?'알리지 않음':null}
                        <Button className={'ModifyButton'} onClick={() => setChangeTarget('gender')} >
                            {'변경'}<KeyboardArrowRight className={'Icon'} />
                        </Button>
                    </div>
                </div>
                <div className={'UserRow'}>
                    <div className={'AttributeCell'}>
                        {'생년월일'}
                    </div>
                    <div className={'ValueCell'}>
                        {moment(user.birthday).format('YYYY.MM.DD')}
                        <Button className={'ModifyButton'} onClick={() => setChangeTarget('birthday')} >
                            {'변경'}<KeyboardArrowRight className={'Icon'} />
                        </Button>
                    </div>
                </div>
                <div className={'ButtonBox'}>
                    <Button className={'MoveToInquiryButton'} onClick={()=>ChannelService.ChannelIO()('openChat')} >
                        {'서비스 문의하기'}
                    </Button>
                    <br/>
                    <div className={'UserPageButtonGroup'}>
                        <Button className={'UserPageButton'} variant={'contained'} color={'primary'} disableElevation onClick={ () => {
                        setIsLogout(true);
                    }} >
                            {'로그아웃'}
                        </Button>
                        <Button className={'UserPageButton'} variant={'contained'} style={{color: 'red'}} disableElevation onClick={()=>{
                            (async()=>{
                                fn.goto('/account/unregister');
                            })()
                        }} >
                            {'회원탈퇴'}
                        </Button>
                    </div>
                    {isLogout
                        ?<CheckLogout open={isLogout} setOpen={setIsLogout} />
                        :<></>
                    }
                </div>
            </div>
            <Slideup
                style={{
                    maxWidth: 600, borderTopLeftRadius: 10, borderTopRightRadius: 10,
                }}
                handleChild={<DragHandleOutlined style={{color:'rgb(192,192,192)'}}/>}
                open={!!changeTarget}
                setOpen={(newValue)=>{
                    if(!newValue){
                        setChangeTarget(null);
                    }
                }}
                defaultTopMargin={'50%'}
            >
                {changeTarget==='name'
                ?<NameChange name={user.name} callback={(name)=>{
                    (async()=>{
                        const res = await updateProfile({name});
                        if (res){
                            await sessionRefresh();
                            setChangeTarget(null);
                        }else {
                            alert("이름 수정에 실패했습니다")
                        }
                    })();
                }}/>
                :changeTarget==='addressid'
                ?<AddressidChange addressid={user.addressid} callback={(addressid)=>{
                    (async()=>{
                        const res = await updateProfile({addressid});
                        if (res){
                            await sessionRefresh();
                            setChangeTarget(null);
                        }else {
                            alert("영어작가명 수정에 실패했습니다")
                        }
                    })();
                }}/>
                :changeTarget==='gender'
                ?<GenderChange gender={user.gender} callback={(gender)=>{
                    (async()=>{
                        const res = await updateProfile({gender});
                        if (res){
                            await sessionRefresh();
                            setChangeTarget(null);
                        }else {
                            alert("성별 수정에 실패했습니다")
                        }
                    })();
                }}/>
                :changeTarget==='birthday'
                ?<BirthdayChange birthday={user.birthday} callback={(birthday)=>{
                    (async()=>{
                        const res = await updateProfile({birthday});
                        if (res){
                            await sessionRefresh();
                            setChangeTarget(null);
                        }else {
                            alert("생일 수정에 실패했습니다")
                        }
                    })();
                }}/>
                :<></>}
            </Slideup>
            <LoadingCircle show={isImageLoading || isLoading} />
        </div>
    );
};


export default UserPage;