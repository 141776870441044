import axios from "axios";
import ss from './Session';

export async function createCompetitionEmail ({email, type}) {
    try{
        let res = await axios.put('https://challenge-api.webtoon.today/competition-email', {email, type}, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        })

        if (res.data && res.data.code === 200){
            return res.data.data;

        } else {
            return null;

        }
    }catch(e){
        return null;
    }
}