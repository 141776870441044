import React from 'react';
import { Button, TextField } from '@material-ui/core';
import moment from 'moment-timezone';

const BirthdayChange = ({birthday: givenBirthday, callback=(property)=>{}, }) => {
    
    let [birthday, setBirthday] = React.useState(givenBirthday);
    
    return(
        <div className={'UserPageArea'}>
            <div className={'BodyContainer'} >
                <div className={'InnerInputBox'}>
                    <div className={'BoxLabel'}>
                        {'생년월일'}
                    </div>
                    <div style={{marginTop: '10px'}}>
                        <TextField type={'date'} fullWidth value={moment(birthday).format('YYYY-MM-DD')} onChange={ (e) => {
                            setBirthday(moment(e.target.value).format().replace('T', ' '));
                        }} inputProps={{style:{color: birthday?'black':'lightgray'}}} />
                    </div>
                </div>
            </div>
            <div className={'Footer'}>
                <Button fullWidth className={'SaveButton'} variant={'contained'} color={'primary'} onClick={()=>{
                    if (moment(birthday).format('YYYY-MM-DD') !== birthday) {
                        callback(birthday);
                    }
                }} >
                    {'저장'}
                </Button>
            </div>
        </div>
    );
}

export default BirthdayChange;