import { useEffect, useState } from "react";
import { proxy } from "../../../Page/MarketAnalysis/MarketAnalysisFunctions";
import SearchBar from "./SearchBar";
import FrequentlyUsedMaterial from "./FrequentlyUsedMaterial";
import PlatformSerializationTrend from "./PlatformSerializationTrend";
import PlatformRepresentatives from "./PlatformRepresentatives";

import SearchTrend from "./SearchTrend";
import SerializationTrend from "./SerializationTrend";
import WebtoonVirals from "./WebtoonVirals";
import EmptyResult from "./EmptyResult";
import { readPlatformTrendAnalytics, readTrendAnalytics } from "../../../Data/TrendAnalytics";
import LoadingCircle from "../../../Component/LoadingCircle";

import './Styles/Result.scss';

import PlatformTopPopularity from "./PlatformTopPopularity";
import PlatformTopicStatus from "./PlatformTopicStatus";
import PlatformTopWebtoons from "./PlatformTopWebtoons";
import PlatformSurgeTopics from "./PlatformSurgingTopics";

import { GroupAdd, Info } from "@material-ui/icons";
import { Button, Dialog } from "@material-ui/core";
import { copyClipboard, sharingPage } from "../Util/Functions";
import { getKeywordToTopic } from "../../../Data/MarketKeyword";
import { useToastAlert } from '@webtoontoday/toast';


const platformTopicList: {[topic: string]: string} = {
    "네이버웹툰": "naver",
    "카카오페이지": "kakao",
    "레진코믹스": "lezhin",
    "리디북스": "ridi",
    "봄툰": "bomtoon",
    "카카오웹툰": "daum",
    "탑툰": "toptoon",
    "투믹스": "toomics",
}

const platformDataTypes: {[platform: string]: string} = {
    "네이버웹툰": "rank",
    "레진코믹스": "rank",
    "리디북스": "rank",
    "탑툰": "rank",
    "투믹스": "rank",
    "카카오웹툰": "view",
    "카카오페이지": "view",
    "봄툰": "view",
}

const Result = ({ 
    searchText
}:{
    searchText: string,
}) => {

    const { toastAlert } = useToastAlert();
    
    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ topic, setTopic ] = useState<string>(searchText || '');
    const [ trendData, setTrendData ] = useState<{[type: string]: {code: number, data: any}}>({});
    const [ openInviting, setOpenInviting ] = useState<boolean>(false);
    const [ keywordToTopic, setKeywordToTopic] = useState<{[key: string]: string} | null>(null);
    
    useEffect( () => {
        ( async () => { 
            let keywordTopicDic = await getKeywordToTopic();
            setKeywordToTopic(keywordTopicDic);
        })();
    },[])

    useEffect( () => {
        ( async () => {
            if(!searchText || !keywordToTopic){
                return;
            }

            setIsLoading(true);
            window.scrollTo({top: 0});

            const selectedTopic = keywordToTopic[searchText];

            if (selectedTopic) {
                setTopic(selectedTopic);
            } else {
                setTopic('');
                setIsLoading(false);
                return;
            }
            
            let res

            if ( platformTopicList[selectedTopic] ) {
                res = await readPlatformTrendAnalytics(platformTopicList[selectedTopic])
            } else {
                res = await readTrendAnalytics(selectedTopic)
            }

            if (!res) {
                setIsLoading(false);
                return;
            }

            let newTopicList: {[key: string]: any} = {}

            for ( const topic of Object.entries(res)) {
                newTopicList[topic[0]] = topic[1]
            }
            setTrendData(newTopicList);

            setIsLoading(false);
        })()
    },[keywordToTopic, searchText])

    const checkHasLastSyllable = (name: string): boolean => {
        const charCode = name.charCodeAt(name.length - 1);
        
        const consonantCode = (charCode - 44032) % 28;
        
        if(consonantCode === 0 || consonantCode === 8){
            return false;
        }
        
        return true;
    }
    
    return <div className={'TrendSearchResultArea'}>
        <div className={'ResultSearchContainer'}>
            <div className={'LogoBox'} >
                <img src={proxy('https://static.webtoon.today/ddah/logo-01.png', 240)} alt={'logo-01'} style={{width: 120, margin: '0 auto'}} />
            </div>
            <div className={'SearchBox'}>
                <SearchBar
                    searchType="trend"
                    searchText={searchText}
                    style={{
                        padding: '10px 10px 10px 20px'
                    }}
                    iconStyle={{
                        fontSize: '2rem'
                    }}
                    placeholder={'궁금한 웹툰 토픽, 플랫폼을 입력해주세요.'}
                />
            </div>
            <Button className={'InvitingBox'} onClick={()=>setOpenInviting(true)} >
                <GroupAdd style={{marginRight: 10}} />
                {'친구 초대하기'}
            </Button>
        </div>
        {!topic && searchText
            // 대토픽에 없는 검색어일 경우.
            ?<div className={'StatisticsAndChartConatiner'}>
                <EmptyResult
                    searchText={searchText}
                />
            </div>
            // 대토픽에 있는 검색어일 경우.
            :<div className={'StatisticsAndChartConatiner'} >
                {topic !== searchText ?<div className={'SearchResultMessage'} style={{display: `${isLoading?'none':'flex'}`}}>
                    <span className={'InfoIcon'}><Info /> </span>
                    <span className={'TopicWord'}>{topic}</span>
                    {`${(checkHasLastSyllable(topic))?`으`:``}로 검색한 결과입니다.`}
                </div>:<></>}
                {!platformTopicList[topic]
                    // 플랫폼 검색이 아닌 일반 대토픽 검색어일 경우
                    ?[
                        "platformTopicUsedWithAnalytics", "platformTotalTopicTransitionAnalytics",
                        "platformRandomTopicTransitionAnalytics", "trendWithKeywordAnalytics",
                        "platformTopicPopularTitleAnalytics", "naverViralWithKeywordAnalytics"
                    ].map( type => ({type, ...trendData[`${type}`]}) )
                    .map( ({type, code, data}) => 
                        code === 200?
                            type === "platformTopicUsedWithAnalytics"
                                ?<FrequentlyUsedMaterial
                                searchText={searchText} topic={topic} data={data}
                                key={`${topic}:${type}`}
                            />
                            :type === "platformTotalTopicTransitionAnalytics"
                                ?<SerializationTrend
                                    topic={topic} data={data}
                                    key={`${topic}:${type}`}
                                />
                            :type === "platformRandomTopicTransitionAnalytics"
                                ?<PlatformSerializationTrend
                                    topic={topic} data={data} 
                                    key={`${topic}:${type}`}
                                />
                            :type === "trendWithKeywordAnalytics" 
                                    && data[0]?.ntrend?.results[0]?.data 
                                    && data[0].ntrend.results[0].data.length > 0
                                    && data[0].ntrend.results[0].data.filter( ({ratio}:{ratio:number}) => ratio > 0 ).length > 0
                                        ?<SearchTrend 
                                            topic={topic} data={data}
                                            key={`${topic}:${type}`}
                                        />
                            :type === "platformTopicPopularTitleAnalytics"
                                ?<PlatformRepresentatives 
                                    topic={topic} data={data}
                                    key={`${topic}:${type}`}
                                />
                            :type === "naverViralWithKeywordAnalytics"
                                ?<WebtoonVirals
                                    topic={topic} data={data}
                                    key={`${topic}:${type}`}
                                />
                            :<div key={`${topic}:${type}`}></div>
                        :<div key={`${topic}:${type}`}></div>)
                    // 플랫폼 검색일 경우
                    :Object.entries(trendData).map( ([type, result]) => (
                        result.code === 200 && Object.keys(result).length > 0
                            ?type === "platformPopularityAnalyticsByMonth"
                                ?<PlatformTopicStatus
                                    topic={topic} data={result.data}
                                    key={`${topic}:${type}`}
                                />
                            :type === "platformPopularityTransitionAnalytics"
                                ?<PlatformTopPopularity
                                    topic={topic} type={platformDataTypes[topic]} data={result.data}
                                    key={`${topic}:${type}`}
                                />
                            :type === "platformNewTitlePopularityAnalytics"
                                ?<PlatformTopWebtoons
                                    topic={topic} data={result.data} type={platformDataTypes[topic]}
                                    title={`${topic} 신작 ${platformDataTypes[topic] === "rank"?"인기순위":"조회수"} Top 3`}
                                    subtitle={ `최근 30일 동안 ${platformDataTypes[topic] === "rank" && topic === "네이버웹툰"?"관심등록순위":platformDataTypes[topic] === 'rank'?"인기순위":"조회수"}가 가장 높은 작품`}
                                    key={`${topic}:${type}`}
                                />
                            :type === "platformRecentTopicTrendAnalytics"
                                ?<PlatformSurgeTopics
                                    topic={topic} type={platformDataTypes[topic]} data={result.data}
                                    key={`${topic}:${type}`}
                                />
                            :type === "platformTitlePopularityAnalytics"
                                ?<PlatformTopWebtoons 
                                    topic={topic} data={result.data} type={platformDataTypes[topic]}
                                    title = {`${topic} 인기 웹툰 TOP ${(result.data || []).length}`}
                                    subtitle = {`최근 한 달 동안 ${platformDataTypes[topic] === "rank" && topic === "네이버웹툰"?"관심등록순위":platformDataTypes[topic] === 'rank'?"인기순위":"조회수"}가 높은 작품`}
                                    key={`${topic}:${type}`}
                                />
                                :<div key={`${topic}:${type}`}></div>
                            :<div key={`${topic}:${type}`}></div>
                    ))
                }
            </div>}
            <Dialog open={openInviting} onClose={ () => setOpenInviting(false) } >
                <div style={{margin: '20px 30px', fontSize: '1rem'}} >
                    <div style={{width: '100%',textAlign: 'center'}} >
                        <div style={{marginBottom: 8, width: '100%', wordBreak: 'keep-all'}}>
                            {`웹툰 트렌드 분석이 필요한 친구에게 초대장을 보내보세요!`}
                        </div>
                        <div  >
                            {`오직 `}<span style={{fontWeight: 'bold'}}>{'2명'}</span>{`의 친구만 초대할 수 있어요.`}
                        </div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '0 20px'}}>
                        <Button 
                            fullWidth variant={'contained'} color={'primary'} disableElevation 
                            style={{fontFamily: 'inherit', fontWeight: 400}}
                            onClick={ async () => navigator['share']
                                ?sharingPage({
                                    title: '오늘의웹툰 신규 서비스!', text: `웹툰 트렌드 분석으로 초대합니다 :)`,
                                    url: window.location.protocol + "//" + window.location.host + `/market/trendsearch`,
                                    log: {what: 'invite-trendsearch', detail: {codeNumber: 'n/a'}}
                                })
                                :copyClipboard(`오늘의웹툰 신규 서비스! 웹툰 트렌드 분석으로 초대합니다 :)  ${window.location.protocol+"//"+window.location.host+('/market/trendsearch' || window.location.pathname)}`, 
                                    () => {
                                        setOpenInviting(false)
                                        toastAlert('복사를 성공했습니다.')
                                    }, 
                                    () => {
                                        setOpenInviting(false)
                                        toastAlert('복사를 실패했습니다.')
                                    }
                                )
                            }
                        >
                            {'초대코드 공유하기'}
                        </Button>
                    </div>
                </div>
            </Dialog>
        <LoadingCircle show={isLoading} />
    </div>
}

export default Result;