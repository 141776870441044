import React, { DragEventHandler, ReactNode, SetStateAction, TouchEventHandler, useEffect, useRef, useState } from "react";
import { checkAndRenameFiles, filterFileListBySize, resizeImages, sliceFileList } from "../Util/Functions";
import { uploadImages, uploadZipFiles } from "../Data/UploadFiles";
import './Styles/MultiUploadImageDisplayed.scss';
import { Clear } from "@material-ui/icons";
import { useToastAlert } from '@webtoontoday/toast';
import UploadScreen from "./UploadScreen";
import LoadingCircle from "../../../Component/LoadingCircleOnSmallArea";
import './Styles/MultiUploadImageDisplayed.scss';

const MAX_UPLOAD_IMG_LEN = 10;
const DEBOUNCE_DELAY = 300;

const MultiUploadImageDisplayed = ({
    gridItemCounts = 5, padding: givenPadding = 8, gap = 8, screenTitle = "", screenSubtitle = [""], children = <></>,
    files, setFiles
}:{ 
    gridItemCounts: number, padding?: number | undefined,
    gap?: number | undefined
    screenTitle?: string, screenSubtitle?: string[],
    children?: ReactNode,
    files: {name: string, image: string, createdAt: number}[],
    setFiles: (files: {name: string, image: string, createdAt: number}[]) => void,
}) => {
    const padding = givenPadding + 'px';
    const { toastAlert } = useToastAlert();

    const [ uploadingFiles, setUploadingFiles ] = useState<{name: string, image: string, createdAt: number}[]>([])
    const [ uploadedFiles, setUploadedFiles ] = useState<{name: string, image: string, createdAt: number}[]>(files || []);
    const [ onUploadScreen, setOnUploadScreen ] = useState<boolean>(false);
    
    const [ draggedFilename, setDraggedFilename ] = useState<string>('');
    const [ isEditing, setIsEditing ] = useState<boolean>(false);

    const [ isFileRecognizing, setIsFileRecognizing ] = useState<boolean>(false);

    const [ isGrab, setIsGrab ] = useState(false);
    const [ isRipple, setIsRipple ] = useState(false);

    const grabTimer: React.MutableRefObject<ReturnType<typeof setTimeout> | null> = useRef(null);
    
    // TODO: files와 uploadedFiles이 useEffect에 의해 체이닝되어 있습니다.
    useEffect( () => {
        const timer = setTimeout(()=> {
            if (JSON.stringify(files) !== JSON.stringify(uploadedFiles) ) {
                setUploadedFiles(files);
            }
        }, DEBOUNCE_DELAY);
        
        return () => {
            clearTimeout(timer);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[files]);
    
    // TODO: files와 uploadedFiles이 useEffect에 의해 체이닝되어 있습니다.
    useEffect( () => {
        const timer = setTimeout(()=> {
            if (JSON.stringify(files) !== JSON.stringify(uploadedFiles) ) {
                setFiles(uploadedFiles);
            }
        }, DEBOUNCE_DELAY);
    
        return () => {
            clearTimeout(timer);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[uploadedFiles]);

    useEffect(()=>{
        if (!grabTimer || !grabTimer.current) {
            return;
        }

        const timer = grabTimer.current;

        return () => {
            clearTimeout(timer);
        }
    },[]);

    const isUploading = uploadingFiles.length > 0;

    const fileUploadFunction = async (FileList: FileList) => {
        setIsFileRecognizing(true);
        if ( !FileList ) {
            setIsFileRecognizing(false);
            return;
        };

        let files: File[] | FileList = FileList;
        const fileTypes = Object.values(FileList).map( ({type}) => type);

        if ( fileTypes.filter( type => !( type.includes('image') || type.includes('zip') )).length > 0 ) {
            setIsFileRecognizing(false);
            toastAlert('jpg, png, zip 파일만 업로드 할 수 있습니다.', 3000);
            return;
        };

        if ( uploadedFiles.length + files.length > MAX_UPLOAD_IMG_LEN ) {
            toastAlert(`홍보컷은 최대 ${MAX_UPLOAD_IMG_LEN}개까지 등록할 수 있습니다.`, 3000);
            files = sliceFileList(FileList, MAX_UPLOAD_IMG_LEN - uploadedFiles.length);
        };
        
        const FilesUnder10MB = filterFileListBySize(files, 1024*1024*10);
        
        if ( FilesUnder10MB.length < files.length ) {
            toastAlert('10MB 이하의 파일만 업로드가 가능합니다.', 3000);
            files = FilesUnder10MB;
        }

        const fileArray = checkAndRenameFiles(Object.values(FilesUnder10MB), uploadedFiles.map( ({name}) => name ))

        const zipFiles = Object.values(fileArray).filter( ({type}) => type.includes('zip') )
        const imageFiles = await resizeImages( Object.values(fileArray).filter( ({type}) => type.includes('image') ), 600, 600 )

        if ( (zipFiles || []).length > 0 ) {
            await uploadZipFiles({ files: zipFiles, setFiles: setUploadedFiles, toastAlert });
        }
        if ( (imageFiles || []).length > 0 ){
            await uploadImages({ files: imageFiles, setLoadingFiles: setUploadingFiles, setFiles: setUploadedFiles } );
        }

        setIsFileRecognizing(false);
    }

    // 업로드 핸들러
    const uploadHandler = async () => {
        if ( isGrab || isRipple ) {
            return;
        }

        const input = document.createElement('input');
        input.setAttribute('type','file');
        input.setAttribute('accept','image/*, .zip');
        input.setAttribute('multiple', 'true');
        input.click();

        input.addEventListener('change', async (e) => {
            if ( !input.files ) return;
            await fileUploadFunction(input.files);
        })
    }

    // 콘테이너 핸들러
    const dragOverHandler: DragEventHandler<HTMLDivElement> = (e) => {
        e.preventDefault();
        const types = e.dataTransfer.types
        if ( types.length > 0 && types.filter( type => type !== 'Files').length < 1 ) {
            setOnUploadScreen(true);
        }
    }

    // 스크린 핸들러
    const onDropHandler: DragEventHandler<HTMLDivElement> = (e) => {
        e.preventDefault();
        e.stopPropagation();
        
        (async () => {
            setOnUploadScreen(false);
            await fileUploadFunction(e.dataTransfer?.files);
        })()
    }
    const mouseLeaveScreenHandler: DragEventHandler<HTMLDivElement> = (e) => {
        setOnUploadScreen(false);
    }

    // img태그 핸들러
    const imgDragOverHandler: DragEventHandler<HTMLDivElement> = (e) => {
        e.stopPropagation();
        if (uploadingFiles.length > 0) return;

        const draggedIndex = uploadedFiles.findIndex( ({name}) => name === draggedFilename );
        
        const { clientY: dropPositionY } = e;

        const targetRect = (e.target as HTMLElement).getBoundingClientRect();
        const currentIndex = uploadedFiles.findIndex( ({name}) => name === (e.target as HTMLElement).id);
        const imagesCopy = [...uploadedFiles];
        
        // 같은 이미지라면 이동하지 않고, 세로로 해당 이미지를 벗어나도 이동하지 않음, index가 없어도 이동하지 않음
        if (  draggedIndex === currentIndex 
          || (dropPositionY < targetRect.y || dropPositionY > (targetRect.y + targetRect.height) )
          || !(draggedIndex >= 0 && currentIndex >= 0)
        ) return;

        let insertedImages = [...imagesCopy];

        if (currentIndex < draggedIndex) {
            insertedImages = [
                ...imagesCopy.slice(0, currentIndex), 
                imagesCopy[draggedIndex],
                ...imagesCopy.slice(currentIndex, draggedIndex),
                ...imagesCopy.slice(draggedIndex + 1)
            ];
        } else {
            insertedImages = [
                ...imagesCopy.slice(0, draggedIndex), 
                ...imagesCopy.slice(draggedIndex + 1, currentIndex + 1),
                imagesCopy[draggedIndex],
                ...imagesCopy.slice(currentIndex + 1)
            ];
        }
        
        setUploadedFiles(insertedImages.filter(object => object));
    }

    const imgDragStartHandler: DragEventHandler<HTMLDivElement> = (e) => {
        setDraggedFilename(e.currentTarget.id);
        e.dataTransfer.setDragImage(e.currentTarget, e.currentTarget.offsetWidth/2, e.currentTarget.offsetHeight/2);
    }
    
    const imgTouchStartHandler: TouchEventHandler<HTMLDivElement> = (e) => {
        setIsRipple(true);
        grabTimer.current = setTimeout(()=>{
            setIsGrab(true);
        }, 100);
        
        setDraggedFilename(e.currentTarget.id);
    }
    
    const imgTouchEndHandler: TouchEventHandler<HTMLDivElement> = (e) => {
        setDraggedFilename('');
        setIsGrab(false);
        setIsRipple(false);

        if (grabTimer && grabTimer.current) {
            clearTimeout(grabTimer.current);
        }
    }
    
    const imgTouchMoveHandler: TouchEventHandler<HTMLDivElement> = (e) => {
        if (uploadingFiles.length > 0 || !isGrab) {
            return;
        };
        const targetEl = e.target as HTMLElement;

        if (!targetEl) {
            return;
        }

        e.preventDefault();
        setIsRipple(false);

        const {clientX: touchClientX, clientY: touchClientY } = e.changedTouches[0];

        const {offsetWidth: targetRectWidth, offsetHeight: targetRectHeight} = targetEl;        
        const {left: targetPositionX, top: targetPositionY} = targetEl.getBoundingClientRect();
        
        const imagesCopy = [...uploadedFiles];
        
        // 개별 이미지들의 가운데 x, y 좌표
        const targetCenterX = targetPositionX + targetRectWidth / 2;
        const targetCenterY = targetPositionY + targetRectHeight / 2;
        
        const offsetX = Math.floor( ( touchClientX - targetCenterX ) / (targetRectWidth + givenPadding) );
        const offsetY = Math.floor( ( touchClientY - targetCenterY ) / (targetRectHeight + givenPadding) ) * gridItemCounts;
        
        const touchedIndex = imagesCopy.findIndex( ({name}) => name === draggedFilename );

        const touchedRow = Math.floor(touchedIndex / gridItemCounts);
        const changedRow = Math.floor((touchedIndex + offsetX) / gridItemCounts);

        // offsetY가 0 이고 offsetX 를 touchedIndex에 더했을 경우 grid의 row가 증감하는 경우
        // offsetY 를 touchedIndex에 더했을 경우 음수이거나, 업로드 이미지 파일 길이 이상일 경우
        if ((   offsetY === 0 && touchedRow !== changedRow)
            ||  touchedIndex + offsetY < 0 
            ||  touchedIndex + offsetY > uploadedFiles.length - 1) {
            return;
        }
    
        const currentIndex 
            = Math.max(
                0, 
                (Math.min (
                    uploadedFiles.length - 1,
                    touchedIndex + offsetX + offsetY
                ))
            );

        if (touchedIndex === currentIndex) {
            return;
        }

        let insertedImages = [...imagesCopy];

        if (currentIndex < touchedIndex) {
            insertedImages = [
                ...imagesCopy.slice(0, currentIndex), 
                imagesCopy[touchedIndex],
                ...imagesCopy.slice(currentIndex, touchedIndex),
                ...imagesCopy.slice(touchedIndex + 1)
            ];
        } else {
            insertedImages = [
                ...imagesCopy.slice(0, touchedIndex), 
                ...imagesCopy.slice(touchedIndex + 1, currentIndex + 1),
                imagesCopy[touchedIndex],
                ...imagesCopy.slice(currentIndex + 1)
            ];
        }

        setUploadedFiles(insertedImages.filter(object => object));
    } 
    
    const imgDragEndHandler: DragEventHandler<HTMLDivElement> = (e) => {
        setDraggedFilename('');
    }

    // 편집모드 핸들러
    const deleteFileHandler = (factor: {name: string, image: string}) => {
        setUploadedFiles(prevState => {
            const filesAfterDelete = prevState.filter( ({name, image}) => name !== factor.name || image !== factor.image );
            if (filesAfterDelete.length < 1) {
                setIsEditing(false);
            }
            return filesAfterDelete; 
        })
    }

    const isLoadingFile = (loadingFiles: {name: string, image: string}[], targetFile: {name: string, image: string}) => {
        const fileInLoading = loadingFiles.filter( file => file.name === targetFile.name) || [];
        if (fileInLoading.length > 0) {
            return true;
        } else {
            return false;
        }
    }
    
    return(
        <div className={'MultiUploadImageDisplayedArea'} style={{gap}} >
            <MultiUploadControlBox 
                uploadHandler = {uploadHandler} isEditing={isEditing} 
                setIsEditing={setIsEditing}
                uploadedFiles={uploadedFiles}
                deleteAll={() => setUploadedFiles([]) }
                isUploading={isUploading}
            />
            <div className={'MultiUploadImageContainer'}
                style={{
                    width: `calc(100% - ${padding}*2)`, padding, gap: padding,
                    alignItems: `${Object.keys(uploadedFiles).length <= gridItemCounts?'flex-start':'center'}`,
                    aspectRatio: `${Object.keys(uploadedFiles).length > gridItemCounts * 2?'auto':`${gridItemCounts}/2`}`,
                    gridTemplateColumns: `repeat(auto-fit, calc( (100% - ${padding}*${gridItemCounts-1}) / ${gridItemCounts} ))`, 
                }}
                onDragOver={dragOverHandler}
            >
                {uploadedFiles.map( ({name, image}) => (
                    <div className={'UploadImageContainer'} draggable key={`${name}:${image}`} >
                        <img 
                            src={image} alt={image} id={name}
                            onDragStart={imgDragStartHandler}
                            onDragOver={imgDragOverHandler}
                            onDragEnd={imgDragEndHandler}
                            onTouchStart={imgTouchStartHandler}
                            onTouchMove={imgTouchMoveHandler}
                            onTouchEnd={imgTouchEndHandler}
                            onContextMenu={ () => false }
                            style={{
                                width: '100%', height: '100%', objectFit: 'cover',
                                opacity: `${isLoadingFile(uploadingFiles, {name, image})?0.1:1}`,
                                transition: '3s', borderRadius: 5,
                            }}
                        />
                        {isEditing && 
                        <div className={'DeleteDimFilter'}
                            onClick={() => {
                                if ( !onUploadScreen ) {
                                    deleteFileHandler({name, image})}
                                }
                            }  
                        >
                            <div className={'DeleteFileIcon'} >
                                <Clear style={{width: 17, height: 17, color: 'rgb(255,255,255)'}} />
                            </div>
                        </div>}
                        <div 
                            className={`Ripple${
                                isGrab && name === draggedFilename ?
                                    isRipple ? ' Animation Grab' 
                                    : ' Grab' 
                                : ''}`
                            }
                        />
                    </div>
                ))}
                {(onUploadScreen || uploadedFiles.length === 0) && 
                    <UploadScreen 
                        mouseLeaveHandler={mouseLeaveScreenHandler} 
                        onDropHandler={onDropHandler}
                        subTitles={screenSubtitle} 
                    />
                }
            </div>
            <div className={'NumberOfFiles'}>
                {`${uploadedFiles.length}/10`}
            </div>
            {children}
            <LoadingCircle show={isFileRecognizing} />
        </div>
    )
}

export default MultiUploadImageDisplayed;

const MultiUploadControlBox = ({
    uploadHandler, isEditing, setIsEditing, uploadedFiles, deleteAll, isUploading
}:{
    uploadHandler: () => void,
    isEditing: boolean,
    setIsEditing: React.Dispatch<SetStateAction<boolean>>,
    uploadedFiles: {name: string, image: string}[],
    deleteAll: () => void,
    isUploading: boolean,
}) => {
    const isFileUploaded = uploadedFiles.length > 0;

    return(
        <div className={'MultiUploadControlBox'} >
            <button className={'MultiUploadeButton'} onClick={uploadHandler} disabled={isUploading} >
                {'내 PC'}
            </button>
            {!isEditing
                ?<button className={`MultiUploadeButton ${!isFileUploaded || isUploading?'Disabled':''}`} onClick={ () => { if (isFileUploaded) setIsEditing(!isEditing); }} >
                    {'편집'}
                </button>
                :<div className={'OnEditingButtons'} >
                    <button className={'DeleteAllButton'} onClick={ () => { deleteAll(); setIsEditing(false); } }>
                        {'전체 삭제'}   
                    </button>
                    <button className={'FinishEditingButton'} onClick={ () => setIsEditing(false) } >
                        {'완료'}
                    </button>
                </div>}
        </div>
    )
}
