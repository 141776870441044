
export const sliceFileList = (files: FileList, limit: number = 0) => {
    const orgFileList: FileList = files;
    const newFiles: File[] = [];

    for ( let i=0; i<limit; i++ ) {
        const orgFile: File | undefined = orgFileList[i]
        
        if (orgFile) {
            newFiles.push(orgFile)
        } else {
            break;
        }
    }

    const dataTransfer = new DataTransfer();
    newFiles.forEach( file => dataTransfer.items.add(file))

    const newFileList: FileList = dataTransfer.files;

    return newFileList;
}

export const checkAndRenameFiles = (newFiles: File[], existingFiles: string[]): File[] => {
    if (existingFiles.length === 0) {
        return newFiles;
    }
    const renamedFiles: File[] = [];
  
    const checkIfExists = (fileName: string, number = 0): string => {
        let newName = number > 0?`${fileName}(${number})`:`${fileName}`
        if (existingFiles.findIndex( name => name.includes(newName) ) >= 0) {
            return checkIfExists(fileName, number + 1);
        }
        return newName;
    };
  
    for (const file of newFiles) {
            const baseFileName = file.name.split('.')[0];
            const extension = file.name.split('.').slice(1).join('.');
            const fileName = file.name;

        if (existingFiles.includes(fileName)) {
            const newName = checkIfExists(baseFileName);
            const updatedFileName = `${newName}.${extension}`;

            const updatedFile = new File([file], updatedFileName, { type: file.type });
            renamedFiles.push(updatedFile);
        } else {
            renamedFiles.push(file);
        }
    }
  
    return renamedFiles;
};

export const resizeImages = async (files: File[], width: number, height: number): Promise<File[]> => {
    const resizedFiles: File[] = [];
    
    for (const file of files) {
        const img = new Image();
        img.src = URL.createObjectURL(file);
  
        await new Promise<HTMLImageElement>((resolve) => {
            img.onload = () => resolve(img);
        });
        const originalWidth = img.width;
        const originalHeight = img.height;
        const originalAspectRatio = originalWidth / originalHeight;
        const targetAspectRatio = width / height;
    
        let newWidth, newHeight;
        let startX = 0;
        let startY = 0;
    
        if (originalAspectRatio >= targetAspectRatio) {
            // 원본 이미지의 가로가 더 긴 경우
            newHeight = originalHeight;
            newWidth = originalHeight * targetAspectRatio;
            startX = (originalWidth - newWidth) / 2;
        } else {
            // 원본 이미지의 세로가 더 긴 경우
            newWidth = originalWidth;
            newHeight = originalWidth / targetAspectRatio;
            startY = (originalHeight - newHeight) / 2;
        }

        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
    
        const ctx = canvas.getContext('2d');
        if (!ctx) {
            throw new Error('Failed to get canvas context');
        }
  
        ctx.drawImage(img, startX, startY, newWidth, newHeight, 0, 0, width, height);

        const resizedBlob = await new Promise<Blob | null>((resolve) => {
            canvas.toBlob((blob) => resolve(blob), file.type);
        });
    
        if (resizedBlob) {
            const { name, type, lastModified } = file
            const resizedFile = new File([resizedBlob], name, {
                type, lastModified,
            });
            resizedFiles.push(resizedFile);
        }
    }
  
    return resizedFiles;
  }

export const filterFileListBySize = (fileList: FileList, maxSize: number): File[] => {
    const filteredFiles: File[] = [];
    for (let i = 0; i < fileList.length; i++) {
        const file = fileList[i];
        if (file.size <= maxSize) {
            filteredFiles.push(file);
        }
    }
    return filteredFiles;
}