import React, {useRef, ReactNode, useCallback} from 'react';
import { Button, IconButton, Paper } from '@material-ui/core';
import './WebtoonScore.scss';
import {fn} from '../../../../Functions';
import { ArrowDownwardRounded as ArrowDownwardRoundedIcon, ChevronRight } from '@material-ui/icons';

import {useOnScreen} from '../../../../Hooks';
import {logBehavior} from '../../../../Data/Behavior';
let didSeen = false;

const UploadImg = 'https://static.webtoon.today/ddah/Upload.svg';
const SNSAdsImg = 'https://static.webtoon.today/ddah/SNSAds.svg';
const AnalysisImg = 'https://static.webtoon.today/ddah/Analysis.svg';
const ExampleReport = 'https://static.webtoon.today/application/ExampleReport.png';
const ExtraGraph = 'https://static.webtoon.today/application/ExtraGraph.png';

type DetailBoxProps = {
    pageIndex: number, title: string, icon: string, text: ReactNode, extraText: ReactNode
};
const DetailBoxHOC = ({page, pageIndex, title, icon, text, extraText, setPageCallback}: DetailBoxProps & {page: number, setPageCallback: Function}) => {
    return (
        <Paper className={`Paper ${page === 1?"Union":""}`}>
            <div className={"PaperTitle"}>{title}</div>
            <div className={"PaperIcons"}>
                <img src={icon} alt={"text"}/>
            </div>
            <div className={"PaperText"}>{text}</div>
            <div className={"ExtraText"} hidden={!(page === pageIndex)}>{extraText}</div>
            <div>
                {page === pageIndex && <IconButton
                    color={"primary"} className={"ExtraTextIcon"}
                    onClick={()=>{setPageCallback(pageIndex)}}
                >
                    <ChevronRight style={{transform: 'rotate(-90deg) translate(-10px,0)'}}/>
                </IconButton>}
                {page !== pageIndex && <IconButton
                    color={"primary"} className={"ExtraTextIcon"}
                    onClick={()=>{setPageCallback(pageIndex)}}
                >
                    <ChevronRight style={{transform: 'rotate(90deg) translate(10px,0)'}}/>
                </IconButton>}
            </div>
        </Paper>
    );
}

const WebtoonScore = () => {

    const [paper, setPaper] = React.useState(-1);

    const handle = useCallback((index: number) => {
        setPaper(index)
        if (paper === index){
            setPaper(-1)
        }
    }, [paper]);

    const DetailBox = useCallback((props: DetailBoxProps) => <DetailBoxHOC
        page={paper} setPageCallback={handle} 
        {...props}
    />, [paper, handle]);

    const ref = useRef(null);
    const onScreen = useOnScreen(ref);
    if (!didSeen && onScreen){
        didSeen = onScreen;
        logBehavior('scroll-webtoonscore', {});
    }

    return (
        <div ref={ref}>
            <div className={"WebtoonScoreTemplate LandingSection"} id={"WebtoonScore"}>
                <div className={"TemplateMainBody"}>
                    <div className={"TitleBox"}>
                        <div className={"Title"}>
                            {"내 웹툰은 "}
                            <span className={"SignatureText"}>{"몇 점"}</span>
                            {"일까요?"}
                        </div>
                        <div className={"TextLine"}>
                            {"독자의 마음을 데이터 분석 서비스로 직접 확인하세요."}
                        </div>
                    </div>
                    <div className={"Explanation"}>
                            <DetailBox
                                pageIndex={1}
                                title={"완성된 원고 업로드"}
                                icon={UploadImg}
                                text={"총 3화의 완성된 원고를 업로드합니다."}
                                extraText={'연독률 파악을 위해 원고 최소 3화를 권장합니다.'}
                            />
                            <DetailBox
                                pageIndex={2}
                                title={"예상 독자들의 작품 읽기"}
                                icon={SNSAdsImg}
                                text={"SNS를 통해 예상 독자들에게 나의 작품이 전달됩니다."}
                                extraText={'독자 반응 수집을 위해 SNS 퍼포먼스 마케팅(광고게재)이 활용됩니다.'}
                            />
                            <DetailBox
                                pageIndex={3}
                                title={"데이터 분석 결과 확인"}
                                icon={AnalysisImg}
                                text={"이탈률, 완독률 등의 객관적 수치를 활용할 수 있습니다."}
                                extraText={<>
                                    {'다음과 같은 지표가 제공됩니다.'}
                                    <img src={ExtraGraph} alt={"graph"}/>
                                </>}
                            />
                    </div>
                    <div className={"IntroducingReport"}>
                        <ArrowDownwardRoundedIcon style={{color: 'rgb(0,176,240)', borderRadius:40, border:'1px rgba(0,176,240) solid'}}/>
                        <Button variant={"outlined"} className={"Introductions"}
                            href={"https://static.webtoon.today/ddah/210621-report%20review.pdf"}
                            onClick={fn.gotoByAnchor}
                        >
                            <div className={"IntroductionText"}>{"데이터 분석 리포트의 실제 모습이 궁금해요."}</div>
                            <img src={ExampleReport} alt={"ExampleReport"} />
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WebtoonScore;