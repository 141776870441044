import React, {MouseEventHandler, ReactNode, useRef} from 'react';
import './PostScript.scss';

import { Paper } from '@material-ui/core'
import { ChevronRight as ChevronRightIcon, ChevronLeft as ChevronLeftIcon} from '@material-ui/icons';

import SwipeableViews from "react-swipeable-views";

import { autoPlay } from 'react-swipeable-views-utils';

import {useOnScreen} from '../../../../Hooks';
import {logBehavior} from '../../../../Data/Behavior';
import { fn } from '../../../../Functions';
let didSeen = false;

const ThumbsUp = 'https://static.webtoon.today/application/ThumbUp.png';
const Feedbacks = 'https://static.webtoon.today/application/Feedbacks.png';
const Results = 'https://static.webtoon.today/application/Results.png';
const Evaluations = 'https://static.webtoon.today/application/Evaluations.png';

const Stars = 'https://static.webtoon.today/application/Stars.png';

const Happiness = 'https://static.webtoon.today/ddah/220512-happy-recipe.jpg';
const PurpleGiraffe = 'https://static.webtoon.today/ddah/220511-1331.jpg';
const AnotherMe = 'https://static.webtoon.today/ddah/220511-to%20another%20me.jpg'
const Clumsy = 'https://static.webtoon.today/ddah/220511-clumpsy%20clumpsy%20but%20pond%20of.jpg';
const Carnism = 'https://static.webtoon.today/ddah/220511-carnism%20high%20school.jpg';
const Cooko = 'https://static.webtoon.today/ddah/220511-cooko%20cub.jpg'
const TrainInDark = 'https://static.webtoon.today/ddah/220511-train%20in%20the%20dark.jpg'


const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

const AuthorReviewContainer = ({mainText, genre, content}: {mainText: string, genre: string, content: ReactNode}) => {
    
    return (
        <div className='ReviewRow'>
            <div className={"Review"}>
                <div className={"AuthorColumn"}>
                    <div className={"Writer"}>
                        <div className={"MainText"}>{mainText}</div>
                        <div>{genre}</div>
                    </div>
                    <div className={"StarBox"}>
                        <img className={"Stars"} src={Stars} alt={"Stars"}/>
                    </div>
                </div>
                <div className={"CommentColumn"}>
                    <div className={"Content"}>
                        {content}
                    </div>
                </div>
            </div>
        </div>);
}

const AuthorReview = () => {
    return (
        <div className={"AuthorReviews"}>
            <div className={"TitleText"}>
                {"실제 고객 리뷰"}
            </div>
            <AuthorReviewContainer
                mainText={"몬*** 작가님 | 베스트도전 연재"}
                genre={"일상, 추리물"}
                content={<>
                    {"이런 데이터 분석은 스포츠나 다른 분야에서만 보던 건데"}
                    <span className={"EmphasizeText"}>{" 제 작품에 적용할 수 있어 신기하고 유익"}</span>{"한 경험이었습니다."}<br/>
                    {"또 지적해 주신 점들이 그동안 고민했었던 부분이라 많은 도움이 되었습니다."}
                </>}
            />
            <AuthorReviewContainer
                mainText={"보*** 작가님 | 연재 확정"}
                genre={"재난, 스릴러물"}
                content={<>
                    {"숫자로 정리 된 데이터라 설득력이 있었고, 커뮤니티가 아닌 SNS 광고 링크로 유입되는 독자 대상으로 한 통계라 여기서 유의미한 성과가 나오면"}
                    <span className={"EmphasizeText"}>{" 확실하게 상업성 여부를 판단"}</span>
                    {" 할 수 있을 거라는 생각이 듭니다."}
                </>}
            />
            <AuthorReviewContainer
                mainText={"작가지망생"}
                genre={""}
                content={<>
                    {"개인적으로 디렉터님들의 피드백이 크게 도움되었습니다."}<br/>
                    {"사실 "}
                    <span className={"EmphasizeText"}>{"전문가에게 분석 받을 기회"}</span>
                    {"가 흔치 않아 정말 좋았고, 이 점이"}
                    <span className={"EmphasizeText"}>{" 개인 작가들에게 큰 도움"}</span>
                    {"이 될 것 같습니다!"}
                </>}
            />
            <AuthorReviewContainer
                mainText={"작가지망생"}
                genre={"스릴러물"}
                content={<>
                    {"테스트 결과 너무 감명 깊게 보았습니다."}<br/>
                    {"제 예상과 같은 부분도 있고 다른 부분도 있어 흥미로웠고, "}
                    <span className={"EmphasizeText"}>{" 디렉터분들의 피드백"}</span>
                    {"도 너무나 유익했습니다."}<br/>
                    {"또 제가 "}
                    <span className={"EmphasizeText"}>{"생각했던 단점과 생각지 못했던 단점 둘 다 들을 수 있어"}</span>
                    {" 너무 좋았습니다."}
                </>}
            />
        </div>);
}

const PostScript = () => {

    const ref = useRef(null);
    const onScreen = useOnScreen(ref);
    if (!didSeen && onScreen){
        didSeen = onScreen;
        logBehavior('scroll-postscript', {});
    }

    const slidesData: {
        id: string, divClass: string, imgSrc: `http${'s'|''}://${string}`, alt: string, href: `http${'s'|''}://${string}`, onClick?: MouseEventHandler<any>, label?: string,
    }[] = [
        {
            id: 'slide1',
            divClass: 'WebtoonSlide LinkedSlide',
            imgSrc: Happiness,
            alt: 'Happiness',
            href: 'https://comic.naver.com/webtoon/detail?titleId=784840&no=1&weekday=dailyplus',
            onClick: fn.gotoByAnchor,
        },
        {
            id: 'slide2',
            divClass: 'WebtoonSlide LinkedSlide',
            imgSrc: PurpleGiraffe,
            alt: 'PurpleGiraffe',
            href: 'https://series.naver.com/comic/detail.series?productNo=8029236',
            onClick: fn.gotoByAnchor,
        },
        {
            id: 'slide3',
            divClass: 'WebtoonSlide LinkedSlide',
            imgSrc: AnotherMe,
            alt: 'AnotherMe',
            href: 'https://series.naver.com/comic/detail.series?productNo=8207959',
            onClick: fn.gotoByAnchor,
        },
        {
            id: 'slide4',
            divClass: 'WebtoonSlide',
            imgSrc: Clumsy,
            alt: 'Clumsy',
            href: 'https://www.manhwakyung.com/title/173',
            onClick: fn.gotoByAnchor,
        },
        {
            id: 'slide5',
            divClass: 'WebtoonSlide',
            imgSrc: Carnism,
            alt: 'Carnism',
            href: 'https://comic.naver.com/webtoon/list?titleId=804364',
            onClick: fn.gotoByAnchor,
        },
        {
            id: 'slide6',
            divClass: 'WebtoonSlide',
            imgSrc: Cooko,
            alt: 'Cooko',
            href: 'https://page.kakao.com/content/61143224',
            onClick: fn.gotoByAnchor,
        },
        {
            id: 'slide7',
            divClass: 'WebtoonSlide',
            imgSrc: TrainInDark,
            alt: 'TrainInDark',
            href: 'https://series.naver.com/comic/detail.series?productNo=8650471',
            onClick: fn.gotoByAnchor,
        },
    ]


    const [slidePage, setSlidePage] = React.useState(0);

    const totalSlides = (slidesData || []).length

    const handleChangePrev = () => {
        ( slidePage <= 0 )?setSlidePage(totalSlides - 1):setSlidePage(slidePage - 1)
    };

    const handleChangeNext = () => {
        ( slidePage >= totalSlides - 1 )?setSlidePage(0):setSlidePage(slidePage + 1)
    };


    
    return(
        <div ref={ref}> 
            <div className={"PostScriptArea LandingSection"} id={"PostScript"}>
                <div className={"PostScriptBody"}>                        
                    <div className={"AverageSummary"}>
                        <div className={"Satisfaction"}>
                            {"서비스 평균 만족도"}
                        </div>
                        <div className={"ScoreBoard"}>
                            <img src={ThumbsUp} alt={"ThumbsUp"} />
                            <div className={"Score"}>{"9.32 점"}</div>
                            <div className={"FullScore"}>{"(10점 만점)"}</div>
                        </div>
                    </div>
                    <AuthorReview />
                    <div className={"AdditionalMessage"}>
                        {"+ 이외에도 300명이 넘는 작가님들이 서비스 재이용을 희망했습니다."}
                    </div>
                    <div className={"AdvantagesOfAnalysis"}>
                        <div className={"TitleText"}>
                            <div className={"Space"}>{"데이터 분석 서비스는"}</div>
                            <div>{"뭐가 좋나요?"}</div>
                        </div>
                        <div className={"Advantages"}>
                            <div className={"DocumentBox"}>
                                <Paper className={"Paper"}>
                                    <img className={"PaperIcons"} src={Results} alt={"Results"}/>
                                    <div className={"PaperText"}>{"명료하고 직관적인 데이터 분석 결과"}</div>
                                </Paper>
                                <Paper className={"Paper"}>
                                    <img className={"PaperIcons"} src={Feedbacks} alt={"Feedbacks"}/>
                                    <div className={"PaperText"}>{"전문 디렉터 피드백"}</div>
                                </Paper>
                                <Paper className={"Paper"}>
                                    <img className={"PaperIcons"} src={Evaluations} alt={"Evaluations"}/>
                                    <div className={"PaperText"}>{"독자들의 객관적인 평가"}</div>
                                </Paper>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"PostScriptArea2 LandingSection"} id={"ContractCases"} >
                <div className={"PostScriptBody"}>  
                    <div className={"ContractedWorks"}>
                        <div className={"TitleBox"}>
                            <div className={"TitleText"}>
                                {"성공 사례"}
                            </div>
                            <div className={"TitleSubtext"}>
                                {"데이터 분석의 도움을 받아 실제 연재 계약으로 이어진 작품입니다."}
                            </div>
                        </div>
                        <div className={"Webtoons"}>
                            <AutoPlaySwipeableViews index={slidePage} enableMouseEvents onChangeIndex={(index: number)=>setSlidePage(index)}>
                                {slidesData.map( (slide) => (
                                    <div className={`${slide.divClass}`} key={slide.id}>
                                        <a
                                            href={slide.href
                                                ?slide.href
                                                :''}
                                            onClick={slide.onClick} 
                                        >
                                            <img className={'WebtoonImg'} src={slide.imgSrc} alt={slide.alt} 
                                            />
                                            {slide.label
                                            ?<span className={'SlideLabel'}>{slide.label}</span>
                                            :<></>}
                                        </a>
                                    </div>
                                ))}
                            </AutoPlaySwipeableViews>
                            <div className={"ArrowPrev"} onClick={handleChangePrev}>
                                <ChevronLeftIcon />
                            </div>
                            <div className={"ArrowNext"} onClick={handleChangeNext}>
                                <ChevronRightIcon />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PostScript