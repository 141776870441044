import { fn } from '../../../Functions';
import { checkHasLastSyllable } from '../Util/Functions';
import './Styles/FrequentlyUsedMaterial.scss';

const FrequentlyUsedMaterial =({
    searchText,
    topic, data
}: {
    searchText: string,
    topic: string,
    data: {[Key: string]: number}
}) => {
    
    return <div className={'FrequentlyUsedMaterialArea'}>
        <div className={'Title'}>
            <span className={'SearchText'}>{topic}</span>{`${checkHasLastSyllable(topic)?'과':'와'} 함께 자주 쓰이는 토픽`}
        </div>
        <div className={'FrequentlyUsedKeywords'}>
            {Object.keys(data).map( keyword => 
                <div key={keyword} className={'Keyword'} onClick={()=>{
                    if ( searchText === keyword ) {
                        window.scrollTo({top: 0})
                    } else {
                        fn.goto(`/market/trendsearch?topic=${keyword}`)
                    }
                }} >
                    {keyword}
                </div>)}
        </div>
    </div>
}

export default FrequentlyUsedMaterial;