import React, { useState } from "react";

import './Styles/Hashtag.scss';
import { Close } from "@material-ui/icons";
import { Dialog } from "@material-ui/core";
import { Circle } from "../../../Page/Title/Template";
import { useToastAlert } from '@webtoontoday/toast';

const Hashtag = ({
    tags: givenTags,
    setTags,
    placeholder,
    fullWidth=false
}:{
    tags: string[],
    placeholder?: string,
    setTags: (value: string[]) => void,
    fullWidth?: boolean
}) => {
    const representativeTags = givenTags.slice(0,2);
    const tags = givenTags.slice(2);

    const { toastAlert } = useToastAlert();

    const [ text, setText ] = useState<string>('');
    const [ isPreviewOpen, setIsPreviewOpen ] = useState<boolean>(false);

    const addKeywordFromText = ( text: string ) => {
        setText("");
        let newRepTags = ["",""]
        
        if (representativeTags.length > 0) {
            newRepTags = newRepTags.concat(representativeTags).reverse().slice(0,2).reverse();
        }
        setTags([...newRepTags, ...tags].concat(text));
    }

    const deleteTag = ( deletingTag: string ) => {
        const filteredRepresentativsTags = representativeTags.filter(tag=>tag && tag !== deletingTag).concat(["",""]).slice(0,2);
        const filteredTags = tags.filter(tag => tag !== deletingTag);

        setTags( filteredRepresentativsTags.concat(filteredTags) );
    }
    
    return(<div className={'HashtagArea'}>
        <div className={'HashtagInner Adding'}>
            <div className={`HashtagContainer ${fullWidth?'FullWidth':''}`}>
                {tags.map( tag => (<div className={`HashtagBox`} key={`HashtagItem:${tag}`} >
                    <div className={`HashtagItem`} >
                        {'# ' + tag}
                    </div>
                    <Close className={'DeleteTagButton'} onClick={ () => deleteTag(tag) }  />
                </div>))}

                {tags.length < 5
                    ?<input 
                        value={text} 
                        placeholder={`${tags.length === 0?"작품을 표현할 수 있는 태그를 입력해주세요.":""}`}
                        onChange={(e) => {
                            if ( e.target.value.length > 6 ) return;
                            
                            let value = e.target.value;
                            if (value.match(/[\s]/g)){
                                value = e.target.value.replace(/\s/g, "");
                            };
                            
                            setText(value);
                        }}
                        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                            const value = ( e.target as HTMLInputElement).value
                            if ( value.length === 0 
                                && e.key === 'Backspace'
                            ) {
                                if (tags.length < 1) return;

                                const newTags = [...representativeTags,...tags];
                                newTags.pop();
                                setTags(newTags);
                            } else if ( value.length > 0 
                                &&  ( e.key === 'Enter' || e.key === 'Tab' )
                                &&  !e.nativeEvent.isComposing 
                            ) {
                                if ( tags.includes(value) ) {
                                    toastAlert('이미 등록한 해시태그예요.');
                                    return;
                                }
                                
                                addKeywordFromText(text);
                            }
                        }}
                        style={{
                            width: `${tags.length === 0?'100%':'70px'}`
                        }}
                    />:<></>}
            </div>
            <BottomSubTextLine 
                supplementText={'태그 당 최대 6글자'}
                restriction={'최대 5개'}
            />
        </div>
        {tags.length > 0?<div className={'HashtagInner Selecting'} >
            <div className={'ExampleButton'} onClick={()=>setIsPreviewOpen(true)} >
                {'미리보기'}
            </div>
            <div className={`HashtagContainer ${fullWidth?'FullWidth':''}`}>
                {tags.map( tag => (<div 
                    className={`HashtagItem ${representativeTags.includes(tag)?'Representative':''} ${representativeTags.filter( tag => tag ).length === 2 && !representativeTags.filter( tag => tag ).includes(tag)?'Disabled':'' }`} 
                    key={`selectingHashtag:${tag}`}
                    onClick={ (e) => {
                        if (    representativeTags.filter(tag => tag).length === 2 
                            && !representativeTags.includes(tag) ) {
                            return
                        }

                        if ( representativeTags.includes(tag) ){
                            const filteredTags = representativeTags.filter( repTag => repTag && repTag !== tag)
                            setTags(filteredTags.concat(["",""]).slice(0,2).concat(tags));
                        } else {
                            let newSelectedTags = ["",""];
                            const filteredRepresentativsTags = representativeTags.filter( tag => tag );

                            if (filteredRepresentativsTags.length === 0 ){
                                newSelectedTags[0] = tag;
                            } else if ( filteredRepresentativsTags.length >= 1 ) {
                                newSelectedTags = filteredRepresentativsTags.reverse().slice(0,1).concat(tag);
                            }
                            setTags(newSelectedTags.concat(tags));
                        }
                    } }
                >
                    {'# ' + tag}
                </div>))}
            </div>
            <BottomSubTextLine 
                supplementText={'작품 홈에서 노출하고 싶은 해시태그를 선택해주세요.'}
                restriction={'최대 2개'}
            />
        </div>:<></>}
        <Dialog open={isPreviewOpen} onClose={ () => setIsPreviewOpen(false) } >
            <Circle
                title={{
                    title: '해시태그 예시', author: '', themeColor: 'rgb(255,255,255)', 
                    thumbnail: 'https://static.webtoon.today/ddah/templatePreviewLogo.png',
                    keywords: representativeTags.join(',')
                }}
            />
        </Dialog>
    </div>)
}

const BottomSubTextLine = ({supplementText, restriction}:{supplementText: string, restriction: string}) => {

    return(<div className={'BottomSupplementLine'} >
        <div className={'SupplementText'}> {supplementText} </div>
        <div className={'TagRestriction'}> {restriction} </div>
    </div>)
}

export default Hashtag;