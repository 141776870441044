import { Button, TextField } from '@material-ui/core';
import React from 'react';
import { logBehavior } from '../../../../Data/Behavior';
import './ContestEnd.scss';
import { fn, validateEmailForm } from '../../../../Functions';
import { verifyEmail } from '../../../../Data/Email';
import { readLoginInfo } from '../../../../Data/Account';
import { useSession, useSignIn } from '../../../../Recoil/Session/Session';
import { useToastAlert } from '@webtoontoday/toast';

let ceremonyEndUnixTime  = new Date("2023-01-13 08:00:00+09:00").getTime()/1000;
let currentUnixTime      = new Date().getTime()/1000;

const ContestEnd = () => {
    const { toastAlert } = useToastAlert();
    const { session } = useSession();
    const { EmailTokenSignin } = useSignIn();

    const [ openEmailLogin, setOpenEmailLogin ] = React.useState(false);
    const [ onAppliedEmailVerifying, setOnAppliedEmailVerifying ] = React.useState(false);
    const [ isLoading, setIsLoading ] = React.useState(false);
    const [ appliedEmail, setAppliedEmail ] = React.useState("");
    const [ otpForCheking, setOtpForChecking ] = React.useState("");
    const [ isOtpForCheckingFailed, setIsOtpForChekingFailed ] = React.useState(false);
    const [ isAppliedEmailVerified, setIsAppliedEmailVerified ] = React.useState(false);

    return (
        <div className={'ContestEndArea'} >
            {currentUnixTime < ceremonyEndUnixTime
                ?<><img src={'https://static.webtoon.today/ddah/2022university-end.svg'} alt={'2022university-end'} />
                    <div className={'AnnounceText'} >
                        {'공모전 결과는 2023년 1월 13일 금요일에 발표 예정입니다.'}
                    </div>
                    <div className={'AnnounceText'} >
                        {'2022 과제 부활전에 많은 관심 주셔서 감사합니다!'}
                    </div></>:<></>}
            <div className={'InnerArea'}>
                <div className={'SearchSumbittedWebtoons'} style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'stretch'}}>
                    <Button
                        disableElevation type={"submit"} 
                        className={`SubmitButton`}
                        style={{color: 'rgb(255,255,255)', backgroundColor: 'rgb(0,176,240)', maxWidth: 480, width: '100%', margin: '50px 0 0 0', fontSize: '1.1rem', paddingTop: 10, paddingBottom: 10}}
                        disabled={isLoading}
                        onClick={ async () => {
                            if (session && session.memberState && session.memberState === 'regular') {
                                fn.goto('/portfolio');
                            } else {
                                setOpenEmailLogin(true);
                            }
                        }}
                    >
                        {"제출한 작품 확인하기"}
                    </Button>
                    {openEmailLogin
                        ?<div style={{width: '100%', marginBottom: 15, marginTop: 20, maxWidth: 480}} >
                            <label style={{fontWeight: 'bold', marginBottom: 5}}>{"이메일 "}
                                <span className={"redAsterisk"}>{"*"}</span>
                            </label>
                            <div className={`AppliedEmailVerifying`}>
                                <TextField
                                    onBlur={()=>{
                                        if ( appliedEmail !== "" ) {
                                            logBehavior('input-email',{})
                                        }
                                    }}
                                    disabled={ onAppliedEmailVerifying || isAppliedEmailVerified } 
                                    className={"Tags"}
                                    value={appliedEmail}
                                    size={"small"}
                                    onChange={(event) => setAppliedEmail(event.target.value)}
                                    style={{flex: 1}}
                                    autoFocus={openEmailLogin}
                                />
                                <Button color={'primary'} disableElevation onClick={ async () => {
                                        setIsLoading(true);

                                        const result = await verifyEmail(appliedEmail);
                                        if (result) {
                                            logBehavior('input-onAppliedEmailVerifying',{});
                                            setOnAppliedEmailVerifying(true);
                                        };

                                        setIsLoading(false);
                                    }}
                                    className={`VerifyingButton`}
                                    disabled={onAppliedEmailVerifying || isAppliedEmailVerified || !validateEmailForm(appliedEmail)}
                                >
                                    {"메일인증"}
                                </Button>
                            </div>
                        </div>:<></>}
                    {onAppliedEmailVerifying
                        ?<div style={{width: '100%', maxWidth: 480}} >
                            <label className={"Labels"}>{"인증키"}
                                <span className={"redAsterisk"}>{"*"}</span>
                            </label>
                            <div className={"VerifiedOtp"}>
                                <TextField
                                    onBlur={()=>{
                                        if ( otpForCheking !== "" ){
                                            logBehavior('input-otpForCheking',{})
                                        }
                                    }}
                                    className={"Tags"} 
                                    value={otpForCheking}
                                    size={"small"}
                                    onChange={(event) => setOtpForChecking(event.target.value)}
                                    style={{flex: 1}} disabled={isLoading}
                                    autoFocus={onAppliedEmailVerifying}
                                />
                                <Button color={"primary"} disableElevation onClick={async () => {
                                        setIsLoading(true);

                                        const {isEmailLoginable} = await readLoginInfo(appliedEmail);

                                        if  ( isEmailLoginable ) {
                                            const response = await EmailTokenSignin(appliedEmail, otpForCheking);
                                            
                                            if (response) {
                                                setIsAppliedEmailVerified(true);
                                                logBehavior('input-appliedEmailVerified',{});
                                                setOnAppliedEmailVerifying(false);
                                                
                                                fn.goto('/portfolio');

                                                setIsLoading(false);
                                                return;
                                            } else {
                                                setIsOtpForChekingFailed(true);
                                                setIsLoading(false);
                                                return;
                                            };
                                        } else {
                                            toastAlert('현재 이메일로 신청된 작품이 없습니다.', 3000);
                                        };

                                        setIsLoading(false);
                                    }}
                                    className={"VerifiedOtpButton"} disabled={isLoading}
                                >
                                    {"인증하기"}
                                </Button>
                            </div>
                            {isOtpForCheckingFailed
                                ?<div style={{fontSize: '0.8rem', color: 'red'}}>
                                    {"* 인증에 실패했어요. 인증키를 다시 확인해주세요."}
                                </div>
                                :<></>
                            }
                        </div>
                        :<></>}
                </div>
            </div>
        </div>
    )
};

export default ContestEnd;