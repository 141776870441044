import axios from 'axios';

import ss from './Session';

export const AskMDLToken = async () => {
    try{
        let res = await axios.post(`https://api.webtoon.today/phone-certification`,{
            type: 'ask_mdl_tkn'
        },{
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        })
        if (res.data){
            return res.data.MDL_TKN;
        } else {
            return null;
        }

    } catch(e) {
        console.error(e);
        return null;
    }
}

/**
 * 
 */
export const AskCertificate = async (mdl_tkn) => {

    if (!mdl_tkn){
        return new Error("no mdl token submitted");
    }

    let name = "실명인증";
    let option = "width = 430, height = 640, scrollbars = yes"
    let child = window.open("", name, option);

    if (child && child.closed){
        return new Error("popup blocked");
    }

    const form = child.document.createElement('form');

    const newHiddenInputWithValue = (name,value) => {
        let input = child.document.createElement('input');
        input.setAttribute('name', name);
        input.setAttribute('type', 'hidden');
        input.value = value;
        return input;
    }

    child.document.body.append(form);

    form.append( newHiddenInputWithValue('tc',     'kcb.oknm.online.safehscert.popup.cmd.P931_CertChoiceCmd') );
    form.append( newHiddenInputWithValue('cp_cd',  'V57760000000') );
    form.append( newHiddenInputWithValue('mdl_tkn', mdl_tkn) );
    form.append( newHiddenInputWithValue('target_id', '') );
    
    form.action = 'https://safe.ok-name.co.kr/CommonSvl';
    form.method = 'post';
    form.submit();

    return await new Promise((resolve,reject)=>{
        let timer = setInterval(()=>{
            if (child && child.closed){
                resolve(1);
                clearInterval(timer);
            }
        }, 100)
    })
}

export const FetchCertificationResult = async (mdl_tkn) => {

    if (!mdl_tkn){
        return new Error("no mdl token submitted");
    }

    try{
        let res = await axios.post(`https://api.webtoon.today/phone-certification`,{
            type: 'read_from_server',
            mdl_tkn
        },{
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        })
        if (res.data){
            return res.data;
        } else {
            return null;
        }

    } catch(e) {
        console.error(e);
        return null;
    }
}