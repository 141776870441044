import React, { CSSProperties, useEffect } from 'react'

import './DisplayAds.scss';

const DisplayAds = ({style = {}, adSlot='3664016133'}:{style?:CSSProperties, adSlot?: string}) => {
    useEffect(() => {
        const pushAd = () => {
            try {
                const adsbygoogle = (window as any).adsbygoogle;
                
                if (!adsbygoogle){
                    return;
                }

                adsbygoogle.push({});
            } catch (e) {
                console.error(e)
            }
        }

        let interval = setInterval(() => {
            const adsbygoogle = (window as any).adsbygoogle;
            // Check if Adsense script is loaded every 300ms
            if (!adsbygoogle) {
                return;
            }
            pushAd();
            // clear the interval once the ad is pushed so that function isn't called indefinitely
            clearInterval(interval);
        
        }, 300);

        return () => {
            clearInterval(interval);
        }
  }, [])

  return (
    <ins
        className='adsbygoogle'
        style={{display:'block', ...style}}
        data-ad-client='ca-pub-8155206596802945'
        data-ad-slot={adSlot}
    ></ins>
  )
}

export default DisplayAds;
