import { fn } from '../../../Functions';
import './Styles/PopularMaterials.scss';

const PopularMaterials = ({ 
    topics, style, rowStyle
}: {
    topics: string[],
    style?: React.CSSProperties,
    rowStyle?: React.CSSProperties
}) => {
    
    return <div className={'PopularMaterialsTopicBox'} style={{...style}} >
        {topics.map((topic: string, index: number) => (
            <div className={'Topic'} key={`PopularMaterialsTopicBox:${topic}`} onClick={() => {
                fn.goto(`/market/trendsearch?topic=${topic}`)
            }} style={{...rowStyle}} >
                <div className={'TopicIndex'} >{(index + 1)}</div>{topic}
            </div>
        ))}
    </div>
}

export default PopularMaterials;