import { logBehavior } from "../../../Data/Behavior";

export const quarterDataKeyExtractor = ( key: string, count: number = 4 ) => {
    if (!key) return [];
    const [ year , quarter ] = key.replace(/q/,'').split('-');
    const quarterArray = ['1q','2q','3q','4q'] 

    let keyArray = [key];

    for (let i: number = 1; i < count; i++) {
        let newYear
        let newQuarter
        
        newQuarter = quarterArray[ ( (Number(quarter) - 1 - i ) % 4 + 4) % 4]
        newYear = Number(year) + Math.floor(( Number(quarter) - 1 - i) / 4)

        keyArray.push(([newYear, newQuarter]).join('-'))
    }

    return keyArray;
};

export const checkHasLastSyllable = (name: string): boolean => {
    const charCode = name.charCodeAt(name.length - 1);
    
    const consonantCode = (charCode - 44032) % 28;
    
    if(consonantCode === 0){
        return false;
    }
    
    return true;
}

export const shuffleArray = (array: any[]): any[] => {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }

    return array;
}

export const copyClipboard = async (
    text: string,
    successAction?: () => void,
    failAction?: () => void,
) => {
    try {
        await navigator.clipboard.writeText(text);
        successAction && successAction();
    } catch (error) {
        failAction && failAction();
    }
};

export const sharingPage = async ({
    title, text, url, log,
}: {
    title: string, text: string, url: string,
    log: {what: string, detail: object}
}) => {
    try{
        await navigator.share({
            title, url, text,
        });

        if (log.what) {
            logBehavior(log.what, log.detail);
        };
    } catch (e: any) {
        if (!e.includes("AbortError")){
            alert(`Error: ${e}`);
        };
    };
}


export const numericUnitConverter = ( income: string | number, floatUnit?: number ): string => {
    const strIncome = `${income}`
    if (!income) return strIncome;

    const number: number = Number(income)
    if (!number) return strIncome;

    const units = [ '', '만', '억', '조', '경']
    const unitOfIncome = Math.floor(((strIncome || '').length - 1) / 4)
    const floatingPoint = typeof floatUnit === "number"?floatUnit:unitOfIncome

    return `${(number / (10000 ** unitOfIncome)).toFixed(floatingPoint)}${units[unitOfIncome]}`
}