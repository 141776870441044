import React from 'react';
import { Button } from '@material-ui/core';
import {fn} from '../../Functions';
import { useSetRecoilState } from 'recoil';
import { pageHeaderOptions } from '../../Recoil/PageHeader/PageHeader';

const UnregisterComplete = () => {

    const setPageHeader = useSetRecoilState(pageHeaderOptions);
    React.useEffect(()=>{
        setPageHeader({
            titleText: '회원 탈퇴 완료',
            headerStyle: {borderBottom: '1px rgb(231,231,231) solid'}
        })
    },[setPageHeader])

    return (
        <div className={'UserPageArea'}>
            <div className={'BodyContainer'} >
                <div className={'ImgCenterBox'}>
                    <img src={'https://static.webtoon.today/ddah/logo-03.png'} alt={'logo-03'} style={{width: 70}} />
                </div>
                <div className={'TextCenterBox'}>
                    <div className={'ProcessStateText'} >
                        {'회원 탈퇴가 완료되었습니다.'}
                    </div>
                    <div  className={'UserDataInformText'} >
                        {'고객님의 소중한 회원 정보는 즉시 삭제됩니다.'}
                    </div>
                    <div  className={'GreetingText'} >
                        {`그동안 오늘의웹툰과 함께해주셔서 감사합니다.
                    더 나은 모습으로 다시 만나뵐게요.`}
                    </div>
                </div>
            </div>
            <div className={'Footer'} style={{paddingLeft: 16, paddingRight: 16}}>
                <Button fullWidth className={'SaveButton'} variant={'contained'} color={'primary'} onClick={ () => {
                    fn.goto('/')
                }} >
                    {'확인'}
                </Button>
            </div>
        </div>
    )
}

export default UnregisterComplete;