
const contestStartUnixTime               = new Date("2024-12-16 00:00:00+09:00").getTime()/1000;
const contestEndUnixTime                 = new Date("2025-01-01 00:00:00+09:00").getTime()/1000;
const ceremonyEndUnixTime                = new Date("2025-01-13 14:00:00+09:00").getTime()/1000;
const afterYearFromCeremoneyEndUnixTime = new Date("2026-01-01 00:00:00+09:00").getTime()/1000; 

const currentUnixTime = Date.now()/1000;

const isBeforeCompetition = currentUnixTime < contestStartUnixTime;

const isOnSubmission  = currentUnixTime > contestStartUnixTime 
                        && currentUnixTime < contestEndUnixTime;

const isUnderReview   = currentUnixTime > contestEndUnixTime
                        && currentUnixTime < ceremonyEndUnixTime;

const isCeremoneyEnd  = currentUnixTime > ceremonyEndUnixTime;

const isLockedMonth   = isCeremoneyEnd 
                        && currentUnixTime < afterYearFromCeremoneyEndUnixTime;

const isDeletablePeriod     = currentUnixTime > afterYearFromCeremoneyEndUnixTime;

                                            
const currentSchoolCompetitionYear = "2024";

export {
    isBeforeCompetition, 
    isOnSubmission, 
    isUnderReview, 
    isCeremoneyEnd, 
    isLockedMonth, 
    isDeletablePeriod,

    currentSchoolCompetitionYear
};
