import React from 'react';
import { Visibility, Favorite, ChevronRight } from '@material-ui/icons';
import './Square.scss';


const Square = ({title, isFavoriteTitle = false}) => {

    let keywords = [];
    if (title.keywords.indexOf(',') > 0) {
        const selectedKeywords = title.keywords.split(',').slice(0,2).filter(keyword => keyword);
        keywords = selectedKeywords;
    };

    return(
        <div 
            className={`SquareTemplateContainer ${title.themeColor==='rgb(255,255,255)'?'WhiteCircleTemplate':''}`} 
            style={{backgroundColor: `${title.themeColor==='rgb(255,255,255)'?'rgb(250,250,250)':title.themeColor}`}} 
        >
            <div className={'TitleName'} >
                {title.title}
            </div>
            <div className={'TitleThumbnail'} >
                <div className={'ThumbnailSideBar KeywordSide'} >
                    <div className={'HorizentalLine'} >
                        <div style={{borderRight: '1px solid rgb(3,3,3)'}} />
                    </div>
                    {keywords.map( ( keyword, index ) => (
                        <div className={'Background'} key={`Basic:${keyword}:${index}`} style={{display: `${!keyword?'none':'block'}`}} >
                            <div className={'Keyword'} >
                                {`#${keyword}`}
                            </div>
                        </div>
                    ))}
                </div>
                <div style={{width: 296, height: 296, position: 'relative'}}>
                    <img src={title.thumbnail} alt={'img'} style={{width: 296, height: 296, objectFit: 'cover' }} />
                </div>
                <div className={'ThumbnailSideBar AuthorSide'} >
                    <span className={'Text'} >
                        {title.author}
                    </span>    
                    <div className={'HorizentalLine'}>
                        <div style={{borderRight: '1px solid rgb(3,3,3)'}} />
                    </div>
                </div>
            </div>
            <div style={{maxWidth: 328, width: '100%'}}>
                <div className={'StatisticsOfTitle'} >
                    <div className={'StatisticsCell'} >
                        <Visibility fontSize={'small'} style={{color: 'rgb(51,51,51)'}} />
                        <span style={{textAlign: 'center'}}>
                            {`조회 ${title.titleViewCount || 0}명`}
                        </span>
                    </div>
                    <div className={'DevidingLine'} >
                        {'l'}
                    </div>
                    <div className={'StatisticsCell'} >
                        <Favorite fontSize={'small'} style={{color: 'rgb(51,51,51)'}} />
                        <span style={{textAlign: 'center'}}>
                            {`관심 ${title.subscriptionCount || 0}`}
                        </span>
                    </div>
                    <div className={'DevidingLine'} >
                        {'l'}
                    </div>
                    <div className={'StatisticsCell'} >
                        <span style={{width:20, height:20, display: 'flex',justifyContent: 'center', alignItems: 'center' }}>
                            <ChevronRight style={{color: 'rgb(255,255,255)', backgroundColor: 'rgb(51,51,51)', borderRadius: '50%', width: 16, height: 16}} />
                        </span>
                        <span style={{textAlign: 'center'}}>
                            {`연독률 ${title.continueRate || 0}%`}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Square;