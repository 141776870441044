
const contestStartUnixTime               = new Date("2023-12-18 00:00:00+09:00").getTime()/1000;
const contestEndUnixTime                 = new Date("2024-01-01 00:00:00+09:00").getTime()/1000;
const ceremonyEndUnixTime                = new Date("2024-01-15 14:00:00+09:00").getTime()/1000;
const afterMonthFromCeremoneyEndUnixTime = new Date("2024-02-15 14:00:00+09:00").getTime()/1000; 

const currentUnixTime = Date.now()/1000;

const isBeforeCompetition = currentUnixTime < contestStartUnixTime;

const isOnSubmission  = currentUnixTime > contestStartUnixTime 
                        && currentUnixTime < contestEndUnixTime;

const isUnderReview   = currentUnixTime > contestEndUnixTime
                        && currentUnixTime < ceremonyEndUnixTime;

const isCeremoneyEnd  = currentUnixTime > ceremonyEndUnixTime;

const isLockedMonth   = isCeremoneyEnd 
                        && currentUnixTime < afterMonthFromCeremoneyEndUnixTime;

const isDeletablePeriod     = currentUnixTime > afterMonthFromCeremoneyEndUnixTime;
                                            
const currentSchoolCompetitionYear = "2023";

export {
    isBeforeCompetition, 
    isOnSubmission, 
    isUnderReview, 
    isCeremoneyEnd, 
    isLockedMonth, 
    isDeletablePeriod,

    currentSchoolCompetitionYear
};
