import React, { useState } from 'react';
import ChannelService from '../../../Component/ChannelService';
import { fn } from '../../../Functions';

import "./Styles/Footer.scss";

const Footer = () => {

    const [ isOpened, setIsOpened ] = useState<boolean>(false);

    return (
    <div className={'Footer'}>
        <div className={'FooterMenu'}>
            <span className={'BizInfo'} onClick={(e)=>{
                    setIsOpened(!isOpened);
                }}>
                <span>
                    {'사업자 정보'}
                </span>
                <span className={`DetailButton`}>
                    <img src={'https://static.webtoon.today/ddah/chevron.svg'} alt={'보기'} className={`${isOpened?``:`Closed`}`}/>
                </span>
            </span>
            <span className={'Pipe'}>{'|'}</span>
            <a href={'https://webtoon.today'} onClick={fn.gotoByAnchor}>            
                <span className={'ServiceIntroduction'}>
                {'서비스 소개'}
                </span>
            </a>
            <span className={'Pipe'}>{'|'}</span>
            <a href={'https://webtoon.today/privacypolicy/kr'} onClick={fn.gotoByAnchor}>
                <span className={'PrivacyPolicy'}>
                {'개인정보처리방침'}
                </span>
            </a>
            <span className={'Pipe'}>{'|'}</span>
            <a href={'https://webtoon.today/notice'} onClick={fn.gotoByAnchor}>
                <span className={'Notice'}>
                {'공지사항'}
                </span>
            </a>
            <span className={'Pipe'}>{'|'}</span>
            <a href={'https://webtoon.today/#QnA'} onClick={fn.gotoByAnchor}>
                <span className={'FAQ'}>
                {'자주 묻는 질문'}
                </span>
            </a>
            <span className={'Pipe'}>{'|'}</span>
            <span className={'Inquiry'} onClick={()=>ChannelService.ChannelIO()('openChat')}>{'문의하기'}</span>
        </div>
        {isOpened&&
        <div className={'CompanyDetail'}>
            <div className={'FirstLine'}>
                <span>{'상호: 주식회사 오늘의웹툰'}</span>
                <span>{'|'}</span>
                <span>{'대표자 : 진수글'}</span>
                <span>{'|'}</span>
                <span>{'사업자등록번호 : 765-81-02007'}</span>
                <span>{'|'}</span>
                <span>{'통신판매업신고번호 : 2023-서울강남-04131'}</span>
            </div>
            <div className={'SecondLine'}>
                <span>{'주소 : 서울특별시 강남구 테헤란로 2길 27, 1131호 (역삼동, 패스트파이브 빌딩)'}</span>
                <span>{'|'}</span>
                <span>{'전화번호 : 010-3479-0623'}</span>
            </div>
        </div>}
        <div className={'CompanyName'}>
            {'ⓒ 주식회사 오늘의웹툰'}
        </div>
    </div>
    );
}

export default Footer;