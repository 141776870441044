import React from 'react';

import './FloatingCard.scss';

const FloatingCard = ({ className="", style, children}) => {
    return (
    <div className={"FloatingCard "+className } style={style}>
        {children}
    </div>);
}

export default FloatingCard;