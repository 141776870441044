import React from 'react';
import { Visibility, Favorite, ChevronRight } from '@material-ui/icons';
import './Circle.scss';

const Square = ({title, isFavoriteTitle = false}) => {

    const [ isReaderButtonTooltipOn, setIsReaderButtonTooltipOn] = React.useState(false);

    React.useEffect(()=>{

        let timer = setTimeout( () => setIsReaderButtonTooltipOn(false) , 700 );

        return ()=>{ 
            clearTimeout(timer) 
        }

    }, [isReaderButtonTooltipOn]);
    
    let keywordsArray = [];
    
    if (title.keywords.indexOf(',') > 0) {
        const selectedKeywords = title.keywords.split(',').slice(0,2).filter(keyword => keyword);

        for (const keyword of selectedKeywords) {
            const sharpAddedKeyword = '#' + keyword;
            keywordsArray.push(sharpAddedKeyword);
        };
    };
    
    const isWhiteThemeColor = () => {
        if (title.themeColor === 'rgb(255,255,255)'){
            return true;
        } else {
            return false;
        };
    };
    
    return(
        <div 
            className={`CircleTemplateContainer ${isWhiteThemeColor()?'WhiteCircleTemplate':''}`} 
            style={{backgroundColor: `${isWhiteThemeColor()?'rgb(250,250,250)':title.themeColor}`}} 
        >
            <div className={'TitleName'} >
                {title.title}
            </div>
            <div className={'TitleThumbnail'} >
                <div className={'TextBoxOnCircle'} style={{height: 354}}>
                    {(title.author || '' ).split('')
                        .map( (letter, index) => (
                            <span className={'RotatedTextBar'} key={`circleAuthor:${index}${letter}`} 
                                style={{
                                    height: 354,
                                    transform: `rotate(${(-title.author.length / 2 * 8 + 4) + (index * 8)}deg)`,
                                }}
                            >
                                <span className={`AuthorLetter ${isWhiteThemeColor()?'AuthorWhiteLetter':''}`} style={{backgroundColor: `${isWhiteThemeColor()?'rgb(250,250,250)':title.themeColor}`}} >
                                    {letter}
                                </span>
                            </span>
                    ))}
                </div>
                <img src={title.thumbnail} alt={'img'} style={{width: 296, height: 296, borderRadius: '50%', objectFit: 'cover' }} />
                <div className={'TextBoxOnCircle'} style={{height: 354, display: `${!title.keywords?'none':'block'}`}} >
                    {keywordsArray
                        .join(' ').split('').reverse()
                        .map( (letter, index) => (
                            <span className={'RotatedTextBar'} key={`circleKeyword:${index}${letter}`}
                                style={{
                                    height: 354,
                                    transform: `rotate(${(-Math.floor(keywordsArray.join(' ').length / 2) * 8 + ((keywordsArray.join(' ').length) % 2 === 0?4:0)) + 180 + (index * 8)}deg)`,
                                }}
                            >
                                {letter === ' '?
                                <div className={`LetterOfKeyword ${isWhiteThemeColor()?'WhiteLetterOfKeyword':''}`} style={{backgroundColor: `${isWhiteThemeColor()?'rgb(250,250,250)':title.themeColor}`, color: title.themeColor, width: 16, height: 24}}>
                                    {letter}
                                </div>
                                :<div className={`LetterOfKeyword ${isWhiteThemeColor()?'WhiteLetterOfKeyword':''}`} style={{backgroundColor: `${isWhiteThemeColor()?'rgb(250,250,250)':title.themeColor}`}}>
                                    {letter}
                                </div>}
                            </span>
                    ))}
                </div>
            </div>
            <div style={{maxWidth: 328, width: '100%'}}>
                <div className={'StatisticsOfTitle'} >
                    <div className={'StatisticsCell'} >
                        <Visibility fontSize={'small'} style={{color: 'rgb(51,51,51)'}} />
                        <span>
                            {`조회 ${title.titleViewCount || 0}명`}
                        </span>
                    </div>
                    <div className={'DevidingLine'} >
                        {'l'}
                    </div>
                    <div className={'StatisticsCell'} >
                        <Favorite fontSize={'small'} style={{color: 'rgb(51,51,51)'}} />
                        <span>
                            {`관심 ${title.subscriptionCount || 0}`}
                        </span>
                    </div>
                    <div className={'DevidingLine'} >
                        {'l'}
                    </div>
                    <div className={'StatisticsCell'} >
                        <span style={{width:20, height:20, display: 'flex',justifyContent: 'center', alignItems: 'center' }}>
                            <ChevronRight style={{color: 'rgb(255,255,255)', backgroundColor: 'rgb(51,51,51)', borderRadius: '50%', width: 16, height: 16}} />
                        </span>
                        <span>
                            {`연독률 ${title.continueRate || 0}%`}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Square;