import { Button } from "@material-ui/core";
import React from 'react';
import { useGoogleLogin } from "@react-oauth/google";

const GoogleIcon = 'https://static.webtoon.today/challenge/GoogleIcon.png';

const GoogleLogin = ({ onSuccess }) => {

    const googleSocialLogin = useGoogleLogin({
        flow: 'auth-code',
        onSuccess: async (codeResponse) => {
            onSuccess(codeResponse)
        },
    });
    
    return(
        <Button onClick={(e)=>{
                e.preventDefault();
                googleSocialLogin();
            }} 
            className={'Button'} style={{backgroundColor: 'rgb(255,255,255)', color:'black', height: '45px', border: '1px solid rgb(51,51,51)'}} >
            <img src={GoogleIcon} alt={'KakaoLogo'} style={{maxHeight: '20px', position: 'absolute', left: '15px'}} />
            {'Google로 계속하기'}
        </Button>
    )
}

export default GoogleLogin;
