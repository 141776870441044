
import axios from 'axios';
import ss from './Session';

export type WebtoonTitleType = {
    name: string;
    thumbnail: string;
    tags: string;
    title: string;
    titleId: string;
    titleViewCount: number;
    onlineDescription: string;
    popularity: string | null;
    rank: string | null;
    rating: string | null;
    reviewPolicy: string;
    storyType: string;
    subscriptionCount: number;
    weekday: string;
    author: string;
    authorId: string;
    serviceId: string;
    campaignId: string | null;
    continueRate: number;
    description: string;
    episodeCount: number;
    fullReadRate: number;
    exposure: string;
    href: string;
    isAdult: number;
    is_finished: number;
    keywords: string;
    musicAddress: string;
    illust_narrow: string;
    illust_wide: string;
    firstEpisodeHref: string;
    firstEpisodeId: string;
    firstEpisodeThumbnail: string;
    firstEpisodeTitle: string;
    firstEpisodeUpdatedAt: number;
    freeEpisodeCount: number;
    lastEpisodeHref: string;
    lastEpisodeId: string;
    lastEpisodeThumbnail: string;
    lastEpisodeTitle: string;
    lastEpisodeUpdatedAt: number;
    lastFreeEpisodeHref: string;
    lastFreeEpisodeId: string;
    lastFreeEpisodeThumbnail: string;
    lastFreeEpisodeTitle: string;
    lastFreeEpisodeUpdatedAt: number;
    themeColor: string;
    themeTemplate: string;
    slides: SlideType[];
    lastUpdatedAt: number;
    createdAt: number;
};

export type SlideType = {
    name: string;
    image: string;
    createdAt: number;
};




export const getTitle = async (serviceId: string | null, titleId: string | null): Promise<WebtoonTitleType[]> => {
    
    try{
        let res = await axios.post('https://challenge-api.webtoon.today/webtoon/title', {key : [serviceId, titleId].join(':')}, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });
        
        if (res.data && res.data.code === 200){
            
            return res.data.data;

        } else {
            return [];
        };

    }catch(e){
        if (axios.isAxiosError(e) && e.response && e.response.status === 404) {
            console.log('404 Error')
            return [];
        }
        return [];
    };
};

/**
 * 
 * @param {{serviceId: string, titleId: string}[]} titles 
 * @returns 
 */
export const getTitles = async (titles: {serviceId:string, titleId: string}[]) => {
    
    try{
        let res = await axios.post('https://challenge-api.webtoon.today/webtoon/title', {key : titles.map(({serviceId, titleId})=>[serviceId, titleId].join(':')).join(",")}, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });
        
        if (res.data && res.data.code === 200){
            return res.data.data;
        } else {
            return null;
        };

    }catch(e){
        return null;
    };
};


export const readSortedTitles = async ({method = ''}) => {
    
    try{
            
        let res = await axios.get(`https://cached-challenge-api.webtoon.today/webtoon/title?method=${method}`, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });

        if (res.data.code === 200 && res.data.data.length > 0) {
            return res.data.data;
        } else {
            return []
        };
    }catch(e){
        if (axios.isAxiosError(e) && e.response && e.response.status === 404) {
            console.log(e);
            return [];
        };
        console.error(e);
        return [];
    };
};

export const readSortedByTagsTitles = async ({ tags, storyType = '', sortType = '', limit = 10}:{tags: string, storyType?: string, sortType?: string, limit?: number}) => {

    try{

        let res = await axios.post(`https://challenge-api.webtoon.today/webtoon/title?`, {tags, storyType, sortType, limit}, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });

        if (res.data.code === 200 && res.data.data.length > 0) {
            return res.data.data;
        } else {
            return []
        };
    }catch(e){
        if (axios.isAxiosError(e) && e.response && e.response.status === 404) {
            console.log(e);
            return [];
        };
        console.error(e);
        return [];
    };
}

export const createTitle = async (title:any) => {

    try{
        let res = await axios.put('https://challenge-api.webtoon.today/webtoon/title', title, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });
        
        if (res.data && res.data.code === 200){
            
            return res.data.key;

        }else{
            return null;
        };

    }catch(e){
        return null;
    };
};

export const updateTitle = async (title: any) => {

    try{
        let res = await axios.patch('https://challenge-api.webtoon.today/webtoon/title', title, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });
        
        if (res.data && res.data.code === 200){

            return res.data.key;

        }else{
            return null;
        }
    }catch(e){
        console.error(e)
        return null;
    }
};

export const deleteTitle = async ({serviceId, titleId}: {serviceId: string, titleId:string}) => {

    try{
        let res = await axios.delete(`https://challenge-api.webtoon.today/webtoon/title?serviceId=${serviceId}&titleId=${titleId}`, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });

        if (res.data && res.data.code === 200){
            
            return res.data.key;

        }else{
            return null;
        }

    }catch(e){
        return null;
    };
};