import axios from "axios";
import ss from './Session';

const targetTypes = ['global', 'daily', 'thriller', 'history', 'drama', 'gag', 'fantasy', 'sport', 'action', 'emotional', 'romance'];

export const getFeedData = async (
    recommend: boolean = false,
    clickLogs: InteractionLogType[] = [],
    exposureLogs: InteractionLogType[] = [],
    type: 'global' | 'tags', target: string,
    offset: number, previousBlank: number,
    pageSize: number = 12,blank: number = 2
): Promise<FeedDataType | null> => {
    try {
        if(!targetTypes.includes(target)){
            return null;
        }
        let res = await axios.post('https://challenge-api.webtoon.today/webtoon/feed', {
            recommend,
            clickLogs,
            exposureLogs,
            type, target,
            offset, pageSize,
            blank, previousBlank
        }, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });

        if (res.data && res.data.code === 200){
            return res.data.data;
        }else{
            return null;
        }
    } catch(e) {
        return null;
    }
}