import axios from "axios";
import ss from './Session';


export const readEpisodeReaction = async ({ serviceId, titleId, episodeId }) => {
    
    try {
        let res = await axios.post('https://challenge-api.webtoon.today/interaction/episode-reaction', { serviceId, titleId, episodeId }, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });
        
        if (res.data.code === 200){
            return res.data.isReacted;
        } else {
            return null;
        };

    } catch (e) {

        console.error(e)
        return null;
    };
};

export const readEpisodeEmoticonReaction = async ({ serviceId, titleId, episodeId }) => {

    try {
        let res = await axios.post('https://challenge-api.webtoon.today/interaction/episode-reaction', { serviceId, titleId, episodeId }, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });

        if (res.data.code === 200){
            return res.data.reaction;
        } else {
            return null;
        };

    } catch (e) {

        console.error(e)
        return null;
    };
};

export const createEpisodeReaction = async ({ serviceId, titleId, episodeId }) => {
    
    try {
        let res = await axios.put('https://challenge-api.webtoon.today/interaction/episode-reaction', { serviceId, titleId, episodeId }, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });
        
        if (res.data.code === 200 && res.data.key){
            return res.data.key;
        } else {
            return null;
        };

    } catch (e) {

        console.error(e)
        return null;
    };
};

export const updateEpisodeReaction = async ({ serviceId, titleId, episodeId, reaction = 'none' }) => {

    try {
        let res = await axios.patch('https://challenge-api.webtoon.today/interaction/episode-reaction', { serviceId, titleId, episodeId, reaction }, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });

        if (res.data.code === 200 && res.data.key){
            return res.data.key;
        } else {
            return null;
        };

    } catch (e) {

        console.error(e)
        return null;
    };
};

export const deleteEpisodeReaction = async ({ serviceId, titleId, episodeId }) => {
    
    try {
        let res = await axios.delete(`https://challenge-api.webtoon.today/interaction/episode-reaction?serviceId=${serviceId}&titleId=${titleId}&episodeId=${episodeId}`, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });
        
        if (res.data.code === 200 && res.data.key){
            return res.data.key;
        } else {
            return null;
        };

    } catch (e) {

        console.error(e)
        return null;
    };
};