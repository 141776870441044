import React from 'react';
import './SignIn.scss';

import { Button, TextField, Checkbox, FormControlLabel, Dialog, 
    DialogContent, DialogActions, DialogTitle, } from '@material-ui/core';
import { ArrowForwardIos, } from '@material-ui/icons';

import { fn } from '../../Functions';

import { updateProfile } from '../../Data/Profile';
import { logBehavior } from '../../Data/Behavior';
import { useSession, useSignIn } from '../../Recoil/Session/Session';
import { useSetRecoilState } from 'recoil';
import { pageHeaderOptions } from '../../Recoil/PageHeader/PageHeader';

const PersonalSetting = ({email, name, setName, gender, otp, password, setGender, birthday, setBirthday, certificatedName, certificatedBirthday, certificatedPhoneNumber})=> {
    const { session, sessionRefresh } = useSession();
    const { EmailSignin } = useSignIn();

    if (!email){
        fn.goto('/signin');
    }

    const setPageHeader = useSetRecoilState(pageHeaderOptions);
    React.useEffect(()=>{
        setPageHeader({
            titleText: '회원가입',
            headerStyle: {borderBottom: '1px rgb(231,231,231) solid'}
        })
    },[setPageHeader])
    
    const [ consentedArray, setConsentedArray ] = React.useState([]);

    const consentList = [
        "이용 약관 동의 (필수)",
        "개인정보 수집 및 이용동의 (필수)",
        "마케팅 활용 동의 (선택)"
    ];

    const [ isOpenTermsOfService, setIsOpenTemsOfService ] = React.useState(false);
     
    const termsOfServiceClose = () => {
        setIsOpenTemsOfService(false);
    }
    
    const [ isAllChecked, setIsAllChecked ] = React.useState(false);
    
    const [ isDetailTermsOpen, setIsDetailTermsOpen ] = React.useState(false);

    const detailTermsClose = () => {
        setIsDetailTermsOpen(false)
    }

    const [ termsTypeIndex, setTermsTypeIndex ] = React.useState(null);

    return(
        <div className={'MainArea'}>
            <div className={'BodyContainer'}>
                <div className={'InnerInputBox'}>
                    <div className={'BoxLabel'}>
                        {'닉네임'}
                    </div>
                    <TextField fullWidth value={name || ''} placeholder={'오늘의 웹툰에서 사용할 이름을 입력해주세요.'} onChange={(event)=>{
                        setName(event.target.value)
                    }} inputProps={{style:{fontSize: '0.9rem'}}} style={{marginTop: '10px'}} />
                </div>
                <div className={'InnerInputBox'}>
                    <div className={'BoxLabel'}>
                        {'성별'}
                    </div>
                    <div className={'GenderGroup'} >
                        {Object.entries({M: '남자',W: '여자',N: '알리지 않음'}).map(([key, label],index) => (
                            <Button className={`${key === gender?'FocusedButton':''}`} disableElevation style={{borderRadius: 0, width: '33%', padding: '5px 10px'}} variant={'outlined'} 
                                key={`${key}`} onClick={()=> setGender(key)} >
                                {label}
                            </Button>
                        ))}
                    </div>
                </div>
                <div className={'InnerInputBox'}>
                    <div className={'BoxLabel'}>
                        {'생년월일'}
                    </div>
                    <div style={{marginTop: '10px'}}>
                        <TextField
                            type={'date'} fullWidth
                            value={birthday}
                            onChange={ (e) => {
                                setBirthday(e.target.value)
                            }}
                            inputProps={{
                                style:{color: birthday?'black':'lightgray'},
                                max:(new Date()).toISOString().substring(0,10)
                            }}
                        />
                    </div>
                </div>
                <Button variant={'contained'} color={'primary'} className={'NextModeButton'}
                    disabled={ !name || !gender || !birthday } 
                    onClick={()=>{
                        if ( !name ){
                            alert('이름을 입력해주세요.');
                        } else if (!gender) {
                            alert('성별을 선택해주세요.');
                        } else if (!birthday) {
                            alert('생년월일을 선택해주세요.')
                        }
                        setIsOpenTemsOfService(true)
                    }} 
                >{'다음'}</Button>
            </div>
            <Dialog className={'SignInTermsOfServiceConsentDialog'} onClose={termsOfServiceClose} open={isOpenTermsOfService}
                PaperProps={{
                    style:{margin: 0, borderRadius: '4px 4px 0 0', width: '100%'}
                }} >
                <DialogTitle style={{padding: '32px 0 16px 32px'}} >
                    <div style={{fontSize: '1rem'}}>
                        {`서비스 이용약관에 동의해주세요.`}
                    </div>
                </DialogTitle>
                <DialogContent dividers style={{border: 0, padding: '0 32px 16px'}}>
                    <div>
                        <div >
                            <FormControlLabel style={{margin: 0}}
                                control={<Checkbox color={'primary'} checked={isAllChecked} size={'small'} onChange={(e)=>{
                                    if (!e.target.checked) {
                                        setConsentedArray([]);
                                        setIsAllChecked(false);
                                    } else if (e.target.checked) {
                                        const newList = []
                                        for (let text of consentList) {
                                            newList.push(text)
                                        }
                                        setConsentedArray(newList)
                                        setIsAllChecked(true)
                                    }
                                }} />}
                                labelPlacement={"end"}
                                label={<div style={{fontSize: '0.9rem', color: 'rgb(0,176,240)'}}>{"약관 전체 동의"}</div>}
                            />
                        </div>
                        <div style={{borderTop: '1px solid lightgray', margin: '10px 0'}} />
                        <div>
                            {consentList.map(consentText => (
                            <div style={{display: 'flex', justifyContent:'space-between', alignItems:'center'}} key={consentText}>
                                <FormControlLabel style={{margin: 0}} 
                                    control={<Checkbox color={'primary'} checked={consentedArray.includes(consentText)} size={'small'}
                                    onChange={(e)=>{
                                        if (!e.target.checked) {
                                            setConsentedArray(consentedArray.filter(consent => consent !== consentText))
                                        } else if (e.target.checked) {
                                            setConsentedArray(consentedArray.concat(consentText))
                                        }
                                    }} />}
                                    labelPlacement={"end"}
                                    label={<div style={{fontSize: '0.9rem'}}>{consentText}</div>}
                                />
                                <ArrowForwardIos style={{color: 'lightgray', borderLeft:'1px solid rgb(230,230,230)', paddingLeft: '15px', cursor: 'pointer'}} fontSize={'small'} 
                                onClick={ () => {
                                    const termsTypeIndex = consentList.indexOf(consentText)
                                    setTermsTypeIndex(termsTypeIndex)
                                    setIsDetailTermsOpen(true)
                                }} />
                            </div>
                            ))}
                        </div>
                    </div>
                </DialogContent>
                <DialogActions style={{ padding: '0 32px 16px 32px'}} >
                    <Button autoFocus fullWidth color={'primary'} variant={'contained'} 
                        onClick={() =>{

                            (async ()=>{
                                try{
                                    if (password){
                                        await EmailSignin({email, otp, password, addressid: session.userid, name, gender, birthday,})
                                        await updateProfile({name, email, certificatedName, certificatedBirthday, certificatedPhoneNumber})
                                        await sessionRefresh()
                                    }else{
                                        await updateProfile({name, email, addressid: session.userid, gender, birthday})
                                        await sessionRefresh()
                                    }
                                    
                                    logBehavior('signin(success)', {name, email, addressid: session.userid, gender, birthday, certificated: certificatedName?true:false })
                                    logBehavior('signup', {name, email});
                                    fn.goto('/signin/complete')
                                } catch (e) {
                                    logBehavior('singin(fail)', {name, email, addressid: session.userid, gender, birthday, certificated: certificatedName?true:false} )
                                    console.error(e)
                                }
                            })()
                        }}
                        disabled={!consentedArray.includes("개인정보 수집 및 이용동의 (필수)") || !consentedArray.includes("이용 약관 동의 (필수)")}>
                        {'가입하기'}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog fullScreen onClose={detailTermsClose} open={isDetailTermsOpen} style={{maxWidth: '600px', margin: '0 auto'}} >
                <DialogContent >
                    <div style={{width: '100%', height: '100%'}}>
                        {termsTypeIndex === 0
                            ?<iframe title={'termsOfUse'} src={'https://webtoon.today/termsofuse/kr'} alt={'termsofuse'} style={{width: '100%',height: '80vh',maxHeight: '100%', border: 0}} />
                        :termsTypeIndex === 1
                            ?<iframe title={'privacypolicy'} src={'https://webtoon.today/privacypolicy/kr'} alt={'privacypolicy'} style={{width: '100%',height: '80vh',maxHeight: '100%', border: 0}} />
                        :termsTypeIndex === 2
                            ?<div style={{padding: '30px 30px 30px 50px', fontSize: '108%'}}>
                                <p>{'마케팅 정보 활용 동의'}</p>
                                {`마케팅 정보 활용에 동의하지 않으셔도 오늘의웹툰 서비스는 정상적으로 이용하실 수 있습니다.
                                동의할 경우 각종 소식 및 이벤트 관련 안내가 이메일로 전송됩니다.`}
                                <div style={{paddingLeft: 20, fontSize: '97.2%'}}>
                                    <p>{'마케팅 정보 활용 목적'}</p>
                                    <div style={{paddingLeft: 20, fontSize: '78.7%'}}>
                                        {'-'}
                                        {'이벤트 및 광고성 정보 전송'}
                                    </div>
                                    <div style={{paddingLeft: 20, fontSize: '78.7%'}}>
                                        {'-'}
                                        {'서비스 관련 정보 전송'}
                                    </div>
                                </div>
                                <div style={{paddingLeft: 20, fontSize: '97.2%'}}>
                                    <p>{'보유 및 이용기간'}</p>
                                    <div style={{paddingLeft: 20, fontSize: '78.7%'}}>
                                        {'-'}
                                        {'이용자가 동의를 철회하거나, 탈퇴시까지 보유/이용'}
                                    </div>
                                </div>
                            </div>
                            :<></>
                        }
                    </div>
                </DialogContent>
                <DialogActions style={{ padding: '0 32px 16px 32px'}} >
                    <Button autoFocus color={'primary'} variant={'contained'} onClick={detailTermsClose} >
                        {'확인'}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default PersonalSetting;