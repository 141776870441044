import React, { useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import LoadingCircle from '../../Component/LoadingCircle';
import { GuideTextBoxForGeneral } from '../../Component/TextBox';
import WithLabel from '../../Component/WithLabel';
import SingleUploadImageDisplayed from '../Analysis/Components/SingleUploadImageDisplayed';
import MultiUploadFilenameDisplayed from '../Analysis/Components/MultiUploadFilenameDisplayed';
import NotFound from '../../Page/NotFound';
import { analyticsSubmitInfo, readAnalyticsSubmitInfo } from '../../Data/AnalyticsSubmit';
import { logBehavior } from '../../Data/Behavior';
import { createContents, readContents } from '../../Data/Contents';
import { createEpisode, deleteEpisode, readEpisode, updateEpisode } from '../../Data/Episode';
import { listMyOwnTitles } from '../../Data/Ownership';
import { pageHeaderOptions } from '../../Recoil/PageHeader/PageHeader';
import { useSession } from '../../Recoil/Session/Session';
import { flags, fn } from '../../Functions';
import './Styles/PostEpisode.scss';
import { isLockedMonth, isOnSubmission, isUnderReview, currentSchoolCompetitionYear } from '../Analysis/Univ2024/Data/Variables';
import UnivAlarmBar from './Component/UnivAlarmBar';
import { useToastAlert } from '@webtoontoday/toast';
import { useEpisodes } from '../../Recoil/Title/Episodes';


const TITLE_CHAR_LIMITED = 25;

const PostEpisode = ({
    serviceId, titleId, episodeId, edit
}:{
    serviceId: string,
    titleId: string,
    episodeId: string,
    edit: boolean,
}) => {
    const { session } = useSession();
    const { toastAlert } = useToastAlert();
    const {refresh} = useEpisodes();

    const previousPage = localStorage.getItem('previousurl');
    if (session.memberState !== 'regular') {
        fn.goto('/notfound');
    };

    if (!edit) {
        localStorage.setItem('previousurl', window.location.pathname);
    };

    const [ backwardWarning, setBackwardWarning ] = useState<boolean>(false);
    const [ isEpisodeModified, setIsEpisodeModified ] = useState<boolean>(false);
    const [ openBackWarning, setOpenBackWarning ] = useState<boolean>(false);   

    useEffect(()=>{
        (async()=>{
            const submitInfo = await readAnalyticsSubmitInfo({serviceId, titleId});
            setSubmitInfos(submitInfo);
        })()
    },[serviceId, titleId])

    useEffect(()=>{
        if (!edit) {
            if ( openBackWarning ) {
                setBackwardWarning(true);
                setOpenBackWarning(false);
            };
            return;
        } 

        if ( isEpisodeModified ) {
            if (previousPage === `/enroll/${serviceId}/${titleId}/${episodeId}`) {
                setOpenBackWarning(true);
                fn.goto(`/enroll/${serviceId}/${titleId}/${episodeId}`);
            };
            return;
        }
        
        if (previousPage === `/contents/${serviceId}/${titleId}`) {
            fn.goto(`/enroll/${serviceId}/${titleId}/${episodeId}`);
            return;
        }
        fn.goto(`/contents/${serviceId}/${titleId}`);
    },[edit, episodeId, isEpisodeModified, openBackWarning, previousPage, serviceId, titleId])

    const alert = fn.alert;

    const setPageHeader = useSetRecoilState(pageHeaderOptions);

    useEffect(()=>{
        setPageHeader({titleText: '회차 등록'});
    },[setPageHeader]);

    const [ isLoading, setIsLoading ] = useState<boolean>(false);

    const [ isEpisodeEditing, setIsEpisodeEditing ] = useState<boolean>(false);
    
    const [ episodeTitle, setEpisodeTitle ] = useState<string>('');
    const [ orgEpisodeTitle, setOrgEpisodeTitle ] = useState<string>('');
    const [ episodeThumbnail, setEpisodeThumbnail ] = useState<imageType[]>([]);
    const [ orgEpisodeThumbnail, setOrgEpisodeThumbnail ] = useState<string>('');
    const [ manuscripts, setManuscripts ] = useState<imageType[]>([]);
    const [ orgManuscripts, setOrgManuscripts ] = useState<string[]>([]);

    const [ episodeNumber, setEpisodeNumber ] = useState<string>('');

    const [ isNotFound, setIsNotFound ] = useState<boolean>(false);

    useEffect(()=>{
        ( async () => {
            if (!serviceId || !titleId || !episodeId ) {
                return
            };
            setIsLoading(true);

            const ownershipList = await listMyOwnTitles();
            if (ownershipList.filter(ownership => ownership.titleId === titleId).length < 1) {
                
                fn.goto('/notfound');
                setIsLoading(false);
                return;
            };

            if ( titleId && episodeId ) {
                const currentOwnership = ownershipList.filter(ownership => ownership.titleId === titleId && ownership.userid === session.userid) || [];
                if (!currentOwnership[0]) {
                    setIsLoading(false);
                    return;
                };
                
                const episodes:EpisodeType[] = await readEpisode(currentOwnership[0].serviceId, currentOwnership[0].titleId);
                setEpisodeTitle(`${episodes.length + 1}화`);

                const currentEpisode = episodes.filter( episode => episode.episodeId === episodeId);
                
                const isNewEpisode = currentEpisode.length > 0;
                
                if (!isNewEpisode) {
                    setEpisodeNumber(episodeId);
                    setIsLoading(false);
                    return;
                }

                const contentsRes = await readContents(currentOwnership[0].serviceId, titleId, episodeId,"");

                if (!contentsRes){
                    setIsLoading(false);
                    return;
                }
                const contents = contentsRes.data;

                const newImages:imageType[] = [];
                for (const image of contents.images) {
                    const parsingImage = image.split('/');
                    const imageName = parsingImage[parsingImage.length - 1];
                    newImages.push({name: imageName, image, createdAt:currentEpisode[0].createdAt});
                }

                if ( serviceId && titleId && currentEpisode[0].episodeId) {
                    const savedEpisodeThumbnail = (!currentEpisode[0].thumbnail)? [] : [{ name: currentEpisode[0].thumbnail.split('/').at(-1) || currentEpisode[0].title + ' thumbnail',
                                                    image: currentEpisode[0].thumbnail, 
                                                    createdAt:currentEpisode[0].createdAt}];

                    const manuscriptImages:imageType[] = contents.images.map((image)=>{
                        const parsingImage = image.split('/');
                        const name = parsingImage[parsingImage.length - 1];
                        return {name, image, createdAt:currentEpisode[0].createdAt}
                    })

                    setIsEpisodeEditing(true)
                    setEpisodeThumbnail(savedEpisodeThumbnail);
                    setOrgEpisodeThumbnail(currentEpisode[0].thumbnail);
                    setEpisodeTitle(currentEpisode[0].title);
                    setOrgEpisodeTitle(currentEpisode[0].title);
                    setEpisodeNumber(currentEpisode[0].episodeId);
                    setOrgManuscripts(contents.images);
                    setManuscripts(manuscriptImages);
                };
                
                if ( episodes.length === 0 && !serviceId && !titleId && !episodeId ){
                    setIsNotFound(true);
                };

                setEpisodeNumber(episodeId);
            }
            setIsLoading(false);
        })();

    },[serviceId, titleId, episodeId, session.userid]);

    const [ submitInfos, setSubmitInfos ] = useState<analyticsSubmitInfo>({ isAnalyticsSubmitted: false,
                                                                            isCompetitionSubmitted: false,
                                                                            isSchoolCompetitionSubmitted: false,
                                                                            isSchoolCompetitionModifiable: false,
                                                                            isAnalyticsValidatedAt: false,
                                                                            isAnalyticsCompleted: false,
                                                                            isAutomatedAnalyticsCompleted: false,
                                                                            analyticsSubmitCreatedAt: '',
                                                                            key: '',
                                                                            schoolCompetitionSubmittedYear: ''});


    const { isAnalyticsSubmitted, isAnalyticsCompleted } = submitInfos;
    
    const isSchoolAccepted = submitInfos.isSchoolCompetitionSubmitted
    const isSchoolRejected = submitInfos.isSchoolCompetitionModifiable
    const isSchoolCompetition = isSchoolAccepted || isSchoolRejected;

    const isThisYearSchoolCompetition   = submitInfos.schoolCompetitionSubmittedYear === currentSchoolCompetitionYear;
    
    let isDeleteVisible: boolean = true;
    let isEditVisible: boolean   = true;
    let editLabel                = "수정";

    if ( isAnalyticsSubmitted && !isAnalyticsCompleted ) {
        isDeleteVisible = false;
        isEditVisible = false;
        editLabel = "수정불가";
    } else if ( isSchoolCompetition && isThisYearSchoolCompetition ) {

        if        (isOnSubmission && isSchoolRejected) {
            isDeleteVisible = false;
            isEditVisible   = true;
            editLabel       = "재접수";
        } else if (isOnSubmission && isSchoolAccepted) {
            isDeleteVisible = false;
            isEditVisible   = false;
            editLabel       = "수정불가";
        } else if (isUnderReview  || isLockedMonth) {
            isDeleteVisible = false;
            isEditVisible   = false;
            editLabel       = "수정불가";
        } else {
            isDeleteVisible = true;
            isEditVisible   = true;
            editLabel       = "수정";
        }

    } else {
        isDeleteVisible = true;
        isEditVisible   = true;
        editLabel       = "수정";
    }

    
    const [ manuscriptSizeWarning, setManuscriptSizeWarning ] = useState<boolean>(false);

    useEffect(() => {
        if (!manuscriptSizeWarning){
            return;
        };

        fn.alert(`이미지 가로의 크기가 690px와 달라 일부 변형되어 보일 수 있습니다.`)
        setManuscriptSizeWarning(false);

    },[manuscriptSizeWarning]);

    const [ open, setOpen ] = useState<boolean>(false);
    const [ openPreview, setOpenPreview ] = useState<boolean>(false);

    const previewClose = () => {
        setOpenPreview(false)
    }

    const [ deleteOpen, setDeleteOpen ] = useState<boolean>(false);

    const deleteClose = () => {
        setDeleteOpen(false);
    }

    const [ deleteInformOpen, setDeleteInformOpen ] = useState<boolean>(false);
    
    useEffect( () => {
        if (isLoading || edit || episodeThumbnail.length === 0) {
            return
        };

        if (    orgEpisodeTitle === episodeTitle 
             && orgEpisodeThumbnail === episodeThumbnail[0].image
             && manuscripts.map(row => row.image).join("") === orgManuscripts.join("")) {
            setIsEpisodeModified(false);
            return;
        }
        setIsEpisodeModified(true);
    },[edit, episodeThumbnail, episodeTitle, isLoading, manuscripts, orgManuscripts, orgEpisodeThumbnail, orgEpisodeTitle]);

    if (isNotFound) {
        return (
            <NotFound/>
        );
    }

    return (
        <div className={'EnrollEpisodeArea'}>
            <div className={'BodyContainer'}>
                <div className={`EnrollEpisodeForm${!isEditVisible?" Disabled":""}`}>
                    {isSchoolCompetition && isThisYearSchoolCompetition && <UnivAlarmBar submitInfos={submitInfos} />}
                    <div className={'FormCard'}>
                        <WithLabel label={'회차 번호'}>
                        <GuideTextBoxForGeneral
                            text={`${Number(episodeNumber)}` || ''}
                            onChange={() => { } }
                            onBlur={() => { } }
                            guideTexts={{
                                initial: '',
                                normal: '',
                                success: '',
                                fail: '',
                                required: '필수 입력 정보입니다.'
                            }}
                            isRequired
                            isDisabled 
                            placeholder={''}
                        />
                        </WithLabel>
                        <WithLabel label={'부제'}>
                        <GuideTextBoxForGeneral
                                text={episodeTitle || ''}
                                placeholder={'에피소드의 제목을 입력해주세요.'}
                                onChange={(e)=>{
                                    if (e.target.value.length <= TITLE_CHAR_LIMITED) {
                                        setEpisodeTitle(e.target.value);
                                        return;
                                    }
                                    alert(`글자수가 ${TITLE_CHAR_LIMITED}자를 초과했습니다.`);
                                }}
                                onBlur={()=>{}}
                                guideTexts={{
                                    initial: '',
                                    normal: '',
                                    success: '',
                                    fail: '',
                                    required: '필수 입력 정보입니다.'
                                }}
                                isRequired
                                maxLength={TITLE_CHAR_LIMITED}
                            />
                        </WithLabel>
                        <WithLabel label={`${episodeNumber}화 썸네일`} >
                            <SingleUploadImageDisplayed
                                width={202} height={120}
                                files={episodeThumbnail}
                                setFiles={(files) => setEpisodeThumbnail(files)}
                                screenSubtitle={["이미지 파일(JPG 나 PNG)", "202px * 102px 이상"]}
                            />
                        </WithLabel>
                        <WithLabel label={`${episodeNumber}화 원고`}>
                            <MultiUploadFilenameDisplayed 
                                files={manuscripts} 
                                setFiles={setManuscripts} 
                            />
                        </WithLabel>
                    </div>
                    <hr className={'HorizontalLine'} />
                </div>
                {!isEpisodeEditing?
                <div className={'Footer'}>
                    <Button 
                        className={`Button SubmitButton`} 
                        disabled={!episodeNumber || !episodeTitle || episodeThumbnail.length === 0 || manuscripts.length === 0 }
                        onClick={()=>{
                            setOpen(true);
                        }}
                    >   
                        {'회차 등록하기'}
                    </Button>
                </div>
                :<div className={'Footer Editing'}>
                    { isDeleteVisible  && <Button className={'Delete Button EditingButton'} 
                            disabled={ submitInfos.isSchoolCompetitionSubmitted && (isOnSubmission || isUnderReview) && isThisYearSchoolCompetition }  
                            onClick={() => setDeleteOpen(true)} 
                    >
                        {'삭제'}
                    </Button>}
                    <Button className={'Cancel Button EditingButton'} 
                        onClick={() => fn.goto(`/enroll/${serviceId}/${titleId}/${episodeId}/edit`)} 
                    >
                        {'이전'}
                    </Button>
                    { isEditVisible && <Button className={'Modify Button EditingButton'} 
                        onClick={ async () => {
                            setIsLoading(true);

                            if ( 
                                !episodeNumber || 
                                !episodeTitle || 
                                episodeThumbnail.length === 0 ||
                                (manuscripts.length === 0 && orgManuscripts.length === 0) || 
                                (submitInfos.isSchoolCompetitionSubmitted && isOnSubmission )
                             ) {
                                toastAlert("모든 필수정보를 입력해 주세요.")
                                setIsLoading(false);
                                return;
                            }

                            const res = await updateEpisode({episode: {
                                serviceId, titleId, episodeId, 
                                title: episodeTitle, thumbnail: episodeThumbnail[0]?.image
                            }});

                            if (!res) {
                                console.error('error: updateEpisode was not working');
                                setIsLoading(false);
                                return;
                            }

                            if (manuscripts.length === 0) {
                                flags.didEpisodeUpdated = true;
                                fn.goto(`/contents/${serviceId}/${titleId}`);
                                setIsLoading(false);
                                return;
                            } 

                            const newImages = manuscripts.map((manu)=>manu.image);
                            
                            const content = await createContents({
                                contents: {
                                    serviceId, titleId, episodeId,
                                    data: {images: newImages}
                                }
                            });

                            if (!content){
                                console.error('error: createContents was not working');
                                setIsLoading(false);
                                return;
                            }
                                
                            setIsLoading(false);
                            refresh({serviceId, titleId});
                            
                            logBehavior('create-contents', {serviceId, titleId, episodeId: episodeNumber, snapshotId: content.split(':').reverse()[0]});
                                
                            if (!content) {
                                console.error('error: updateContents was not working');
                                return;
                            }

                            const isSubmitAgain = submitInfos.isSchoolCompetitionModifiable && isOnSubmission && isThisYearSchoolCompetition;

                            flags.didTitleUpdated = true;
                            if ( !isSubmitAgain ) {
                                fn.goto(`/contents/${serviceId}/${titleId}`);
                            } else {
                                // 재접수 페이지 보내기 
                                fn.goto(`/analysis/univapply/${serviceId}/${titleId}`);
                            }
                        }}
                    >
                        {`${editLabel}`}
                    </Button>}
                </div>
                }
                <Dialog className={'EnrollDialog'} onClose={()=>{setOpen(false)}} open={open}>
                    <DialogContent dividers>
                        <div style={{lineHeight: '30px', textAlign: 'center', padding: '10px 0'}}>
                            {`회차를 등록할까요?`}
                        </div>
                    </DialogContent>
                    <DialogActions style={{justifyContent: 'space-evenly'}}>
                        <Button 
                            autoFocus 
                            onClick={ () => {setOpen(false)}} 
                            style={{color: 'black'}} >
                            {'취소'}
                        </Button>
                        <Button 
                            autoFocus 
                            color={"primary"} 
                            style={{justifyContent: 'flex-start'}}
                            onClick={ async () => {
                                if (!serviceId || !titleId || !episodeId || episodeThumbnail.length === 0){
                                    return;
                                }
                                setIsLoading(true);
                                
                                const episodeResponse = await createEpisode({
                                    serviceId, titleId, episodeId: episodeNumber, title: episodeTitle, thumbnail: episodeThumbnail[0].image, exposure:''
                                });
                                
                                if ( !episodeResponse ) {
                                    toastAlert('에피소드 업로드 실패');
                                    setIsLoading(false);
                                    return;
                                }

                                logBehavior('create-episode', {serviceId, titleId, episodeId: episodeNumber, title: episodeTitle, thumbnail: episodeThumbnail[0].image});

                                const slideImages = manuscripts.map((manu)=>manu.image);
                                                                        
                                const resp = await createContents({
                                    contents: {
                                        serviceId, titleId, episodeId: episodeNumber,
                                        data: {images: slideImages}
                                    }
                                });
                                setIsLoading(false);
                                
                                if ( !resp ) {
                                    toastAlert('컨텐츠 업로드 실패');
                                    return;
                                }
                                
                                logBehavior('create-contents', {serviceId, titleId, episodeId: episodeNumber, snapshotId: resp.split(':').reverse()[0]})

                                fn.goto(`/contents/${serviceId}/${titleId}`);
                                setOpen(false);
                            }} 
                        >
                            {'등록하기'}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog className={'EnrollDialog'} onClose={previewClose} open={openPreview} PaperProps={{style:{margin: 0, maxHeight: '100%',borderRadius: 0, height: '100%', width: '100%'}}}>
                    <div style={{display: 'flex', justifyContent: 'space-between', alignContent: 'center', fontSize: '1rem', padding: '10px 20px'}}>
                        {`${episodeNumber}. ${episodeTitle || ''}`}
                        <IconButton style={{padding: '0px'}} onClick={previewClose}>
                            <Close />
                        </IconButton>
                    </div>
                    <DialogContent dividers style={{display: 'flex', flexDirection: 'column', padding: '16px 0 0 0'}}>
                        {manuscripts.map(({name, image}, index) => (
                            <img src={`${image}`} alt={name} style={{width: '100%'}} key={`preview:${name}:${image}:${index}`} />
                        ))}
                    </DialogContent>
                </Dialog>
                <Dialog className={'EnrollDialog'} onClose={deleteClose} open={deleteOpen}>
                    <DialogTitle >
                        {'작품을 삭제하시겠어요?'}
                    </DialogTitle>
                    <DialogContent dividers style={{paddingTop: 0, border: 0}}>
                        {`<${episodeTitle}> 의 모든 정보가 삭제됩니다.`}
                    </DialogContent>
                    <DialogActions style={{padding: '0px 15px 15px 0'}}>
                        <Button autoFocus onClick={deleteClose} className={'SecondaryActionButton BlackColor'}>
                            {'취소'}
                        </Button>
                        <Button autoFocus variant={"contained"} color={"secondary"} className={'SecondaryActionButton'}
                            onClick={ async () => {
                                const deleteKey = await deleteEpisode({serviceId, titleId, episodeId});
                                
                                if (!deleteKey) {
                                    logBehavior('delete-episode(fail)', {serviceId, titleId, episodeId});
                                } 

                                logBehavior('delete-episode(success)', {serviceId, titleId, episodeId});
                                setDeleteOpen(false);
                                setDeleteInformOpen(true);
                        }} >
                            {'삭제'}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog className={'EnrollDialog'} onClose={ () => setDeleteInformOpen(false) } open={deleteInformOpen}>
                    <DialogTitle >
                        {'작품 삭제'}
                    </DialogTitle>
                    <DialogContent dividers style={{paddingTop: 0, border: 0}}>
                        {`<${episodeTitle}> 을 삭제했어요.`}
                    </DialogContent>
                    <DialogActions className={'ActionButtonWrapper'}>
                        <Button autoFocus color={"secondary"} className={'SecondaryActionButton'}
                            onClick={ () => {
                                fn.goto(`/contents/${serviceId}/${titleId}`)
                                setDeleteInformOpen(false)
                        }} >
                            {'확인'}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog className={'EnrollDialog'} onClose={ () => setBackwardWarning(false) } open={backwardWarning}>
                    <DialogTitle >
                        {'나가시겠어요?'}
                    </DialogTitle>
                    <DialogContent dividers style={{paddingTop: 0, border: 0}}>
                        {`변경사항이 저장되지 않습니다.`}
                    </DialogContent>
                    <DialogActions className={'ActionButtonWrapper'}>
                        <Button 
                            onClick={ () => {setBackwardWarning(false)}} 
                            className={'BlackColor'} 
                        >
                            {'취소'}
                        </Button>
                        <Button variant={'contained'} color={"primary"} disableElevation
                            onClick={ async () => {
                                setBackwardWarning(false)
                                fn.goto(`/contents/${serviceId}/${titleId}`);
                            }} >
                            {'확인'}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
            <LoadingCircle show={isLoading} />
        </div>
    );
}

export default PostEpisode;
