import React from 'react';

import "./LoadingCircle.scss";

/**
 * 
 * @param {{show: boolean}} props
 * @returns 
 */
const LoadingCircle = ({show}) => {

    return (<div className={`LoadingCircle ${show?'Show':''}`} style={{width: '100vw', height: '100vh'}}>

    </div>);
}

export default LoadingCircle;