

export const appear = {
    initial: { 
        opacity: 0 
    },
    animate: {
        opacity: 1,
        transition: { 
            staggerChildren: 0.2
    }}
}

export const appearUp = {
    initial: { 
        y: 100, 
        opacity: 0 
    },
    animate: {
        y: 0,
        opacity: 1,
        transition: { 
            duration: 0.9,
            ease: [0.55, 0.25, 0.22, 1.5],
            bounce: 0.3
    }}
}

export const appearDown = {
    initial: { 
        y: -100, 
        opacity: 0 
    },
    animate: { 
        y: 0, 
        opacity: 1,
        transition: { 
            duration: 0.9,
            ease: [0.55, 0.25, 0.22, 1.5]
        }
    }
}

export const appearRight = {
    initial: { 
        x: 60, 
        opacity: 0 
    },
    animate: { 
        x: 0, 
        opacity: 1,
        transition: { 
            duration: 0.9,
            ease: [0.55, 0.25, 0.22, 1.5],
            bounce: 0.4
        }
    }
}


export const appearRotate = {
    initial: { 
        opacity: 0,
        scale: 0 
    },
    animate: { 
        opacity: 1,
        rotate: 360,
        scale: 1,
        transition: {
            duration: 3,
            bounce: 0.4,
            type: "spring",
            stiffness: 260,
            damping: 20,
        }
    }
}


