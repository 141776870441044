import React from 'react';

import {fn} from '../../Functions';

import ProfileEdit from './ProfileEdit';
import ArtsEdit from './ArtsEdit';

import './index.scss';
import { readPortfolio, createOrUpdatePortfolio } from '../../Data/Portfolio';
import { Tooltip } from '@material-ui/core';
import TemplateEdit from './TemplateEdit';

import { useOwnershipTitleList } from '../../Recoil/Ownership/Ownership';
import Share from '../../Component/Share';
import NotFound from '../NotFound';
import { useSetRecoilState } from 'recoil';
import { pageHeaderOptions } from '../../Recoil/PageHeader/PageHeader';
import { useSession } from '../../Recoil/Session/Session';
import { useToastAlert } from '@webtoontoday/toast';

const Portfolio = ({addressid: givenAddressid}) => {
    const { toastAlert } = useToastAlert();
    const { session } = useSession();
    
    const [addressid, setAddressid ] = React.useState(givenAddressid || session.addressid || '');
    const { fetch: fetchOwnership, ownTitleList: titles } = useOwnershipTitleList();
    
    React.useEffect(()=>{
        setAddressid(givenAddressid || session.addressid || '');
    },[givenAddressid, session.addressid])

    const [currentIndex, setCurrentIndex] = React.useState(0);

    //portfolioReady, isEditingMode, isPreviewMode,isLoading
    
    const [isEditingMode, setIsEditingMode] = React.useState(false);
    const [isPreviewMode, setIsPreviewMode] =  React.useState(false);
    const [isPortfolioLoading, setIsPortfolioLoading] = React.useState(true);
    const [portfolioFromServer, setPortfolioFromServer] = React.useState(
        (window.predef_data && window.predef_data.portfolio)
            ?window.predef_data.portfolio
            :null
    );

    const setPageHeader = useSetRecoilState(pageHeaderOptions);

    React.useEffect(()=>{
        setPageHeader({titleText: '포트폴리오'});
    },[setPageHeader]);

    /** @type {['contents'|'template'|'statistics', React.Dispatch<React.SetStateAction<'contents'|'template'|'statistics'>>]} */
    const [PCEditingFor, setPCEditingFor] = React.useState('contents');

    React.useEffect(()=>{
        (async ()=>{
            
            fetchOwnership();

        })();
    },[fetchOwnership]);
    
    const [profile, setProfile] = React.useState({});
    /** @type {[PortfolioContentType[], React.Dispatch<React.SetStateAction<PortfolioContentType[]>>]} */
    const [contents, setContents] = React.useState([]);
    const [template, setTemplate] = React.useState({color: 'rgb(255,255,255)'});
    React.useEffect(()=>{
        (async()=>{

            setIsPortfolioLoading(true);

            let portfolio = null;
            try{
                portfolio = await readPortfolio(addressid);
                setPortfolioFromServer(portfolio);
            }catch(e){
                setPortfolioFromServer(null);
            }

            setIsPortfolioLoading(false);

            setProfile((portfolio || {}).profile || {});
            setContents((portfolio || {}).contents || []);
            setTemplate((portfolio || {}).template || []);
        
        })();

    },[addressid])

    React.useEffect(()=>{
        setPageHeader({titleText: profile.title || '포트폴리오'})
    },[profile, setPageHeader])

    if (portfolioFromServer === null && !isPortfolioLoading && addressid && addressid !== session.addressid){
        return (<NotFound/>);
    }

    if (addressid !== session.addressid || isPortfolioLoading){
        
        return (
        <div className={"Portfolio"}> 
            <div className={"BG"}></div>
            <div className='MainBody'>
                <div className={"PorfolioContainer"} style={{backgroundColor: 'rgba(0,0,0,0)'}}>
                    <ProfileEdit {...{
                        style: {boxShadow: 'none'},
                        addressid,
                        isEditingMode: false, setIsEditingMode: ()=>{fn.goto(`/portfolio/${session.addressid}`)} ,
                        isPreviewMode: false, setIsPreviewMode: ()=>{},
                        isPortfolioLoading,
                        portfolioFromServer, setPortfolioFromServer,
                        profile, setProfile, contents, setContents, template, titles,
                        savePortfolio:()=>{}
                    }} />
                </div>
            </div>
        </div>);
    }

    return (<div className={"Portfolio"}> 
        <div className='PortfolioBG'
            style={{
                backgroundColor: template.color || 'rgb(255,255,255)',
            }}
        ></div>
        <div className={"BG"}></div>
        <div className={"PorfolioContainer"}>
            <div className={"MobileSectionHeader"}>
                {!isEditingMode && !isPreviewMode
                ?["내 작품", "내 프로필"].map((label, index) => 
                <div 
                    key={label}
                    className={`Selection Button ${index === currentIndex?"Selected":""}`}
                    onClick={(event)=>{
                        setCurrentIndex(index)
                    }}
                >{label}</div>)
                :["내용", "꾸미기", "통계"].map((label, index) => 
                <div 
                    key={label}
                    {...(index === 2?{disabled: "true"}:{})}
                    className={`Selection Button ${index+1 === currentIndex?"Selected":""}`}
                    onClick={(event)=>{
                        if (index === 1 && portfolioFromServer === null){
                            toastAlert("포트폴리오를 먼저 꾸며주세요!");
                            return;
                        }
                        if (index === 2){
                            toastAlert("곧 지원 예정이에요!");
                            return;
                        }
                        setCurrentIndex(index+1)
                    }}
                >{label}</div>)}
            </div>
            <div className={`PCHeader`}>
                {(!portfolioFromServer && !isEditingMode)
                ?<div className={"Headline"} style={{paddingLeft: 50}}>
                    <h3>{"내 작품"}</h3>
                </div>
                :<div className={"ButtonContainer"}>
                    <div className={"OutlineButton Button"} onClick={()=>{
                        setIsEditingMode(!isEditingMode);
                        if (isEditingMode){
                            (async()=>{
                                await createOrUpdatePortfolio({
                                    addressid,
                                    profile,
                                    contents,
                                    template
                                });
                                
                                let portfolio = await readPortfolio(addressid); 
                                setPortfolioFromServer(portfolio);
                            })();
                        }
                    }}>
                        {isEditingMode?"저장":"편집"}
                    </div>
                    {!isEditingMode
                        ?<Share
                            pcStyle={{margin: 0}}
                            contents={<div className={"FilledButton Button"}>
                                {'공유'}
                            </div>} 
                            log={{ what: 'share-portfolio', detail: {} }}
                        />
                        :<div className={"FilledButton Button"} onClick = { () => {
                            setIsEditingMode(false);
                        }}>
                            {'취소'}
                        </div>
                    }   
                </div>}
                <div className={"Headline"}>
                    <h3>{"내 프로필"}</h3> 
                </div>
            </div>

            
            <div className={`MainBody PC HideFloatingButtonBox ${currentIndex===0?'ArtsMode':currentIndex>=1?'ProfileMode':''}`}>
                {!isEditingMode
                ?<ArtsEdit {...{titles}}/>
                :<div style={{width: '50%'}}>
                    <div className={"PCEditButtonBox"}>
                        <div className={`Button ${PCEditingFor==='contents'?'Selected':''}`}
                            onClick={()=>{setPCEditingFor('contents')}}
                        >{"내용"}</div>
                        <div className={`Button ${PCEditingFor==='template'?'Selected':''}`}
                            onClick={()=>{setPCEditingFor('template')}}
                        >{"꾸미기"}</div>
                        <Tooltip
                            title={"곧 지원 예정이에요!"}
                        >
                            <div className={`Button ${PCEditingFor==='statistics'?'Selected':''}`} style={{color: 'rgba(192,192,192,1)'}}>{"통계"}</div>
                        </Tooltip>
                    </div>
                    {PCEditingFor==='contents'
                    ?<ProfileEdit
                        style={{
                            width: 'calc(100%)', marginLeft: 0, marginRight: 0,
                            backgroundColor: 'rgba(0,0,0,0)', boxShadow: 'none',
                            paddingTop: 0, paddingLeft: 0, paddingRight: 0,
                        }}
                        {...{
                        addressid,
                        isEditingMode: true, setIsEditingMode: ()=>{}, isPreviewMode, setIsPreviewMode,
                        portfolioFromServer, setPortfolioFromServer,
                        profile, setProfile, contents, setContents, template, titles,
                        savePortfolio:(async ()=>{
                            await createOrUpdatePortfolio({
                                addressid, profile, contents, template
                            })
                            setPortfolioFromServer({
                                addressid, profile, contents, template
                            })
                            
                            let portfolio = await readPortfolio(addressid); 
                            setPortfolioFromServer(portfolio);
                        })
                    }} />
                    :PCEditingFor==='template'
                    ?<TemplateEdit givenTemplate={template} setTemplate={setTemplate} />
                    :<></>}
                </div>}           
                <ProfileEdit {...{
                    addressid,
                    isEditingMode: false, setIsEditingMode: setIsEditingMode, isPreviewMode: isEditingMode, setIsPreviewMode: ()=>{},
                    portfolioFromServer, setPortfolioFromServer,
                    profile, setProfile, contents, setContents, template, titles,
                    savePortfolio:(async ()=>{
                        await createOrUpdatePortfolio({
                            addressid, profile, contents, template
                        })
                        setPortfolioFromServer({
                            addressid, profile, contents, template
                        })

                        let portfolio = await readPortfolio(addressid); 
                        setPortfolioFromServer(portfolio);
                    })
                }} />
            </div>

            <div
                className={`MainBody Mobile ${currentIndex===0?'ArtsMode':currentIndex>=1?'ProfileMode':''}`}
                style={{left: `${-100 * (currentIndex>=1?1:0)}%`}}
            >
                <ArtsEdit {...{titles}}/>
                    
                {currentIndex===1
                ?<ProfileEdit {...{
                    addressid,
                    isEditingMode, setIsEditingMode, isPreviewMode, setIsPreviewMode,
                    portfolioFromServer, setPortfolioFromServer,
                    profile, setProfile, contents, setContents, template, titles,
                    savePortfolio:(async ()=>{
                        await createOrUpdatePortfolio({
                            addressid, profile, contents, template
                        })
                        setPortfolioFromServer({
                            addressid, profile, contents, template
                        })

                        let portfolio = await readPortfolio(addressid); 
                        setPortfolioFromServer(portfolio);
                    })
                }} />
                :<TemplateEdit
                    style={{width: 'calc(100% - 32px)', margin: 16}}
                    givenTemplate={template} setTemplate={setTemplate}
                    className={'ProfileEdit'}
                />}
            </div>
        </div>
    </div>);
};

export default Portfolio;