import React, {useRef} from 'react';
import { Button, IconButton, Paper } from '@material-ui/core';
import './WebtoonScore.scss';
import {fn} from '../../../Functions';
import { ArrowDownwardRounded as ArrowDownwardRoundedIcon } from '@material-ui/icons';

import {useOnScreen} from '../../../Hooks';
import {logBehavior} from '../../../Data/Behavior';
let didSeen = false;

const AnalysisImg = 'https://static.webtoon.today/application/AnalysisImg.png';
const UploadImg = 'https://static.webtoon.today/application/UploadImg.png';
const SNSAdsImg = 'https://static.webtoon.today/application/SNSAdsImg.png';
const ExampleReport = 'https://static.webtoon.today/application/ExampleReport.png';
const ExtraGraph = 'https://static.webtoon.today/application/ExtraGraph.png';

const WebtoonScore = () => {


    const [paper, setPaper] = React.useState("");

    const handle = (index) => {
        setPaper(`${index}`)
        if (paper === index){
            setPaper("")
        }
    }

    const ref = useRef();
    const onScreen = useOnScreen(ref);
    if (!didSeen && onScreen){
        didSeen = onScreen;
        logBehavior('scroll-webtoonscore', {});
    }

    return (
        <div ref={ref}>
            <div className={"WebtoonScoreTemplate"} id={"WebtoonScore"}>
                <div className={"TemplateMainBody"}>
                    <div className={"TitleBox"}>
                        <div className={"Title"}>
                            {"내 웹툰은 "}
                            <span className={"SignatureText"}>{"몇 점"}</span>
                            {"일까요?"}
                        </div>
                        <div className={"TextLine"}>
                            {"독자의 마음을 데이터 분석 서비스로 직접 확인하세요."}
                        </div>
                    </div>
                    <div className={"Explanation"}>
                        <div className={"PaperBox"}>
                            <div className={"PaperCover"}>
                                <div>
                                    <Paper className={`Paper ${paper === "1"?"Union":""}`}>
                                        <div>
                                            <div className={"PaperTitle"}>{"원고를 업로드합니다."}</div>
                                            <img className={"PaperIcons"} src={UploadImg} alt={"UploadImg"}/>
                                            <div className={"PaperText"}>{"완성된 원고 3화를 업로드합니다."}</div>
                                        </div>
                                        <div>
                                            <IconButton color={"primary"} style={{fontSize:'0.8rem', padding:3, width:20, height:20, color:'#9AC9FA', border:'1px #00b0f0 solid'}} onClick={()=>{handle("1")}}>{"?"}</IconButton>
                                        </div>
                                    </Paper>
                                    <Paper className={"ExtraPaper"} hidden={!(paper === "1")}>
                                        <div className={"ExtraText"}>{"연독률 파악을 위해 원고 최소 3화를 권장합니다."}</div>
                                    </Paper>
                                </div>
                            </div>
                            <div className={"PaperCover"}>
                                <div>
                                    <Paper className={`Paper ${paper === "2"?"Union":""}`}>
                                        <div>
                                            <div className={"PaperTitle"}>{"예상독자가 내 작품을 읽습니다."}</div>
                                            <img className={"PaperIcons"} src={SNSAdsImg} alt={"SNSAdsImg"}/>
                                            <div className={"PaperText"}>{"SNS에 노출된 작품에 독자가 반응합니다."}</div>
                                        </div>
                                        <div>
                                            <IconButton color={"primary"} style={{fontSize:'0.8rem', padding:3, width:20, height:20, color:'#9AC9FA', border:'1px #00b0f0 solid'}} onClick={()=>{handle("2")}}>{"?"}</IconButton>
                                        </div>
                                    </Paper>
                                    <Paper className={"ExtraPaper"} hidden={!(paper === "2")}>
                                        <div className={"ExtraText"}>{"독자 반응 수집을 위해 SNS 퍼포먼스 마케팅 (광고게재)을 활용합니다. 업로드해주신 원고는 데이터 수집 후 파기되며 다른 용도로 사용되지 않습니다."}</div>
                                    </Paper>
                                </div>
                            </div>
                            <div className={"PaperCover"}>
                                <div>
                                    <Paper className={`Paper ${paper === "3"?"Union":""}`}>
                                        <div>
                                            <div className={"PaperTitle"}>{"데이터 분석 결과를 확인합니다"}</div>
                                            <img className={"PaperIcons"} src={AnalysisImg} alt={"AnalysisImg"}/>
                                            <div className={"PaperText"}>{"이탈률, 완독률 등 객관적 수치를 확인합니다."}</div>
                                        </div>
                                        <div>
                                            <IconButton color={"primary"} style={{fontSize:'0.8rem', padding:3, width:20, height:20, color:'#9AC9FA', border:'1px #00b0f0 solid'}} onClick={()=>{handle("3")}}>{"?"}</IconButton>
                                        </div>
                                    </Paper>
                                    <Paper className={"ExtraPaper"} hidden={!(paper === "3")}>
                                        <div className={"ExtraText TextCenter"}>{"다음과 같은 지표가 제공됩니다."}</div>
                                        <img className={"ExtraGraph"} src={ExtraGraph} alt={"ExtraGraph"} />
                                    </Paper>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"IntroducingReport"}>
                        <ArrowDownwardRoundedIcon style={{color: 'rgb(0,176,240)', borderRadius:40, border:'1px rgba(0,176,240) solid'}}/>
                        <Button variant={"outlined"} className={"Introductions"}
                            href={"https://static.webtoon.today/ddah/210621-report%20review.pdf"}
                            onClick={fn.gotoByAnchor}
                        >
                            <div className={"IntroductionText"}>{"데이터 분석 리포트의 실제 모습이 궁금해요."}</div>
                            <img src={ExampleReport} alt={"ExampleReport"} />
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WebtoonScore;