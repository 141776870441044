import React from 'react';
import './SignIn.scss';

import { Button } from '@material-ui/core';
import { fn } from '../../Functions';
import { useSetRecoilState } from 'recoil';
import { pageHeaderOptions } from '../../Recoil/PageHeader/PageHeader';
import { useSession } from '../../Recoil/Session/Session';


const CompleteSignin = () => {    
    const { session } = useSession();

    React.useEffect(()=>{
        setTimeout(()=>fn.redirectIfExists(), 200);
    },[]);

    const setPageHeader = useSetRecoilState(pageHeaderOptions);
    React.useEffect(()=>{
        setPageHeader({
            titleText: '회원가입 완료',
            headerStyle: {borderBottom: '1px rgb(231,231,231) solid'},
            mobileShortcuts:<></>
        })
    },[setPageHeader])

    return(
        <div className={'MainArea'}>
            <div className={'BodyContainer'}>
                <div className={'CompleteTitle'}>
                    {`${session.name}님 반가워요 :)`}
                </div>
                <div className={'CompleteText'}>
                    {`가입이 완료되었어요.
                    
                    오늘의웹툰에서 작품을 연재하며
                    정식 연재의 확률을 올려보세요!`}
                </div>
                <div className={'CompleteModeButtonGroup'}  >
                    <Button className={'Button'} variant={'contained'} color={'primary'} fullWidth  
                        onClick={ () => fn.goto('/enroll')}>
                        {'도전만화 올리기'}
                    </Button>
                    <Button className={'ClosePage Button'} onClick={ () => fn.goto('/') } fullWidth  >
                        {'닫기'}
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default CompleteSignin;