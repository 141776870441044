import { atom, useRecoilState } from "recoil";
import { readTitlesByQuery } from "../../Data/Search";
import { logBehavior } from "../../Data/Behavior";
import { useCallback, useEffect, useState } from "react";
import { useTitles } from "../Title/Titles";


const SearchTitleListState = atom<{[key: string]: TitleKeyType[] | null}>({
    key: 'searchTitleList',
    default: {},
});

export const useSearchTitleList = ():{
    searchTitleList: {[key: string]: (TitleType & TitleAugmentedData)[] | null},
    fetch: (query: string, type: string, limit: number) => void
} => {
    const [ searchResult, setSearchResult ] = useRecoilState<{[key: string]: TitleKeyType[] | null}>(SearchTitleListState)
    const {titleObject, fetch: fetchTitles} = useTitles();
    
    const fetch = useCallback(async (query: string, type: string, limit: number): Promise<boolean> => {
        if ( searchResult !== null && searchResult && searchResult[`${query}:${type}`] ) {
            return true;
        }

        const response = await readTitlesByQuery(query, type, limit);
        
        if ( response !== null ) {

            setSearchResult({
                ...(searchResult as {[key: string]: TitleKeyType[]}),
                [`${query}:${type}`]: response
            })
            
            logBehavior('search', {query, type, resultLength: (response || []).length});
            return true;
        } else {
            return false;
        }
    },[searchResult, setSearchResult]);

    const secureFetch = useCallback((query: string, type: string, limit: number)=>{
        if ( searchResult[`${query}:${type}`] !== undefined ) {
            return;
        }
        setSearchResult(searchResult => ({...searchResult, [`${query}:${type}`]: null}));
        fetch(query, type, limit);
    },[fetch, searchResult, setSearchResult])

    const [searchTitleList, setSearchTitleList] = useState<{[key:string]: (TitleType & TitleAugmentedData)[] | null}>({});
    useEffect(() => {
        
        let allTitles = Object.values(searchResult).flat().filter(row => row !== null) as TitleKeyType[];
        fetchTitles(allTitles);

        let ret = Object.fromEntries( Object.entries(searchResult).map(([key, arr]) => ([key, arr?.map(row => {
            return {
                ...row,
                ...titleObject[`${row.serviceId}:${row.titleId}`]
            }
        }) || null])) )
        
        setSearchTitleList(ret);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[searchResult, secureFetch, titleObject])

    return ({searchTitleList, fetch: secureFetch})
}