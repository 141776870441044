import React, { ReactNode, useEffect, useRef, useState } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperClass } from 'swiper/types';
import { EffectFade, Autoplay } from 'swiper';
import 'swiper/scss/effect-fade';

import './Styles/CarouselCard.scss';
import 'swiper/swiper.min.css';
import 'swiper/scss/pagination';

import './Styles/Dissolve.scss';
import { fn } from '../../../Functions';
import { readPortfolio } from '../../../Data/Portfolio';
import { readProfile } from '../../../Data/Profile';
import { logBehavior } from '../../../Data/Behavior';
import { ChevronRight, Done, VisibilityOutlined } from '@material-ui/icons';
import Share from '../../../Component/Share';

import { useClickLogs } from '../../../Recoil/Title/Titles';
import { useSession } from '../../../Recoil/Session/Session';
import { useToastAlert } from '@webtoontoday/toast';

const Dissolve = ({
    titleInfo,
    isFocused,
    subscribeAuthor,
    unsubscribeAuthor,
    subscribeTitle,
    unsubscribeTitle,
    handleFinishedDissolve
}:{
    titleInfo: FeedTitleInfoType,
    isFocused: boolean,
    subscribeAuthor: (authorId: string) => Promise<boolean>,
    unsubscribeAuthor: (authorId: string) => Promise<boolean>,
    subscribeTitle: (serviceId: string, titleId: string) => Promise<boolean>,
    unsubscribeTitle: (serviceId: string, titleId: string) => Promise<boolean>,
    handleFinishedDissolve: (currentTitleKey: string) => void
}) => {
    const { serviceId, titleId, authorInfo, slides, thumbnail } = titleInfo;
    
    return(
        <div className={'CardArea'} key={titleId}>
            <AuthorInfo authorInfo={authorInfo} subscribeAuthor={subscribeAuthor} unsubscribeAuthor={unsubscribeAuthor} isFocused={isFocused} />
            <ImageContainer titleKey={`${serviceId}:${titleId}`} slides={slides} thumbnail={thumbnail} isFocused={isFocused} handleFinishedDissolve={handleFinishedDissolve}>
                <InteractionBar titleInfo={titleInfo} subscribeTitle={subscribeTitle} unsubscribeTitle={unsubscribeTitle} />
            </ImageContainer>
            <WebtoonInfo titleInfo={titleInfo} />
            
        </div>
    );
}

export default Dissolve;

const AuthorInfo = ({ 
    authorInfo,
    subscribeAuthor,
    unsubscribeAuthor,
    isFocused
}:{
    authorInfo: {
        id: string,
        name: string,
        image: string,
        subscriptionCount: number,
        subscribe: boolean,
        otherTitle: {
            serviceId: string,
            titleId: string,
            href: string,   
            thumbnail: string
        }[]
    },
    subscribeAuthor: (authorId: string) => Promise<boolean>,
    unsubscribeAuthor: (authorId: string) => Promise<boolean>,
    isFocused: boolean
}) => {
    const { id, name, image, subscribe } = authorInfo;

    const { toastAlert } = useToastAlert();
    const { session } = useSession();

    // subscribe author Function
    const subscribeAuthorHandler = () => {
        // 로딩
        ( async () => {
            if (session.memberState !== 'regular') {
                fn.goto('/signin');
                return;
            }
            
            if (subscribe) {
                const res = await unsubscribeAuthor(authorInfo.id);
                if (!res) {
                    toastAlert('관심 작가 취소에 실패했습니다.')
                }
            } else {
                const res = await subscribeAuthor(authorInfo.id);
                if (!res) {
                    toastAlert('관심 작가 등록에 실패했습니다.')
                }
            };
        })();
        // 로딩 끝
    };

    // check and go to portfolio
    const handlePortfolioNavigation = () => {
        // 로딩
        (async () => {
            const authorProfile = await readProfile(id);
            const res = await readPortfolio(authorProfile?.addressid);
            logBehavior('click-author', {...authorProfile});
            if ( res ) {
                fn.goto(`/portfolio/${authorProfile.addressid}`);
            } else {
                toastAlert(`아직 작가님의 프로필이 준비되지 않았어요.`);
            };
        })();
        // 로딩 끝
    };

    return(
        <div className={'AuthorInfo'}>
            <div className={'AuthorProfile'} onClick={handlePortfolioNavigation} >
                <img src={image || "https://static.webtoon.today/noprofile.jpg"} alt={name} style={{width: '24px', height: '24px', borderRadius: '50%', border: '1px solid rgba(67,63,70,1)', boxSizing: 'border-box' }} />
                <div>
                    {name}
                </div>
            </div>
            <div className={`FollowButton ${subscribe ? 'Subscribed' : ''} ${isFocused ? 'Focused' : ''}`} onClick={subscribeAuthorHandler} >
                {subscribe && <Done style={{width: '12px', height:'12px', backgroundColor: 'rgba(80, 200, 114, 1)', borderRadius: '50%'}} />}
                <span style={{wordBreak: 'keep-all'}}>{'팔로우'}</span>
            </div>
        </div>
    )
};

const ImageContainer = ({ 
    titleKey, slides, thumbnail, isFocused, handleFinishedDissolve, children
}:{ 
    titleKey: string,
    slides: {name: string, image: string, createdAt: number}[],
    thumbnail: string ,
    isFocused: boolean,
    handleFinishedDissolve: (currentTitleKey: string) => void,
    children?: ReactNode
}) => {
    const slideDelay = 1000;

    const [ swiperInstance, setSwiperInstance ] = useState<SwiperClass | null>(null);
    const [ activeIndex, setActiveIndex ] = useState<number>(0);
    const loopCountRef = useRef(0);
    
    useEffect(() => {
        if(isFocused && swiperInstance){
            setTimeout(() => {
                if((swiperInstance.slides || []).length <= 1){
                    setTimeout(() => {
                        handleFinishedDissolve(titleKey);
                    }, slideDelay + 1000);
                } else{
                    swiperInstance.autoplay.start();
                }
            }, slideDelay);
        }
    }, [handleFinishedDissolve, isFocused, swiperInstance, titleKey])

    return(
        <>
            <div className={'ImageContainer'} >
                {isFocused && <img src={slides[activeIndex]?.image} alt={slides[activeIndex]?.name} width={'100%'} height={'calc(100vw - 32px)'} style={{position: 'absolute', top:0, left:0, filter: 'blur(100px)'}}/>}
                <Swiper
                    style={{width: '100%'}}
                    effect={"fade"}
                    fadeEffect={{crossFade: true}}
                    autoplay={{
                        delay: slideDelay,
                    }}
                    speed={slideDelay}
                    modules={[EffectFade, Autoplay]}
                    className="mySwiper"
                    onActiveIndexChange={(swiper) => {
                        if (isFocused) {
                            setActiveIndex(swiper.activeIndex);
                            if (swiper.isEnd) {
                                if(loopCountRef.current === 0){
                                    loopCountRef.current += 1;
                                } else{
                                    loopCountRef.current = 0;
                                    
                                    setTimeout(() => {
                                        handleFinishedDissolve(titleKey);
                                        swiperInstance?.autoplay?.stop();
                                        swiperInstance?.slideTo(0);
                                    }, slideDelay + 1000);
                                }
                            };
                        } else {
                            swiper.autoplay?.stop();
                        };
                    }}
                    onSwiper={ ( swiper ) => setSwiperInstance(swiper) }
                >
                    {(slides || []).length < 2
                        ?<SwiperSlide style={{width: '100%'}} >
                            <img className={'swiper-slide-Image'} width={'100%'} src={slides[0]?.image || thumbnail} alt={'singleImage'} />
                        </SwiperSlide>
                        :slides.map( slide =>
                            <SwiperSlide key={slide.image} style={{width: '100%'}}>
                                <img className={'swiper-slide-Image'} width={'100%'} src={slide.image} alt={slide.name} />
                            </SwiperSlide>
                        )}
                </Swiper>
                {children}
            </div>
        </>
    )
}

const WebtoonInfo = ({
    titleInfo
}:{
    titleInfo: FeedTitleInfoType
}) => {

    const { handleClickLogs } = useClickLogs();

    const clickCallback = (serviceId: string, titleId: string) => {
        const timestamp = Math.floor(new Date().getTime()/1000);
        handleClickLogs([{serviceId, titleId, timestamp}]);
        logBehavior("feed-click",{
            clickLogs:[
                {serviceId, titleId, timestamp}
            ],
        })
        fn.goto(`/contents/${serviceId}/${titleId}`);
    }

    return(
        <div className={'WebtoonInfo'} >
            <div className={'Title'} onClick={() => clickCallback(titleInfo.serviceId,titleInfo.titleId)}>
                <div>{titleInfo.title}</div>
                <ChevronRight />
            </div>
            <div className={'OtherInfo'}>
                <div className={'ViewCount'}>
                    <VisibilityOutlined style={{width: 18, height: 18}} />
                    <span>{titleInfo.viewCount}</span>
                </div>
                <span>{'|'}</span>
                <span>{`총${titleInfo.episodeCount}화`}</span>
                {!titleInfo.tags.includes('') &&
                <>
                    <span>{'|'}</span>
                    <span>{titleInfo.tags.join(' · ')}</span>
                </>}
            </div>
        </div>
    )
}

const InteractionBar = ({
    titleInfo,
    subscribeTitle,
    unsubscribeTitle
}:{
    titleInfo: FeedTitleInfoType
    subscribeTitle: (serviceId: string, titleId: string) => Promise<boolean>,
    unsubscribeTitle: (serviceId: string, titleId: string) => Promise<boolean>
}) => {
    const { serviceId, titleId, favorite, favoriteCount, firstEpisodeId } = titleInfo;
    const titleHomeUrl = `/contents/${serviceId}/${titleId}`;
    const firstEpisodeUrl = `/contents/${serviceId}/${titleId}/${firstEpisodeId}`;
    
    const { session } = useSession();
    
    const { handleClickLogs } = useClickLogs();

    const clickCallback = (serviceId: string, titleId: string) => {
        const timestamp = Math.floor(new Date().getTime()/1000);
        handleClickLogs([{serviceId, titleId, timestamp}]);
        logBehavior("feed-click",{
            clickLogs:[
                {serviceId, titleId, timestamp}
            ],
        })
        window.history.pushState({}, "");
        fn.replaceWith(titleHomeUrl);
        fn.goto(firstEpisodeUrl);
    }

    const onSubscribeTitleHandler = async () => {
        if (session.memberState !== 'regular') {
            fn.goto('/signin');
            return;
        };

        if (favorite) {
            const res = await unsubscribeTitle(serviceId, titleId);
            if (!res){
                fn.alert("관심작품 취소에 실패했습니다.");
            };
        } else {
            const res = await subscribeTitle(serviceId, titleId);
            if (!res){
                fn.alert("관심작품 등록에 실패했습니다.");
            };
        };
    };
    
    return(
        <div className={'InteractionBar'}>
            <div className={'Interaction'} onClick={onSubscribeTitleHandler} >
                <img src={!!favorite ? 'https://static.webtoon.today/ddah/icon/icon_redFullHeart.svg' : 'https://static.webtoon.today/ddah/icon/icon_heart.svg'} alt={'관심 등록'} width={28} height={28} />
                <span>{favoriteCount || 0}</span>
            </div>
            <div className={'Interaction'} onClick={() => clickCallback(serviceId, titleId)}>
                <img src={'https://static.webtoon.today/ddah/icon/icon_firstEpisode.svg'} alt={'첫 화'}/>
                <span>{'첫 화'}</span>
            </div>
            <Share
                givenUrl={firstEpisodeUrl}
                contents={
                    <div className={'Interaction'}>
                        <img src={'https://static.webtoon.today/ddah/icon/icon_externalSharing.svg'} alt={'공유'}/>
                        <span>{'공유'}</span>
                    </div>
                }
            />
        </div>
    )
}

