import React from 'react';
import { Checkbox, FormControl, FormControlLabel, RadioGroup, Radio, TextField, Button } from '@material-ui/core';
import { fn } from '../../Functions';
import {logBehavior} from '../../Data/Behavior';
import {removeProfile} from '../../Data/Profile';
import { useSession } from '../../Recoil/Session/Session';
import { useSetRecoilState } from 'recoil';
import { pageHeaderOptions } from '../../Recoil/PageHeader/PageHeader';

const unregisterWarning = [
    `아이디, 비밀번호, 이메일, 이름 등 개인정보가 모두 삭제됩니다. 단, [개인정보보호법] 등 기타 법령에서 
    요구하는 정보는 해당 법령에서 요구하는 기간동안 보존합니다.`,
    '작품, 댓글은 함께 삭제되지 않으니 미리 확인해주세요.'
]

const unregisterReasons = [
    '자주 사용하지 않아요',
    '사용하기 불편했어요',
    '다른 사이트를 더 이용해요',
    '사용 기록을 삭제하고 싶어요',
    '콘텐츠가 불만족스러워요',
    '직접 입력'
]


const Unregister = () => {
    const alert = fn.alert;
    const { session, sessionRefresh } = useSession();

    const setPageHeader = useSetRecoilState(pageHeaderOptions);
    React.useEffect(()=>{
        setPageHeader({
            titleText: '회원 탈퇴',
            headerStyle: {borderBottom: '1px rgb(231,231,231) solid'}
        })
    },[setPageHeader])

    if (session.memberState !== 'regular'){
        fn.goto('/')
    }

    const [ checkingWarning, setCheckingWarning ] = React.useState(false)
    const [ unregisterReasonText, setUnregisterReasonText ] = React.useState(null);
    const [reasonValue, setReasonValue] = React.useState(null);

    return (
        <div className={'UserPageArea'}>
            <div className={'BodyContainer'} >
                <div className={'InnerInputBox'}>
                    <div className={'InformSpace'}>
                        <div className={'InformTitle'}>
                            {`${session.name}님 정말 탈퇴하시겠어요?`}
                        </div>
                        <div>
                            <ul style={{paddingLeft: 25, margin: '10px 0', lineHeight: '24px'}}>
                                {unregisterWarning
                                    .map(warning => (
                                        <li style={{margin: '5px 0'}} key={warning}>
                                            {warning}
                                        </li>
                                    ))
                                }
                            </ul>
                            <div style={{display: 'flex', alignItems:'center', marginTop: 18}}> 
                                <span>
                                    <Checkbox checked={checkingWarning} size={'small'} style={{padding: 0, margin: '0 9px 0 2px', color: `${checkingWarning?'rgb(0,176,240)':'rgb(196,196,196)'}`}} 
                                        onClick={ () => {
                                            if (checkingWarning){
                                                setCheckingWarning(false);
                                            } else {
                                                setCheckingWarning(true);
                                            };
                                        }} 
                                    />
                                </span>
                                <span>{'유의사항을 모두 확인했습니다.'}</span>
                            </div>
                        </div>
                    </div>
                    <div className={'InformSpace'}>
                        <div className={'InformTitle'}>
                            {'떠나시는 이유를 알려주세요.'}
                        </div>
                        <div className={'InformText'} >{`${session.name}님의 소중한 피드백을 담아
                        더 나은 서비스로 나중에 찾아뵐게요.`}</div>
                        <FormControl component="fieldset">
                            <RadioGroup aria-label="gender" name="gender1" value={reasonValue || ''} onChange={(event)=> setReasonValue(event.target.value)}>
                                {unregisterReasons.map(reason => (<FormControlLabel style={{margin: 0}} value={reason} control={
                                    <Radio style={{padding: 0, margin: '5px 15px 6px 2px', color: `${reason === reasonValue?'rgb(0,176,240)':'rgb(196, 196, 196)'}`}} disableRipple size={'small'} />} 
                                    label={reason} key={reason} />
                                ))}
                            </RadioGroup>
                        </FormControl>
                        {reasonValue === '직접 입력'
                            ?<TextField
                                variant={'outlined'} 
                                style={{padding: 10}}    
                                minRows={2}
                                multiline={true}
                                placeholder={`더 나은 오늘의 웹툰이 될 수 있도록 소중한 의견을 들려주세요.`} 
                                value={unregisterReasonText || ''}
                                onChange={ (e) => setUnregisterReasonText(e.target.value) }
                            />
                            :<></>
                        }
                    </div>
                </div>
            </div>
            <div className={'Footer'} style={{padding: 34}}>
                <Button 
                    fullWidth
                    className={'SaveButton'} variant={'contained'} color={'primary'} disabled={
                        !checkingWarning || !reasonValue || (reasonValue==='직접 입력' && !unregisterReasonText)
                    }
                    onClick={()=>{
                        (async()=>{

                            logBehavior('submit-withdrawalsurvey', {reason: reasonValue, subjective: unregisterReasonText});
                            logBehavior('withdraw-account');
                            try{

                                const res = await removeProfile(session.userid);
                                // 설문을 전송할 수 있는 api 추가
        
                                if (res) {

                                    sessionRefresh();
                                    fn.goto('/account/unregister/complete');
                                } else {
                                    alert('계정 삭제 중 오류가 발생했어요! 문의하기를 통해 해결해주세요.')
                                }
                            }catch(e){
                                alert('계정 삭제 중 오류가 발생했어요! 문의하기를 통해 해결해주세요.')
                            }
                        })()
                    }} 
                >
                    {'탈퇴하기'}
                </Button>
            </div>
        </div>
    )
};

export default Unregister;