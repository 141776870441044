import { Button } from '@material-ui/core';
import React from 'react';

let naverLogin = null;
const Naver = 'https://static.webtoon.today/challenge/NaverIcon.png';
const NAVER_CALLBACK_URL = window.location.protocol+"//"+window.location.host+'/naverCallback';
const NAVER_CLIENT_ID = "rrk9ZdoADuC0MIGJ_mzu";

const NaverLogin = ({ onSuccess }) => {
    
    const naverRef = React.useRef();
    

    React.useEffect(() => {
        const { naver } = window
        
	    const initializeNaverLogin = () => {
            naverLogin = naverLogin || new naver.LoginWithNaverId({
                clientId: NAVER_CLIENT_ID,
                callbackUrl: NAVER_CALLBACK_URL,
                isPopup: true,
                loginButton: { color: 'green', type: 3, height: 58 },
                callbackHandle: false,
            })
            naverLogin.init()};

        initializeNaverLogin()

        window.updateNaverSession = async (sessionData) => {

            let data = Object.fromEntries(sessionData.url.hash.replace('#','').split("&").map(pair => pair.split("=")))
            
            onSuccess(data);
        }

    }, [onSuccess]);

    const handleNaverLogin = () => {
        naverRef.current.children[0].click();
    };

	return (
		<>
			<div id={"naverIdLogin"} ref={naverRef} hidden />
            <Button onClick={handleNaverLogin} className={'Button'} style={{backgroundColor: 'rgb(3,199,90)', color:'white', height: '45px'}} >
                <img src={Naver} alt={'naverLogo'} style={{maxHeight: '40px', position: 'absolute', left: '5px'}} />
                <span>{'Naver로 계속하기'}</span>
            </Button> 
		</>
	)
}

export default NaverLogin;