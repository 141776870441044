import React from 'react';

import "./LoadingCircle.scss";

/**
 * 
 * @param {{show: boolean}} props
 * @returns 
 */
const LoadingCircle = ({show}) => {

    return (<div className={`LoadingCircle Animated ${show?'Show':''}`} style={{width: '100vw', height: '100vh'}}>
        <div className='Holder Bottom'><div className={"Circle"}/></div>
        <div className='Holder Left'  ><div className={"Circle"}/></div>
        <div className='Holder Top'   ><div className={"Circle"}/></div>
        <div className='Holder Right' ><div className={"Circle"}/></div>
        <div className='Holder Center'><div className={"Circle"}/></div>
    </div>);
}

export default LoadingCircle;