import { useEffect, useState } from "react";
import Search from "./Search";
import Result from "./Result";

const TrendSearchIndex = ({topic}: {topic: string}) => {
    const [ searchText, setSearchText ] = useState<string>("");

    useEffect( () => {
        setSearchText(topic);
    },[topic])
    
    if ( !topic ) {
        return <Search />;
    } else {
        return <Result {...{searchText}} />;
    }
}

export default TrendSearchIndex;
