import { Paper } from '@material-ui/core';
import React from 'react';
import './AdsSlides.scss';

import { MoreVert,
    ChevronRight,
    ChevronLeft,
    FavoriteBorder,
    ChatBubbleOutline,
    Send,
    BookmarkBorder } from '@material-ui/icons';

type AdsSlidesTitlePropsType = {
    title: string;
    slides: {
        name: string;
        image: string;
        createdAt: number;
    }[];
    serviceId: string;
    description: string;
}

const SLIDE_WIDTH = 300;

const AdsSlides = ( {style={}, title = {title: "", slides: [], serviceId: "", description: ""}, carouselOnly=false}: {style?: React.CSSProperties, title?: Partial<AdsSlidesTitlePropsType>, carouselOnly?: boolean} ) => {
    const [adSlide, setAdSlide] = React.useState(0);

    const nextMove = () => {
        if (title.slides) {
            setAdSlide((prevAdSlide) => (prevAdSlide + 1) % ((title.slides || []).length || 1));
        }
    }
    
    const prevMove = () => {
        if (title.slides) {
            setAdSlide((prevAdSlide) => (prevAdSlide + ((title.slides || []).length - 1)) % ((title.slides || []).length || 1));
        }
    }

    const PageHandler = (idx:number) => {
        setAdSlide( idx );
    }
    const CarouselAnimationStyle = {
        transform: `translate(-${adSlide * SLIDE_WIDTH}px, 0px)`
    }
    const [isReadMore, setIsReadMore] = React.useState(false);

    const toggleReadMore = () => {
        setIsReadMore((prevIsReadMore) => !prevIsReadMore);
    }
    
    return (
        <Paper className={"AdsContainer"} elevation={0} style={style}>
            <Paper variant={"outlined"} className={"AdsPaper"}>
                <div className={"Header"} style={carouselOnly?{display:'none'}:{}}>
                    <img src={"https://static.webtoon.today/ddah/instagram-banner.png"} alt={"instagram banner"}/>
                </div>
                <div className={"CompanyArea"} style={carouselOnly?{display:'none'}:{}}>
                    <img className={"CompanyImg"} src={"https://static.webtoon.today/ddah/logo-04.png"} alt={"Logo"} />
                    <div className={"Company"}>
                        <div className={"Brand"}>{"오늘의 웹툰"}</div>
                        <div className={"Sponsored"} >{"Sponsored"}</div>
                    </div>
                    <MoreVert className={"CollapseIcon"} />
                </div>
                <div className={"SliderContainer"}>
                    <ChevronLeft className={"Arrow Prev"} onClick={prevMove} />
                    <ChevronRight className={"Arrow Next"} onClick={nextMove} />
                    <div className={"Slider"} style={CarouselAnimationStyle} >
                        {title.slides
                            ?title.slides.map(({name, image, createdAt}) =>
                                <img className={"SlideCut"} key={`${image}:${name}:${createdAt}`} src={image} alt={'slideCut'} />
                            )
                            :<img className={'SlideCut'} key={`${'noImage.jpg'}`} alt={'noimage-adsSlide'} src={"https://static.webtoon.today/noimage.jpg"} />}
                    </div>
                </div>
                <div className={"AccessMore"} style={carouselOnly?{display:'none'}:{}}>
                    <div className={"MoreText"}>
                        {"더 알아보기"}
                    </div>
                    <ChevronRight />
                </div>
                <div className={"IconsArea"} style={carouselOnly?{display:'none'}:{}}>
                    <FavoriteBorder className={"Favorite Icon"} />
                    <ChatBubbleOutline className={"Chat Icon"} />   
                    <Send className={"Send Icon"} />
                    <div className={"Pagination"}>
                        {title.slides && title.slides.map(({name, image, createdAt}, idx) =>
                            <button 
                                key={`${image}:${name}:${createdAt}`} 
                                value={idx} 
                                className={`PaginationButton ${idx === adSlide ? "Selected":""}`} 
                                onClick={() => PageHandler(idx)} 
                            />
                        )}
                    </div>
                    <BookmarkBorder className={"Bookmark Icon"} />
                </div>
                <div className={"TextArea"} style={carouselOnly?{display:'none'}:{}}>
                    <div className={"ViewContent"}>
                        <div>
                            {title.serviceId && <span className={'ServiceIdText'}>{`${title.serviceId} - `}</span>}
                            <span>{title.title}</span>
                        </div>
                        <div hidden={isReadMore}>
                            <span onClick={toggleReadMore} className={"ViewMore"} >{"... more"}</span>
                        </div>
                        <div hidden={!isReadMore}>
                            {"(링크)"}
                        </div>
                        <div hidden={!isReadMore}>
                            {"---"}
                        </div>
                        <div className={'Description'} hidden={!isReadMore}>
                            {title.description}
                        </div>
                    </div>
                </div>
            </Paper>
        </Paper>
    );
}

export default AdsSlides;