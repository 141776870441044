import React from 'react';
import './SignIn.scss';

import { fn } from '../../Functions';
import {logBehavior} from '../../Data/Behavior';
import AccountSetting from './AccountSetting';
import PersonalSetting from './PersonalSetting';
import EntryPoint from './EntryPoint';
import LoginWithPassword from './LoginWithPassword';
import { useSession, useSignIn } from '../../Recoil/Session/Session';
import { useToastAlert } from '@webtoontoday/toast';

const SignIn = ({mode='signin'}) => {
    const { toastAlert } = useToastAlert();
    const { session, sessionRefresh } = useSession();
    const { EmailTokenSignin } = useSignIn();

    React.useEffect(() => {
        if (session.memberState === 'regular') {
            setTimeout(() => fn.goto("/"), 200);
        }else {
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const [ email, setEmail ] = React.useState(null);
    const [ password, setPassword ] = React.useState(null);
    const [ name , setName ] = React.useState(null)
    const [ gender, setGender ] = React.useState(null);
    const [ birthday, setBirthday ] = React.useState(null);
    const [ otp, setOtp ] = React.useState(null);
    const [ certificatedName, setCertificatedName ] = React.useState(null);
    const [ certificatedBirthday, setCertificatedBirthday ] = React.useState(null);
    const [ certificatedPhoneNumber, setCertificatedPhoneNumber ] = React.useState(null);

    React.useEffect(()=>{
        const alert = fn.alert;

        let timer = setTimeout(()=>{
            (async()=>{
                const url =  new URL(window.location.href)
                const email = url.searchParams.get('email');
                const token = url.searchParams.get('token');
                if (email && token){
                    

                    if (toastAlert === global.alert){
                        setTimeout(()=>{
                            fn.toastAlert("자동 로그인 중입니다.",2000);
                        },100);
                    }else{
                        toastAlert("자동 로그인 중입니다.",2000);
                    }
                    let res = await EmailTokenSignin(email, token);
                    
                    if (res){
                        logBehavior('login(success)');
                        toastAlert("자동 로그인이 성공했습니다.",2000);

                        if (url.searchParams.get('redirectTo')) {
                            fn.goto(url.searchParams.get('redirectTo'));
                        }else {
                            fn.goto('/')
                        }
                        sessionRefresh();
                    }else{
                        alert("인증에 실패했습니다. 다시 확인해주세요.")
                    };
                };

                if (email) {
                    setEmail(email);
                };
            })();
        },200)

        return ()=>{
            clearTimeout(timer);
        }

    },[EmailTokenSignin, sessionRefresh, toastAlert])
    
    if (mode === 'signin'){
        return <EntryPoint
            {...{email, setPassword, setName, setGender, setBirthday, setEmail}}
        />
    } else if (mode === 'accountsetting') {
        return <AccountSetting
            {...{email, birthday, setBirthday, otp, setOtp, password, setPassword, setGender, setName, setCertificatedName, setCertificatedBirthday, setCertificatedPhoneNumber}}
        />;
    } else if (mode === 'personalsetting') {
        return <PersonalSetting
            {...{email, name, setName, gender, setGender, otp, password, birthday, setBirthday, certificatedName, certificatedBirthday, certificatedPhoneNumber}}
        />;
    } else if (mode === 'login') {
        return <LoginWithPassword
            {...{email, setEmail, password, setPassword, }}
        />
    }
}

export default SignIn;