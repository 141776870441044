import React, { ReactNode } from 'react';
import './WithLabel.scss';

const WithLabel = ({children, label}: {children: ReactNode, label: string}) => {
    return (
        <div className={'WithLabel'}>
            <div className={'Label'}>
                {label}
            </div>
            {children}
        </div>
    );
};

export default WithLabel;