

import { IconButton, TextField } from '@material-ui/core';
import { fn } from '../../../Functions';
import { AddCircle, ArrowBackIos, Search as SearchIcon } from '@material-ui/icons';
import { ChangeEvent, Dispatch, KeyboardEvent, SetStateAction, useEffect, useState } from 'react';
import { executeSearch } from '../Util/Functions';

const SearchField = ({ 
    query, type, limit, setCurrentText
}:{ 
    query: string,
    type: string,
    limit: number,
    setCurrentText: Dispatch<SetStateAction<string>>
}) => {

    const [ text, setText ] = useState(query);

    useEffect( () => {
        if (query) {
            setText(query);
        } else {
            setText("");
        }
    }, [query])

    return(
        <div className={'HeaderBox'} >
            <IconButton className={'MoveToHompage'} onClick={ () => {
                fn.goto("/");
            }} >
                <ArrowBackIos />
            </IconButton>
            <div className={'SearchField'} >
                <TextField
                    className={'TextField'} InputProps={{style:{ borderColor: 'white'}}}
                    fullWidth
                    value={ text } placeholder={'제목, 작가, 장르'}
                    onChange={ (e: ChangeEvent) => {
                        const value = (e.target as HTMLInputElement).value;
                        setText(value);
                    }}
                    onKeyUp= { (e: KeyboardEvent) => {
                        if (e.key === 'Enter') {
                            const searchText = (e.target as HTMLInputElement).value
                            executeSearch(searchText, type, limit)
                            setCurrentText(searchText)
                        }
                    }}
                />
                <AddCircle
                    className={'TextClearButton'} 
                    onClick={ () =>  {
                        setText("");
                    }} 
                />
            </div>
            <IconButton className={'SearchStartButton'} onClick={ () =>  executeSearch(text, type, limit) } >
                <SearchIcon />
            </IconButton>
        </div>
    )
}

export default SearchField;