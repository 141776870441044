import React from 'react';
import { Button, TextField } from '@material-ui/core';
import { readProfile } from '../../../Data/Profile';

const NameChange = ({addressid: givenAddressid, callback=(property)=>{}, }) => {
    
    let [addressid, setAddressid] = React.useState(givenAddressid);
    let [isAvailable, setIsAvailable] = React.useState(false);

    return(
        <div className={'UserPageArea'}>
            <div className={'BodyContainer'} >
                <div className={'InnerInputBox'}>
                    <div className={'BoxLabel'}>
                        {'영어 작가명'}
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <TextField fullWidth value={addressid || ''} onChange={(event)=>{
                            setAddressid(event.target.value);
                            if (isAvailable){
                                setIsAvailable(false);
                            }
                        }} inputProps={{style:{fontSize: '0.9rem'}}} style={{marginTop: '10px'}} />
                        <Button
                            disabled={isAvailable}
                            variant={'contained'} color={'primary'}
                            disableElevation
                            onClick={()=>{
                                (async()=>{
                                    let profile = await readProfile(addressid);

                                    if (profile && profile.addressid && !profile.email){
                                        setIsAvailable(false);
                                    }else{
                                        setIsAvailable(true);
                                    }
                                })();
                            }}
                        >
                            {"확인"}
                        </Button>
                    </div>
                </div>
            </div>
            <div className={'Footer'} >
                <Button fullWidth disabled={!isAvailable} className={'SaveButton'} variant={'contained'} color={'primary'} onClick={()=>{
                    if (givenAddressid !== addressid) {
                        callback(addressid);
                    }
                }}>
                    {'저장'}
                </Button>
            </div>
        </div>
    );
}

export default NameChange;