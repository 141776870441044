import ss from '../Data/Session';
import axios from 'axios';

/**
 * @typedef {{
 *   name: string, episodeRange: [number, number], title:string, serviceId:string, titleId:string, school?: string, phoneNumber?: string,
 *   service:'competition'|'analytics'|'2023schoolCompetition'|'2024schoolCompetition'|'schoolCompetition', 
 *   introduction:string, advertisementCuts: {name: string, image: string, createdAt: number}[], 
 *   requestCreatives: boolean, consentList: string[], currentTermsofuseVersion: string,
 *   logline?: string, character?: string 
 * }} CompetitionInfo
 * 
 * @param {CompetitionInfo} competitionInfo
 * @returns 
 */

export const AnalyticsSubmit = async (competitionInfo) => {

    try{
        
        let res = await axios.post('https://challenge-api.webtoon.today/analytics-submit-confirm', competitionInfo,  {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });
        
        if (res.data.code === 200 && res.data.data) {
            return res.data.data;
        } else {
            return null;
        }

    }catch(e){
        if (e.response && e.response.status === 404) {
            console.log(e);
            return {serviceId: competitionInfo.serviceId, titleId: competitionInfo.titleId};
        };
        console.error(e)
        return null;
    };
};


/**
 * @typedef {{
 *     isAnalyticsSubmitted: boolean,
 *     isCompetitionSubmitted: boolean,
 *     isSchoolCompetitionSubmitted: boolean,
 *     isSchoolCompetitionModifiable: boolean,
 *     isAnalyticsValidatedAt: boolean,
 *     isAnalyticsCompleted: boolean,
 *     isAutomatedAnalyticsCompleted: boolean,
 *     analyticsSubmitCreatedAt: string,
 *     key: string,
 *     schoolCompetitionSubmittedYear: string,
 * }} analyticsSubmitInfo
 * 
 * @param {{titleId:string, serviceId:string}}
 * 
 * @returns {Promise<analyticsSubmitInfo>}
 */

export const readAnalyticsSubmitInfo = async ({serviceId, titleId, episodeId, snapshotId}) => {

    try{
        let res = await axios.post('https://challenge-api.webtoon.today/webtoon/analytics-submit-info', {serviceId, titleId, episodeId, snapshotId},  {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });
        
        if (res.data.code === 200 && res.data.data) {
            return res.data.data;
        } else {
            return null;
        };

    }catch(e){
        console.error(e)
        return null;
    };
};