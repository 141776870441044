import React, {useRef} from 'react';
import { appearUp } from "../SettingValues";
import AnimateDiv from './AnimateDiv'
import { Card } from "@material-ui/core";

import './Member.scss'

import {useOnScreen} from '../../../Hooks';
import {logBehavior} from '../../../Data/Behavior';
let didSeen = false;




const Member = () => {

    const ref = useRef();
    const onScreen = useOnScreen(ref);
    if (!didSeen && onScreen){
        didSeen = onScreen;
        logBehavior('scroll-member', {});
    }

    return (

        <div ref={ref} className={"MemberSubRoot"} id={"Member"}>
            
            <div className={"MemberTitle"}>
                <AnimateDiv variants={appearUp} >
                    <div className={"TeamTitle"}>
                        {"오늘의웹툰 팀"}
                    </div>
                </AnimateDiv>

                <AnimateDiv variants={appearUp}>
                    <div className={"TitleFlex"}>
                        <span className={"Blue"}>
                            {"데이터"}
                        </span>
                        {"를 알고 "}
                        <span className={"Blue"} >
                            {"웹툰"}
                        </span>
                        {"을 이해하는 팀이 "}
                        <span style={{whiteSpace:'nowrap'}}>{"함께 합니다."}</span>
                    
                    </div>
                </AnimateDiv>
            </div>
            <div>
                <div className={"Section"} >
                    <div className={"SeperatedSection"}>
                        <div className={"CardBox"}>
                            <AnimateDiv variants={appearUp}>
                                <Card className={"Cards"} elevation={0}>
                                    <div className={"Characters"}>
                                        <img
                                            src={"https://static.webtoon.today/ddah/sugeul.png"}
                                            alt={"진수글"}
                                            className={"CardIcon"}
                                        />
                                    </div>
                                    <div className={"Introduction"}>
                                        <div className={"JobPositions"}>
                                            <div className={"Position"}>
                                                {"대표 "}
                                            </div>
                                            <div className={"Name"}>
                                                {"진수글"}
                                            </div>
                                            <div className={"Position Hidden"}>
                                                {"대표 "}
                                            </div>
                                        </div>
                                        <div>
                                            <ul className={"UlStyles"}>
                                                <li>
                                                    <span
                                                        className={"SubText"}
                                                    >
                                                        {"'19.~'20."}
                                                    </span>
                                                    <br />
                                                    <div className={"Context"}>
                                                        {"브이로거(Vlogr) CTO"}
                                                    </div>
                                                </li>
                                                <li>
                                                    <span
                                                        className={"SubText"}
                                                    >
                                                        {"'16.~'19."}
                                                    </span>
                                                    <br />
                                                    <div className={"Context"}>
                                                        {
                                                            "네이버 검색센터 추천연구팀(AiRS) Modeler"
                                                        }
                                                    </div>
                                                </li>
                                                <li>
                                                    <span className={"SubText"}>
                                                        {"'15.~'15."}
                                                    </span>
                                                    <br />
                                                    <div className={"Context"}>
                                                        {"Bain&Company Research Assistant"}
                                                    </div>
                                                </li>
                                                <li>
                                                    <span className={"SubText"}>
                                                        {"'08.~'13."}
                                                    </span>
                                                    <br />
                                                    <div className={"Context"}>
                                                        {"카이스트 전산학과"}
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </Card>
                            </AnimateDiv>
                        </div>
                        <div className={"CardBox"}>
                            <AnimateDiv variants={appearUp}>
                                <Card className={"Cards"} elevation={0}>
                                    <div className={"Characters"}>
                                        <img 
                                            src={"https://static.webtoon.today/ddah/seojung.png"}
                                            alt={"김서정"}
                                            className={"CardIcon"}
                                        />
                                    </div>
                                    <div className={"Introduction"}>
                                        <div className={"JobPositions"}>
                                            <div className={"Position"}>
                                                {"디렉터 "}
                                            </div>
                                            <div className={"Name"}>
                                                {"김서정"}
                                            </div>
                                            <div className={"Position Hidden"}>
                                                {"디렉터 "}
                                            </div>
                                        </div>
                                        <div>
                                            <ul className={"UlStyles"}>
                                                <li>
                                                    <span className={"SubText"} >
                                                        {"'19.~"}
                                                    </span>
                                                    <br />
                                                    <div className={"Context"}>
                                                        {"유튜브 '두미두미의 웹툰강좌' 운영"}
                                                    </div>
                                                </li>
                                                <li>
                                                    <span className={"SubText"}>
                                                        {"'18.~"}
                                                    </span>
                                                    <br />
                                                    <div className={"Context Multiline"}>
                                                        {"웹툰 연재"}<br/>
                                                        <div className={"SubContext"}>
                                                            {"- 피너툰 '낭만주의자와 쾌락주의자가 만났을 때'"}
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <span className={"SubText"}>
                                                        {"'09.~'17. "}
                                                    </span>
                                                    <br />
                                                    <div className={"Context"}>
                                                        {"윤지운 작가 어시스턴트"}
                                                    </div>
                                                </li>
                                                <li>
                                                    <span className={"SubText"}>
                                                        {"'99.~'01. "}
                                                    </span>
                                                    <br />
                                                    <div className={"Context"}>
                                                        {"치요다 공과예술 전문학교 만화과"}
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </Card>
                            </AnimateDiv>
                        </div>
                    </div>
                    <div className={"SeperatedSection"}>
                        <div className={"CardBox"}>
                            <AnimateDiv variants={appearUp}>
                                <Card className={"Cards"} elevation={0}>
                                    <div className={"Characters"}>
                                        <img
                                            src={"https://static.webtoon.today/ddah/soonjae.png"}
                                            alt={"권순제"}
                                            className={"CardIcon"}
                                        />
                                    </div>
                                    <div className={"Introduction"}>
                                        <div className={"JobPositions"}>
                                            <div className={"Position"}>
                                                {"디렉터 "}
                                            </div>
                                            <div className={"Name"}>
                                                {"권순제"}
                                            </div>
                                            <div className={"Position Hidden"}>
                                                {"디렉터 "}
                                            </div>
                                        </div>
                                        <div>
                                            <ul className={"UlStyles"}>
                                                <li>
                                                    <span className={"SubText"}>
                                                        {"'20.~ "}
                                                    </span>
                                                    <br />
                                                    <div className={"Context"}>
                                                        {"네이버 시리즈 '위대한 폭군' 연재"}
                                                    </div>
                                                </li>
                                                <li>
                                                    <span className={"SubText"}>
                                                        {"'19.~ "}
                                                    </span>
                                                    <br />
                                                    <div className={"Context"}>
                                                        {"SBS아카데미 웹소설 및 시나리오 강의"}
                                                    </div>
                                                </li>
                                                <li>
                                                    <span className={"SubText"}>
                                                        {"'19.~ "}
                                                    </span>
                                                    <br />
                                                    <div className={"Context"}>
                                                        {"넷마블 '제2의 나라' 설정 및 시나리오 기획"}
                                                    </div>
                                                </li>
                                                <li>
                                                    <span className={"SubText"}>
                                                        {"'15.~ "}
                                                    </span>
                                                    <br />
                                                    <div className={"Context"}>
                                                        {"텐센트 웹툰 '유니콘' 스토리 작가 외 다수 웹툰, 웹소설 연재"}
                                                        
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </Card>
                            </AnimateDiv>
                        </div>
                        <div className={"CardBox"}>
                            <AnimateDiv variants={appearUp}>
                                <Card className={"Cards"} elevation={0}>
                                    <div className={"Characters"}>
                                        <img
                                            src={"https://static.webtoon.today/ddah/yeongho.png"}
                                            alt={"서영호"}
                                            className={"CardIcon"}
                                        />
                                    </div>
                                    <div className={"Introduction"}>
                                        <div className={"JobPositions"}>
                                            <div className={"Position"}>
                                                {"디렉터 "}
                                            </div>
                                            <div className={"Name"}>
                                                {"서영호 "}
                                            </div>
                                            <div className={"Position Hidden"}>
                                                {"디렉터 "}
                                            </div>
                                        </div>
                                        
                                        <div>
                                            <ul className={"UlStyles"}>
                                                <li>
                                                    <span className={"SubText"}>
                                                        {"'14.~`20. "}
                                                    </span>
                                                    <br />
                                                    <div className={"Context"}>
                                                        {"카툰팝 만화학원 강의"}
                                                    </div>
                                                </li>
                                                <li>
                                                    <span className={"SubText"}>
                                                        {"'21. "}
                                                    </span>
                                                    <br />
                                                    <div className={"Context"}>
                                                        {"제주 웹툰 캠퍼스 출강"}
                                                    </div>
                                                </li>
                                                <li>
                                                    <span className={"SubText"}>
                                                        {"'13.~'20. "}
                                                    </span>
                                                    <br />
                                                    <div className={"Context"}>
                                                        {"웹툰 연재"}<br />
                                                        <div className={"SubContext"}>
                                                            {"- 카카오페이지 '귀신잡는 말뚝이'"}<br />
                                                            {"- 코미코 '이매용주전'"}<br />
                                                            {"- 만화경 '교육생','페어플레이'"}
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <span className={"SubText"}>
                                                        {"'05.~'12. "}
                                                    </span>
                                                    <br />
                                                    <div className={"Context"}>
                                                        {"공주대 만화 애니메이션 전공"}
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </Card>
                            </AnimateDiv>
                        </div>
                    </div>
                </div>

            </div>

        </div>

    );

}

export default Member;