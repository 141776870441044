
import ss from './Session';
import axios from 'axios';

export const readSubscribedAuthors = async () => {

    try{
        
        let res = await axios.get('https://challenge-api.webtoon.today/interaction/author-subscription', {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });
        
        if (res.data.code === 200 && res.data.data) {
            return res.data.data;
        } else {
            return null;
        };

    }catch(e){
        if (e.response && e.response.status === 404) {
            console.log(e);
            return [];
        };
        console.error(e)
        return null;
    };
};

export const subscribeAuthors = async (authorId) => {

    try{

        let res = await axios.put('https://challenge-api.webtoon.today/interaction/author-subscription', {authorId}, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        })

        
        if (res.data.code === 200 && res.data.key) {
            
            return res.data.key;
        } else {
            return null;
        };

    } catch (e) {
        if (e.response && e.response.status === 404) {
            console.log(e);
            return null;
        };
        console.error(e)
        return null
    }
}

export const unsubscribeAuthors = async (authorId) => {
    
    try{

        let res = await axios.delete(`https://challenge-api.webtoon.today/interaction/author-subscription?authorId=${authorId}`, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        })
        
        if (res.data.code === 200 && res.data.key) {
            
            return res.data.key;
        } else {
            return null;
        };

    } catch (e) {
        if (e.response && e.response.status === 404) {
            console.log(e);
            return null;
        };
        console.error(e)
        return null;
    }
}