import { Button, TextField } from "@material-ui/core";
import { useEffect, useState } from "react";
import LoadingCircle from "../../../Component/LoadingCircle";
import { proxy } from "../../../Page/MarketAnalysis/MarketAnalysisFunctions";

import './Styles/TrendLanding.scss';
import { fn } from "../../../Functions";
import { useSession } from "../../../Recoil/Session/Session";

const TrendLanding = () => {
    const { session } = useSession();

    const [ code, setCode ] = useState<string>('');
    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    
    const { memberState } = session;

    useEffect(() => {
        if (memberState === 'regular'){
            // 최초 진입시 isLoading = false 로 밝은 화면
            // regular 멤버는 화면이 어둡게 변하도록 함
            // (로딩되는 것처럼 보이기 위한 일종의 애니메이션)
            setIsLoading(true);
        }

    },[memberState])

    return(
        <div className={'TrendLandingArea'} >
            <div className={'IntroductionContainer'} >
                <div className={'TrendAnalyticsInner'} >
                    <div className={'WebtoonTodayLogoBox'} >
                        <img src={proxy('https://static.webtoon.today/ddah/logo-01.png')} alt={'logo-01'} style={{width: 140, margin: '0 auto'}} />
                    </div>
                    <div className={'Title'} >
                        {'웹툰 트렌드 분석'}
                    </div>
                    <div className={'AlignBox'}>
                        <span className={'SpeechBubble'}>
                            {'한창 유행하던 회귀물은 요즘도 통할까?'}
                            <div className={'Tail'} />
                            <div className={'TailInner'} />
                        </span>
                    </div>
                    <div className={'AlignBox'}>
                        <span className={'SpeechBubble'}>
                            {'내가 떠올린 이 소재, 독자들이 좋아할까?'}
                            <div className={'Tail'} />
                            <div className={'TailInner'} />
                        </span>
                    </div>
                    <div className={'AlignBox'}>
                        <span className={'SpeechBubble'}>
                            {'지난주 플랫폼에 올라온 신작은 어떤 소재를 썼을까?'}
                            <div className={'Tail'} />
                            <div className={'TailInner'} />
                        </span>
                    </div>
                    <div className={'ServiceIntroductionBox'}>
                        {`감에만 의존하지 않는
                        똑똑한 당신에게 필요한 서비스`}
                    </div>
                </div>
            </div>
            <div className={'BodyContainer'} >
                {memberState === "regular"
                    ?<div className={'InvitingCodeBox'}>
                        <label className={'InvitingLabel'} >
                            {'초대 코드'}
                        </label>
                        <TextField
                            fullWidth value={code} variant={'outlined'}
                            style={{borderRadius: '5px', marginBottom: 24}}
                            inputProps={{
                                style: {
                                    padding: '12px 8px'
                                }
                            }}
                            onChange={(e) => {
                                setCode(e.target.value);
                            }}
                        />
                        <Button
                            variant={'contained'} color={'primary'} fullWidth disableElevation
                            style={{
                                fontFamily: 'inherit',
                                padding: '12px 8px',
                                marginBottom: 60,
                            }}
                        >
                            {'웹툰 트렌드 분석 입장하기'}
                        </Button>
                        <div className={'LoginGuidelines'} >
                            {`이미 트렌드 분석에 초대받아 사용 중이시라면?
                            해당 계정으로 로그인해 주세요!`}
                        </div>
                    </div>
                    :<div className={'LoginCheckBox'} >
                        <Button
                            variant={'contained'} color={'primary'} fullWidth disableElevation
                            style={{
                                fontFamily: 'inherit',
                                padding: '12px 8px',
                                marginBottom: 60,
                                maxWidth: 400,
                                margin: '0 auto',
                            }} 
                            href={`/signin?redirectTo=/market/trendsearch`}
                            onClick={fn.gotoByAnchor}
                        >
                            {'로그인하기'}
                        </Button>
                    </div>
                }
            </div>
            <LoadingCircle show={isLoading} />
        </div>
    )
}

export default TrendLanding;