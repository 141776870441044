
import { Button } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import React from 'react';
import ChannelService from '../../Component/ChannelService';
import { fn } from '../../Functions';
import './QnA.scss';
import { useSetRecoilState } from 'recoil';
import { pageHeaderOptions } from '../../Recoil/PageHeader/PageHeader';

const QnA = () => {
    const setPageHeader = useSetRecoilState(pageHeaderOptions);
    React.useEffect(()=>{
        setPageHeader({titleText: '문의'})
    },[setPageHeader])

    const content = [
        {
            title: `연재는 어떻게 할 수 있나요?`,
            content: 
            <div>
                {"저희 플랫폼은 '도전만화' 와 같이 원고를 자유롭게 올릴 수 있는 사이트입니다."}<br/><br/>
                {"[포트폴리오] - [새 작품 등록하기]를 통해 누구나 조건없이 작품을 올릴 수 있어요."}
            </div>
        },
        {
            title: "데이터 분석 서비스가 무엇인가요?",
            content: 
            <div>
                {"데이터 분석 서비스는 작가님의 작품을 실제로 어떤 독자들이, "}<br/><br/>
                {"어떻게 읽었는지 세세한 지표로 알려드리는 서비스예요."}<br/><br/>
                {"오늘의웹툰 자유연재플랫폼에 등록한 작품에만 무료로 제공하고 있습니다."}<br/><br/>
                {"자세한 설명은 아래에서 확인하실 수 있습니다."}<br/><br/>
                <a href={'https://webtoon.today'} onClick={fn.gotoByAnchor}>{'https://webtoon.today'}</a>
            </div>
        },
        {
            title: "이곳에 올린 작품을 다른 곳에서 연재해도 상관없나요?",
            content: 
            <div>
                {"네, 여러 사이트에 올리실 수 있습니다."}<br/><br/>
                {"작품의 저작권은 온전히 작가님께 있기 때문이에요!"}
            </div>
        },
        {
            title: "연독률, 완독률이 뭔가요?",
            content:
            <div>
                {"연독률은 작품을 읽은 사람 중 다음 화를 누른 사람의 비율로,"}<br /><br/>
                {"다음 화에 대한 기대치를 알 수 있어요."}<br /><br/>
                {"완독률은 작품을 읽은 사람 중 처음부터 끝까지 다 읽은 사람의 비율이에요."}<br /><br/>
                {"전반적으로 작품에 대한 독자의 반응을 확인할 수 있는 지표예요."}
            </div>
        },
        {
            title: "포트폴리오는 어떻게 활용하는 건가요?",
            content: 
            <div>
                {"포트폴리오에서는 [프로필] 과 [내 작품] 을 관리해요."}<br /><br/>
                {"[프로필]에서는 다양한 커스터마이징 기능을 통해 자신을 드러낼 수 있고,"}<br/><br/>
                {"[내 작품] 에서는 작품 등록, 관리, 삭제를 할 수 있습니다."}
            </div>
        },
    ]

    const [page, setPage] = React.useState("");

    const handle = (index) => {
        
        if (index === Number(page)){
            setPage("")
        } else {
            setPage(`${index}`)
        }
    }

    return (
        <div className={'QnAMainArea'}>
            <div className={'QnABody'}>
                <div className={'BodyTitle'} >{'자주묻는 질문'}</div>
                <div className={'QuestionBoard'}>
                    {content.map((line, index) => 
                    <div key={line}>
                        <div
                            onClick={() => {handle(index + 1)}} 
                            className={"QuestionLine"}
                        >
                            <div className={"QuestionText"}>{line.title}</div>
                            <div><ArrowDropDown className={`ArrowDownIcon ${page === `${index + 1}`?"selected":""}`} /></div>
                        </div>
                        
                        <div 
                            className={"ContentText"}
                            hidden={!(page === `${index + 1}`)}
                        >
                            {line.content}
                        </div>
                    </div>)}
                </div>
            </div>
            <div className={'QnAFooter'} >
                <Button fullWidth style={{border: '1px solid rgb(0,176,240)', color: 'rgb(0,176,240)', width: 'calc(100% - 64px)'}} onClick={()=>{
                    ChannelService.ChannelIO()('openChat');
                }} >
                    {'모든 글을 읽었지만 1:1 문의가 필요해요'}
                </Button>
            </div>
        </div>
    )
}

export default QnA;