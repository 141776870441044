import React from 'react';
import {Settings as SettingIcon} from '@material-ui/icons';

import './ArtCard.scss';
import { fn } from '../../Functions';

const ArtCard = ({title}) => {
    return (<div className={"ArtCard"}>
        <div className={"Thumbnail"}>
            <img style={{width: '100%', height: '100%', objectFit: 'cover'}} src={title.thumbnail || "https://static.webtoon.today/noTitle.jpg"} alt={title.title}/>
        </div>
        <div className={"Detail"}>
            <div className={"Title"}>
                {title.title}
            </div>
            <div className={"Description"}>

            </div>
            <div className={"Manage Button"} onClick={()=>{
                fn.goto(`/contents/${title.serviceId}/${title.titleId}`)
            }}>
                <SettingIcon fontSize={'small'} style={{marginRight: 10}}/>
                {"관리하기"}
            </div>
        </div>
    </div>)
}

export default ArtCard;