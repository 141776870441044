import React, { DragEventHandler, ReactNode, useEffect, useState } from "react";
import { checkAndRenameFiles } from "../Util/Functions";
import './Styles/MultiUploadFilenameDisplayed.scss';
import { Clear } from "@material-ui/icons";
import { Dialog } from "@material-ui/core";
import UploadScreen from "./UploadScreen";
import { uploadImages, uploadZipFiles } from "../Data/UploadFiles";
import { useToastAlert } from '@webtoontoday/toast';
import LoadingCircle from "../../../Component/LoadingCircleOnSmallArea";


const MultiUploadFilenameDisplayed = ({
    padding = 8, gap = 8, screenTitle = "" , screenSubtitle = [""], children = <></>,
    files, setFiles, isItemDraggable = true,
}:{ 
    padding?: number | undefined,
    gap?: number | undefined,
    screenTitle?: string, screenSubtitle?: string[],
    children?: ReactNode,
    files: imageType[],
    setFiles: (files: imageType[]) => void,
    isItemDraggable?: boolean,
}) => {
    const { toastAlert } = useToastAlert();

    const [ uploadingFiles, setUploadingFiles ] = useState<imageType[]>([]);
    const [ uploadedFiles, setUploadedFiles ] = useState<imageType[]>(files);
    const [ onUploadScreen, setOnUploadScreen ] = useState<boolean>(false);

    const [ draggedFilename, setDraggedFilename ] = useState<string>('');
    const [ uploadProgress, setUploadProgress ] = useState<[number, number]>([0, 0]);

    const [ isFileRecognizing, setIsFileRecognizing ] = useState<boolean>(false);

    useEffect( () => {
        if (JSON.stringify(files) !== JSON.stringify(uploadedFiles) ) {
            setUploadedFiles(files);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[files]);

    useEffect( () => {
        if (JSON.stringify(files) !== JSON.stringify(uploadedFiles) ) {
            setFiles(uploadedFiles);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[uploadedFiles]);

    const isProgressOn = !(uploadProgress[0] === 0 && uploadProgress[1] === 0);
    const isUploading = uploadingFiles.length > 0;
    const isFileUploaded = uploadedFiles.length > 0;

    const fileUploadFunction = async (FileList: FileList) => {
        setIsFileRecognizing(true);
        if ( isUploading ) {
            toastAlert('진행중인 업로드가 완료된 후 업로드해 주세요.', 3000);
            setIsFileRecognizing(false);
            return;
        }
        if ( !FileList ) {
            setIsFileRecognizing(false);
            return
        };

        let files = FileList;
        const fileTypes = Object.values(FileList).map( ({type}) => type);

        if ( fileTypes.filter( type => !( type.includes('image') || type.includes('zip') )).length > 0 ) {
            toastAlert('jpg, png, zip 파일만 업로드 할 수 있습니다.', 3000);
            return;
        };

        const fileArray = checkAndRenameFiles(Object.values(files), uploadedFiles.map( ({name}) => name ))

        const zipFiles = Object.values(fileArray).filter( ({type}) => type.includes('zip') )
        const imageFiles = Object.values(fileArray).filter( ({type}) => type.includes('image') )

        setUploadProgress([ files.length, uploadedFiles.length ]);
        if ( (zipFiles || []).length > 0 ) {
            await uploadZipFiles({ files: zipFiles, setFiles: setUploadedFiles, toastAlert });
            setUploadProgress( prevState => [prevState[0], prevState[1] + 1]);
        }
        if ( (imageFiles || []).length > 0 ){
            await uploadImages({ files: imageFiles, setLoadingFiles: setUploadingFiles, setFiles: setUploadedFiles } );
        }
        setIsFileRecognizing(false);

        const timer = setTimeout( () => setUploadProgress([0,0]), 1500);

        return () => clearTimeout(timer);
    }
    
    // 업로드 핸들러
    const uploadHandler = async () => {
        const input = document.createElement('input');
        input.setAttribute('type','file');
        input.setAttribute('accept','image/*, .zip');
        input.setAttribute('multiple', 'true');
        input.click();

        input.addEventListener('change', async (e) => {
            if ( !input.files ) return;
            await fileUploadFunction(input.files);
        })
    }

    // 콘테이너 핸들러
    const dragOverHandler: DragEventHandler<HTMLDivElement> = (e) => {
        e.preventDefault();
        const types = e.dataTransfer.types;
        if ( types.length > 0 && types.filter( type => type !== 'Files').length < 1 ) {
            setOnUploadScreen(true);
        }
    }

    // 스크린 핸들러
    const onDropHandler: DragEventHandler<HTMLDivElement> = (e) => {
        e.preventDefault();
        e.stopPropagation();
        
        (async () => {
            setOnUploadScreen(false);
            await fileUploadFunction(e.dataTransfer?.files);
        })()
    }
    const mouseLeaveScreenHandler: DragEventHandler<HTMLDivElement> = (e) => {
        setOnUploadScreen(false);
    }

    // img태그 핸들러
    const imgDragOverHandler: DragEventHandler<HTMLDivElement> = (e) => {
        if ( !isItemDraggable ) return;
        e.stopPropagation();

        const draggedIndex = uploadedFiles.findIndex( ({name}) => name === draggedFilename );
        
        const { clientX: dropPositionX } = e;

        const targetRect = (e.target as HTMLElement).getBoundingClientRect();
        const currentIndex = uploadedFiles.findIndex( ({name}) => name === (e.target as HTMLElement).id);
        const imagesCopy = [...uploadedFiles];
        
        // 같은 이미지라면 이동하지 않고, 가로로 해당 이미지를 벗어나도 이동하지 않음, index가 없어도 이동하지 않음
        if (  draggedIndex === currentIndex 
          || (dropPositionX < targetRect.x || dropPositionX > (targetRect.x + targetRect.width) )
          || !(draggedIndex >= 0 && currentIndex >= 0)
        ) return;

        [ imagesCopy[draggedIndex] , imagesCopy[currentIndex] ] = [ imagesCopy[currentIndex], imagesCopy[draggedIndex] ]
        
        setUploadedFiles(imagesCopy.filter(object => object));
    }
    const imgDragStartHandler: DragEventHandler<HTMLDivElement> = (e) => {
        if ( !isItemDraggable ) return;
        setDraggedFilename(e.currentTarget.id);
        const dragImage = e.currentTarget.cloneNode(true) as HTMLElement;
        dragImage.setAttribute('style', 'border: 1px solid rgb(0,176,240)');
        dragImage.setAttribute('style', 'backgroundColor: rgba(0,176,240,0.1)')
        e.dataTransfer.setDragImage(e.currentTarget, e.currentTarget.offsetWidth/2, e.currentTarget.offsetHeight/2);
    }
    const imgDragEndHandler: DragEventHandler<HTMLDivElement> = (e) => {
        if ( !isItemDraggable ) return;
        setDraggedFilename('');
    }

    // 기타 핸들러
    const deleteFileHandler = (factor: {name: string, image: string}) => {
        setUploadedFiles(prevState => {
            const filesAfterDelete = prevState.filter( ({name, image}) => name !== factor.name || image !== factor.image );
            return filesAfterDelete; 
        })
    }

    const isLoadingFile = (loadingFiles: {name: string, image: string}[], targetFile: {name: string, image: string}) => {
        const fileInLoading = loadingFiles.filter( file => file.name === targetFile.name) || [];
        if (fileInLoading.length > 0) {
            return true;
        } else {
            return false;
        }
    }

    const getProgressPercentage = () => {
        if (uploadedFiles.length === 0){
            return 0;
        }

        if (uploadProgress[0] === 0 || uploadingFiles.length === 0) {
            if ( uploadProgress[0] === 0 && uploadProgress[1] === 0) {
                return 0
            } else if ( uploadProgress[0] + uploadProgress[1] === uploadedFiles.length ) {
                return 100;
            }
            return 0;
        } else {
            return (( uploadProgress[0] - uploadingFiles.length ) / uploadProgress[0]) * 100;
        } 
    }
    
    return(
        <div className={'MultiUploadFilenameDisplayedArea'} >
            <MultiUploadControlBox 
                uploadHandler = {uploadHandler}
                uploadedFiles={uploadedFiles}
                isUploading={isUploading}
            />
            <div className={'MultiUploadFilenameBody'} >
                <div className={`MultiUploadFilenameContainer ${uploadedFiles.length>0?"Solid":""}`}
                    onClick={uploadedFiles.length>0?()=>{}:uploadHandler}
                    onDragOver={dragOverHandler}
                    style={{
                        width: `calc(100% - ${padding}*2)`,
                        aspectRatio: `5/2`,
                    }}
                >
                    <div className={`UploadFilenameContainer Header ${uploadingFiles.length > 0?'Loading':''}`} >
                        <div className={'FilenameRow'} >
                            <img
                                src={"https://static.webtoon.today/ddah/icon/icon_delete.svg"}
                                alt={'delete_Icon'}
                                className={'DeleteFileIcon'} 
                                onClick={() => {
                                    if ( !onUploadScreen ) {
                                        setUploadedFiles([]);
                                    }
                                }}
                            />
                            <div className={`FilenameText ${!isItemDraggable?'Disabled':''}`} >
                                {"파일명.jpg"}
                            </div>
                        </div>
                        { isProgressOn
                            ?<div className={'ProgressBackgroundBar'}>
                                <div className={'ProgressActiveBar'} style={{ width: `${getProgressPercentage()}%` }} />
                            </div>:<></>}
                    </div>
                    <div className={'UploadFilenameFilesArea'} style={{gap}} >
                    {uploadedFiles.map( ({name, image}) => (
                        <div 
                            className={`UploadFilenameContainer ${isLoadingFile(uploadingFiles, {name, image})?'Loading':''}`}
                            key={`${name}:${image}`} id={name}
                            onDragStart={imgDragStartHandler}
                            onDragOver={imgDragOverHandler}
                            onDragEnd={imgDragEndHandler}
                            draggable={isUploading || !isItemDraggable?false:true}
                        >
                            <div className={'FilenameRow'} >
                                <img
                                    src={"https://static.webtoon.today/ddah/icon/icon_delete.svg"}
                                    alt={'delete_Icon'}
                                    className={'DeleteFileIcon'} 
                                    onClick={() => {
                                        if ( !onUploadScreen ) {
                                            deleteFileHandler({name, image})
                                        }
                                    }}
                                />
                                <div className={`FilenameText ${!isItemDraggable?'Disabled':''}`} >
                                    {name}
                                </div>
                            </div>
                        </div>
                    ))}
                    </div>
                    {onUploadScreen || !isFileUploaded
                        ?<UploadScreen 
                            mouseLeaveHandler={mouseLeaveScreenHandler} 
                            onDropHandler={onDropHandler}
                            subTitles={["이미지 파일(JPG 나 PNG) 혹은 ZIP 파일", "가로 690px 이상"]}
                        />
                        :<></>
                    }
                </div>
            </div>
            <div className={'NumberOfFiles'}>
                {`${uploadedFiles.length}개`}
            </div>
            {children}
            <LoadingCircle show={isFileRecognizing} />
        </div>
    )
}

export default MultiUploadFilenameDisplayed;

const MultiUploadControlBox = ({
    uploadHandler, uploadedFiles, isUploading
}:{
    uploadHandler: () => void,
    uploadedFiles: imageType[],
    isUploading: boolean,
}) => {
    const isFileUploaded = uploadedFiles.length > 0;
    const isAllLoaded = !isUploading && isFileUploaded; 

    const [ open, setOpen ] = useState<boolean>(false)
    const closeHandler = () => {
        setOpen(false);
    }
    return(
        <div className={'MultiUploadControlBox'} >
            <button className={'MultiUploadeButton'} onClick={uploadHandler} disabled={isUploading} >
                {'첨부'}
            </button>
            <button 
                className={`PreviewImages ${!isAllLoaded?'Disabled':''}`} 
                onClick={ () => {
                    if ( isAllLoaded ) {
                        setOpen(true);
                    }
                }} >
                {'원고 미리보기'}
            </button>
            <Dialog open={open} onClose={closeHandler} >
                <div style={{display: 'flex', flexDirection: 'column', maxWidth: 690, position: 'relative'}} >
                    {uploadedFiles.map( ({image}) => (
                        <img src={image} alt={image} key={`preview:${image}`} style={{objectFit: 'cover'}} />
                    ))}
                    <Clear 
                        style={{
                            position: 'absolute', right: 20, top: 20, 
                            backgroundColor: 'black', color: 'white', borderRadius: '50%',
                            cursor: 'pointer'
                        }}
                        width={15} height={15}
                        onClick={closeHandler}
                    />
                </div>
            </Dialog>
        </div>
    )
}
