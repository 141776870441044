import { Button, TextField } from '@material-ui/core';
import React from 'react';
import { logBehavior } from '../../../../Data/Behavior';
import { createCompetitionEmail } from '../../../../Data/Competition';
import { validateEmailForm } from '../../../../Functions';
import './SubmitEmail.scss';
import { useToastAlert } from '@webtoontoday/toast';

const SubmitEmail = ({ email, setEmail, hasFooter = true }) => {

    const [ isSubmitted, setIsSubmitted ] = React.useState(false)

    const { toastAlert } = useToastAlert();

    return(
        <div className={'SubmitEmailArea'}>
            <div className={'SeperateLine'} style={{marginTop: 80}} >
                <div className={'BackgroundOfTitle'} >
                    {'접수 시작 알림 받기'}
                </div>
            </div>
            <div className={'SubTitle'} >
                {'잊지 않고 접수할 수 있도록 이메일로 알려드릴게요!'}
            </div>
            <div style={{width: '80%', margin: 'auto', marginBottom: 10}}>
                <label className={"Labels"} >{"이메일 주소"}</label>
            </div>
            <div style={{display: 'flex', justifyContent: 'center', marginBottom: 120}}>
                <div className={`EmailComponent`}>
                    <TextField
                        placeholder={'공모전 접수가 시작되는 날 이 주소로 알려드릴게요.'}
                        style={{width: '90%', margin: '5px 0 5px 10px'}}
                        value={email || ''}
                        onChange={ (e) => {
                            setEmail(e.target.value);
                        }} 
                    />
                    <Button disabled={!validateEmailForm(email) || isSubmitted} style={{backgroundColor: `${validateEmailForm(email)?'rgb(51,51,51)':'rgb(200,200,200)'}`, color: 'rgb(255,255,255)', borderRadius: '4px 0 0 4px', padding: '9px 8px', width: '10%'}}
                        onClick={ async () => {
                            setIsSubmitted(true);

                            const response = await createCompetitionEmail({email, type: '2022university'})
                            await logBehavior('submit-competitionEmail(ready)', response);

                            if (response && response.type && response.email) {
                                await logBehavior('submit-competitionEmail(success)', response);

                                toastAlert('이메일 신청이 완료되었습니다.', 3000)
                            } else {
                                setIsSubmitted(false);
                                toastAlert('이메일 신청이 실패했습니다.', 3000)
                            }
                        }}
                    >
                        {'등록'}
                    </Button>
                </div>
            </div>
            {hasFooter
                ?<><div className={'SeperateLine'} style={{marginTop: 50}} >
                    <div className={'BackgroundOfTitle'}>
                        {'데이터 분석 소개'}
                    </div>
                </div>
                <div className={'SubTitle'} >
                    {'PD의 주관이 들어가지 않는 공모전 평가 방법'}
                </div></>:<></>}
        </div>
    )
}

export default SubmitEmail;