import Dissolve from "./Dissolve";
import './Styles/HomeMobile.scss';
import { lastSeenTitleKey, useFeedTitleList, useSubscribedTitleList } from "../../../Recoil/Title/Titles";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSubscribedAuthorList } from "../../../Recoil/User/Users";
import { useRecoilState } from "recoil";
import { logBehavior } from "../../../Data/Behavior";

const HomeMobile = () => {
    const [ lastSeenTitle, setLastSeenTitle ] = useRecoilState<string>(lastSeenTitleKey);
    const { feedData: titleList, secureFetch } = useFeedTitleList('global');
    const { subscribe: subscribeTitle, unsubscribe: unsubscribeTitle } = useSubscribedTitleList();
    const { subscribe: subscribeAuthor, unsubscribe: unsubscribeAuthor } = useSubscribedAuthorList();

    const [ focusedTitleKey, setFocusedTitleKey ] = useState<string>("");
    
    const renderableTitlelist = useMemo(()=>titleList?.filter(data => data !== null) as FeedTitleInfoType[],[titleList]);
    const focusedTitleThumbnail = renderableTitlelist && (renderableTitlelist.find(title => `${title?.serviceId}:${title?.titleId}` === focusedTitleKey)?.thumbnail || renderableTitlelist[0]?.thumbnail);
    const focusedTitleMusicAddress = renderableTitlelist && (renderableTitlelist.find(title => `${title?.serviceId}:${title?.titleId}` === focusedTitleKey)?.musicAddress || renderableTitlelist[0]?.musicAddress);
    
    const sliderContainerRef = useRef<HTMLDivElement>(null);
    const sliderTrackRef = useRef<HTMLDivElement>(null);

    let isWheelEnabled = useRef(true);
    let isDragging = useRef(false);
    let startPosition = useRef(0);
    let currentTranslate = useRef(0);
    let prevTranslate = useRef(0);
    
    useEffect(()=>{
        (document.getElementsByTagName('body') || [null])[0]?.setAttribute('data-pagename', 'home-mobile');

        return () => {
            (document.getElementsByTagName('body') || [null])[0]?.removeAttribute('data-pagename');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(() => {
        const handleMore = () => {
            secureFetch((titleList?.length || 0) + 12);
        }

        const dragStart = (event: Event) => {
            if (event.type === 'touchstart') {
                startPosition.current = (event as TouchEvent).touches[0].clientY;
            } else {
                startPosition.current = (event as MouseEvent).clientY;
            }
            isDragging.current = true;
        };
    
        const drag = (event: Event) => {
            if (isDragging.current) {
                let currentPosition = 0;
                if (event.type === 'touchmove') {
                    currentPosition = (event as TouchEvent).touches[0].clientY;
                } else {
                    currentPosition = (event as MouseEvent).clientY;
                }
    
                const diffPosition = currentPosition - startPosition.current
                
                currentTranslate.current = prevTranslate.current + diffPosition;
                if (currentTranslate.current > 0) {
                    currentTranslate.current = 0;
                };
    
                const sliderContainerHeight = sliderContainerRef.current?.offsetHeight || 0;
                const sliderTrackHeight = sliderTrackRef.current?.offsetHeight || 0;
                const maxTranslate = sliderContainerHeight - sliderTrackHeight;
                
                if (currentTranslate.current < maxTranslate ) {
                    currentTranslate.current = maxTranslate ;
                };
                if (sliderTrackRef.current) {
                    sliderTrackRef.current.style.transform = `translateY(${currentTranslate.current}px)`;
                };
            };
        };

        const dragEnd = () => {
            isDragging.current = false;
            let page = getCurrentPage();
            // moveY = 움직일 Y 좌표,  
            let moveY = getDestinationY(page);
    
            if(renderableTitlelist){
                const focusedKey = `${renderableTitlelist[page]?.serviceId}:${renderableTitlelist[page]?.titleId}`
                setFocusedTitleKey(focusedKey)
                setLastSeenTitle(focusedKey);
                logBehavior('mobile-impression', {focusedKey});
            }
            
            // 애니메이션 적용
            if (sliderTrackRef.current) {
                sliderTrackRef.current.style.transition = 'transform 0.5s ease-out';
                sliderTrackRef.current.style.transform = `translateY(${moveY}px)`;
                prevTranslate.current = moveY || 0;
                currentTranslate.current = moveY || 0;
                
                // 애니메이션 종료 후 transition 속성 제거
                const handleTransitionEnd = () => {
                    if (sliderTrackRef.current) {
                        sliderTrackRef.current.style.transition = '';
                        sliderTrackRef.current.removeEventListener('transitionend', handleTransitionEnd);
                    }

                    // 마지막의 두번째 페이지에 도착하면 데이터 새로 호출
                    if(titleList && page >= titleList.filter(title => title !== null).length - 3){
                        handleMore();
                    }
                };
                
                sliderTrackRef.current.addEventListener('transitionend', handleTransitionEnd);
            }
        };

        const wheel = (event: WheelEvent) => {
            if (!isWheelEnabled.current) return;
            isWheelEnabled.current = false;

            setTimeout(()=>{
                isWheelEnabled.current = true;
            },100)
            let delta = -(sliderContainerRef.current?.offsetHeight || 0) / 2 * (event.deltaY / Math.abs(event.deltaY));

            
            if (currentTranslate.current + delta > 0){
                return;
            }

            prevTranslate.current = currentTranslate.current;
            currentTranslate.current += delta;

            dragEnd();
        }

        const sliderContainer = sliderContainerRef.current;

        if (sliderContainer) {
            sliderContainer.addEventListener('mousedown', dragStart);
            sliderContainer.addEventListener('touchstart', dragStart);
            sliderContainer.addEventListener('mouseup', dragEnd);
            sliderContainer.addEventListener('touchend', dragEnd);
            sliderContainer.addEventListener('mouseleave', dragEnd);
            sliderContainer.addEventListener('mousemove', drag);
            sliderContainer.addEventListener('touchmove', drag);
            sliderContainer.addEventListener('wheel', wheel);
        }

        return () => {
        if (sliderContainer) {
                sliderContainer.removeEventListener('mousedown', dragStart);
                sliderContainer.removeEventListener('touchstart', dragStart);
                sliderContainer.removeEventListener('mouseup', dragEnd);
                sliderContainer.removeEventListener('touchend', dragEnd);
                sliderContainer.removeEventListener('mouseleave', dragEnd);
                sliderContainer.removeEventListener('mousemove', drag);
                sliderContainer.removeEventListener('touchmove', drag);
                sliderContainer.removeEventListener('wheel', wheel);
        }
        };
    }, [renderableTitlelist, secureFetch, setLastSeenTitle, titleList]);

    const getCurrentPage = () => {
        const prevPoint = prevTranslate.current;
        const currentPoint = currentTranslate.current;

        let CardHeightWithoutMargins = 0;
        if ( sliderTrackRef.current ) {
            CardHeightWithoutMargins = Number((((sliderTrackRef?.current).children[0]).getClientRects()[0]).height);
        };
            
        // 첫번 째 카드에서 모든 카드에 공통적인 한쪽 마진과 첫번 째 카드에만 있는 위쪽의 추가 마진 구하기
        const CardHalfMargin = sliderTrackRef?.current?Number(window.getComputedStyle(sliderTrackRef?.current.children[0]).marginTop.replace('px','')) / 2:0;
        const TopExtraMargin = Number(sliderContainerRef?.current?.style.paddingTop.replace('px',''));

        const cardComponentHeight = CardHeightWithoutMargins + CardHalfMargin * 2;

        // 움직임 후의 페이지 구하기
        const threshold = 100; // 페이지 넘기는 기준값
        const isDirectionUp = Math.abs(prevPoint) > Math.abs(currentPoint);
        let page = Math.trunc( (Math.abs(currentPoint) - ( TopExtraMargin - (isDirectionUp ? threshold : cardComponentHeight - threshold) ) ) / cardComponentHeight );

        return page;
    }

    const getDestinationY = ( index: number ): number => {
        if(!sliderTrackRef.current) return 1;

        const CardHalfMargin = sliderTrackRef?.current?Number(window.getComputedStyle(sliderTrackRef?.current.children[0]).marginTop.replace('px','')) / 2:0;
        const TopExtraMargin = Number(sliderContainerRef?.current?.style.paddingTop.replace('px',''));

        let CardHeightWithoutMargins = 0;
        if ( sliderTrackRef.current ) {
            CardHeightWithoutMargins = Number((((sliderTrackRef?.current).children[0]).getClientRects()[0]).height);
        };

        const cardComponentHeight = CardHeightWithoutMargins + CardHalfMargin * 2;

        let moveY;
        if( index === 0 ){
            moveY = 0;
        } else{
            moveY = -(TopExtraMargin + cardComponentHeight * index);
        }
        
        return moveY;
    } 

    const handleFinishedDissolve = useCallback((currentTitleKey: string) => {
        if(!renderableTitlelist){
            return;
        }
        const nextIndex = renderableTitlelist.findIndex(title => `${title?.serviceId}:${title?.titleId}` === currentTitleKey) + 1;
        if((nextIndex < renderableTitlelist.length)){
            const focusedKey = `${renderableTitlelist[nextIndex].serviceId}:${renderableTitlelist[nextIndex].titleId}`
            setFocusedTitleKey(focusedKey);
            setLastSeenTitle(focusedKey);
            logBehavior('mobile-impression', {focusedKey})
        }

        const moveY = getDestinationY(nextIndex);
        
        if (sliderTrackRef.current && moveY <= 0) {
            sliderTrackRef.current.style.transition = 'transform 0.5s ease-out';
            sliderTrackRef.current.style.transform = `translateY(${moveY}px)`;
            prevTranslate.current = moveY || 0;
            currentTranslate.current = moveY || 0;
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if( !renderableTitlelist || !lastSeenTitle ){
            return;
        }
        const lastSeenIndex = renderableTitlelist.findIndex(title => `${title?.serviceId}:${title?.titleId}` === lastSeenTitle);
        if((lastSeenIndex < renderableTitlelist.length)){
            setFocusedTitleKey(`${renderableTitlelist[lastSeenIndex].serviceId}:${renderableTitlelist[lastSeenIndex].titleId}`);
            logBehavior('mobile-impression', {focusedKey: `${renderableTitlelist[lastSeenIndex].serviceId}:${renderableTitlelist[lastSeenIndex].titleId}`})
        };

        const moveY = getDestinationY(lastSeenIndex);
        
        if (sliderTrackRef.current && moveY <= 0) {
            sliderTrackRef.current.style.transform = `translateY(${moveY}px)`;
            prevTranslate.current = moveY || 0;
            currentTranslate.current = moveY || 0;
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[renderableTitlelist])

    return(
        <div className={'HomeMobileArea'} ref={sliderContainerRef} >
            <div className={'CarouselContainer'} ref={sliderTrackRef} >
                {renderableTitlelist
                    ?.filter( title => title !== null )
                    .map( title => (
                        <Dissolve
                            key={`${title?.serviceId}:${title?.titleId}`}
                            titleInfo={title as FeedTitleInfoType}
                            isFocused={`${title?.serviceId}:${title?.titleId}` === (focusedTitleKey || `${renderableTitlelist[0]?.serviceId}:${renderableTitlelist[0]?.titleId}`)}
                            subscribeAuthor={subscribeAuthor}
                            unsubscribeAuthor={unsubscribeAuthor}
                            subscribeTitle={subscribeTitle}
                            unsubscribeTitle={unsubscribeTitle}
                            handleFinishedDissolve={handleFinishedDissolve}
                        />
                    ))} 
            </div>
            {renderableTitlelist && <RelatedMusic thumbnail={focusedTitleThumbnail} musicAddress={focusedTitleMusicAddress} />}
            <div className={'TopScreen'} />
            <div className={'BottomScreen'} />
        </div>
    )
}

const RelatedMusic = ({
    thumbnail,
    musicAddress,
}:{
    thumbnail?: string,
    musicAddress?: string,
}) => {
    const [ isMusicPlaying, setIsMusicPlaying ] = useState<boolean>(false);
    const [ isNotificationOn, setIsNotificationOn ] = useState<boolean>(false);

    useEffect(() => {
        const setOnTimer = setTimeout(() => {
            setIsNotificationOn(true);
        }, 1000);
        const setOffTimer = setTimeout(() => {
            setIsNotificationOn(false);
        }, 3500);
        return ()=> {
            clearTimeout(setOnTimer);
            clearTimeout(setOffTimer);
        }
    }, [])

    const additionalThumbnailStyle = isNotificationOn ? {border: '2px solid rgba(61, 106, 255, 1)', boxShadow: '0px 0px 34px 1px rgba(61, 106, 255, 0.30)'} : {}
    return(
        <div className={'RelatedMusic'}>
            <Notification message={"음악과 함께 감상해보세요."} isOn={isNotificationOn}/>
            <div 
                className={'RelativeParent'}
                onClick={() => {
                    const audioElement = document.getElementById("titleAudio") as HTMLAudioElement;

                    if(isMusicPlaying){
                        audioElement.pause();
                        logBehavior('music-stop');
                    } else{
                        audioElement.play();
                        logBehavior('music-play');
                    }
                    setIsMusicPlaying(isMusicPlaying => !isMusicPlaying);
                }}
            >
                <img
                    src={thumbnail}
                    alt={'썸네일'} 
                    style={{width: '28px', height: '28px', border: '2px solid rgba(67, 63, 70, 1)', borderRadius: '0.25rem', ...additionalThumbnailStyle}}
                />
                <img
                    src={isMusicPlaying ? 'https://static.webtoon.today/ddah/gif/gif_musicBarsCircle.gif' : 'https://static.webtoon.today/ddah/icon/icon_musicPause.svg'}
                    alt={isMusicPlaying ? '음악 재생' : '음악 정지'}
                    style={{position: 'absolute', top: '-8px', left: '-8px', width: '16px', height: '16px'}}
                />
                <audio autoPlay={isMusicPlaying} id={"titleAudio"} src={musicAddress}/>
            </div>
        </div>
    )
}

const Notification = ({message, isOn} : {message: string, isOn: boolean}) => {
    return (
        <div className={`Notification ${isOn ? 'On' : ''}`}>
            <img src={"https://static.webtoon.today/ddah/background/background_speechBubble.png"} alt="NotifyMusic" width={177} height={40} />
            <span className={'Message'}>
                {message}
            </span>
        </div>
    )
}

export default HomeMobile;