
import { ParentSize } from '@visx/responsive';
import LineChart from './LineChart';
import './Styles/BasicTrendChart.scss';

const SearchTrend = ({ 
    topic, data
}:{
    topic: string,
    data: [{
        topic: string,
        ntrend: {
            startDate: string,
            endDate: string,
            timeUnit: string,
            results: [{
                title: string,
                keywords: string[],
                data: {period: string, ratio: number}[]
            }]
        }
    }]
}) => {
    
    const lineData = data[0].ntrend.results[0].data
    
    return <div className={'BasicTrendChartArea'}>
        <div className={'Title'} >
            <span className={'SearchText'} >{`${topic} 웹툰`}</span>
            {' 검색 트렌드'}
        </div>
        <div className={'ChartSpace'} >
            <ParentSize>
                {({width, height}) => 
                    <LineChart
                        searchType='trend' width={width} height={height * 0.9} data={lineData} 
                    />
                }
            </ParentSize>
        </div>
    </div>
}

export default SearchTrend;