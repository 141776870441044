
import { Group } from '@visx/group';
import { scaleBand, scaleLinear } from '@visx/scale';
import { Bar } from '@visx/shape';
import React from 'react';

import './Styles/BarChart.scss';

const verticalMargin = 100;

const BarChart = ({
    width, height, data, subtitle
} : {
    width: number, height: number, subtitle?: string;
    data: {
        name: string, 
        matchCount: number
    }[]
}) => {
    const chartPaddingInner = 0.2
    
    const [ xMax, setXMax ] = React.useState(0);
    const [ yMax, setYMax ] = React.useState(0);
    
    React.useEffect( () => {
        setXMax(width);
        setYMax(height - verticalMargin);
    },[height, width])

    const xScale = React.useMemo(() => (
        scaleBand<string>({
            range: [0, xMax],
            round: true,
            domain: data.map(({name, matchCount}: {name: string, matchCount: number}) => name),
            paddingInner: chartPaddingInner,
        })
    ), [data, xMax]);

    const biggestCount = Math.max(...data.map(({matchCount}) => matchCount));
    
    const yScale = React.useMemo(() =>(
        scaleLinear<number>({
            range: [yMax, 0],
            round: true,
            domain: [0, Math.ceil(biggestCount * 1.1)],
        })
    ), [biggestCount, yMax]);
    
    return (<div className={'SearchTrendBarChartArea'} >
            {subtitle?<div className={'ChartSubTitle'} >
                {subtitle}
            </div>:<></>}
            <svg width={width} height={height}>
                <rect width={width} height={height} style={{position: 'relative'}} fill="rgb(255,255,255)" rx={14} />
                    <Group top={verticalMargin / 2}>
                        {data.map(({name, matchCount}:{name: string, matchCount: number}, idx) => {
                            const barWidth = xScale.bandwidth();
                            const barHeight = yMax - (yScale(matchCount) ?? 0) >= 0 ? yMax - (yScale(matchCount) ?? 0) : 0;
                            
                            const barX = xScale(name);
                            const barY = yMax - barHeight;
                            
                            return (<g key={name}>
                                <Bar
                                    key={`bar-${name}`} rx={5}
                                    x={barX}
                                    y={barY}
                                    width={barWidth}
                                    height={barHeight + 1}
                                    fill={'rgb(187, 208, 255)'}
                                    style={{
                                        borderRadius: 5,
                                    }}
                                    overlineThickness={3}
                                />
                                <text
                                    x = {barX && barX + barWidth / 2}
                                    y = {yMax + 20}
                                    style={{
                                        fontSize: '0.625rem',
                                    }}
                                    textAnchor = {'middle'}
                                >
                                    {`${data.length - 1 === idx?'~':''}` + name.split('-')[1].toUpperCase()}
                                </text>
                                {data.map(({name})=>name.split('-')[0]).indexOf(name.split('-')[0]) === idx
                                    ?<text
                                        key={name}
                                        x = {barX && barX + barWidth / 2}
                                        y = {yMax + 33}
                                        textAnchor = {'middle'}
                                        style={{
                                            fontSize: '0.625rem',
                                        }}
                                    >
                                        {name.split('-')[0].toUpperCase()}
                                    </text>:<></>}
                            </g>);
                        })}
                    </Group>
            </svg>
        </div>
    )
}

export default BarChart;
