import axios from 'axios';
import ss from './Session';

export const readComment = async ({serviceId, titleId, episodeId, commentId, sortBy}) => {
    try {
        
        let res = await axios.post('https://challenge-api.webtoon.today/ugc/comment', {serviceId, titleId, episodeId, commentId, sortBy}, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        })
        
        if (res.data && res.data.code === 200){
            return res.data.data;
        }else{
            return null;
        }
        
    } catch (e) {
        console.error(e)
        return null;
    }
}

export const createComment = async ({serviceId, titleId, episodeId, content}) => {

    try {
        
        let res = await axios.put(`https://challenge-api.webtoon.today/ugc/comment`, {serviceId, titleId, episodeId, content} ,{
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        })

        if (res.data.code && res.data.key) {
            
            return res.data.key;
        } else {
            return null;
        }
    } catch (e) {
        console.error(e);
        return null;
    }  
}

export const deleteComment = async ({id, serviceId, titleId, episodeId, authorId}) => {
    
    try {
        const res = await axios.delete(`https://challenge-api.webtoon.today/ugc/comment?id=${id}`,{
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });
        
        if (res.data.code === 200 && res.data.key){
            
            return true;

        } else {
            console.error('fail to delete Comment')
            return false;
        };


    } catch (e) {
        console.error(e);
        return false;
    }
}