
import { Button } from '@material-ui/core';
import { BarChart, Visibility } from '@material-ui/icons';
import { useState } from 'react';
import { fn } from '../../../Functions';
import { keyToPlatformIcon, proxy } from '../../../Page/MarketAnalysis/MarketAnalysisFunctions';
import { numericUnitConverter } from '../Util/Functions';
import './Styles/PlatformTopWebtoons.scss';

const PlatformTopWebtoons = ({ 
    topic: searchedTopic, title, subtitle, data, type
}:{
    topic: string, type:string, title: string, subtitle: string,
    data: {
        platform: string,
        thumbnail: string,
        title: string,
        href: string,
        description: string,
        topic: string[],
        rank: string,
        serviceId: string,
        titleId: string,
        viewCount: string,
    }[],
}) => {
    
    const [ page, setPage ] = useState(0);
    
    return <div className={'PlatformTopWebtoonsArea'}>
        <div className={'Title'} >
            {title}
        </div>
        <div className={'SubTitle'} >
            {subtitle}
        </div>
        <div className={'RepresentativeWebtoons'}>
            {(data.length > 3 ? data.slice(0, 3 * page + 3) : data)
                .map(({thumbnail, title, topic: titleTopics, description, platform, href, rank, viewCount}) => 
                    <div className={'WebtoonRow'} key={title + href}>
                        <a className={'VisibleInfomation'} href={href} onClick={fn.gotoByAnchor} >
                            <div className={'Thumbnail'}>
                                <img src={proxy(thumbnail.replace('jpg;','jpg'), 200)}
                                    alt={'Thumbnail'} style={{width: 100, height: 100, objectFit: 'cover', objectPosition: '50% 0%', borderRadius: '4px', border: '1px solid rgb(207, 207, 207)'}}
                                    onError={(event: any)=>{
                                        if (event.target && typeof event.target.getAttribute === 'function'
                                        && event.target.getAttribute('src') === proxy(thumbnail.replace('jpg;','jpg'), 200) ){
                                            event.target.setAttribute('src', thumbnail);
                                        }
                                    }}
                                />
                                <img src={keyToPlatformIcon(platform)} alt={'platformIcon'}
                                    style={{width: '25%', position: 'absolute', left: 0, top: 0, borderRadius: '3px 0 3px 0', border: '1px solid rgb(207,207,207)', borderWidth: '1px 0 0 1px'}} />
                            </div>
                            <div className={'WebtoonInformations'} >
                                <div className={'TitleInfo'} >
                                    {title}
                                    {type && type === 'view'
                                        ?<div className={'ViewOrRankBox'} >
                                            <Visibility style={{marginRight: 4}} />
                                            {numericUnitConverter(viewCount)}
                                        </div>
                                        :<div className={'ViewOrRankBox'} >
                                            <BarChart />
                                            {`${rank}위`}
                                        </div>}
                                </div>
                                <div className={'DescriptionInfo'}>
                                    {description}
                                </div>
                            </div>
                        </a>
                        <div className={'KeywordsInfomation'}>
                            {titleTopics.slice(0,5).map( titleTopic => 
                                <a className={'Keyword'} href={`/market/trendsearch?topic=${titleTopic}`} onClick={fn.gotoByAnchor} key={titleTopic} >
                                    {titleTopic}
                                </a>)}
                        </div>
                    </div>)}
                    {data.length > (3 * page + 3)?
                        <div className={'MoreBlogButtonBox'}>
                            <Button
                                variant={'outlined'} 
                                onClick={() => {
                                    setPage( (cur) => cur + 1 )
                                }} 
                            >
                                {'더 보기'}
                            </Button>
                    </div>:<></>}
        </div>
    </div>
}

export default PlatformTopWebtoons;