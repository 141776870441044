import axios from 'axios';

const nullSession = {userid:null, addressid: null, token:null, email: null, jwt: '', memberState: null, image: null, gender: null, birthday: null, name: null, isManager: null};
const crnt = {
    session: global.localStorage ? JSON.parse(localStorage.getItem('session') || '{}') : nullSession
};

const sessionRefresh = async () => {
    const session = crnt.session;
    
    try{

        /** @type {sessionResponseType} res */
        let res = await axios.post(
            'https://challenge-api.webtoon.today/session', 
            (session.userid && session.token)?{userid: session.userid, token: session.token}:{}
        );
        
        if (res.data.code === 200 && res.data.data && res.data.data.userid){
            if (!res.data.data.image){
                res.data.data.image = "https://static.webtoon.today/noimage.jpg";
            }
            crnt.session = res.data.data;
        } else {
            crnt.session = nullSession;
        }
    }catch(e){
        crnt.session = nullSession;
    } finally{
        return crnt.session;
    }
}       


const EmailLogin = async (email: string, password: string) => {
    if ((window as any).gtag){
        (window as any).gtag('event', 'conversion', {'send_to': 'AW-604780621/UKKOCJ3C1t4BEM3wsKAC'});
    }
    
    if (email) {
        try{
            /** @type {sessionResponseType} res */
            let res = await axios.post(
                'https://challenge-api.webtoon.today/session',
                {email, password}
            );
            
            if (res.data.code === 200 && res.data.data && res.data.data.userid){
                if (!res.data.data.image){
                    res.data.data.image = "https://static.webtoon.today/noimage.jpg";
                }
                crnt.session = res.data.data;
            }else{
                crnt.session = nullSession;
            }
        }catch(e){
            console.error(e);
        } finally{
            return crnt.session;
        }
    }
    return crnt.session;
};

const EmailSignin = async ({email, otp, password, addressid, name, gender, birthday}: {email: string, otp: string, password: string, addressid: string, name: string, gender: string, birthday: string}) => {
    if ((window as any).gtag){
        (window as any).gtag('event', 'conversion', {'send_to': 'AW-604780621/UKKOCJ3C1t4BEM3wsKAC'});
    }
    
    if (email) {
        try{
            /** @type {sessionResponseType} res */
            let res = await axios.put(
                'https://challenge-api.webtoon.today/session',
                {email, otp, password, addressid, name, gender, birthday, image: "https://static.webtoon.today/noimage.jpg"}
            );
            
            if (res.data.code === 200 && res.data.data && res.data.data.userid){
                if (!res.data.data.image){
                    res.data.data.image = "https://static.webtoon.today/noimage.jpg";
                }
                crnt.session = res.data.data;
            }else{
                crnt.session = nullSession;
            }
        }catch(e){
            console.error(e);
        } finally{
            return crnt.session;
        }
    }
    return crnt.session;
};

const EmailTokenSignin = async (email: string, token: string) => {
    if ((window as any).gtag){
        (window as any).gtag('event', 'conversion', {'send_to': 'AW-604780621/UKKOCJ3C1t4BEM3wsKAC'});
    }
    if (email){
        try{
            /** @type {sessionResponseType} res */
            let res = await axios.put(
                'https://challenge-api.webtoon.today/session',
                {email, token}
            );

            if (res.data.code === 200 && res.data.data && res.data.data.userid){
                if (!res.data.data.image){
                    res.data.data.image = "https://static.webtoon.today/noimage.jpg";
                }
                crnt.session = res.data.data;
            }else{
                crnt.session = nullSession;
            }
        }catch(e){
            console.log(e);
        } finally{
            return crnt.session;
        }
    }
    return crnt.session;
}

const EmailSignupWithOtp = async (email:string, otp:string, name: string) => {
    if ('gtag' in window){
        (window as any).gtag('event', 'conversion', {'send_to': 'AW-S8C0P0TZZX/login'});
    }
    if (email){
        try{
            let res = await axios.put(
                'https://challenge-api.webtoon.today/session',
                {email, otp, name}
            );

            if (res.data.code === 200 && res.data.data && res.data.data.userid){
                if (!res.data.data.image){
                    res.data.data.image = "https://static.webtoon.today/noimage.jpg";
                }
                crnt.session = res.data.data;
            }else{
                crnt.session = nullSession;
            }
        }catch(e){
            console.error(e);
        } finally{
            return crnt.session;
        }
    }
    return crnt.session;
}

export const AskResetPassword = async ({email}: {email: string}) => {
    try {
        
        let res = await axios.post('https://challenge-api.webtoon.today/user/password-reset', {email}, {
            headers: {
                Authorization: `Bearer ${crnt.session.jwt}`
            },
            withCredentials: true
        })
        
        if (res.data && res.data.code === 200){
            return res.data.data;
        }else{
            return null;
        }
        
    } catch (e) {
        console.error(e)
        return null;
    }
}

const GoogleSignin = async (response: any, ) => {
    if ((window as any).gtag){
        (window as any).gtag('event', 'conversion', {'send_to': 'AW-604780621/UKKOCJ3C1t4BEM3wsKAC'});
    };

    if (response.code){
        try{
            /** @type {sessionResponseType} res */
            let res = await axios.put(
                'https://challenge-api.webtoon.today/session',
                {gsso: response}
            );
            
            if (res.data.code === 200 && res.data.data && res.data.data.userid){
                if (!res.data.data.image){
                    res.data.data.image = "https://static.webtoon.today/noimage.jpg";
                }
                crnt.session = res.data.data;
            }else{
                crnt.session = nullSession;
            }
        }catch(e){
            console.error(e);
        } finally{
            return crnt.session;
        }
    }
    return crnt.session;
}

const logout = async () => {
    crnt.session = nullSession;
    window.location.reload();
    return crnt.session;
}

/**
 * @param {facebookResponseType} response
 */
 const FacebookSignin = async (response: any, ) => {
    if ((window as any).gtag){
        (window as any).gtag('event', 'conversion', {'send_to': 'AW-604780621/UKKOCJ3C1t4BEM3wsKAC'});
    }
    if (response.accessToken){
        try{
            /** @type {sessionResponseType} res */
            let res = await axios.put(
                'https://challenge-api.webtoon.today/session',
                {fsso: response}
            );

            if (res.data.code === 200 && res.data.data && res.data.data.userid){
                if (!res.data.data.image){
                    res.data.data.image = "https://static.webtoon.today/noimage.jpg";
                }
                crnt.session = res.data.data;
            }else{
                crnt.session = nullSession;
            }
        }catch(e){
            console.error(e);
        } finally{
            return crnt.session;
        }
    }
    return crnt.session;
}

/**
 * @param {NaverResponseType} response
 */
 const NaverSignin = async (response: any, ) => {
    if ((window as any).gtag){
        (window as any).gtag('event', 'conversion', {'send_to': 'AW-604780621/UKKOCJ3C1t4BEM3wsKAC'});
    }
    try{
        
        if (!response.access_token){
            throw new Error("no access token!");
        }

        /** @type {sessionResponseType} res */
        let res = await axios.put(
            'https://challenge-api.webtoon.today/session',
            {nsso: response}
        );

        if (res.data.code === 200 && res.data.data && res.data.data.userid){
            if (!res.data.data.image){
                res.data.data.image = "https://static.webtoon.today/noimage.jpg";
            }
            crnt.session = res.data.data;
        }else{
            crnt.session = nullSession;
        }
    }catch(e){
        console.error(e);
    } finally{
        return crnt.session;
    }
}


/**
 * @param {NaverResponseType} response
 */
 const KakaoSignin = async (response: any, ) => {
    if ((window as any).gtag){
        (window as any).gtag('event', 'conversion', {'send_to': 'AW-604780621/UKKOCJ3C1t4BEM3wsKAC'});
    }
    if (response.response.access_token){
        try{
            /** @type {sessionResponseType} res */
            let res = await axios.put(
                'https://challenge-api.webtoon.today/session',
                {ksso: response}
            );

            if (res.data.code === 200 && res.data.data && res.data.data.userid){
                if (!res.data.data.image){
                    res.data.data.image = "https://static.webtoon.today/noimage.jpg";
                }
                crnt.session = res.data.data;
            }else{
                crnt.session = nullSession;
            }
        }catch(e){
            console.error(e);
        } finally{
            return crnt.session;
        }
    }
    return crnt.session;
}

async function isManager () {
    if (crnt.session.isManager !== null && crnt.session.isManager !== undefined){
        return crnt.session.isManager;
    }
    try{
        let res = await axios.get(`https://challenge-api.webtoon.today/user/is-manager`,{
            headers: {
                Authorization: `Bearer ${crnt.session.jwt}`
            },
            withCredentials: true
        })
        
        if (res.data && res.data.code === 200){
            crnt.session = {...crnt.session, isManager: res.data.data};
            return res.data.data;
        } else {
            crnt.session = {...crnt.session, isManager: false};
            return false;
        }

    } catch(e) {
        crnt.session = {...crnt.session, isManager: false};
        return false;
    }
}

const sessionFuncs = {
    GoogleSignin,
    FacebookSignin,
    NaverSignin,
    KakaoSignin,
    sessionRefresh,
    EmailLogin,
    EmailSignin,
    EmailTokenSignin,
    EmailSignupWithOtp,
    logout,
    isManager,
    getCurrentSession: () => {return crnt.session},
};

export default sessionFuncs;
