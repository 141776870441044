import { ReactNode } from "react";
import { fn } from "../Functions";

const AnchorButton = ({ 
    href, onClick, className, children, style 
}:{
    href: string, onClick?: () => void, className?: string, children: ReactNode, style?: React.CSSProperties
}) => {
    
    return(<a style={{textDecoration: 'none', color: 'inherit'}} {...{href, onClick}} >
        <div {...{className, style}} onClick={fn.gotoByAnchor} >
            {children}
        </div>
    </a>)
}

export default AnchorButton;