
import { useEffect, useState } from 'react';
import TrendLanding from './Components/TrendLanding';
import TrendSearchIndex from './Components/TrendSearchIndex';
import { useSetRecoilState } from 'recoil';
import { pageHeaderOptions } from '../../Recoil/PageHeader/PageHeader';
import { useSession } from '../../Recoil/Session/Session';


const TrendSearch = ({query}: {query: string}) => {
    const { session } = useSession();

    const [ isValidUser, setIsValidUser ] = useState<boolean>(false);

    const setPageHeader = useSetRecoilState(pageHeaderOptions);
    useEffect(()=>{    
        setTimeout(()=>{
            setPageHeader({
                titleText: '오늘의웹툰',
            });
        },10);
    }, [query, setPageHeader]);

    useEffect(()=>{
        const { memberState } = session;

        // 최초 진입한 regular 유저는 landing페이지를 보여주다 0.4초 뒤 이용이 가능하도록 함
        const timer = setTimeout(()=>{
            setIsValidUser(memberState === 'regular');
        },400);

        return ()=>{
            clearTimeout(timer);
        }
    },[session]);

    if ( !isValidUser ) {
        
        return(
            <TrendLanding />
        )
    } else {
        
        return(
            <TrendSearchIndex topic={(new URLSearchParams(query)).get('topic') || ''} />
        )
    }

}

export default TrendSearch;