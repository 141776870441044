import {useRef} from 'react';
import { motion } from "framer-motion";
import { appear, appearUp, appearRight } from "../../SettingValues";
import { Container } from "@material-ui/core";

import "./MotherBoard.scss";

import {useOnScreen} from '../../../../Hooks';
import {logBehavior} from '../../../../Data/Behavior';
let didSeen = false;

const BoardImg1 = "https://static.webtoon.today/ddah/210622-reprot%20example.png";
const BoardImg2 = 'https://static.webtoon.today/application/BoardImg2.png';
const WebtoonAnalyticsLogo = "https://static.webtoon.today/application/AnalyticsLogo.png";

const MotherBoard = () => {

    const ref = useRef(null);
    const onScreen = useOnScreen(ref);
    if (!didSeen && onScreen){
        didSeen = onScreen;
        logBehavior('scroll-motherboard', {});
    }

    return (
        <motion.div
            ref={ref}
            initial="initial"
            animate="animate"
            className={"MotherBoardSubRoot"}
            id={"Home"}
        >

            <motion.div
                data-sizes="auto"
                variants={appearUp}
                className={"BoardImgMotionContainer"}
            >
                <div className={"BoardImgContainer"}>
                    <img 
                        alt="product"
                        className={"BoardImg Left"}
                        src={BoardImg2}
                    />
                    <img 
                        alt="product"
                        className={"BoardImg Right"}
                        src={BoardImg1}
                    />
                </div>

            </motion.div>
            <motion.div
                variants={appear}
                className={"BoardOverlay"}
            />

            <Container maxWidth="lg">
                <div className={"Content LandingSection"}>
                    <motion.div variants={appearRight}>
                        <div
                            className={"HeaderContent"}
                        >
                            <div className={"TitleLine"}>
                                <div>{"독자의 마음을 분석하는"}</div>
                                <div>{"새로운 서비스"}</div>
                            </div>
                            <div className={"ImageLine"}>
                                <img src={WebtoonAnalyticsLogo} alt={"WebtoonAnalytics"} className={"Logo"} />
                            </div>
                        </div>
                    </motion.div>
                </div>
            </Container>
        </motion.div>
    );
}

export default MotherBoard;