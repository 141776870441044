
import ss from './Session';
import axios from 'axios';


export const readSubscribedTitle = async () => {

    try{
        
        let res = await axios.get('https://challenge-api.webtoon.today/interaction/title-subscription', {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });
        
        // code가 200이 안나옴; (undefined);
        if (res.data.key) {
            return res.data.key.split(',');
        } else if(res.data.key === ""){
            return [];
        } else {
            return null;
        };

    }catch(e){
        if (e.response && e.response.status === 404) {
            console.log(e);
            return null;
        };
        console.error(e)
        return null;
    };
};

export const subscribeTitle = async (serviceId, titleId) => {

    try{
        
        let res = await axios.put('https://challenge-api.webtoon.today/interaction/title-subscription', {serviceId, titleId}, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });
        
        if (res.data.code === 200 && res.data.key) {
            return res.data.key;
        } else {
            return null;
        };

    }catch(e){
        console.error(e)
        return null;
    };
};

export const unsubscribedTitle = async (serviceId, titleId) => {

    try{
        
        let res = await axios.delete(`https://challenge-api.webtoon.today/interaction/title-subscription?serviceId=${serviceId}&titleId=${titleId}`, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });
        
        if (res.data.code === 200 && res.data.key) {
            return res.data.key;
        } else {
            return null;
        };

    }catch(e){
        console.error(e)
        return null;
    };
};