import React from 'react';
import { Visibility, Favorite, ChevronRight } from '@material-ui/icons';
import './Basic.scss';

const Basic = ({title, isFavoriteTitle = false}) => {

    const themeColorDecision = (basicColor) => {
        if ( title.themeColor === 'rgb(255,255,255)' ) {
            return basicColor;
        } else if (title.themeColor) {
            return title.themeColor;
        } else {
            return 'rgb(51,51,51)';
        }
    }

    let keywords = [];
    if (title.keywords.indexOf(',') > 0) {
        const selectedKeywords = title.keywords.split(',').slice(0,2).filter(keyword => keyword);
        keywords = selectedKeywords
    };
    
    return(
        <div className={`BasicTemplateContainer ${title.themeColor==='rgb(255,255,255)'?'WhiteBasicTemplate':''}`} >
            <div style={{maxWidth: 328}} >
                <div className={'AuthorName'} >
                    {title.author}
                </div>
                <div className={'VerticalLine'} >
                    <span style={{height: 25, borderRight: `2px solid ${themeColorDecision('rgb(217,217,217)')}`}} />
                </div> 
                <div className={'TitleName'} >
                    <span style={{position: 'relative'}}>
                        <div style={{
                            width: 40, height: 40, backgroundColor: themeColorDecision('rgb(217,217,217)'), 
                            position: 'absolute', left:-15, top: -17, borderRadius: '50%'
                            }} 
                        />
                        <div style={{position: 'relative'}}>{title.title}</div>
                    </span>
                </div>
                <div className={'TitleThumbnail'} >
                    <img src={title.thumbnail} alt={'img'} style={{width: 328, height: 328, objectFit: 'cover' }} />
                    <div className={'KeywordBox'}>
                        {keywords.map( (keyword, index) => (
                            <div className={'Background'} key={`Basic:${keyword}:${index}`} style={{display: `${!keyword?'none':'block'}`}} >
                                <div className={'Keyword'} >
                                    {`# ${keyword}`}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className={'StatisticsOfTitle'} >
                    <div className={'StatisticsRow'} >
                        <Visibility style={{color: themeColorDecision('rgb(51,51,51)')}} fontSize={'small'} />
                        <span style={{marginLeft: 5}}>
                            {`${title.titleViewCount || 0}명이 보고있어요.`}
                        </span>
                    </div>
                    <div className={'DevidingLine'} />
                    <div className={'StatisticsRow'} >
                        <Favorite style={{color: themeColorDecision('rgb(51,51,51)')}} fontSize={'small'} />
                        <span style={{marginLeft: 5}}>
                            {`${title.subscriptionCount || 0}명의 팬들이 있어요.`}
                        </span>
                    </div>
                    <div className={'DevidingLine'} />
                    <div className={'StatisticsRow'} >
                        <span style={{width:20, height:20, display: 'flex',justifyContent: 'center', alignItems: 'center' }}>
                            <ChevronRight style={{
                                color: 'rgb(255,255,255)', backgroundColor: themeColorDecision('rgb(51,51,51)'), borderRadius: '50%', width: 16, height: 16}} />
                        </span>
                        <span style={{marginLeft: 5}}>
                            {`연독률 ${title.continueRate || 0}%를 돌파했어요.`}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Basic;