import React from 'react';

import './ArtsEdit.scss';
import ArtCard from './ArtCard';
import NewArtCard from './NewArtCard';
import ErrorMessage from './ErrorMessage';

const ArtsEdit = ({titles}) => {

    // eslint-disable-next-line no-unused-vars
    const [isLoading, setIsLoading] = React.useState(false);

    return (<div className={"ArtsEdit"}>
        <div className={"ArtsContainer"}>
            <NewArtCard />
            {titles?.map((title) => <ArtCard key={`${title.serviceId}:${title.titleId}`} title={title} />) || <ErrorMessage />}
        </div>
    </div>);
};

export default ArtsEdit;