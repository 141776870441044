import React from 'react';


const NaverChild = () => {

    React.useEffect(()=>{
        window.opener.updateNaverSession({message: "!", url: window.location})
        
        window.close();
    },[])

    return(<>
    </>);

}

export default NaverChild;