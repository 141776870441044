import React, {useRef} from 'react';
import './QnA.scss';
import { ArrowDropDown as ArrowDropDownIcon } from '@material-ui/icons';
import { Button } from '@material-ui/core';

import {useOnScreen} from '../../../Hooks';
import {logBehavior} from '../../../Data/Behavior';
import ChannelService from '../../ChannelService';
let didSeen = false;

const QnA = () => {

    const ref = useRef();
    const onScreen = useOnScreen(ref);
    if (!didSeen && onScreen){
        didSeen = onScreen;
        logBehavior('scroll-qna', {});
    }

    const content = [
        {
            title: `콘티로도 분석 가능한가요? 완성 원고를 꼭 첨부해야 하나요?`,
            content: 
            <div>
                {"데이터 분석은 실제 독자 30~100여 명에게 작품을 읽게 하고 그 활동을 분석하는 방식으로 이루어집니다. "}< br/>
                {"따라서 콘티 상태로는 측정이 불가능하며, 완성 원고가 필요합니다."}< br/>
                {"3회분의 완성 원고를 사용할때 가장 정확하지만, 1회분 원고로도 측정이 가능합니다."}
            </div>
        },
        {
            title: "데이터 분석은 얼마나 걸리나요?",
            content: 
            <div>
                {"일반적으로 2~3일 정도 소요되지만, 데이터 수집 정도에 따라 조금 더 소요될 수 있습니다."}< br/><br />
                {"최대 5영업일 이내 메일로 데이터 분석 리포트를 보내드립니다."}
            </div>
        },
        {
            title: "데이터 분석은 어떤 식으로 진행되나요?",
            content: 
            <div>
                {"1. 오늘의웹툰이 자체 개발한 시스템에 작가님의 원고를 게시하고 SNS(인스타그램 등)에 작품의 광고를 집행합니다."}<br/><br />
                {"2. 광고를 클릭한 독자는 오늘의웹툰 시스템에 접속하지만, 실제 웹툰 사이트와 완전히 동일한 형태로 테스트임을 인식하지 못하고 작품을 읽게 됩니다."}<br /><br />
                {"3. 독자가 작품을 읽으면서 남기는 반응(컷을 넘기는 속도, 이탈 지점 등)을 수집해 지표로 분석합니다"}<br /><br />
                {"4. 테스트가 종료되면 광고 해제와 동시에 페이지가 닫히고, 작가님의 원고와 테스트 결과는 안전하게 보호됩니다."}
            </div>
        },
        {
            title: "무료 서비스인가요?",
            content:
            <div>
                {"네, 저작권 권리 관계(에이전시 계약)와 무관하게 데이터 분석 서비스를 이용할 수 있습니다."}<br />
                {"이미 70여회 이상 작품 분석을 진행했습니다."}<br /><br />
                {"에이전시 계약은 작가님이 먼저 요청주시는 경우에 한해서만 검토하고 있습니다."}
            </div>
        },
        {
            title: "데이터 분석 서비스를 여러 번 이용할 수 있나요?",
            content: 
            <div>
                {"네, 가능합니다."}<br />
                {"베타테스트가 끝나기 전까지는 완전 무료로 제공됩니다."}<br/>
                {"단 완전히 동일한 원고에 대해서는 반려될 수 있습니다.(리메이크 예외)"}
            </div>
        },
        {
            title: "데이터 분석 리포트를 어디에 활용할 수 있나요?",
            content:
            <div>
                {"1. 작품 개발의 방향성을 잡아보세요."}<br />
                {"정량적인 지표와 작가 지망생 중 순위를 보여줌과 동시에 작가 경력 디렉터의 피드백을 제공하여 어떻게 더 발전해야 할지를 보여줍니다."}<br /><br />
                {"2. 작품 투고 시에 첨부자료로 제출해 보세요."}<br />
                {"내 작품이 얼마나 흥행하는지, 객관적인 수치로 보여줄 수 있습니다. 분석 보고서의 내용을 변형하지 않는 조건으로 직접 투고할 때 보고서를 같이 제출하거나, 다른 제작사에 제출하는 것까지도 전부 허용됩니다."}
            </div>
        }
    ]


    const [page, setPage] = React.useState("");


    const handle = (index) => {
        
        if (index === Number(page)){
            setPage("")
        } else {
            setPage(`${index}`)
        }
    }

    return(
        <div ref={ref}>
            <div className={"QnATemplate"} id={"QnA"}>
                <div className={"QnABody"}>
                    <div className={"TitleTexts"}>
                        <div className={"MainTitle"}>{"자주 묻는 질문"}</div>
                    </div>
                    <div className={"QuestionBoard"}>
                        {content.map((line, index) => 
                            <div key={`Question${index}`}>
                                <div 
                                    onClick={() => {handle(index + 1)}} 
                                    className={"QuestionLine"}
                                >
                                    <div className={"QuestionText"}>{line.title}</div>
                                    <div><ArrowDropDownIcon className={`ArrowDownIcon ${page === `${index + 1}`?"selected":""}`} /></div>
                                </div>
                                
                                <div 
                                    className={"ContentText"}
                                    hidden={!(page === `${index + 1}`)}
                                >
                                    {line.content}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className={"InquiryMail"}>
                        <Button variant={"contained"} color={"primary"} onClick={() => ChannelService.ChannelIO()('openChat')} >
                            <div className={"SubTitle"}>
                                    {"1:1 문의하기"}
                            </div>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default QnA