import { atom, useRecoilState } from "recoil";
import { listMyOwnTitles, } from "../../Data/Ownership";
import { useCallback, useEffect, useState } from "react";
import { useTitles } from "../Title/Titles";
import { useSession } from "../Session/Session";


const OwnershipTitleKeyList = atom<{[key:string]: 'owner'|'write'|'read'|'none'|'access'}| null>({
    key: 'ownershipTitleKeyList',
    default: null,
    dangerouslyAllowMutability: true,
});

export const useOwnershipTitleList = () => {
    const { session } = useSession();
    const [ ownershipResult, setOwnershipResult ] = useRecoilState(OwnershipTitleKeyList)
    const {titleObject, fetch: fetchTitles} = useTitles();
    
    const fetch = useCallback(async () => {
        if ( ownershipResult ) {
            return true;
        }

        const response = await listMyOwnTitles();
        
        if ( response !== null ) {
            setOwnershipResult(Object.fromEntries(response.filter(row => row.userid === session.userid).map(row => [`${row.serviceId}:${row.titleId}`, row.authority])))            
        } else {
            setOwnershipResult(null);
        }
    },[ownershipResult, session.userid, setOwnershipResult]);

    const secureFetch = useCallback(()=>{
        if ( ownershipResult ) {
            return;
        }
        setOwnershipResult({});
        fetch();
    },[fetch, ownershipResult, setOwnershipResult])

    const [ownTitleList, setOwnTitleList] = useState<(TitleType&TitleAugmentedData&{ownership: 'owner'|'write'|'read'|'access'|'none'})[]>([]);
    useEffect(() => {
        
        let allTitles = Object.keys(ownershipResult || {}).map(key => key.split(':')).map(([serviceId, titleId])=> ({serviceId, titleId}));
        fetchTitles(allTitles);

        let ret = Object.entries(ownershipResult || {}).filter(([key, ownership]) => key in titleObject).map(([key, ownership]) => ({
            ownership,
            ...titleObject[key],
        }));
        
        setOwnTitleList(ret);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ownershipResult, secureFetch, titleObject])

    const getOwnership = useCallback((serviceId: string, titleId: string)=>{
        return ownershipResult?ownershipResult[`${serviceId}:${titleId}`]:'none';
    },[ownershipResult])

    return ({ownTitleList, getOwnership, fetch: secureFetch})
}