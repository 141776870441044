import { Button, MenuItem, Select, Tooltip } from '@material-ui/core';
import { CheckBox, Search, Timeline } from '@material-ui/icons';
import React from 'react';
import { fn } from '../../Functions';
import AdsSlides from '../../Component/AdsSlides';
import './ApplyingAnalysis.scss';

import { logBehavior } from '../../Data/Behavior';
import { AnalyticsSubmit } from '../../Data/AnalyticsSubmit';
import { updateTitle } from '../../Data/Title';

import LoadingCircle from '../../Component/LoadingCircle';
import { useSession } from '../../Recoil/Session/Session';
import { pageHeaderOptions } from '../../Recoil/PageHeader/PageHeader';
import { useSetRecoilState } from 'recoil';

import { useEpisodes } from '../../Recoil/Title/Episodes';
import { useTitles } from '../../Recoil/Title/Titles';

const currentTermsofuseVersion = "/termsofuse/termsofuse/analytics/20220202/kr";
/** @typedef {import('../../Data/AnalyticsSubmit').CompetitionInfo} CompetitionInfo */
/** @typedef {{name: string, image: string, createdAt: number}} AdvertisementCutType */

const consentList = [
    "독자 반응 수집을 위한 SNS 퍼포먼스 마케팅(광고 게재)에 동의합니다.",
    "접수된 작품의 저작권이 본인의 소유임을 확인합니다.",
    "데이터 분석 결과 및 피드백의 저작권은 오늘의웹툰에 있음을 확인합니다.",
    "접수 시 오늘의웹툰 자유연재플랫폼에 작품이 게재됨을 확인합니다.",
];

const ApplyingAnalysis = ({serviceId, titleId}) => {
    const { session } = useSession();
    const { episodeObject, fetch: fetchEpisode } = useEpisodes();
    const { titleObject, fetch: fetchTitles, refreshTitle } = useTitles();

    const setPageHeader = useSetRecoilState(pageHeaderOptions);
    React.useEffect(()=>{
        setPageHeader({titleText: '데이터 분석 신청'})
    },[setPageHeader])
    
    const [isLoading, setIsLoading] = React.useState(false);
    const [title, setTitle] = React.useState({});
    const [episodes, setEpisodes] = React.useState([]);

    /** @type {[null|[number,number], React.Dispatch<React.SetStateAction<null|[number,number]>>]} */
    const [ episodeRange, setEpisodeRange ] = React.useState(null);
    /** @type {[null|'competition'|'analytics', React.Dispatch<React.SetStateAction<null|'competition'|'analytics'>>]} */
    const [ service, setService ] = React.useState(null);
    /** @type {[null|string, React.Dispatch<React.SetStateAction<null|string>>]} */
    const [ introduction,      setIntroduction ] = React.useState(null);
    /** @type {[AdvertisementCutType[], React.Dispatch<React.SetStateAction<AdvertisementCutType[]>>]} */
    const [ advertisementCuts, setAdvertiseMentCuts ] = React.useState([]);
    
    const firstEpisode = episodeRange?episodes.filter(ep => ep.episodeId === episodeRange[0])[0]:null;
    const lastEpisode  = episodeRange?episodes.filter(ep => ep.episodeId === episodeRange[1])[0]:null;

    React.useEffect(()=>{
        (async()=>{
            fetchTitles([{serviceId, titleId}]);
            fetchEpisode({serviceId,titleId});
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[serviceId, titleId])

    React.useEffect(()=>{

        let title = titleObject[`${serviceId}:${titleId}`] || {};
        let episodes = episodeObject[`${serviceId}:${titleId}`] || [];

        setTitle(title);

        setIntroduction(title.description || "");
        setAdvertiseMentCuts(title.slides || []);

        setEpisodes(episodes);

        if (episodes.length>0){
            setEpisodeRange([
                episodes[0].episodeId,
                episodes[episodes.length-1].episodeId
            ])
        }else {
            setEpisodeRange(null)
        }
    },[episodeObject, titleObject, serviceId, titleId])
    
    return(
        <div className={'ApplyingAnalysisMainArea'}>
            <div className={'ApplyingAnalysisBody'}>
                <div className={'Section'}>
                    <div className={'SectionTitle'} >
                        {'독자들의 마음을 데이터로 확인하세요.'}
                    </div>
                    <div className={'SectionText'}>
                        {`클릭률, 완독률, 연독률, 감상시간, 이탈구간 등 객관적인 지표를 알려드립니다.`}
                    </div>
                    <div className={'SectionText'}>
                        {`명료한 데이터 결과로 작품 개발의 방향성을 잡아보세요.`}
                    </div>
                </div>
                <div className={'ButtonSection'}>
                    <Button className={'SectionButton'} href={'https://webtoon.today'} onClick={fn.gotoByAnchor} >
                        <span style={{color: 'rgb(0,176,240)', display: 'flex', marginRight: 3}} >
                            <Search fontSize={'small'} />
                        </span>
                        {'더 자세한 설명 보기'}
                    </Button>
                    <Button className={'SectionButton'} href={'https://challenge.webtoon.today/report/today/the-law-of-the-jungle/1/94'} onClick={fn.gotoByAnchor} >
                        <span style={{color: 'rgb(0,176,240)', display: 'flex', marginRight: 3}} >
                            <Timeline fontSize={'small'} />
                        </span>
                        {'실제 데이터 리포트 보기'}
                    </Button>
                </div>
                <div className={"Section"}>
                    
                    <div className={'SectionTitle'}>
                        {'데이터분석에 활용될 필수 정보입니다.'}
                    </div>
                    <table className={'ApplyingInfo'}>
                        <tbody>
                            <tr>
                                <td>{"✅"}</td>
                                <td>{"작가명"}</td>
                                <td>{session.name}</td>
                            </tr>
                            <tr>
                                <td>{episodeRange?"✅":"❌"}</td>
                                <td>{`에피소드`}</td>
                                <td>{!episodeRange?<Button onClick={()=>fn.goto(`/enroll/${serviceId}/${titleId}/1`)}>{"에피소드 추가하러 가기"}</Button>:
                                [
                                    (`${firstEpisode.title?`${firstEpisode.title}(${firstEpisode.episodeId}화)`:`${firstEpisode.episodeId}화`}`),
                                    (firstEpisode!==lastEpisode?'부터':''),
                                    (firstEpisode!==lastEpisode?`${lastEpisode.title?`${lastEpisode.title}(${lastEpisode.episodeId}화)`:`${lastEpisode.episodeId}화까지`}`:'')
                                ].filter(text => text).join(' ')}</td>
                            </tr>
                            <tr>
                                <td>{"✅"}</td>
                                <td>{"제목"}</td>
                                <td>{title.title}</td>
                            </tr>
                            <tr>
                                <td>{service?"✅":"❌"}</td>
                                <td>{"신청분야"}</td>
                                <td>
                                    <Select value={service} onChange={(event)=>setService(event.target.value)} style={{fontSize: 14, }}>
                                    <MenuItem value={'competition'}>{'분석 서비스 + 창작지원금 응모'}</MenuItem>
                                    <MenuItem value={'analytics'}>{'분석 서비스 + 디렉터 피드백'}</MenuItem>
                                    </Select>
                                </td>
                            </tr>
                            <tr>
                                <td>{"✅"}</td>
                                <td>{"작품소개"}</td>
                                <td>{introduction}</td>
                            </tr>
                            <tr>
                                <td>{advertisementCuts&&advertisementCuts.length > 0?"✅":"❌"}</td>
                                <td>{`광고컷`}</td>
                                <td>
                                    {advertisementCuts.length === 0
                                        ?<Button onClick={()=>fn.goto(`/enroll/${serviceId}/${titleId}`)}>{"광고컷 입력하러 가기"}</Button>
                                        :<></>
                                    }
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    {advertisementCuts.length>0
                        ?<div className={'Section'} style={{paddingBottom:32}}>
                            <AdsSlides title={title}/>
                        </div>
                        :<></>}
                </div>
                <div className={'Section'} style={{marginBottom: 30}}>
                    {consentList.map(consent => (
                        <div className={'CheckRow'} key={consent} >
                            <Tooltip title={"필수 선택사항이에요."}>
                                <CheckBox style={{color:"rgba(0,176,240)", fontSize:'1.2rem', marginRight:'0.2rem'}}/>
                            </Tooltip>
                            {consent}
                        </div>
                    ))}
                </div>
            </div>
            <div className={'ApplyingAnalysisFooter'}>
                <Button fullWidth disableElevation variant={'contained'} color={'primary'} onClick={()=>{
                    (async()=>{
                        setIsLoading(true);

                        const currentTitle = title;

                        if (currentTitle.exposure === 'private' || ['unlist','private'].indexOf(currentTitle.exposure) >= 0){
                            const response = await updateTitle({...currentTitle, exposure: 'public'});
                            refreshTitle([{serviceId,titleId}]);
                            
                            if (!response) {
                                logBehavior('change-exposure(fail)', {message: '작품 unlist 변경 실패', currentTitle, response});
                            } else {
                                logBehavior('change-exposure(success)', {message: '작품 unlist 변경 성공', currentTitle, response});
                            };
                        };

                        logBehavior('click-applicationbutton',{
                            consentList, currentTitle,
                            name: session.name, episodeRange,
                            title: title.title, serviceId, titleId,
                            service, introduction, advertisementCuts, requestCreatives: false,
                            currentTermsofuseVersion, isSurveyRequired: false,
                        })


                        let res = await AnalyticsSubmit({
                            name: session.name, episodeRange,
                            title: title.title, serviceId, titleId,
                            service, introduction, advertisementCuts, requestCreatives: false,
                            consentList, currentTermsofuseVersion, isSurveyRequired: false
                        });
                        if (!res){
                            alert("신청에 실패했어요.")
                        }else{
                            fn.goto(`/analysis/complete/${serviceId}/${titleId}`);
                        }

                        setIsLoading(false);
                    })();
                }} disabled={
                    !advertisementCuts.length > 0
                    || !service
                    || !episodeRange
                    || isLoading
                }>
                    {'동의하고 신청하기'}
                </Button>
            </div>
            <LoadingCircle show={isLoading} />
        </div>
    )
}

export default ApplyingAnalysis;