import React, {useEffect, useState} from "react";
import { useEpisodes } from '../../Recoil/Title/Episodes';
import { useTitles } from '../../Recoil/Title/Titles';
import { readSortedByTagsTitles } from '../../Data/Title';
import { fn } from "../../Functions";
import { readEpisodeEmoticonReaction, updateEpisodeReaction } from "../../Data/EpisodeReaction";

import './EpisodeLikeSlides.scss';
import { useToastAlert } from '@webtoontoday/toast';

type emotion = 'like'|'awesome'|'scared'|'sad'|'fun'|'none';

const emoticonList: {[key:string]: string} = {
    like: 'https://static.webtoon.today/ddah/episodeLike-like.svg',
    awesome: 'https://static.webtoon.today/ddah/episodeLike-awesome.svg',
    scared: 'https://static.webtoon.today/ddah/episodeLike-scared.svg',
    sad: 'https://static.webtoon.today/ddah/episodeLike-sad.svg',
    fun: 'https://static.webtoon.today/ddah/episodeLike-fun.svg',
};

const emoticonLabelList: {[key:string]: string} = {
    like: '좋아요',
    awesome: '멋져요',
    scared: '무서워요',
    sad: '슬퍼요',
    fun: '재밌어요',
};

const EpisodeLikeBox = ({serviceId, titleId, episodeId}:{serviceId: string, titleId: string, episodeId: string}) => {
    const { toastAlert } = useToastAlert();
    
    const { titleObject, fetch: fetchTitles } =useTitles();
    const { episodeObject, fetch: fetchEpisodes } = useEpisodes();
    const [ selectedEmoticon, setSelectedEmoticon ] = useState<emotion>('none');
    const [ recommendTitles, setRecommendTitles ] = React.useState<TitleType[]>([]);

    const isFreeTitle = (episodeObject[`${serviceId}:${titleId}`] || []).filter(row => !row.isFree).length === 0;

    useEffect(()=>{
        fetchTitles([{serviceId, titleId}]);
        fetchEpisodes({serviceId,titleId});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[serviceId, titleId]);
    
    const episodes = episodeObject[`${serviceId}:${titleId}`] || [];
    const episode = (episodes.filter(episode => episode.episodeId === episodeId))[0] || {};
    
    useEffect( () => { // get existing reaction
        (async()=>{
            const reaction = await readEpisodeEmoticonReaction({serviceId, titleId, episodeId});
            setSelectedEmoticon(reaction);
        })();
    },[episodeId, serviceId, titleId]);

    useEffect(() => { // get recommendation
        ( async () => {
            const currentTitle = titleObject[`${serviceId}:${titleId}`];

            if (currentTitle?.title) {

                const tag = (currentTitle || {tags: ['드라마']}).tags[0] || '드라마'
                const sortedTitles = await readSortedByTagsTitles({tags: tag, limit: 5})
                
                if ( sortedTitles.length > 0 ) {
                    setRecommendTitles(sortedTitles);
                } else {
                    toastAlert("추천 작품 로딩에 실패했어요.")
                }

            }
        })()
    },[serviceId, titleId, titleObject, toastAlert])

    return (<div className='EpisodeLikeArea' style={{marginBottom: 16}}>
        <div className={"EmotionSlide"} style={{display: 'flex', justifyContent:'space-between'}}>
            {Object.entries(emoticonList).map(([key, value]) => {
                return <div
                    key={key}
                    style={{
                        boxSizing: 'border-box',
                        height: 100, width: selectedEmoticon === key?'calc(24% + 4%)':'24%',
                        padding: selectedEmoticon === key?'8px calc(8px + 4%)':'8px calc(8px + 6%)',
                        display: "flex", flexDirection: 'column', justifyContent:'flex-end', transition: '200ms'
                    }}
                    onClick={()=>{
                        setSelectedEmoticon(key as emotion);
                        updateEpisodeReaction({serviceId,titleId,episodeId, reaction: key});
                    }}
                >
                    <img style={{width: '100%', objectFit: 'contain', flexShrink: 0}} src={value} key={key} alt={key}/>
                </div>
            })}
        </div>
        <div className={"EmotionSlide"} style={{display: 'flex', justifyContent:'space-between'}}>
            {Object.entries(emoticonList).map(([key, value]) => {
                return <div
                    key={key}
                    style={{
                        boxSizing: 'border-box',
                        height: 24, width: '24%',
                        display: "flex", flexDirection: 'column', justifyContent:'center', transition: '200ms', textAlign: 'center'
                    }}
                >
                    <span >{emoticonLabelList[key]}</span>
                    <span style={{fontWeight: selectedEmoticon === key?700:400}} >{`${Number( (episode as any || {[`${key}Count`]: 0})[`${key}Count`] ) + (selectedEmoticon === key?1:0) }`}</span>
                </div>
            })}
        </div>
        
        {isFreeTitle && <div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '50px 0', lineHeight: '30px'}}>
            <div style={{whiteSpace: 'pre-line', textAlign: 'center'}}>
                {`작품에 반응을 남기고
                다른 작품을 추천받아 보세요!`}
            </div>
        </div>}
        {selectedEmoticon !== 'none'
        ?<div className={'RecommendBox'} >
            {isFreeTitle && recommendTitles
                .map(title => (
                    <div key={`${title.serviceId}:${title.titleId}`} className={'TitleBox'} onClick={() => fn.goto(`/contents/${title.serviceId}/${title.titleId}`)} >
                        <img className={'Thumbnail'} src={title.thumbnail || 'https://static.webtoon.today/noimage.jpg'} alt={`recommendTitle:${title.serviceId}:${title.titleId}`} />
                        <div className={'Title'}> {title.title} </div>
                    </div>
                ))}
        </div>:<></>}
    </div>);
}

export default EpisodeLikeBox;