import React from 'react';
import { getTitle } from '../../Data/Title';
import './AnalysisComplete.scss';
import { Button } from '@material-ui/core';
import { fn } from '../../Functions';
import { useSetRecoilState } from 'recoil';
import { pageHeaderOptions } from '../../Recoil/PageHeader/PageHeader';

const AnalysisComplete = ({serviceId, titleId}) => {
    const setPageHeader = useSetRecoilState(pageHeaderOptions);
    React.useEffect(()=>{
        setPageHeader({titleText: '데이터 분석 신청'})
    },[setPageHeader])
    
    const [ titleData, setTitleData ] = React.useState({});

    React.useEffect(()=>{
        (async()=>{
            const res = await getTitle(serviceId, titleId)
            if (res.length > 0){
                setTitleData(res[0])
            }
        })()
    },[serviceId, titleId])

    return(
        <div className={'AnalysisCompleteMainArea'}>
            <div className={'AnalysisCompleteBody'}>
                <div className={'BodyTitle'}>
                    {'신청 완료'}
                </div>
                <div className={'ImageBox'}>
                    <img src={titleData.thumbnail} alt={'titleImage'} style={{width: 210, height: 130, objectFit:'contain'}} />
                </div>
                <div className={'TextBox'}>
                    {`<${titleData.title}>의 
                    데이터 분석 신청이 완료되었어요.`}
                </div>
                <div className={'TextBox'}>
                    {`데이터 분석에는 최대 5 영업일이 소요되니, 조금만 기다려주세요.`}
                </div>
            </div>
            <div className={'AnalysisCompleteFooter'}>
                <Button fullWidth className={'Button'} variant={'contained'} color={'primary'} onClick={()=>{
                    fn.goto('/');
                }} >
                    {'확인'}
                </Button>
            </div>
        </div>
    )
}

export default AnalysisComplete