
import { ParentSize } from '@visx/responsive';
import MultiChart from './MultiChart';
import './Styles/BasicTrendChart.scss';

const PlatformTopPopularity = ({ 
    topic, type, data
}:{
    topic: string, type: string,
    data: {
        [Date: string]: {
            [topic: string]: number
        }
    }

}) => {
    
    let newChartData:{[key: string]: string | number}[] = []

    for ( const [date, dateData] of Object.entries(data) ) {
        newChartData.push({
            ...dateData, period: date
        })
    }
    
    return <div className={'BasicTrendChartArea'}>
        <div className={'Title'} >
            {`${topic} 대표 인기 웹툰 현황`}
        </div>
        <div className={'SubTitle'}>
            {`최근 30일 동안 ${type === 'rank' && topic === "네이버웹툰"?"관심등록순위":type === 'rank'?"인기순위":'조회수'} TOP 5 작품`}
        </div>
        <div className={'ChartSpace'}>
            <ParentSize>
                {({width, height}) => 
                    <MultiChart
                        width={width} height={height * 0.9} key={`line:PlatformTopPopularity`}
                        data={newChartData} chartType={'line'}
                    />
                }
            </ParentSize>
        </div>
    </div>
}

export default PlatformTopPopularity;