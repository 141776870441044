import React from 'react';
import { fn } from '../../../Functions';
import './University.scss';
import Landing from '../../../Component/Explanation/Landing';
import SubmitEmail from './University/SubmitEmail';
import LandingFooter from '../../../Component/Explanation/LandingFooter';
import ContestApplication from './University/ContestApplication';
import { Button } from '@material-ui/core';
import ContestEnd from './University/ContestEnd';
import { logBehavior } from '../../../Data/Behavior';
import { useSetRecoilState } from 'recoil';
import { pageHeaderOptions } from '../../../Recoil/PageHeader/PageHeader';

let contestStartUnixTime = new Date("2022-12-19 00:00:00+09:00").getTime()/1000;

// eslint-disable-next-line no-unused-vars
let contestEndUnixTime   = new Date("2023-01-01 00:00:00+09:00").getTime()/1000;
let ceremonyEndUnixTime  = new Date("2023-01-13 08:00:00+09:00").getTime()/1000;
let currentUnixTime      = new Date().getTime()/1000;

const univAwards = [
    {
        image: 'https://static.webtoon.today/competition/230111-homework-rebirth-award-01.png',
        urls: ['/contents/RIBORI/INeedToBeAMermaid/1']
    },
    {
        image: 'https://static.webtoon.today/competition/230111-homework-rebirth-award-02.png',
        urls: ['/contents/leehan/Which_one_of_the_two/1']
    },
    {
        image: 'https://static.webtoon.today/competition/230111-homework-rebirth-award-03.png',
        urls: ['/contents/sooncong/something_lost/1']
    },
    {
        image: 'https://static.webtoon.today/competition/230111-homework-rebirth-award-04.png',
        urls: ['/contents/TAFI/SIREN/1','/contents/SOI/Chucks_Island/1']
    },
    {
        image: 'https://static.webtoon.today/competition/230111-homework-rebirth-award-05.png',
        urls: ['/contents/HATTO/Next_door_friends/1','/contents/tae_won_kim/I_AM_THE_ONE/1']
    },
    {
        image: 'https://static.webtoon.today/competition/230111-homework-rebirth-award-06.png',
        urls: ['/contents/KimHanhee/Zombeauty/1','/contents/Yeoklin/The_Story_of_DanA/1']
    },
    {
        image: 'https://static.webtoon.today/competition/230111-homework-rebirth-award-07.png',
        urls: ['/contents/Toonree/military_discharg/1','/contents/HASEON/No_debt_No_light/1']
    }
]

const University = () => {

    const setPageHeader = useSetRecoilState(pageHeaderOptions);
    React.useEffect(()=>{
        setPageHeader({
            isBackable: false, titleText: '과제 부활전!', isHiddenForPC: true, isHiddenForMobile: true, isAppbarHidden: true
        });
    },[setPageHeader])

    const [ email, setEmail ] = React.useState(null);

    return (
        <div className={'UniversityMainArea'} >
            <div className={'ImageArea'}>
                {currentUnixTime>ceremonyEndUnixTime
                    ?<>
                        <img src={'https://static.webtoon.today/competition/230111-homework-rebirth-poster-01.png'} alt={'PosterDetail'} className={'ContestImages'} />
                        {univAwards.map( ({image, urls}, index) => (
                            <div style={{position: 'relative'}} key={image}>
                                <img src={image} alt={image} className={'ContestImages'} />
                                <div style={{display: 'flex', justifyContent: 'center', position: 'absolute', bottom: `${index < 3?'1%':'5%'}`, transform: 'translate(0)',width: '90%', padding: '0 5%'}}>
                                    {urls.map( (url, idx) => (
                                        <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                                            <Button
                                                href={url}
                                                className={'LinkButton'}
                                                onClick={(e)=> {
                                                    fn.gotoByAnchor(e)
                                                    logBehavior('click-winningwebtoon', {image, urls});
                                                }}
                                                disableElevation
                                                variant={'contained'} color={'primary'} >
                                                {'보러 가기'}
                                            </Button>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                        {Array(10).fill(0).map( (v,i) => 
                            <img src={`https://static.webtoon.today/competition/230111-homework-rebirth-data-${i + 1 > 9? i + 1:`0${i + 1}`}.png`} alt={`230111-homework-rebirth-data-0${i + 1}`} key={`homework-rebirth-data-0${i + 1}`} className={'ContestImages'} />
                        )}
                        <img src={'https://static.webtoon.today/competition/230111-homework-rebirth-poster-02.png'} alt={'PosterDetail'} className={'ContestImages'} />
                        <img src={'https://static.webtoon.today/competition/230111-homework-rebirth-poster-03.png'} alt={'PosterDetail'} className={'ContestImages'} />
                        <img src={'https://static.webtoon.today/competition/230111-homework-rebirth-poster-04.png'} alt={'PosterDetail'} className={'ContestImages'} />
                        <img src={'https://static.webtoon.today/competition/230111-homework-rebirth-poster-05.png'} alt={'PosterDetail'} className={'ContestImages'} />
                        <img src={'https://static.webtoon.today/competition/230111-homework-rebirth-poster-06.png'} alt={'PosterDetail'} className={'ContestImages'} />
                    </>
                    :<>
                        <img src={'https://static.webtoon.today/competition/221214-homework-rebirth-poster-1.png'} alt={'PosterDetail'} className={'ContestImages'} />
                        <img src={'https://static.webtoon.today/competition/221214-homework-rebirth-poster-2.png'} alt={'PosterDetail'} className={'ContestImages'} />
                        <img src={'https://static.webtoon.today/competition/221214-homework-rebirth-poster-3.png'} alt={'PosterDetail'} className={'ContestImages'} />
                        <img src={'https://static.webtoon.today/competition/221214-homework-rebirth-poster-4.png'} alt={'PosterDetail'} className={'ContestImages'} />
                    </>}
                    <div className={'OperationPolicy'} >
                    <Button style={{fontFmaily: 'inherit'}} onClick={() => fn.goto('https://webtoon-today.notion.site/e89bc117c561488caccc07c1b42069b3')} >
                        {'과제 부활전 운영정책 보기'}
                    </Button>
                </div>
            </div>
            {currentUnixTime <= contestStartUnixTime
            ?<>
                <SubmitEmail email={email} setEmail={(text) => setEmail(text)} />
                <Landing />
                <SubmitEmail email={email} setEmail={(text) => setEmail(text)} hasFooter={false} />
                <LandingFooter />
            </>
            :currentUnixTime <= contestEndUnixTime
                ?<ContestApplication />
                :<ContestEnd />}
        </div>
    )
}

export default University;