
import { ParentSize } from '@visx/responsive';
import MultiChart from './MultiChart';
import './Styles/BasicTrendChart.scss';

const PlatformTopicStatus = ({ 
    topic, data
}:{
    topic: string,
    data: {
        [Date: string]: {
            [topic: string]: number
        }
    }

}) => {
    
    let newChartData:{[key: string]: string | number}[] = []

    for ( const [date, dateData] of Object.entries(data) ) {
        newChartData.push({
            ...dateData, period: date
        })
    }

    return <div className={'BasicTrendChartArea'}>
        <div className={'Title'} >
            {`${topic} 토픽 현황`}
        </div>
        <div className={'SubTitle'}>
            {'최근 30일 연재된 작품의 토픽'}
        </div>
        <div className={'ChartSpace'} >
            <ParentSize>
                {({width, height}) => 
                    <MultiChart
                        width={width} height={height * 0.9} key={`area:platformTopicStatus`}
                        data={newChartData} chartType={'area'}
                    />
                }
            </ParentSize>
        </div>
    </div>
}

export default PlatformTopicStatus;