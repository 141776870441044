import React from 'react';
import ErrorIcon from '@material-ui/icons/Error';
import './ErrorMessage.scss';

// created as a general name in case of scalability
const ErrorMessage = ({containerStyle = {}} : {containerStyle: React.CSSProperties}) => {
    return (
        <div className='ErrorMessageContainer' style={containerStyle}>
            <div className='Title'>
                <ErrorIcon />
                {"작품 로딩에 문제가 생겼어요."}
            </div>
            <p className='description'>{"작가님의 작품은 잘 저장돼있지만, 불러오는 과정에서 오류가 발생했어요."}</p>
            <p className='description'>{"다시 접속해도 문제가 계속된다면 우측 하단 채널톡으로 문의해주세요."}</p>
        </div>
    );
};

export default ErrorMessage;