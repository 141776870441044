
import React, { useState } from 'react';
import TextCheckBox from '../../../Component/TextCheckBox';
import './Styles/Disclaimer.scss';
import { fn } from '../../../Functions';
import { readLoginInfo } from '../../../Data/Account';
import { useSession, useSignIn } from '../../../Recoil/Session/Session';
import { updateProfile } from '../../../Data/Profile';
import { createTitle, getTitle } from '../../../Data/Title';
import { logBehavior } from '../../../Data/Behavior';
import { createEpisode } from '../../../Data/Episode';
import { createContents } from '../../../Data/Contents';
import { AnalyticsSubmit } from '../../../Data/AnalyticsSubmit';
import LoadingCircle from '../../../Component/LoadingCircle';
import { useToastAlert } from '@webtoontoday/toast';

const AgreementsLabel: {[key: string]: string} = {
    serviceProvisionAgreement: "서비스 제공 약관에 동의합니다.",
    marketingAgreement: "독자 반응 수집을 위한 SNS 퍼포먼스 마케팅(광고 게재)에 동의합니다.",
    webtoonCopyingAgreement: "접수된 작품의 저작권이 본인의 소유임을 확인합니다.",
    analysisCopyingAgreement: "데이터 분석 결과 및 피드백의 저작권은 오늘의웹툰에 있음을 확인합니다.",
    platformUploadAgreement: "접수 시 오늘의웹툰 자유연재플랫폼에 작품이 게재됨을 확인합니다.",
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const serviceKey: {[key: string]: 'competition' | 'analytics'} = {
    "공모전(창작 지원금 50만원) + 데이터 분석 ": 'competition',
    "웹툰 디렉터의 상세한 피드벡 + 데이터 분석 ": 'analytics'
}
const currentTermsofuseVersion = 'https://webtoon.today/termsofuse/kr';

const Disclaimer = ({ 
    agreements, setAgreements, goToPreviousPage, analysisApplicationData
}: {
    agreements: agreementType,
    setAgreements: (agreements: agreementType) => void,
    goToPreviousPage: () => void,
    analysisApplicationData: analysisApplicationDataType
}) => {
    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    
    const { toastAlert } = useToastAlert();
    const { sessionRefresh } = useSession();
    const { EmailSignin } = useSignIn();

    const analyAppyingProcess = async () => {
        setIsLoading(true);

        const isAllAgreed = Object.values(agreements).every( value => value);
        const { 
            email, emailKey, nameKor, titleId, addressid, title, adCuts, 
            representativeImage, episodeInfo, isAutoAdCut, serviceType
        } = analysisApplicationData

        if ( !isAllAgreed ) {
            toastAlert('모든 약관에 동의가 필요합니다.');
            setIsLoading(false);
            return;
        } else {
            const { isProfileExist } = await readLoginInfo(email);
            
            if ( !isProfileExist ) {
                // 가입 되지 않은 이메일의 경우, 신규 가입 시키기
                const newUser = await EmailSignin({
                    email, otp: emailKey, addressid, name: nameKor
                });

                if ( newUser ) {
                    await updateProfile({
                        email, addressid, name: nameKor
                    })
                    await sessionRefresh();
                  
                } else {
                    toastAlert("새로운 아이디 생성에 실패했습니다.");
                    setIsLoading(false);
                    return;
                }
            }
            
            const [ themeColor, themeTemplate ] = ['rgb(255,255,255)', 'circle'];
            const thumbnail = representativeImage[0].image;
            // 작품 등록
            const titleKey = await createTitle({
                serviceId: addressid, titleId, title, themeColor, themeTemplate,
                slides: adCuts, thumbnail: representativeImage[0]?.image
            });

            if (!titleKey) { 
                toastAlert('작품 등록에 실패했습니다.');
                setIsLoading(false);
                return;
            } else {
                logBehavior("create-title", {serviceId: addressid, titleId, title, themeColor, themeTemplate, slides: adCuts, thumbnail});
            }

            const [ serviceId, ] = titleKey.split(':');

            let episodeCount = 0;

            // episode, contents들 등록
            for (let i=0; i<episodeInfo.length; i++ ) {
                const thumbnail = episodeInfo[i]?.thumbnail[0]?.image;
                const title = `${i+1}화`;
                
                // Episode 등록
                const episodeKey = await createEpisode({
                    serviceId, titleId, episodeId: `${i+1}`, title, thumbnail, exposure: 'public'
                });

                if (episodeKey) {
                    let [ ,, episodeId] = episodeKey.split(':');
                    logBehavior("create-episode", {serviceId, titleId, episodeId, title, thumbnail});

                    // Episode 등록이 됐다면, contents 등록
                    const contentKey = await createContents({
                        contents: {
                            serviceId, titleId, episodeId, 
                            data: {images: episodeInfo[i].manuscripts.map(row => row.image)}
                        }
                    });
                    
                    if (contentKey) {
                        episodeCount++;
                    } else {
                        toastAlert('콘텐츠 등록에 실패했습니다.');
                        setIsLoading(false);
                        return;
                    }
                    
                    const [,,,snapshotId] = contentKey.split(':');

                    logBehavior('create-contents', {serviceId, titleId, episodeId, snapshotId});

                } else {
                    toastAlert('에피소드 등록에 실패했습니다.');
                    setIsLoading(false);
                    return;
                }
            }

            // 생성된 작품을 조회 및 확인
            const newTitle = await getTitle(addressid, titleId);
            if(newTitle.length < 1) {
                toastAlert("작품 읽기에 실패했습니다.");
                setIsLoading(false);
                return;
            }

            const episodeRange:[number, number] = [1, episodeCount || 1];
            const consentList = Object.keys(agreements).map( key => AgreementsLabel[key] );
            
            const formJSON = {
                name: nameKor || '', episodeRange,
                title: newTitle[0].title, serviceId: newTitle[0].serviceId, titleId: newTitle[0].titleId, service: serviceType,
                introduction: newTitle[0].description, advertisementCuts: adCuts, requestCreatives: isAutoAdCut,
                consentList, school: '', currentTermsofuseVersion
            }

            // 데이터 분석 신청 api에 formJSON을 보내 분석신청
            logBehavior('input-submitted', {});
            const result = await AnalyticsSubmit(formJSON);

            if (result) {
            
                // 데이터 분석신청 완료 
                logBehavior('data-analytics', {detail: { name: nameKor, email }});
                
                // 아이디를 새로 가입한 유저들에게 비밀번호 재설정 메일
                if (!isProfileExist) {
                    logBehavior('dataAnalytics', {detail: { name: nameKor, email }});
                }
                
                setIsLoading(false);

                fn.goto(`/portfolio/${addressid}`);

            } else {
                setIsLoading(false);
                toastAlert("데이터 전송이 실패했습니다.");
            }
        }
    }
    
    return(
        <div className={'DisclaimerArea'} >
            <div className={'DisclaimerInfo'}>
                <div className={'DisclaimerTitle'} >
                    {'서비스 제공 약관 및 기타 동의'}
                </div>
                <div className={"DisclaimerBody"} >
                    <div className={'SurviceAllAgreementsBox'}>
                        <a className={'TermsOfService'} href={currentTermsofuseVersion} onClick={fn.gotoByAnchor} >
                            {'서비스 이용 약관'}
                        </a>
                        <TextCheckBox
                            text={'전체 동의'}
                            isChecked={Object.values(agreements).every( value => value )}
                            onChange={ (e) => {
                                const result = {...agreements};
                                for ( const agreement in agreements ) {
                                    result[agreement as keyof agreementType] = e.target.checked;
                                }
                                setAgreements(result);
                            }} 
                            color={'rgb(121, 120, 130)'}
                            style={{
                                justifyContent: 'space-between', height: 'auto',
                                gap: 8, padding: 0, borderWidth: 0,
                                fontSize: '0.875rem', color: 'rgb(189, 189, 194)',
                                backgroundColor: 'rgb(255,255,255)',
                                fontWeight: 500
                            }}
                            key={'전체 동의'}
                        />
                    </div>
                    <div className={'Divider'} />
                    <div className={'AgreementBox'} >
                    {Object.entries(agreements).map( ([key, value]) => (
                        <div className={'AgreementRow'} key={key} >
                            <div className={'MandatoryNotice'} >{'(필수)'}</div>
                            <TextCheckBox
                                text={AgreementsLabel[key]}
                                isChecked={value}
                                onChange={ (e) => setAgreements({...agreements, [key]: !value}) }
                                color={'rgb(121, 120, 130)'}
                                style={{
                                    width: '100%', justifyContent: 'space-between', 
                                    gap: 8, padding: 0, borderWidth: 0,
                                    fontSize: '0.875rem', color: 'rgb(25, 24, 27)',
                                    backgroundColor: 'rgb(255,255,255)',
                                    fontWeight: 600
                                }}
                                key={AgreementsLabel[key]}
                            />
                        </div>
                    ))}
                    </div>
                </div>
            </div>
            <hr className={'HorizontalLine'} />
            <div className={'ButtonInfo'} >
                <div className={'Previous Button'} onClick={ () => goToPreviousPage() }>{'이전'}</div>
                <div className={`ApplyComplete Button`} onClick={analyAppyingProcess}>{'분석 신청 완료'}</div>
            </div>
            <LoadingCircle show={isLoading} />
        </div>
    )
}

export default Disclaimer;