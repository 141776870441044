import React from 'react';

import {
    Edit as EditIcon, Add as AddIcon, Link as LinkIcon, FormatAlignLeft, FormatAlignCenter, FormatAlignRight,
    ImageOutlined as ImageIcon, PlayCircleOutline, Brush as BrushIcon, Delete, DragIndicator, DeleteForever,
} from '@material-ui/icons';

import './ProfileEdit.scss';

import { createImageWithS3 } from '../../Data/Image';
import { decodeEntities, randomString } from '../../Functions';
import { IconButton, TextField } from '@material-ui/core';
import CollapseCell from './CollapeCell';
import DragAndDrop from './DragAndDrop';
import { readSiteMeta } from '../../Data/SiteMeta';

import Toggle from '../../Component/Toggle';
import PortfolioView from './PortfolioView';

import { fn } from '../../Functions';

import LoadingCircle from '../../Component/LoadingCircle';
import Share from '../../Component/Share';
import { logBehavior } from '../../Data/Behavior';
import ErrorMessage from './ErrorMessage';
import { useSession } from '../../Recoil/Session/Session';

const RectButton = ({style, onClick, children}) => {
    return (
        <div className={"RectButton Button"} style={style}
            onClick={onClick}
        >
            <div className={"RectButtonChild"}>
                {children}
            </div>
        </div>);
}

/**
 * @typedef {import('../../Data/Portfolio').PortfolioContentType} PortfolioContentType
 */

const DraggableCollapseCell = ({
    style={}, className='', bodyStyle={}, title="", defaultIsOpen=true,
    setOnMovingIdx=()=>{}, setOnTargetIdx=()=>{}, DropEventHandler=()=>{},
    onDragDrop=(event)=>{},
    needToBlock=true,
    children, index,
}) => {

    const currRef = React.useRef();

    const [isDragEnter, setIsDragEnter] = React.useState(false);

    const [isDragging, setIsDragging] = React.useState(false);
    return (
    <CollapseCell
        className={`${className} ${isDragEnter?"Over":""} ${isDragging?"Dragging":""}`}
        titleHeader={
            <DragIndicator
                style={{height: 18, color: 'rgb(196,196,196)'}}
                onMouseDown={()=>{currRef.current.setAttribute('draggable', "true")}}
                onMouseUp={()=>{currRef.current.setAttribute('draggable', "false")}}
            />}
        forcedProps={{
            ref: currRef,
            onDragStart: event=>{setIsDragging(true); setOnMovingIdx(index);},
            onDragEnd: event=>{
                currRef.current.setAttribute('draggable', "false");
                setIsDragging(false); DropEventHandler(event);
            },
            onDragOver: event=>event.preventDefault(),
        }}
        {...{style, bodyStyle, title, defaultIsOpen, }}
    >
        {children}
        <div
            className={`DragCover ${needToBlock?'Nondragging':''}`}
            onDragEnter={(event)=>{ setIsDragEnter(true); setOnTargetIdx(index); }} onDragLeave={(event) => {setIsDragEnter(false);}}
            onDrop={(event)=>{
                setIsDragEnter(false);
                setIsDragging(false);
                onDragDrop(event);
                currRef.current.setAttribute('draggable', "false");
            }}
        ></div>
    </CollapseCell>
    );
}

const ShowHideToggle = ({show, contents, setContents, index}) => (    
<Toggle size={"medium"} labelOn='show' labelOff='hide' defaultValue={show} onChange={(value)=>{
    setContents([
        ...(contents.slice(0,index)),
        {...contents[index], show: value},
        ...(contents.slice(index+1)),
    ])
}}/>
)

const DeleteIconButton = ({contents, setContents, index}) => (
<IconButton
    size={"small"}
    style={{padding:2, marginRight:20}}
    onClick={()=>{
        setContents([
            ...(contents.slice(0,index)),
            ...(contents.slice(index+1)),
        ])
    }}
>
    <Delete style={{ height: 16, width: 16}}/>
</IconButton>
);

const ProfileEdit = ({
    style={},
    addressid="",
    isEditingMode, setIsEditingMode, isPreviewMode, setIsPreviewMode, isPortfolioLoading,
    portfolioFromServer, profile, setProfile, contents, setContents, template={}, titles=[],
    savePortfolio=async ()=>{}
}) => {
    const { session } = useSession();

    const [uniqueId,] = React.useState(randomString(16));

    const [onMovingIdx, setOnMovingIdx] = React.useState(null);
    const [onTargetIdx, setOnTargetIdx] = React.useState(null);

    React.useEffect(()=>{
        console.log({onMovingIdx, onTargetIdx})
    },[onMovingIdx, onTargetIdx])

    const DropEventHandler = (event)=>{

        if (onMovingIdx > onTargetIdx){
            setContents([
                ...(contents.slice(0,onTargetIdx)),
                contents[onMovingIdx],
                ...(contents.slice(onTargetIdx,onMovingIdx)),
                ...(contents.slice(onMovingIdx+1)),
            ])
        }else if (onMovingIdx === onTargetIdx){
            return;
        }else {
            setContents([
                ...(contents.slice(0,onMovingIdx)),
                ...(contents.slice(onMovingIdx+1, onTargetIdx+1)),
                contents[onMovingIdx],
                ...(contents.slice(onTargetIdx+1)),
            ])
        }
        
        setOnMovingIdx(null);
    }

    const [isLoading, setIsLoading] = React.useState(false);

    if (isPortfolioLoading){
    return (
    <div className={[
        uniqueId, 'ProfileEdit', "PortfolioExists", "NoEditingMode", "NoPreviewMode"
    ].join(' ')} style={{
        backgroundColor: template.color || 'rgb(255,255,255)',
        ...style}}>
                
        
        <div className={"View"}>
            <PortfolioView portfolio={portfolioFromServer || {profile: {}, contents: [], template: {}}} isPortfolioLoading={isPortfolioLoading}/>

        </div>

    </div>)}

    return (
    <div className={[
        uniqueId,
        'ProfileEdit',
        !!portfolioFromServer?"PortfolioExists":"NoPortfolioExists",
        isEditingMode        ?"EditingMode"    :"NoEditingMode",
        isPreviewMode        ?"PreviewMode"    :"NoPreviewMode"
    ].join(' ')} style={{
        backgroundColor: template.color || 'rgb(255,255,255)',
        ...style}}>
        <div className={"Guide"}>
            <div className={"ProfileImage"}>
                <div>
                    <div className={"Image Noimage"}></div>
                </div>
            </div>
            <div className={"CreateProfileButton"} onClick={()=>{
                if (session.memberState !== 'regular') {
                    fn.goto('/signin')
                    return;
                };

                setIsEditingMode(true)
            }}>
                <EditIcon fontSize={'small'} style={{marginRight: 10, cursor: 'pointer'}}/>
                {"프로필 만들기"}
            </div>
            <div className={"Explanation"}>
                <h3>{"프로필을 만들면"}</h3>
                <ul>
                    <li>{"작가님의 다양한 작업물과 이력사항을 보기 쉽게 정리할 수 있어요."}</li>
                    <li>{"웹툰 관계자들의 컨택이 쉬워져요."}</li>
                    <li>{"작가님이 어딘가에 포트폴리오를 낼 때도, 이 페이지 링크 하나 공유하면 끝!"}</li>
                </ul>
                <br/>
                <h3>{"독자님도 프로필을 만들 수 있어요!"}</h3>
                <ul>
                    <li>{"자유롭게 포트폴리오로 사용하셔도 좋고,"}</li>
                    <li>{"자신만의 웹툰 리스트를 만들어서 친구들에게 공유할 수도 있어요."}</li>
                </ul>
            </div>
        </div>

        <div className={"Edit"}>
            <div className={"AddContent Button"} onClick={()=>{
                setContents([{key: randomString(12), type: null, title: null, cardType: 'card', show: false}, ...contents])
            }}>
                <EditIcon fontSize={'small'} style={{marginRight: 10}}/>
                {"내용 추가하기"}
            </div>
            <CollapseCell title={"프로필"}>
                <div className={"ProfileImage"}>
                    <div className={"Image"}>
                        {profile.image
                            ?<img src={profile.image} alt={profile.name}/>
                            :<img src={'https://static.webtoon.today/noprofile.jpg'} alt={'noprofile'} />}
                        <input type={"file"} name={"newImage"} id={uniqueId+"_newImage"} onChange={async ()=> {
                            let data = await createImageWithS3(document.getElementById(uniqueId+"_newImage"))
                            setProfile({...profile, image: data});
                        }}/>
                        {profile.image
                        ?<IconButton
                            className={'AddButton'}
                            style={{backgroundColor: 'rgb(234,0,0)'}}
                            onClick={()=>{setProfile({...profile, image: null})}}
                        >
                            <DeleteForever/>
                        </IconButton>
                        :<IconButton
                            className={'AddButton'}
                            onClick={()=>{document.getElementById(uniqueId+"_newImage").click()}}
                        >
                            <AddIcon/>
                        </IconButton>}
                        
                    </div>
                </div>
                <div style={{marginTop:20, fontSize: 12}}>{"이름"}</div>
                <TextField
                    fullWidth
                    placeholder={"이름이나 대표문구"}
                    inputProps={{style:{fontSize: 14}}}
                    value={profile.title || ""}
                    onChange={(event) => {
                        setProfile({
                            ...profile,
                            title: event.target.value
                        })
                    }}
                />
                <div style={{marginTop:20, fontSize: 12}}>{"소개"}</div>
                <TextField
                    fullWidth
                    placeholder={"추가로 넣고 싶은 설명"}
                    inputProps={{style:{fontSize: 14}}}
                    value={profile.subtitle || ""}
                    onChange={(event) => {
                        setProfile({
                            ...profile,
                            subtitle: event.target.value
                        })
                    }}
                />
            </CollapseCell>
            {contents.map(({type, cardType, viewerType, key: contentsKey, title, link, textAlign, description, images, show}, index) =>
                type===null
                ?<DraggableCollapseCell
                    className={contentsKey} key={contentsKey}
                    {...{setOnMovingIdx, setOnTargetIdx, DropEventHandler, index}}
                    title={
                        <div style={{display:'flex', justifyContent:'space-between', alignItems:'flex-end'}}>
                            <div style={{width: 'calc(100% - 5px)', paddingRight: 5}}>{"항목 추가하기"}</div>
                            <DeleteIconButton {...{contents, setContents, index}}/>
                        </div>
                    }
                needToBlock={onMovingIdx!==null}>
                    <div className={"FlexLeftTop"}>
                        {["link", "image", "youtube", "text", "wtComic"].map(type=>
                            <RectButton key={type} onClick={()=>{
                                setContents([
                                    ...(contents.slice(0,index)),
                                    {
                                        ...contents[index],
                                        type, show:true,
                                        ...( type==='link'   ?{cardType: 'icon'}
                                            :type==='image'  ?{viewerType: 'slide'}
                                            :type==='text'   ?{}
                                            :type==='youtube'?{}
                                            :type==='wtComic'?{}
                                            :{}),
                                    },
                                    ...(contents.slice(index+1)),
                                ])
                            }}>

                                {type==="link"  ?<><LinkIcon/>         {"링크"}</>:
                                type==="image"  ?<><ImageIcon/>        {"이미지"}</>:
                                type==="text"   ?<><FormatAlignLeft/>  {"텍스트"}</>:
                                type==="youtube"?<><PlayCircleOutline/>{"동영상"}</>:
                                type==="wtComic"?<><BrushIcon/>        {"내 만화"}</>:<></>}
                            </RectButton>
                        )}
                    </div>
                </DraggableCollapseCell>:
                type==="link"
                ?<DraggableCollapseCell className={contentsKey} key={contentsKey} title={
                    <div style={{display:'flex', justifyContent:'space-between', alignItems:'flex-end'}}>
                        <div style={{display:'flex', justifyContent:'flex-start', alignItems:'flex-end'}}>
                            <div style={{paddingRight: 5}}>{"링크"}</div>
                            <ShowHideToggle {...{show, contents, setContents, index}}/>
                        </div>
                        <DeleteIconButton {...{contents, setContents, index}}/>
                    </div>
                } {...{setOnMovingIdx, setOnTargetIdx, DropEventHandler, index}}
                needToBlock={onMovingIdx!==null}>
                    <div className={"CardTypeSelector"}>
                        <div
                            className={`Selection Button ${cardType === 'icon'?'Selected':''}`}
                            onClick={()=>{
                                setContents([
                                    ...(contents.slice(0,index)),
                                    {...contents[index], cardType: 'icon'},
                                    ...(contents.slice(index+1)),
                                ])
                            }}
                        >{"아이콘형"}</div>
                        <div
                            className={`Selection Button ${cardType === 'card'?'Selected':''}`}
                            onClick={()=>{
                                setContents([
                                    ...(contents.slice(0,index)),
                                    {...contents[index], cardType: 'card'},
                                    ...(contents.slice(index+1)),
                                ])
                            }}
                        >{"카드형"}</div>
                    </div>
                    <div style={{marginTop:20, fontSize: 12}}>{"제목"}</div>
                    <TextField
                        fullWidth
                        placeholder={"보여지는 텍스트 제목"}
                        inputProps={{style:{fontSize: 14}}}
                        value={title || ""}
                        onChange={(event) => {
                            setContents([
                                ...(contents.slice(0,index)),
                                {...contents[index], title: event.target.value},
                                ...(contents.slice(index+1)),
                            ])
                        }}
                    />
                    <div style={{marginTop:20, fontSize: 12}}>{"링크 주소"}</div>
                    <TextField
                        fullWidth
                        inputMode={'url'}
                        placeholder={"연결되는 링크 주소"}
                        inputProps={{style:{fontSize: 14}}}
                        value={link || ""}
                        onChange={(event) => {
                            setContents([
                                ...(contents.slice(0,index)),
                                {...contents[index], link: event.target.value},
                                ...(contents.slice(index+1)),
                            ])
                        }}
                        onKeyDown={(e)=>{
                            if (e.key === 'Enter'){
                                e.target.blur();
                            }
                        }}
                        onBlur={()=>{
                            (async ()=>{
                                setIsLoading(true);
                                if (link && !link.startsWith('http')){
                                    setIsLoading(false);
                                    return;
                                }
                                let sitemeta = await readSiteMeta(link);
                                if (sitemeta){
                                    setContents([
                                        ...(contents.slice(0,index)),
                                        {...contents[index], title: decodeEntities(sitemeta.title), images: [{name:'thumbnail', image: sitemeta.thumbnail, lastModified: (new Date()).getTime()}].filter(row => row.image)},
                                        ...(contents.slice(index+1)),
                                    ])
                                }
                                setIsLoading(false);
                            })();
                        }}
                    />
                    <div style={{marginTop:20, fontSize: 12}}>{"썸네일"}</div>
                    <DragAndDrop
                        disabled={false}
                        isEditable={true}
                        file={images || []} 
                        setFile={(newData)=>{
                            setContents([
                                ...(contents.slice(0,index)),
                                {...contents[index], images: newData},
                                ...(contents.slice(index+1)),
                            ])
                        }} 
                        setIsImagesLoading={(boolean)=>{}}
                        placeholder={``}
                        maximum={10}
                    />
                    
                </DraggableCollapseCell>:
                type==="image"
                ?<DraggableCollapseCell className={contentsKey} key={contentsKey} title={
                    <div style={{display:'flex', justifyContent:'space-between', alignItems:'flex-end'}}>
                        <div style={{display:'flex', justifyContent:'flex-start', alignItems:'flex-end'}}>
                            <div style={{paddingRight: 5}}>{"이미지"}</div>
                            <ShowHideToggle {...{show, contents, setContents, index}}/>
                        </div>
                        <DeleteIconButton {...{contents, setContents, index}}/>
                    </div>
                } {...{setOnMovingIdx, setOnTargetIdx, DropEventHandler, index}}
                needToBlock={onMovingIdx!==null}>
                    <div className={"CardTypeSelector"}>
                        <div
                            className={`Selection Button ${viewerType === 'slide'?'Selected':''}`}
                            onClick={()=>{
                                setContents([
                                    ...(contents.slice(0,index)),
                                    {...contents[index], viewerType: 'slide'},
                                    ...(contents.slice(index+1)),
                                ])
                            }}
                        >{"크게 한장씩"}</div>
                        <div
                            className={`Selection Button ${viewerType === 'board'?'Selected':''}`}
                            onClick={()=>{
                                setContents([
                                    ...(contents.slice(0,index)),
                                    {...contents[index], viewerType: 'board'},
                                    ...(contents.slice(index+1)),
                                ])
                            }}
                        >{"작게 여러 장"}</div>
                    </div>
                    <DragAndDrop
                        disabled={false}
                        isEditable={true}
                        file={images || []} 
                        setFile={(newData)=>{
                            setContents([
                                ...(contents.slice(0,index)),
                                {...contents[index], images: newData},
                                ...(contents.slice(index +1)),
                            ])
                        }} 
                        setIsImagesLoading={(boolean)=>{}}
                        placeholder={``}
                        maximum={10}
                    />
                </DraggableCollapseCell>:
                type==="text"
                ?<DraggableCollapseCell className={contentsKey} key={contentsKey} title={
                    <div style={{display:'flex', justifyContent:'space-between', alignItems:'flex-end'}}>
                        <div style={{display:'flex', justifyContent:'flex-start', alignItems:'flex-end'}}>
                            <div style={{paddingRight: 5}}>{"텍스트"}</div>
                            <ShowHideToggle {...{show, contents, setContents, index}}/>
                        </div>
                        <DeleteIconButton {...{contents, setContents, index}}/>
                    </div>
                } {...{setOnMovingIdx, setOnTargetIdx, DropEventHandler, index}}
                needToBlock={onMovingIdx!==null}>
                    <div style={{marginTop:20, fontSize: 12}}>{"제목"}</div>
                    <TextField
                        fullWidth
                        placeholder={"큰 글씨로 보일 내용"}
                        inputProps={{style:{fontSize: 14}}}
                        value={title || ""}
                        onChange={(event) => {
                            setContents([
                                ...(contents.slice(0,index)),
                                {...contents[index], title: event.target.value},
                                ...(contents.slice(index+1)),
                            ])
                        }}
                    />
                    <div style={{marginTop:20, fontSize: 12}}>{"본문"}</div>
                    <TextField
                        fullWidth
                        placeholder={"작은 글씨로 보일 내용"}
                        inputProps={{style:{fontSize: 14}}}
                        multiline
                        rows={3}
                        value={description || ""}
                        
                        onChange={(event) => {
                            setContents([
                                ...(contents.slice(0,index)),
                                {...contents[index], description: event.target.value},
                                ...(contents.slice(index+1)),
                            ])
                        }}
                    />
                    <div style={{marginTop:20, fontSize: 12}}>{"텍스트 정렬"}</div>
                    <div className={"FlexLeftTop"} style={{justifyContent:"space-evenly", fontsize: 12}}>
                        <RectButton
                            style={{fontSize:12, ...(textAlign==='left'?{color:'white', backgroundColor: 'rgba(51,51,51,1)'}:{})}}
                            onClick={()=>{
                                setContents([
                                    ...(contents.slice(0,index)),
                                    {...contents[index], textAlign: 'left'},
                                    ...(contents.slice(index+1)),
                                ])
                            }}
                        >
                            <FormatAlignLeft/>{"왼쪽"}
                        </RectButton>
                        <RectButton
                            style={{fontSize:12, ...(textAlign==='center'?{color:'white', backgroundColor: 'rgba(51,51,51,1)'}:{})}}
                            onClick={()=>{
                                setContents([
                                    ...(contents.slice(0,index)),
                                    {...contents[index], textAlign: 'center'},
                                    ...(contents.slice(index+1)),
                                ])
                            }}
                        >
                            <FormatAlignCenter/>{"가운데"}
                        </RectButton>
                        <RectButton
                            style={{fontSize:12, ...(textAlign==='right'?{color:'white', backgroundColor: 'rgba(51,51,51,1)'}:{})}}
                            onClick={()=>{
                                setContents([
                                    ...(contents.slice(0,index)),
                                    {...contents[index], textAlign: 'right'},
                                    ...(contents.slice(index+1)),
                                ])
                            }}
                        >
                            <FormatAlignRight/>{"오른쪽"}
                        </RectButton>
                    </div>
                </DraggableCollapseCell>:
                type==="youtube"
                ?<DraggableCollapseCell className={contentsKey} key={contentsKey} title={
                    <div style={{display:'flex', justifyContent:'space-between', alignItems:'flex-end'}}>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'flex-end'}}>
                            <div style={{paddingRight: 5}}>{"동영상"}</div>
                            <ShowHideToggle {...{show, contents, setContents, index}}/>
                        </div>
                        <DeleteIconButton {...{contents, setContents, index}}/>
                    </div>
                } {...{setOnMovingIdx, setOnTargetIdx, DropEventHandler, index}}
                needToBlock={onMovingIdx!==null}>
                    <div style={{marginTop:20, fontSize: 12}}>{"링크 주소"}</div>
                    <TextField
                        fullWidth
                        inputMode={'url'}
                        placeholder={"연결되는 링크 주소"}
                        inputProps={{style:{fontSize: 14}}}
                        value={link || ""}
                        onChange={(event) => {
                            setContents([
                                ...(contents.slice(0,index)),
                                {...contents[index], link: event.target.value},
                                ...(contents.slice(index+1)),
                            ])
                        }}
                        onKeyDown={(e)=>{
                            if (e.key === 'Enter'){
                                e.target.blur();
                            }
                        }}
                        onBlur={()=>{
                            (async ()=>{
                                setIsLoading(true);
                                let youtubemeta = await readSiteMeta(link);
                                if (youtubemeta){
                                    setContents([
                                        ...(contents.slice(0,index)),
                                        {...contents[index], title: decodeEntities(youtubemeta.title), images: [{name:'thumbnail', image: youtubemeta.thumbnail, lastModified: (new Date()).getTime()}].filter(row => row.image)},
                                        ...(contents.slice(index+1)),
                                    ])
                                }
                                setIsLoading(false);
                            })();
                        }}
                    />
                </DraggableCollapseCell>:
                type==="wtComic"
                ?<DraggableCollapseCell className={contentsKey} key={contentsKey} title={
                    <div style={{display:'flex', justifyContent:'space-between', alignItems:'flex-end'}}>
                        <div style={{display:'flex', justifyContent:'flex-start', alignItems:'flex-end'}}>
                            <div style={{paddingRight: 5}}>{"내 만화 추가하기"}</div>
                            <ShowHideToggle {...{show, contents, setContents, index}}/>
                        </div>
                        <DeleteIconButton {...{contents, setContents, index}}/>
                    </div>
                } {...{setOnMovingIdx, setOnTargetIdx, DropEventHandler, index}}
                needToBlock={onMovingIdx!==null}>
                    {titles?.map(title => (
                    <div style={{
                        display:'flex', flexDirection:'row', justifyContent:'stretch', alignItems:'center',
                        borderBottom: '.75px rgb(241,241,241) solid',
                        padding:4,
                    }}>
                        <div style={{width:'100%'}}>{title.title}</div>
                        <div
                            style={{flexShrink:0}} className={"Button"}
                            onClick={()=>{
                                setContents([
                                    ...(contents.slice(0,index)),
                                    {...contents[index], type: 'link',
                                        images: [{name: 'thumbnail',image: title.thumbnail, lastModified: (new Date()).getTime()}],
                                        title: title.title, link: `/contents/${title.serviceId}/${title.titleId}`
                                    },
                                    ...(contents.slice(index+1)),
                                ])
                            }}
                        >{"선택"}</div>
                    </div>
                    ))
                    || <ErrorMessage containerStyle={{marginTop: "20px"}} />
                    }
                </DraggableCollapseCell>:<></>
            )}
        </div>

        <div className={"Preview"}>
            <PortfolioView portfolio={{addressid, profile, contents, template}} isPortfolioLoading={isPortfolioLoading}/>

        </div>
        
        <div className={"View"}>
            <PortfolioView portfolio={portfolioFromServer || {profile: {}, contents: [], template: {}}} isPortfolioLoading={isPortfolioLoading}/>

        </div>
        
        <div className={`FloatingButtonBox`}>
            {isEditingMode && !isPreviewMode
            ?<>
                <div className={"OutlineButton"} onClick={()=>{
                    setIsPreviewMode(true);
                }}>
                    {"미리보기"}
                </div>
                <div className={"FilledButton"} onClick={()=>{
                    (async()=>{
                        await savePortfolio();
                        setIsEditingMode(false);
                        logBehavior('save-portfolio');
                    })();
                }}>
                    {"저장"}
                </div>
            </>
            :isPreviewMode
            ?<>
                <div className={"FilledButton Button"} onClick={()=>{
                    setIsPreviewMode(false);
                }}>
                    {"편집으로 돌아가기"}
                </div>
            </>
            :!!portfolioFromServer && !isEditingMode && !isPreviewMode && portfolioFromServer.addressid === session.addressid
            ?<>
                <Share className={"OutlineButton Button"} 
                    log={{ what: 'share-portfolio', detail: {} }}
                    contents={<div className={"OutlineButton BUtton"}>
                        {'공유'}
                    </div>} 
                />
                <div className={"FilledButton Button"} onClick={()=>{
                    setIsEditingMode(true);
                }}>
                    {"편집"}
                </div>
            </>
        :<></>}
        </div>
        <LoadingCircle show={isLoading}/>
    </div>);
    
};

export default ProfileEdit;