import axios from "axios";
import ss from './Session';

export type TitleCardType = {
    serviceId: string, titleId: string,
    title: string, author: string, thumbnail: string,
    isValid: boolean, rejectReason: string | null,
    validatedAt?: number, createdAt: number
};

export const readUnivReport = async ({serviceId, titleId, episodeId}:{serviceId: string, titleId: string, episodeId?: string}) => {
    try{
        let res = await axios.post('https://challenge-api.webtoon.today/process-school-competition', {
            serviceId, titleId, episodeId
        }, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });

        if (res.data && res.data.code === 200){
            const latestReport = res.data.data.sort( ( a: TitleCardType, b: TitleCardType ) => b.createdAt - a.createdAt );
            return latestReport;

        }else{
            return null;
        }
    } catch (e) {
        return null;
    }
}

export const readUnivReportList = async ({year}:{year: string}): Promise<TitleCardType[] | null> => {
    try {
        let res = await axios.post('https://challenge-api.webtoon.today/process-school-competition/list', {
            year
        }, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });

        if ( res.data && res.data.code === 200) {
            return res.data.data;
        }else{
            return null;
        }
    } catch (e) {
        return null;
    }
}