import { useEffect, useState } from "react";
import { proxy } from "../../../Page/MarketAnalysis/MarketAnalysisFunctions";
import PopularMaterials from "./PopularMaterials";
import SearchBar from "./SearchBar";

import { platformAndTopicRank } from "../Util/PlatformTopicRank";

import './Styles/Search.scss'
import { Button, Dialog } from "@material-ui/core";
import { copyClipboard, sharingPage } from "../Util/Functions";
import { GroupAdd } from "@material-ui/icons";
import { useToastAlert } from '@webtoontoday/toast';

const keyToTabname: {[index: string]: string} = {
    naver: '네이버 인기 토픽',
    kakao: '카카페 인기 토픽',
    ridi: '리디 인기 토픽',
    bomtoon: '봄툰 인기 토픽',
    daum: '카웹 인기 토픽',
    toptoon: '탑툰 인기 토픽',
    toomics: '투믹스 인기 토픽'
}

const Search = ( ) => {
    const { toastAlert } = useToastAlert();

    const [ selectedTab, setSelectedTab ] = useState<string>('');
    const [ platformRankList, setPlatformRankList ] = useState<[string, string[]][]>([]);
    const [ openInviting, setOpenInviting ] = useState<boolean>(false);

    useEffect(() => {
        const platformAndTopicArray = Object.entries(platformAndTopicRank);
    
        const getRandomTopicRank = (): [string, string[]][] => {
            let randomTopicArray: [string, string[]][] = [];
            
            while (randomTopicArray.length < 3) {
                const randomIndex = Math.floor(Math.random() * Object.keys(platformAndTopicArray).length);
                
                if (randomTopicArray.indexOf(platformAndTopicArray[randomIndex]) < 0){
                    randomTopicArray.push(platformAndTopicArray[randomIndex]);
                }
            }
            
            return randomTopicArray

            
        }
    
        const generatedArray = getRandomTopicRank()
        setSelectedTab(generatedArray[0][0])
        setPlatformRankList(generatedArray)
    },[])

    return <div className={'TrendSearchArea'} >
        <div className={'TrendSearchContainer'} >
            <div className={'ContainerInner'} >
                <div className={'ResultLogoBox'} >
                    <img src={proxy('https://static.webtoon.today/ddah/logo-01.png')} alt={'logo-01'} style={{width: 120, margin: '0 auto'}} />
                </div>
                <div className={'Title'} >
                    {'웹툰 트렌드 분석'}
                </div>
                <div className={'SearchBarBox'}>
                    <SearchBar
                        searchType="trend"
                        style={{
                            padding: '10px 10px 10px 20px'
                        }}
                        iconStyle={{
                            fontSize: '2rem'
                        }}
                        placeholder={'궁금한 웹툰 토픽, 플랫폼을 입력해주세요.'}
                    />
                </div>
                <div className={'Tabs'} >
                    {!!selectedTab && platformRankList.map( ([key, ], index) => (
                        <div 
                            key={key}
                            className={`Tab ${selectedTab === key?'Selected':'Deselected'}`} 
                            style={{width: `${100 / 3}%`}}
                            onClick={() => setSelectedTab(key)}
                        >
                            {keyToTabname[key]}
                        </div>
                    ))}
                </div>
            </div>
            <Button className={'InvitingBox'} onClick={()=>setOpenInviting(true)} >
                <GroupAdd style={{marginRight: 10}} />
                {'친구 초대하기'}
            </Button>
        </div>
        <div className={'RecommendedTopicContainer'} >
            <div className={'ContainerInner'} >
                <PopularMaterials {...{
                    topics: platformAndTopicRank[selectedTab] || [], 
                    style:{
                        margin: '10px 10%',
                    },
                    rowStyle:{
                        padding: '24px 0',
                    }   
                }} />
            </div>
        </div>
        <Dialog open={openInviting} onClose={ () => setOpenInviting(false) } >
            <div style={{margin: '20px 30px', fontSize: '1rem'}} >
                <div style={{width: '100%',textAlign: 'center'}} >
                    <div style={{marginBottom: 8, width: '100%', wordBreak: 'keep-all'}}>
                        {`웹툰 트렌드 분석이 필요한 친구에게 초대장을 보내보세요!`}
                    </div>
                    <div  >
                        {`오직 `}<span style={{fontWeight: 'bold'}}>{'2명'}</span>{`의 친구만 초대할 수 있어요.`}
                    </div>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '0 20px'}}>
                    <Button 
                        fullWidth variant={'contained'} color={'primary'} disableElevation
                        style={{fontFamily: 'inherit', fontWeight: 400}}
                        onClick={ async () => navigator['share']
                            ?sharingPage({
                                title: '오늘의웹툰 신규 서비스!', text: `웹툰 트렌드 분석으로 초대합니다 :)`,
                                url: window.location.protocol + "//" + window.location.host + `/market/trendsearch`,
                                log: {what: 'invite-trendsearch', detail: {codeNumber: 'n/a'}}
                            })
                            :copyClipboard(`오늘의웹툰 신규 서비스! 웹툰 트렌드 분석으로 초대합니다 :)  ${window.location.protocol+"//"+window.location.host+('/market/trendsearch' || window.location.pathname)}`,
                            () => {
                                setOpenInviting(false)
                                toastAlert('복사를 성공했습니다.')
                            }, 
                            () => {
                                setOpenInviting(false)
                                toastAlert('복사를 실패했습니다.')
                            })
                        }
                    >
                        {'초대코드 공유하기'}
                    </Button>
                </div>
            </div>
        </Dialog>
    </div>
}

export default Search;