import {
    AnimatedAxis,
    AnimatedGrid,
    AnimatedLineSeries,
    AreaStack,
    XYChart
} from "@visx/xychart";
import moment from 'moment-timezone';
import { curveLinear } from '@visx/curve';
import { LegendItem, LegendOrdinal } from "@visx/legend";
import { scaleOrdinal } from "@visx/scale";
import './Styles/MultiLineChart.scss';
import { Tooltip } from "@material-ui/core";
import { useState } from "react";
import { Circle } from "@visx/shape";
import { unique } from "../../../Functions";

const MultiChart = ({
        width, height, data: givenData, chartType
    }:{
        width: number, height: number, chartType: 'line' | 'area',
        data: {[key:string]: number | string}[]
    }) => {
        const data = givenData.filter( row => row );
        
        const [ open, setOpen ] = useState<string>('');

        let leftAxisTickFormat = (d: string) => d
        
        const topicArray = Object.keys(data[0]).filter( topic => topic !== "period")

        const topicToIdx = Object.fromEntries( Object.entries(topicArray).map(([idx, topic]) => [topic,idx]) );
        let titlesLastRank = topicArray;
        switch (chartType) {

            case 'line':
                leftAxisTickFormat = (d: string) => `${5 - Number(d) < 5?`${6 - Number(d)}위`:'순위외'}`;

                titlesLastRank = new Array(5);
                for(const title in data[3]){
                    if(title === "period"){
                        continue;
                    }
                    if(Number(data[3][title]) <= 5){
                        titlesLastRank[Number(data[3][title]) - 1] = title;
                    }
                }

                break;

            case 'area' :

                break;
        }

        titlesLastRank = titlesLastRank.concat(topicArray).filter(unique).slice(0, 10);
        
        const colorScale: string[] = [
            'rgb(255, 187, 203)',
            'rgb(255, 226, 170)',
            'rgb(191, 239, 168)',
            'rgb(182, 205, 255)',
            'rgb(220, 186, 255)',
            'rgb(253, 148, 148)',
            'rgb(255, 242, 124)',
            'rgb(148, 214, 117)',
            'rgb(134, 165, 236)',
            'rgb(185, 136, 208)',

            'rgb(245, 177, 193)',
            'rgb(245, 216, 160)',
            'rgb(181, 229, 158)',
            'rgb(172, 195, 245)',
            'rgb(210, 176, 245)',
            'rgb(233, 138, 138)',
            'rgb(215, 232, 94)',
            'rgb(118, 174, 77)',
            'rgb(104, 145, 206)',
            'rgb(145, 106, 178)',
        ]
        
        return(
            <div className={'MultiLineChartArea'} key={`${chartType}`} >
                <XYChart
                    height={height}
                    width={width * 0.79}
                    xScale={{ type: 'point' }}
                    yScale={{ type: 'linear', zero: false, nice: true }}
                    margin={{ left: 40, top: 30, bottom: 30, right: 15 }}
                >
                    <AnimatedGrid
                        columns={false}
                        numTicks={0}
                    />
                    <AnimatedAxis
                        orientation="bottom"
                        numTicks={5}
                        strokeWidth={2}
                        tickFormat={(d: string) => moment(d).format('MM.DD')}
                        hideZero={false}
                        hideTicks={true}
                        stroke={'rgb(117,117,117)'}
                    />
                    <AnimatedAxis
                        orientation="left"
                        numTicks={4}
                        strokeWidth={1}
                        labelClassName={'YLabel'}
                        hideZero={false}
                        hideTicks={true}
                        stroke={'rgb(117,117,117)'}
                        tickFormat={ leftAxisTickFormat }
                        tickLabelProps={()=>({
                            style: {
                                wordBreak: 'keep-all'
                            }
                        })}
                    />
                    {chartType === 'line' && data.length > 0 && topicArray.length > 0
                        ?<>
                            {topicArray.map( (topic, idx) =>{
                                const iterateData = data.map( week => ({period: week.period, [topic]: week[topic]}))
                                return <AnimatedLineSeries
                                    key={`${chartType}:${topic}`}
                                    stroke={colorScale[topicArray.indexOf(topic)]}
                                    strokeWidth={2}
                                    
                                    dataKey={topic}
                                    data={iterateData}
                                    curve={curveLinear}
                                    xAccessor={(d) => new Date(`${d?.period}T00:00:00`)}
                                    yAccessor={(d) => `${6 - Number(d[topic])}` } 
                                />}
                            )}
                        </>
                    :chartType === 'area'
                        ?<AreaStack curve={curveLinear} >
                            {topicArray.map( (topic, idx) =>
                                <AnimatedLineSeries
                                    key={`${chartType}:${topic}`}
                                    dataKey={`area:${topic}`}
                                    data={data}
                                    xAccessor={(d) => new Date(`${d?.period}T00:00:00`)}
                                    yAccessor={(d) => d[topic]}
                                    fillOpacity={0.4}
                                    fill={colorScale[9 - idx]} 
                                />
                            )}
                        </AreaStack>
                        :<></>
                    }
                    {chartType === 'line'
                    ?topicArray.map( (topic, idx) =>{
                        const iterateData = data.map( (week) => ({period: week.period, [topic]: week[topic]}))
                        return <g>
                            {iterateData
                                .map( (week, index) => [week, index] as const)
                                .filter(([week, index]) => Number(week[topic]) <= 5 )
                                .map( ([week, index]) => (
                                <Circle
                                    key={week + topic}
                                    r={3 }
                                    cx={ index / 4 * (width - 40  + (width < 350?-15:0)) + 40 }
                                    cy={ (Number(week[topic]) - 1) / 6 * (height - 17.5) + 30 }
                                    fill={colorScale[idx]}
                                    stroke="transparent"
                                />
                            ))}
                        </g>;
                    }):<></>}
                </XYChart>
                <div className={'LegendBox'}>
                    <LegendOrdinal 
                        scale={scaleOrdinal({
                            domain: titlesLastRank,
                            range: titlesLastRank.map(topic =>  colorScale[topicToIdx[topic] as unknown as number])
                        })} 
                        direction={'column'}
                        legendLabelProps={{
                            style:{
                                margin: 0,
                            }
                        }}
                        shapeMargin={'1px 2px 0 1px'}
                    >
                        {(labels) => (
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            {labels.map((label, i) => (
                                <LegendItem
                                    key={`legend-quantile-${i}`}
                                    onClick={(events: React.MouseEvent<HTMLElement>) => {
                                        if (events) {
                                            setOpen(`${label.text}`);
                                        }
                                    }}
                                    style={{cursor: 'pointer'}}
                                >
                                    <svg className={'visx-legend-shape'} >
                                    <rect className={'visx-legend-shape-square'} fill={label.value} />
                                    </svg>
                                        <Tooltip 
                                            title={label.text} open={open === label.text?true:false} onClose={ () => setOpen('')}
                                            enterDelay={500} leaveDelay={300} arrow={true} placement={'left'}
                                        >
                                            <div className={'visx-legend-label'}>{label.text}</div>
                                        </Tooltip>
                                </LegendItem>
                            ))}
        
                        </div>
                        )}
                    </LegendOrdinal>
                </div>
            </div>
        )
    }

export default MultiChart;