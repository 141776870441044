import React from 'react';
import './ContestApplication.scss';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, TextField, Tooltip } from '@material-ui/core';

import { logBehavior } from '../../../../Data/Behavior';
import { fn, randomString, validateEmailForm } from '../../../../Functions';
import { verifyEmail, verifyOtp } from '../../../../Data/Email';
import { readLoginInfo } from '../../../../Data/Account';
import { AddPhotoAlternate, CheckBox, Close, HelpOutline, HighlightOff, KeyboardArrowDown } from '@material-ui/icons';
import { consentList, currentTermsofuseVersion } from '../../../Analytics/AnalysisApplicationConstants';
import { updateProfile } from '../../../../Data/Profile';
import { createTitle, getTitle, updateTitle } from '../../../../Data/Title';
import { AnalyticsSubmit } from '../../../../Data/AnalyticsSubmit';
import { createImageWithBase64, getThumbnail } from '../../../../Data/Image';
import { createEpisode } from '../../../../Data/Episode';
import { createContents } from '../../../../Data/Contents';

import MultiManuscriptUpload from '../../../../Component/MultiManuscriptUpload';
import DragAndDrop from '../../../../Component/DragAndDropForAds';
import LoadingCircle from '../../../../Component/LoadingCircle';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import AdsSlides from '../../../../Component/AdsSlides';
import AutocompletableTextField from '../../../../Component/AutoCompletableTextField';
import ChannelService from '../../../../Component/ChannelService';
import { useSession, useSignIn } from '../../../../Recoil/Session/Session';
import { useToastAlert } from '@webtoontoday/toast';

const isOnlyEnglishAndNumber = (text) => {
    const result = /^[a-zA-Z0-9_]*$/.test(text);
    return result;
};

const ContestApplication = () => {
    const { session, sessionRefresh } = useSession();
    const { EmailSignin, EmailTokenSignin } = useSignIn();

    const { toastAlert } = useToastAlert();

    const [email, setEmail] = React.useState("");
    const [onVerifying, setOnVerifying] = React.useState(false);
    const [otp, setOtp] = React.useState("");
    const [isOTPVerifyingFailed, setIsOTPVerifyingFailed] = React.useState(false);
    const [isEmailVerified, setIsEmailVerified] = React.useState(false);
    const [school, setSchool] = React.useState("");

    const [name, setName] = React.useState("")
    const [addressid, setAddressid] = React.useState(randomString(16));

    const [title, setTitle] = React.useState("");
    const [titleId, setTitleId] = React.useState(randomString(16));

    const [manuscript, setManuscript] = React.useState([]);
    const [previewManuscript, setPreviewManuscript] = React.useState([]);

    const [thumbnail, setThumbnail] = React.useState(null);
    const [description, setDescription] = React.useState('');
    const [advertisementCuts, setAdvertisementCuts] = React.useState([]);
    
    const [imgSrc, setImgSrc] = React.useState('');
    const [cropModalOpen, setCropModalOpen] = React.useState(false);
    const [croppedImage, setCroppedImage] = React.useState('');
    const [cropBoxSize, setCropBoxSize] = React.useState({});
    const cropperRef = React.useRef(null);
    
    const [isImagesLoading, setIsImagesLoading] = React.useState([]);

    const [isLogin, setIsLogin] = React.useState(false);
    const [hasUnfilledField, setHasUnfilledField] = React.useState(false);

    const [isExampleAdsCutOpen, setIsExampleAdsCutOpen] = React.useState(false);
    const [errorPopupOpen, setErrorPopupOpen] = React.useState(false);

    const [popupOpen, setPopupOpen] = React.useState(false);

    const [applicationStep, setApplicationStep] = React.useState(0);
    const [errorMessage, setErrorMessage] = React.useState("");

    const [isLoading, setIsLoading] = React.useState(false);

    const [submitted, setSubmitted] = React.useState(false);

    const [openEmailLogin, setOpenEmailLogin] = React.useState(false);

    const [appliedEmail, setAppliedEmail] = React.useState("");
    const [onAppliedEmailVerifying, setOnAppliedEmailVerifying] = React.useState(false);
    const [otpForCheking, setOtpForChecking] = React.useState("");
    const [isOtpForCheckingFailed, setIsOtpForChekingFailed] = React.useState(false);
    const [isAppliedEmailVerified, setIsAppliedEmailVerified] = React.useState(false);

    const applicationErrorHandler = (message) => {
        setErrorMessage(message)
        setErrorPopupOpen(true);

        setApplicationStep(0);
    };
    
    React.useEffect(() => {
        if (session.memberState === 'regular') {
            setEmail(session.email);
            setName(session.name);
            setIsEmailVerified(true);
            setAddressid(session.addressid);

            setIsLogin(true);
        }
        
    },[session.addressid, session.email, session.memberState, session.name])

    const applicationProcess = async () => {
        setApplicationStep(1);
        
        let {isProfileExist} = await readLoginInfo(email);
        // 세션 생성 && 프로필(회원가임)
        if (session.memberState !== 'regular') {
            if ( !isProfileExist ) {

                const newUser = await EmailSignin({
                    email, otp, addressid, name
                });
                
                if (newUser) {
                    // await updateProfile({name, email, certificatedName, certificatedBirthday, certificatedPhoneNumber});
                    await updateProfile({name, email});
                    await sessionRefresh()
                    
                    setTitleId(session.titleId);
                    
                } else {
                    applicationErrorHandler("새로운 아이디 생성에 실패했습니다.");
                    return;
                };
            };
        };

        
        // 작품 등록 (title, episode, content 생성)
        setApplicationStep(2);
        const themeColor = 'rgb(255,255,255)';
        const themeTemplate = 'circle';
        
        let manuscripts = [];
        
        if (manuscript.length > 0 && manuscript[0][0] && manuscript[0][1]){
            manuscripts.push(manuscript);
        };
        
        const searchTitle = getTitle(addressid, titleId);

        if (searchTitle.length > 0 ) {
            applicationErrorHandler('동명의 작품이 이미 존재합니다.')
            return;
        };
        
        const titleKey = await createTitle({
            serviceId: addressid, titleId, title, themeColor, themeTemplate, 
            slides: advertisementCuts, thumbnail, description
        });
        
        if (titleKey) {
            logBehavior("create-title", {serviceId: addressid, titleId, title, themeColor, themeTemplate, slides: advertisementCuts, thumbnail, description});
            
        } else {
            applicationErrorHandler('작품 등록에 실패했습니다.')
            return;
        }

        const [serviceId, ] = titleKey.split(':');

        // 에피소드, 컨텐츠 만들기
        let episodeCount = 0;
        
        setApplicationStep(3);
            
        for (let i = 0; i < manuscripts.length; i++) {
            const generatedTitleThumbnail = await getThumbnail([manuscripts[i][0][1]])
            if(!generatedTitleThumbnail) {
                applicationErrorHandler('에피소드 썸네일 생성을 실패했습니다.')
                return;
            };
            const thumbnail = generatedTitleThumbnail[0];
            
            let title = `${i+1}화`;
            const episodeKey = await createEpisode({
                serviceId: addressid, titleId, episodeId: `${i+1}`,
                title, thumbnail
            });

            if (episodeKey){
                let [,,episodeId] = episodeKey.split(':');
                logBehavior("create-episode", {serviceId, titleId, episodeId, title, thumbnail});
                
                
                let contentImages = [];
                for (let [, image] of manuscripts[i]) {
                    if (image) {
                        contentImages.push(image);
                    };
                };

                const contentKey = await createContents({
                    contents: {
                        serviceId: addressid, titleId, episodeId: `${i+1}`,
                        data: {images: contentImages},
                    }
                });

                if (contentKey) {
                    episodeCount++;
                } else {
                    applicationErrorHandler('콘텐츠 등록에 실패했습니다.');
                    return;
                };
                
                const [,,,snapshotId] = contentKey.split(':');

                logBehavior('create-contents', {serviceId, titleId, episodeId, snapshotId});


            } else {
                applicationErrorHandler('에피소드 등록에 실패했습니다.');
                return;
            };
        };

        const updateKey = await updateTitle({
            serviceId: addressid, titleId, title, themeColor, themeTemplate, 
            slides: advertisementCuts, thumbnail, description, exposure: 'unlist'
        })

        if (updateKey) {
            logBehavior("update-title", {
                serviceId: addressid, titleId, title, themeColor, themeTemplate, 
                slides: advertisementCuts, thumbnail, description, exposure: 'unlist'
            })
        } else {
            applicationErrorHandler('작품 공개범위 업데이트에 실패했습니다.')
        };

        setIsLoading(true)

        setApplicationStep(4);
        
        const newTitle = await getTitle(addressid, titleId);
        if(newTitle.length < 1) {
            applicationErrorHandler("신청한 작품의 조회를 실패했습니다.")
            return;
        }

        const episodeRange = [`${1}`, `${episodeCount || 1}`];

        const formJson = {
            name: session.name, episodeRange, service: 'schoolCompetition',
            title: newTitle[0].title, serviceId: newTitle[0].serviceId, titleId: newTitle[0].titleId, 
            introduction: newTitle[0].description, advertisementCuts, school,
            consentList: [
                ...consentList
            ],
            currentTermsofuseVersion
        };


        logBehavior('input-submitted',{})
        const result = await AnalyticsSubmit(formJson);
        
        if (result) {
            setPopupOpen(true);

            const { email, name } = session;

            logBehavior('schoolCompetition', {detail: {name, email}, title: newTitle[0].title});
        } else {
            setErrorMessage("데이터 전송이 실패했습니다")
            setErrorPopupOpen(true);
        };

        setApplicationStep(0);
        
        setIsLoading(false);
    };

    const imageHandler = (event) => {
        const input = document.createElement('input');
        input.setAttribute('type','file');
        input.setAttribute('accept','image/*');
        input.click();

        input.addEventListener('change', async(e)=>{
            setIsLoading(true);

            if (input.files && input.files.length > 0) {
                const reader = new FileReader();

                reader.readAsDataURL(input.files[0]);

                reader.addEventListener("load", () => {
                    const image = reader.result;

                    setImgSrc(image);
                    setCropModalOpen(true);
                });
            };

            setIsLoading(false);
        });
    };
    
    const onCrop = (e) => {
        const imageElement = cropperRef?.current;
        const cropper = imageElement?.cropper;
        const cropViewBox = cropper.getCroppedCanvas();

        setCroppedImage(cropViewBox.toDataURL());
        const {width, height} = cropViewBox
        setCropBoxSize({width, height})
    };

    const cropModalClose = () => { setCropModalOpen(false) }

    const previewClose = () => {
        setPreviewManuscript(null)
    };

    const popupClose = () => {
        
        setSubmitted(true)
        setPopupOpen(false);

        fn.goto(`/portfolio/${session.addressid}`);
    };
    
    return (
        <div className={"ContestApplicationSubmitArea"}>
            <div className={"InfomationForm"}>
                <div style={{marginBottom: 60, display: 'flex', justifyContent: 'center'}} id={'Apply'}>
                    <img src={'https://static.webtoon.today/ddah/2022university-apply-button.svg'} alt={'applyButton'} style={{maxWidth: 'calc(100% - 60px)'}} />
                </div>
                <div className={`FormGrid`} >
                    <div className={"GridItem"}>
                        <div className={'ItemBox'} >
                            <label className={"Labels"}>{"이메일 "}
                                <span className={"redAsterisk"}>{"*"}</span>
                            </label>
                            <div className={`EmailVerifying ${hasUnfilledField && !isEmailVerified?'UnfilledField':''}`}>
                                <TextField
                                    onBlur={()=>{
                                        if ( email !== "" ) {
                                            logBehavior('input-email',{})
                                        }
                                    }}
                                    disabled={ onVerifying || isEmailVerified || submitted } 
                                    className={"Tags"}
                                    value={email}
                                    size={"small"}
                                    onChange={(event) => setEmail(event.target.value)}
                                    style={{flex: 1}}
                                />
                                <Button color={'primary'} disableElevation onClick={ async () => {
                                        setIsLoading(true);

                                        const result = await verifyEmail(email);
                                        if (result) {
                                            logBehavior('input-onVerifying',{});
                                            setOnVerifying(true);
                                        };

                                        setIsLoading(false);
                                    }}
                                    className={`VerifyingButton`}
                                    disabled={onVerifying || isEmailVerified || !validateEmailForm(email)}
                                >
                                    {"메일인증"}
                                </Button>
                            </div>
                            {!onVerifying && isEmailVerified
                                ?<div className={"Formli"} style={{fontSize: '0.8rem', color: 'rgba(0,176,240,1)'}}>
                                    {"* 이메일이 인증되었습니다."}
                                </div>
                                :<></>
                            }
                        </div>
                    </div>
                    <div className={`GridItem`}>
                        <div className={'ItemBox'} >
                            <label className={"Labels"}>{"학교명 "}
                                <span className={"redAsterisk"}>{"*"}</span>
                            </label>
                            <AutocompletableTextField
                                candidates={[
                                    {pattern: /[가-힇]+학$/, prefix: '', postfix: '교'},
                                    {pattern: /[가-힇]+대$/, prefix: '', postfix: '학교'},
                                    {pattern: /[가-힇]+고$/, prefix: '', postfix: '등학교'},
                                    {pattern: /[가-힇]+[가-닿댁-힇] *$/, prefix: '', postfix: '대학교'},
                                    {pattern: /[가-힇]+[가-곟곡-힇] *$/, prefix: '', postfix: '고등학교'},
                                ]}
                                antiPattern={/학교|예고|한예종/}
                                onBlur={()=>{
                                    if ((school !== "")){
                                        logBehavior('input-school',{})
                                    }
                                }}
                                className={`Tags ${hasUnfilledField && !school?'UnfilledField':''}`}
                                disabled={submitted}
                                type="text"
                                value={school}
                                size={"small"}
                                variant={"outlined"}
                                InputProps={{
                                    style: {
                                        borderRadius: 4,
                                    }
                                }}
                                onChange={(event) => setSchool(event.target.value)}
                            />
                            <label style={{fontSize: '0.8rem', color: 'rgb(196,196,196)'}}>
                                {"재학 중이거나 졸업한 학교 이름을 써주세요."}
                            </label>
                        </div>
                    </div>
                    {onVerifying
                        ?<>
                            <div className={"GridItem"}>
                                <div className={'ItemBox'} >
                                    <label className={"Labels"}>{"인증키"}
                                        <span className={"redAsterisk"}>{"*"}</span>
                                    </label>
                                    <div className={"VerifiedOtp"}>
                                        <TextField
                                            onBlur={()=>{
                                                if ( otp !== "" ){
                                                    logBehavior('input-otp',{})
                                                }
                                            }}
                                            className={"Tags"} 
                                            value={otp}
                                            size={"small"}
                                            onChange={(event) => setOtp(event.target.value)}
                                            style={{flex: 1}}
                                        />
                                        <Button color={"primary"} disableElevation onClick={async () => {
                                                setIsLoading(true);
                                                setIsOTPVerifyingFailed(false);

                                                const {isProfileExist} = await readLoginInfo(email);

                                                if  ( isProfileExist ) {
                                                    const response = await EmailTokenSignin(email, otp);
                                                    
                                                    if (response) {
                                                        setIsEmailVerified(true);
                                                        logBehavior('input-emailVerified',{});
                                                        setOnVerifying(false);
                                                        
                                                        setName(session.name?session.name:name);
                                                        setAddressid(session.addressid?session.addressid:addressid);

                                                        setIsLoading(false);
                                                        setIsLogin(true);
                                                        return;
                                                    } else {
                                                        setIsOTPVerifyingFailed(true);
                                                        setIsLoading(false);
                                                        return;
                                                    }
                                                };

                                                const result = await verifyOtp(email, otp)
                                                if (result) {
                                                    setIsEmailVerified(true);
                                                    logBehavior('input-emailVerified',{})
                                                    setOnVerifying(false);
                                                }  else {
                                                    setIsOTPVerifyingFailed(true);
                                                }
                                                setIsLoading(false);
                                            }}
                                            className={"VerifiedOtpButton"}
                                        >
                                            {"인증하기"}
                                        </Button>
                                    </div>
                                    {isOTPVerifyingFailed
                                        ?<div className={"Formli"} style={{fontSize: '0.8rem', color: 'red'}}>
                                            {"* 인증에 실패했어요. 인증키를 다시 확인해주세요."}
                                        </div>
                                        :<></>
                                    }
                                </div>
                            </div>
                            <div className={"GridItem"}>
                            </div>
                        </>
                        :<></>}
                    <div className={`GridItem`}>
                        <div className={'ItemBox'} >
                            <label className={"Labels"}>{"이름 or 필명 "}
                                <span className={"redAsterisk"}>{"*"}</span>
                            </label>
                            <TextField
                                onBlur={()=>{
                                    if ((name !== "")){
                                        logBehavior('input-name',{})
                                    }
                                }}
                                className={`Tags ${hasUnfilledField && !name?'UnfilledField':''}`}
                                disabled={submitted || isLogin}
                                type="text"
                                value={name}
                                size={"small"}
                                variant={"outlined"}
                                InputProps={{
                                    style: {
                                        borderRadius: 4,
                                    }
                                }}
                                onChange={(event) => setName(event.target.value)}
                            />
                        </div>
                    </div>
                    <div className={`GridItem`}>
                        <div className={'ItemBox'} >
                            <label className={"Labels"}>{"영문 이름 or 영문 필명 "}
                                <span className={"redAsterisk"}>{"*"}</span>
                            </label>
                            <TextField
                                onBlur={()=>{
                                    if ((addressid !== "")){
                                        logBehavior('input-addressid',{})
                                    }
                                }}
                                className={`Tags ${hasUnfilledField && !addressid?'UnfilledField':''}`}
                                disabled={submitted || isLogin}
                                type="text"
                                value={addressid}
                                size={"small"}
                                variant={"outlined"}
                                InputProps={{
                                    style: {
                                        borderRadius: 4,
                                    }
                                }}
                                onChange={(event) => {
                                    if(/\s/.exec(event.target.value)){
                                        setAddressid(event.target.value.replace(/\s/g,'_'))
                                        return;
                                    };

                                    setAddressid(event.target.value);
                                }}
                            />
                            {session.memberState !== 'regular'
                                ?<label style={{fontSize: '0.8rem', color: `${isOnlyEnglishAndNumber(addressid)?'rgb(196,196,196)':'red'}`}}>
                                    {isOnlyEnglishAndNumber(addressid)
                                        ?"랜덤으로 생성된 이름이에요. 수정이 가능해요."
                                        :"영어, 숫자만 사용할 수 있어요."
                                    } 
                                </label>
                                :<></>
                            }
                        </div>
                    </div>
                    <div className={`GridItem`}>
                        <div className={'ItemBox'} >
                            <label className={"Labels"}>{"작품 제목"}
                                <span className={"redAsterisk"}>{" *"}</span>
                            </label>
                            <TextField
                                onBlur={()=>{
                                    if ((title !== "")){
                                        logBehavior('input-title',{})
                                    }
                                }}
                                className={`Tags ${hasUnfilledField && !title?'UnfilledField':''}`}
                                disabled={submitted}
                                type="text"
                                value={title}
                                size={"small"}
                                variant={"outlined"}
                                InputProps={{
                                    style: {
                                        borderRadius: 4,
                                        borderColor: 'white',
                                    }
                                }}
                                onChange={(event) => setTitle(event.target.value)}
                            />
                        </div>
                    </div>
                    <div className={`GridItem`}>
                        <div className={'ItemBox'} >
                            <label className={"Labels"}>{"작품 영문명"}
                                <span className={"redAsterisk"}>{" *"}</span>
                            </label>
                            <TextField
                                onBlur={()=>{
                                    if ((titleId !== "")){
                                        logBehavior('input-titleId',{})
                                    }
                                }}
                                className={`Tags ${hasUnfilledField && !titleId?'UnfilledField':''}`}
                                disabled={submitted}
                                type="text"
                                value={titleId}
                                size={"small"}
                                variant={"outlined"}
                                InputProps={{
                                    style: {
                                        borderRadius: 4,
                                    }
                                }}
                                onChange={(event) => {
                                    if(/\s/g.exec(event.target.value)){
                                        setTitleId(event.target.value.replace(/\s/g,'_'))
                                        return;
                                    };

                                    setTitleId(event.target.value)
                                }}
                            />
                            <label style={{fontSize: '0.8rem', color: `${isOnlyEnglishAndNumber(titleId)?'rgb(196,196,196)':'red'}`}}>
                                {isOnlyEnglishAndNumber(titleId)
                                    ?"랜덤으로 생성된 이름이에요. 수정이 가능해요."
                                    :"영어, 숫자만 사용할 수 있어요."
                                }
                            </label>
                        </div>
                    </div>
                </div>
                <div className={'ManuscriptsInfo'} style={{marginBottom: 50}}>
                    <div className={`Manuscripts`}>
                        <label className={`ManuscriptLabels`} >
                            <label className={"Labels"}>{"원고 "}
                                <span className={"redAsterisk"}>{"*"}</span>
                            </label>
                            <div>
                                <Button className={'Button'} onClick={ () => setManuscript([]) } >
                                    {'전체 삭제'}
                                </Button>
                                <Button 
                                    className={'Button'} style={{marginLeft: 10}}
                                    onClick={ (e) => {
                                        setPreviewManuscript(manuscript);
                                    }}
                                >
                                    {'원고 미리보기'}
                                </Button>
                            </div>
                        </label>
                        <div className={`ManuscriptUploadArea ${hasUnfilledField && manuscript.length < 1 ?'UnfilledManuscript':''}`}>
                            <MultiManuscriptUpload {...{
                                manuscript: manuscript, setManuscript: setManuscript, setIsLoading, setIsImagesLoading
                            }}/>
                        </div>
                    </div>
                    <label className={'SubLabels'} >
                        {'가로 690px 이상의 이미지 파일, 혹은 ZIP 파일을 올려주세요.'}
                    </label>
                </div>
                <div className={`TitleThumbnail`} style={{marginBottom: 50}} >
                    <label className={'Labels'} >
                        {'작품 썸네일'} 
                        <span className={'redAsterisk'}>{" * "}</span>
                    </label>
                    <div className={`${hasUnfilledField && !thumbnail?'UnfilledField':''} ThumbnailBox ${thumbnail?'Include':''}`} onClick={!thumbnail?imageHandler:()=>{}} >
                        {thumbnail
                            ?<HighlightOff style={{position: 'absolute', top: 0, right: -25, color: 'rgb(196,196,196)', cursor: 'pointer'}} onClick={(e)=>{
                                e.preventDefault();
                                setThumbnail(null);
                            }} />
                            :<></>}
                        <div className={`BoxInner ${thumbnail?'ImageBoxInner':''}`} >
                            {thumbnail
                            ?<img src={thumbnail} alt={'thumbnail'} style={{height: 118, width: 190, objectFit:'contain'}} />
                            :<AddPhotoAlternate fontSize={'large'} style={{color: 'rgb(196,196,196)'}} />}
                        </div>
                    </div>
                    <label className={'SubLabels'} >
                        <span>{"(가로세로 600*600 이상의 이미지를 넣어주세요.)"}</span>
                    </label>
                </div>
                <div style={{marginBottom: 50}} >
                    <label className={'Labels'}>{'줄거리'}</label>
                    <span style={{color: 'rgb(0,176,240)', marginLeft: 10, fontSize: '0.875rem'}}>{'선택사항'}</span>
                    <div style={{marginTop: 10, backgroundColor: 'rgb(255,255,255)'}}>
                        <TextField
                            variant={'outlined'} fullWidth multiline minRows={3} maxRows={3}
                            value={description}
                            onChange={ (e) => { 
                                if (e.target.value.length > 500) {
                                    toastAlert('줄거리는 500자까지 입력할 수 있어요.', 3000);
                                    return;
                                };

                                setDescription(e.target.value)
                            }}
                        />
                    </div>
                    <label className={'SubLabels'} >
                        <span>{"500자까지 입력할 수있어요."}</span>
                    </label>
                </div>
                <div className={`AdsCutUl`}>
                    <div className={`AdsCutli ${hasUnfilledField && (advertisementCuts.length < 1)?'UnfilledField':''}`}>
                        <div style={{fontWeight: 'bold', marginBottom: 20}}>
                            {`홍보컷`}
                            <span className={'redAsterisk'}>{" * "}</span>
                            {`(가로세로 600*600 이상의 이미지를 넣어주세요.)`}
                        </div>
                        <div style={{marginBottom: 12}}>
                            {'데이터분석 서비스 이용시 예상 독자에게 보여질 광고 이미지입니다.'}
                        </div>
                        <div style={{marginBottom: 20}}>
                            {'내가 독자라면 이 만화는 더 보고 싶다는 생각이 드는 홍보컷을 만들어주세요.'}
                        </div>
                        <DragAndDrop
                            disabled={submitted}
                            isEditable={true}
                            file={advertisementCuts}
                            setFile={(newData)=>setAdvertisementCuts(newData)}
                            setIsImagesLoading={(boolean)=>{setIsImagesLoading(boolean)}}
                            maximum={10}
                        />
                        <div className={'RequestAndExample'}>
                            <div className={'Explanation'} onClick={()=>setIsExampleAdsCutOpen(true)}>
                                <span className={'IconAndText'} ><HelpOutline style={{fontSize: '1.1rem', marginRight: 2}} /></span>
                                <span className={'IconAndText'} >{'내 홍보컷 미리보기'}</span>
                            </div>
                            <Dialog open={isExampleAdsCutOpen} onClose={() => setIsExampleAdsCutOpen(false)}>
                                <DialogTitle style={{padding: '23px 30px 20px'}}>
                                    {"내 홍보컷 미리보기"}
                                    <div style={{fontSize: '0.8rem', whiteSpace: 'pre-line', marginTop: '5px'}}>{`예상 독자에게 SNS 광고를 통해 보여지는 모습이에요.`}</div>
                                </DialogTitle>
                                <DialogContent>
                                    <AdsSlides title={{serviceId: addressid, title, slides: advertisementCuts, description}} />
                                </DialogContent>
                                <DialogActions>
                                    <Button variant={"contained"} disableElevation className={"PopupButton"}
                                        onClick={()=>setIsExampleAdsCutOpen(false)} color={"primary"} >
                                        {"확인"}
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </div>
                    </div>
                </div>
                <div className={"AgreementsArea"}>
                    <div className={"ClickArea"}>
                        <div className={"ConsentArea"}>
                            <div className={`CheckRow`}>
                                <Tooltip title={"필수 선택사항이에요."}>
                                    <CheckBox style={{color:"rgba(0,176,240)", fontSize:'1.2rem', marginRight:'0.2rem'}}/>
                                </Tooltip>
                                <div><span onClick={(e) => fn.goto(currentTermsofuseVersion)} style={{textDecoration: 'none', color: 'rgb(0,176,240)', cursor: 'pointer'}}>{"서비스 제공 약관"}</span>{'에 동의합니다.'}</div>
                            </div>
                            {consentList.map( consent => (
                                <div className={`CheckRow`} 
                                    key={consent}>
                                    <Tooltip title={"필수 선택사항이에요."}>
                                        <CheckBox style={{color:"rgba(0,176,240)", fontSize:'1.2rem', marginRight:'0.2rem'}}/>
                                    </Tooltip>
                                    {consent}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className={`PopupArea`}>
                    <Button
                        variant={"contained"} disableElevation color={"primary"}
                        type={"submit"} 
                        className={`SubmitButton`}
                        onClick={ async () => {
                            setIsLoading(true);

                            logBehavior('click-applicationbutton', {
                                submitted, title, titleId,
                                titleIdIsOnlyEnglishAndNumber: isOnlyEnglishAndNumber(titleId),
                                name, addressid, description,
                                addressIdIsOnlyEnglishAndNumber: isOnlyEnglishAndNumber(addressid),
                                isEmailVerified, thumbnail, advertisementCuts, school,
                                manuscript,
                            })

                            const DOM = document.querySelector('.InfomationForm')
                            const offsetY = window.pageYOffset;
                            const DOMTop = DOM.getBoundingClientRect().top

                            if (   submitted
                                || !title
                                || !titleId
                                || !isOnlyEnglishAndNumber(titleId)
                                || !name
                                || !addressid
                                || !isOnlyEnglishAndNumber(addressid)
                                || !isEmailVerified
                                || !school
                                || !thumbnail
                                || advertisementCuts.length === 0 ) {

                                setHasUnfilledField(true);
                                window.scrollTo({top: offsetY + DOMTop , behavior: 'smooth'});
                                toastAlert('필수 정보를 입력해 주세요.', 3000);
                                setIsLoading(false);
                                return;
                            };
                            
                            if ( !manuscript[0] ) {
                                window.scrollTo({top: offsetY + DOMTop , behavior: 'smooth'});
                                toastAlert(`원고를 최소 1화 이상 입력해주세요.`,3000);
                                setHasUnfilledField(true);
                                setIsLoading(false);
                                return;
                            };
                            
                            applicationProcess();

                            setIsLoading(false);
                        }}
                        disabled={isImagesLoading.length > 0 || isLoading || applicationStep > 0 }
                    >
                        {"동의 후 접수하기"}
                    </Button>
                    <Button
                        disableElevation color={"primary"}
                        type={"submit"} 
                        className={`SubmitButton`}
                        style={{border: '1px solid rgb(0,176,240)', backgroundColor: 'rgb(255,255,255)'}}
                        disabled={isLoading}
                        onClick={ async () => {
                            if (session && session.memberState && session.memberState === 'regular') {
                                fn.goto('/portfolio');
                            } else {
                                setOpenEmailLogin(true);
                            }
                        }}
                    >
                        {"제출한 작품 확인하기"}
                    </Button>
                    {openEmailLogin
                        ?<div style={{width: '100%', marginBottom: 15, marginTop: 20}} >
                            <label style={{fontWeight: 'bold', marginBottom: 5}}>{"이메일 "}
                                <span className={"redAsterisk"}>{"*"}</span>
                            </label>
                            <div className={`AppliedEmailVerifying`}>
                                <TextField
                                    onBlur={()=>{
                                        if ( appliedEmail !== "" ) {
                                            logBehavior('input-email',{})
                                        }
                                    }}
                                    disabled={ onAppliedEmailVerifying || isAppliedEmailVerified } 
                                    className={"Tags"}
                                    value={appliedEmail}
                                    size={"small"}
                                    onChange={(event) => setAppliedEmail(event.target.value)}
                                    style={{flex: 1}}
                                    autoFocus={openEmailLogin}
                                />
                                <Button color={'primary'} disableElevation onClick={ async () => {
                                        setIsLoading(true);

                                        const result = await verifyEmail(appliedEmail);
                                        if (result) {
                                            logBehavior('input-onAppliedEmailVerifying',{});
                                            setOnAppliedEmailVerifying(true);
                                        };

                                        setIsLoading(false);
                                    }}
                                    className={`VerifyingButton`}
                                    disabled={onAppliedEmailVerifying || isAppliedEmailVerified || !validateEmailForm(appliedEmail)}
                                >
                                    {"메일인증"}
                                </Button>
                            </div>
                        </div>:<></>}
                    {onAppliedEmailVerifying
                        ?<div style={{width: '100%'}} >
                            <label className={"Labels"}>{"인증키"}
                                <span className={"redAsterisk"}>{"*"}</span>
                            </label>
                            <div className={"VerifiedOtp"}>
                                <TextField
                                    onBlur={()=>{
                                        if ( otpForCheking !== "" ){
                                            logBehavior('input-otpForCheking',{})
                                        }
                                    }}
                                    className={"Tags"} 
                                    value={otpForCheking}
                                    size={"small"}
                                    onChange={(event) => setOtpForChecking(event.target.value)}
                                    style={{flex: 1}} disabled={isLoading}
                                    autoFocus={onAppliedEmailVerifying}
                                />
                                <Button color={"primary"} disableElevation onClick={async () => {
                                        setIsLoading(true);
                                        setIsOTPVerifyingFailed(false);

                                        const {isEmailLoginable} = await readLoginInfo(appliedEmail);

                                        if  ( isEmailLoginable ) {
                                            const response = await EmailTokenSignin(appliedEmail, otpForCheking);
                                            
                                            if (response) {
                                                setIsAppliedEmailVerified(true);
                                                logBehavior('input-appliedEmailVerified',{});
                                                setOnAppliedEmailVerifying(false);
                                                
                                                fn.goto('/portfolio');

                                                setIsLoading(false);
                                                return;
                                            } else {
                                                setIsOtpForChekingFailed(true);
                                                setIsLoading(false);
                                                return;
                                            };
                                        } else {
                                            toastAlert('현재 이메일로 신청된 작품이 없습니다.', 3000);
                                        };

                                        setIsLoading(false);
                                    }}
                                    className={"VerifiedOtpButton"} disabled={isLoading}
                                >
                                    {"인증하기"}
                                </Button>
                            </div>
                            {isOtpForCheckingFailed
                                ?<div style={{fontSize: '0.8rem', color: 'red'}}>
                                    {"* 인증에 실패했어요. 인증키를 다시 확인해주세요."}
                                </div>
                                :<></>
                            }
                        </div>
                        :<></>}
                    <Dialog className={"Dialog"} open={applicationStep > 0}>
                        <DialogTitle style={{width: 300, display: 'flex', justifyContent: 'center'}}>
                            {applicationStep === 1
                                ?<div>{'프로필 생성 중...'}</div>
                            :applicationStep === 2
                                ?<div>{'작품 생성 중...'}</div>
                            :applicationStep === 3
                                ?<div>{'데이터 분석 신청 중...'}</div>
                            :applicationStep === 4
                                ?<div>{'포트폴리오 생성 중...'}</div>
                            :<></>}
                        </DialogTitle>
                        <DialogContent style={{padding: '24px 24px 30px'}} >
                            <div style={{ display: 'flex', justifyContent: 'center', width: '100%', position: 'relative'}}>
                                <CircularProgress
                                    size={130}
                                    thickness={4}
                                    style={{
                                        color: 'rgb(0,176,240)',
                                        strokeLinecap: 'round',
                                    }}
                                />
                                <div style={{position: 'absolute', top: 'calc(50% - 12px)', color: 'rgb(0,176,240)', fontSize: '1.25rem'}} >
                                    {`${applicationStep/4*100} %`}
                                </div>
                            </div>
                        </DialogContent>
                    </Dialog>
                    <Dialog className={"Dialog"} open={popupOpen} onClose={popupClose}>
                        <DialogTitle style={{paddingBottom: 0}}>
                            <div>
                                {"신청 완료"}
                            </div>
                        </DialogTitle>
                        <DialogContent>
                            <div>
                                <div>{"작가님의 소중한 작품을 접수했어요!"}</div>
                                <div>{"멋진 결과가 있기를 응원할게요 😊"}</div>
                            </div>  
                        </DialogContent>
                        <DialogActions>
                            <Button variant={"contained"} disableElevation className={"PopupButton"}
                                onClick={popupClose} color={"primary"} >
                                {"확인"}
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog className={"FailDialog"} open={errorPopupOpen}>
                        <DialogTitle style={{padding: '20px 30px 10px'}}>
                            {"오류가 발생했어요."}
                        </DialogTitle>
                        <DialogContent style={{padding: '8px 70px 20px 30px'}}>
                            <DialogContentText style={{color: 'rgb(51,51,51)'}}>
                                <div style={{wordBreak: 'keep-all'}}>{errorMessage}</div>
                                <div>{'같은 문제가 반복되면 문의해 주세요.'}</div>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions style={{padding: '0px 20px 16px 8px'}}>
                            <Button disableElevation onClick={()=>{
                                setErrorPopupOpen(false);
                            }} >
                                {'다시 해보기'}
                            </Button>
                            <Button variant={"contained"} disableElevation className={"PopupButton"}
                                onClick={()=>{
                                    setErrorPopupOpen(false)
                                    ChannelService.ChannelIO()('openChat')
                                }} color={"primary"} >
                                {"문의하기"}
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog onClose={previewClose} open={!!previewManuscript && previewManuscript.length > 0} PaperProps={{style:{margin: 0, maxHeight: '100%',borderRadius: 0, height: '100%', width: '100%'}}}>
                        <div style={{display: 'flex', justifyContent: 'space-between', alignContent: 'center', fontSize: '1rem', padding: '10px 20px'}}>
                            <IconButton style={{padding: '0px'}} onClick={previewClose}>
                                <Close />
                            </IconButton>
                        </div>
                        <DialogContent dividers style={{display: 'flex', flexDirection: 'column', padding: '16px 0 0 0'}}>
                            {previewManuscript
                            ?previewManuscript.map(([name, imageUrl], index) => (
                                <img src={`${imageUrl}`} alt={name} style={{width: '100%'}} key={`previousManuscript:${imageUrl}`} />
                            )):<></>}
                        </DialogContent>
                    </Dialog>
                    <Dialog onClose={() => { setCropModalOpen(false) }} open={cropModalOpen} >
                        <DialogTitle >
                            <div style={{fontSize: '0.9rem', padding: '16px 0 10px'}}>
                                <span style={{color: 'rgb(0,176,240)', fontWeight: 'bold', marginRight: 16}}>
                                    {'TIP!'}
                                </span>
                                <span>
                                    {`600 x 600 px 이상의 이미지를 넣으면 썸네일 화질이 좋아요.`}
                                </span>
                            </div>
                        </DialogTitle>
                        <DialogContent style={{paddingTop: 0, border: 0}}>
                            <div style={{display: 'block', padding: '0 30px'}} >
                                <Cropper 
                                    crop={onCrop} src={imgSrc} ref={cropperRef} 
                                    aspectRatio={1/1} initialAspectRatio={1/1}
                                    viewMode={1} checkOrientation={false}
                                    background={false} data={{y: 40}}
                                />
                            </div>
                            <div style={{textAlign: 'center', fontSize: '0.9rem', paddingTop: '16px', fontWeight: 'bolder', color:'rgb(0,176,240)'}}>
                                {cropBoxSize.width + ' x ' + cropBoxSize.height}
                            </div>
                        </DialogContent>
                        <DialogActions style={{display: 'flex', justifyContent: 'center', padding: '16px 24px'}}>
                            <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                                <Button onClick={ () => {
                                    setCropModalOpen(false)
                                }} style={{color: 'rgb(0,176,240)', border: '1px solid rgb(0,176,240)', width: 'calc(50% - 20px)'}} >
                                    {'취소'}
                                </Button>
                                <Button variant={'contained'} color={"primary"} style={{width: 'calc(50% - 20px)'}} disableElevation
                                    onClick={ async () => {
                                        setIsLoading(true);
                                        const cropImage = await createImageWithBase64(croppedImage);

                                        if (cropImage) {
                                            setThumbnail(cropImage);
                                        } else {
                                            toastAlert('썸네일 업로드가 실패했습니다.');
                                        }

                                        setIsLoading(false);
                                        cropModalClose()
                                    }} >
                                    {'확인'}
                                </Button>
                            </div>
                        </DialogActions>
                    </Dialog>
                    <div className={'FloatingBar'}>
                        <div
                            className={'AddNewTitleButton'}
                            onClick={()=>{
                                logBehavior('click-apply2022universitybutton',{})

                                const ApplyRect = document.querySelector('#Apply').getBoundingClientRect()
                                const relativeTop = ApplyRect.top

                                const scrolledLength = window.pageYOffset;

                                window.scrollTo(0, relativeTop + scrolledLength)
                            }}
                        >
                            <div>{'접수하기'}</div>
                            <KeyboardArrowDown size={'small'} />
                        </div>
                    </div>
                </div>
            </div>
            <LoadingCircle show={isLoading} />
        </div>
    )
}

export default ContestApplication;