import axios from "axios";
import ss from './Session';


export const readEpisode = async (serviceId, titleId) => {

    try{
        let res = await axios.post('https://challenge-api.webtoon.today/webtoon/episode', {serviceId, titleId}, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });

        if (res.data && res.data.code === 200){
            
            return res.data.data.sort( (a,b) => a.episodeId - b.episodeId);

        }else{
            return [];
        }
    }catch(e){
        return [];
    }
}



export const createEpisode = async ({serviceId, titleId, episodeId, title, thumbnail, exposure}) => {
    
    try {
        let res = await axios.put('https://challenge-api.webtoon.today/webtoon/episode', {serviceId, titleId, episodeId, title, thumbnail, exposure}, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });

        if (res.data && res.data.code === 200){
            
            return res.data.key;

        }else{
            return null;
        }
    } catch(e) {
        return null;
    }
}

export const updateEpisode = async ({episode}) =>{
    try{
        let res = await axios.patch('https://challenge-api.webtoon.today/webtoon/episode', episode, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });
        
        if (res.data && res.data.code === 200){
            
            return res.data.key;

        }else{
            return null;
        }
    }catch(e){
        return null;
    }
}

export const deleteEpisode = async ({serviceId, titleId, episodeId}) => {
    try{
        let res = await axios.delete(`https://challenge-api.webtoon.today/webtoon/episode?serviceId=${serviceId}&titleId=${titleId}&episodeId=${episodeId}`, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });

        if (res.data && res.data.code === 200){
            
            return res.data.key;

        }else{
            return null;
        }
    }catch(e){
        return null;
    }
}

/**
 * 
 * @param {{challengeUrl: string, serviceId: string, titleId: string, episodeId: string}} param0 
 * @returns {string[]}
 */
export const createEpisodeFromChallenge = async ({challengeUrl, serviceId, titleId, episodeId, source}) => {

    try {
        let res = await axios.put('https://challenge-api.webtoon.today/create-episode-from-challenge', {serviceId, titleId, episodeId, source}, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });
        
        if (res.data.urlArr && res.data.code === 200){
            
            return res.data.urlArr;

        }else{
            return [];
        }
    } catch(e) {
        return [];
    }

}