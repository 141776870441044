import React, { useEffect } from 'react';
import './AnalysisApplyPage2024.scss';
import AnalysisApplyHeader from '../Components/AnalysisApplyHeader';
import ApplicationForm from '../Components/ApplicationForm';
import { useSetRecoilState } from 'recoil';
import { pageHeaderOptions } from '../../../Recoil/PageHeader/PageHeader';

const AnalysisApplyPage2024 = () => {
    const setPageHeader = useSetRecoilState(pageHeaderOptions);

    useEffect(() => {
        setPageHeader({
            isHiddenForPC: true,
            isHiddenForMobile: true,
            isAppbarHidden: true,
        })
    }, [setPageHeader])

    return (
        <div className={'AnalysisApplyPage'}>
            <AnalysisApplyHeader />
            <ApplicationForm />
        </div>
    );
};

export default AnalysisApplyPage2024;