import React, { useEffect } from 'react';
import './MyPage.scss';
import { Checkbox, Tab, Tabs } from '@material-ui/core';
import { Panorama, Create, Bookmark, AssignmentInd, BookmarkBorder,
    Favorite, Lock } from '@material-ui/icons';
import moment from 'moment-timezone';
import LoadingCircle from '../../Component/LoadingCircle';
import { fn } from '../../Functions';
import { useSubscribedTitleList } from '../../Recoil/Title/Titles';
import { useSubscribedAuthorList } from '../../Recoil/User/Users';
import { useBookmarkList } from '../../Recoil/Bookmark/Bookmark';
import { useSetRecoilState } from 'recoil';
import { pageHeaderOptions } from '../../Recoil/PageHeader/PageHeader';
import { useSession } from '../../Recoil/Session/Session';

const pageObject = {
    titleSubscription: '관심 웹툰',
    authorSubscription: '관심 작가',
    bookmark: '책갈피',
    designation: '내 칭호'
}

const MyPage = () => {
    const { session } = useSession();

    const [ selectedTab, setSelectedTab ] = React.useState("titleSubscription");
    const sessionName = session.name;

    let panelContent = <></>;
    switch(selectedTab){
        case 'titleSubscription':
            panelContent = <TitlePanel />;
            break;
        case 'authorSubscription':
            panelContent = <AuthorPanel />;
            break;
        case 'bookmark':
            panelContent = <BookmarkPanel />;
            break;
        case 'designation':
            panelContent = <DesignationPanel />;
            break;
        default:
            panelContent = <></>;
            break;
    }

    const setPageHeader = useSetRecoilState(pageHeaderOptions);
    React.useEffect(()=>{
        setPageHeader({titleText: '히스토리'})
    },[setPageHeader])
    
    const handleChange = (event, newValue) => {
        setSelectedTab(newValue);
    };
    
    if (session.memberState !== 'regular'){
        fn.goto('/signin');
    };

    return(
        <div className={'MyPageArea'}>
            <div className={'MyPageHomeBody'} >
                <div className={'BodyInner'}>
                    <div role='tabpanel' className={'TabPageTitle'}>
                        {`${sessionName}의 ${pageObject[selectedTab]}`}
                    </div>
                    <Tabs className={'MenuBox'}
                        value={selectedTab}
                        onChange={handleChange}
                        textColor="primary"
                        scrollButtons="off"
                        TabIndicatorProps={{style:{background: 'transparent'}}}
                    >
                        {
                            Object.keys(pageObject).map(key => (
                                <Tab className='Menu' key={key} value={key} label={
                                    <div className={`TabBox ${selectedTab !== key?'Basic':''}`} >
                                    {
                                        key === 'titleSubscription'
                                        ?<Panorama className={'Icon'} />
                                        :key === 'authorSubscription'
                                        ?<Create className={'Icon'}  />
                                        :key === 'bookmark'
                                        ?<Bookmark className={'Icon'}  />
                                        :key === 'designation'
                                        ?<AssignmentInd className={'Icon'}  />
                                        :<></>
                                    }
                                    <span className={'Label'} >{pageObject[key]}</span>
                                </div>
                                }></Tab>
                            ))
                        }
                    </Tabs>
                    {panelContent}
                </div>
            </div>
            <div className={'Footer'}>

            </div>
        </div>
    )
}


const TitlePanel = () => {
    const { titleList: subscribedTitles, fetch } = useSubscribedTitleList();

    React.useEffect( () => {
        fetch();
    },[fetch]);

    let content;
    if(subscribedTitles?.length > 0){
        content = (
            [...subscribedTitles]
                .sort( (a,b) => b.createdAt - a.createdAt )
                .map( ({serviceId, titleId, title, description, thumbnail, firstEpisodeUpdatedAt, author, subscriptionCount}, index) => (
                    <div className={'TitleRow'} key={`mypageEpisode:${titleId}`} onClick={() => {
                        fn.goto(`/contents/${serviceId}/${titleId}`)
                    }} >
                        <img className={'TitleThumbnail'} src={thumbnail} alt={'thumbnail'} />
                        <div className={'TextCell'} >
                            <div className={'TitleName'} >
                                {`${title}`}
                            </div>
                            <div className={'Description'} >
                                {description}
                            </div>
                            <div className={'TitleInformations'}>
                                <div className={'FavoriteCount'} >
                                    <Favorite className={'FavoriteIcon'} />
                                    {subscriptionCount || 0}
                                </div>
                                <div className={'DividingLine'} >{'l'}</div>
                                <div className={'CreatedTime'} >
                                    {moment(firstEpisodeUpdatedAt * 1000).format('YY.MM.DD')}
                                </div>
                                <div className={'DividingLine'} >{'l'}</div>
                                <div>
                                    {author}
                                </div>
                            </div>
                        </div>                   
                    </div>
            ))
        );
    } else{
        content = (
            <div className={'EmptyBoxMessage'} >
                <img src={"https://static.webtoon.today/ddah/logo-not-found.png"} alt={'noWebtoon'} />
                <div className={'TextBox'} >
                    <div>
                        {`관심 작품이 없네요. `}
                    </div>
                    <div className={'FlatTextLine'} >
                        {`다음화가 궁금한 작품에 `}
                        <span className={'FavoriteIcon'} >
                            <Favorite className={'Icon'} />
                        </span>
                        {'를 눌러보세요.'}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            <div role='tabpanel' style={{marginTop: 40}}>
                {content}
            </div>
            <LoadingCircle show={!subscribedTitles} />
        </>
    );
};


const AuthorPanel = () => {
    const {profileLoadable: subscribedAuthorsProfileLoadable, isLoading, isFetchingError, fetch} = useSubscribedAuthorList();

    React.useEffect( () => {
        (async()=>{
            const res = await fetch();
            if(!res){
                alert('작가 프로필 로딩이 실패했습니다.');
            }
        })()
    },[fetch]);

    useEffect(() => {
        if(isFetchingError){
            alert('작가 프로필 로딩이 실패했습니다.');
        }
    }, [isFetchingError])

    let content;
    if(subscribedAuthorsProfileLoadable.state === 'hasValue' && subscribedAuthorsProfileLoadable.contents?.length > 0){
        content = (
            <div className={'SubscribedAuthorContainer'} >
                {subscribedAuthorsProfileLoadable.contents.map((author, index) => (
                    <div key={author.addressid} className={'AuthorBox'} onClick={() => fn.goto(`/portfolio/${author.addressid}`)} >
                        <img src={author.image?author.image:"https://static.webtoon.today/noprofile.jpg"} alt={'AuthorImage'} className={'Image'} />
                        <span>{author.name}</span>
                    </div>
                ))}
            </div>
        );
    } else{
        content = (
            <div className={'EmptyBoxMessage'} style={{marginTop: 40}}>
                <img src={"https://static.webtoon.today/ddah/logo-not-found.png"} alt={'noWebtoon'} />
                <div className={'TextBox'} >
                    <div>
                        {`관심 작가가 없네요. `}
                    </div>
                    <div className={'FlatTextLine'} >
                        {`새 소식이 궁금한 작가에게 `}
                        <span className={'FavoriteIcon'} >
                            <Favorite className={'Icon'} />
                        </span>
                        {'를 눌러보세요!'}
                    </div>
                </div>
            </div>
        );
    }
    return (
        <>
            <div role='tabpanel'>
                {content}
            </div>
            <LoadingCircle show={isLoading} />
        </>
    );
}


const BookmarkPanel = () => {
    const { bookmarkList: bookmarks, isLoading, fetchBookmarkList, deleteBookmarkFromList } = useBookmarkList()
    const [ bookmarksToDelete, setBookmarksToDelete ] = React.useState([]);

    React.useEffect( () => {
        (async()=>{
            await fetchBookmarkList();
        })();
    },[fetchBookmarkList]);

    let content;
    if(bookmarks?.length > 0){
        content = (
            <>
                <div className={'BookmarkDeleteButton'} 
                    onClick={ async () => {
                        const deletedBookmarkKeys = await deleteBookmarkFromList(bookmarksToDelete);

                        if ( !deletedBookmarkKeys ) {
                            alert('북마크 삭제가 실패했습니다.')
                        };
                    }}
                >
                    {'삭제'}
                </div>
                {[...bookmarks]
                    .sort( (a,b) => b.createdAt - a.createdAt )
                    .map( ({serviceId, titleId, episodeId, contentIndex, title, episodeTitle, thumbnail}) => (
                        <div className={'EpisodeRow'} key={`mypageEpisode:${titleId}:${episodeTitle}:${contentIndex}`} >
                            <img 
                                src={thumbnail} alt={'thumbnail'} 
                                style={{width: 90, height: 90, objectFit: 'cover', border: '1px solid rgb(241, 241, 241)', boxSizing: 'border-box', cursor: 'pointer'}}
                                onClick={ () => fn.goto(`/contents/${serviceId}/${titleId}/${episodeId}#${contentIndex}`) }
                            />
                            <div 
                                className={'TextCell'}
                                style={{cursor: 'pointer'}}
                                onClick={ () => fn.goto(`/contents/${serviceId}/${titleId}/${episodeId}#${contentIndex}`) } 
                            >
                                <div className={'EpisodeTitle'} >
                                    {`${title}`}
                                </div>
                                <div className={'Description'} >
                                    {`${episodeId}화 ${episodeTitle || ''}`}
                                </div>
                            </div>
                            <div className={'DeleteAddButtonOfBookmark'} >
                                <Checkbox style={{margin: 0, padding: 0}} color={'primary'} size={'small'} onClick={ () => {

                                    const isExistingBookmark = bookmarksToDelete.filter(bookmark => bookmark.serviceId === serviceId && bookmark.titleId === titleId && bookmark.episodeId === episodeId ).length > 0
                                        if (isExistingBookmark) {
                                            
                                            const newBookmarksToDelete = bookmarksToDelete.filter(bookmark => bookmark.serviceId !== serviceId && bookmark.titleId !== titleId && `${bookmark.episodeId}` !== `${episodeId}` )
                                            
                                            setBookmarksToDelete(newBookmarksToDelete)
                                        } else {
                                            const newBookmarksToDelete = [...bookmarksToDelete]
                                            setBookmarksToDelete(newBookmarksToDelete.concat({serviceId, titleId, episodeId}))
                                        }
                                    }} 
                                />
                            </div>
                        </div>
                    ))
                }
            </>
        );
    } else{
        content = (
            <div className={'EmptyBoxMessage'} style={{marginTop: 40}}>
                <img src={"https://static.webtoon.today/ddah/logo-not-found.png"} alt={'noWebtoon'} />
                <div className={'TextBox'} >
                    <div>
                        {`책갈피 꽂은 곳이 없네요. `}
                    </div>
                    <div>
                        {'웹툰을 읽다가 표시하고 싶은 부분이 있으면'}
                    </div>
                    <div className={'FlatTextLine'} >
                        <span className={'FavoriteIcon'} >
                            <BookmarkBorder className={'Icon'} />
                        </span>
                        {'을 눌러보세요.'}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            <div role='tabpanel'>
                {content}
            </div>
            <LoadingCircle show={isLoading} />
        </>
    );
}


const designationList = {
    begginner: ['비기너', '에피소드 3화 이상 조회하려면'],
    advanceParty: ['선발대', '아무도 발견하지 않은 웹툰을 최초로 보면'],
    pioneer: ['개척자', '신규 웹툰을 10개 이상 조회하면'],
    complimenter: ['칭찬봇','칭찬 도장을 10번 이상 찍으면'],
    favoriter: ['잡덕', '관심작품을 5개 이상 등록하면'],
    reactionMachine: ['리액션 자판기', '댓글에 반응을 20번 이상 하면'],
    celebrity: ['인기쟁이', 'BEST 댓글에 5번 이상 오르면'],
    proAttendee: ['프로 참석러','댓글 남긴 작품이 10개 이상이면'],
    bigFan: ['열혈팬', '특정 작품에 모-든 활동을 하면'],
}

const DesignationPanel = () => {
    const [ designations, ] = React.useState(['begginner'])

    return (
        <>
            <div role='tabpanel'>
            {Object.entries(designationList).map(([key, [title, description]]) => (
                <div className={'DesignationContainer'} key={`Designations:${key}`} >
                    {designations.indexOf(key) >= 0?
                        <div className={'OpenedBox'} >
                            <img className={'Icon'} src={`https://static.webtoon.today/ddah/${key}.png`} alt={title} />
                            <div className={'TextBox'} >
                                <div className={'Description'} >
                                    {description}
                                </div>
                                <div className={'Name'} >
                                    {title}
                                </div>
                            </div>
                        </div>
                        :
                        <div className={'ClosedBox'} >
                            <div className={'IconBox'}>
                                <Lock className={'Icon'} alt={'closedIcon'}  />
                            </div>
                            <div className={'TextBox'} >
                                <div className={'Description'} >
                                    {'쉿! 조건은 비밀입니다 :)'}
                                </div>
                                <div className={'Name'} >
                                    {title}
                                </div>
                            </div>
                        </div>
                    }
                </div>
            ))}
            </div>
        </>
    );
}

export default MyPage;