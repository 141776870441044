
import ss from './Session';
import axios from 'axios';

/**
 * 
 * @typedef {{ serviceId: string, titleId: string, episodeId: string, contentIndex: string, thumbnail: string, title: string, episdoeTitle: string }} Bookmark
 *  
 */

export const readBookmark = async () => {

    try{
        
        let res = await axios.get('https://challenge-api.webtoon.today/interaction/bookmark', {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });
        
        if (res.data.code === 200 && res.data.data) {
            return res.data.data;
        } else {
            return null;
        }

    }catch(e){
        console.error(e)
        if (e.response && e.response.status === 404) {
            return [];
        } else {
            return null;
        }
    };
};



export const createBookmark = async ({serviceId, titleId, episodeId, contentIndex}) => {

    try{
        
        let res = await axios.put('https://challenge-api.webtoon.today/interaction/bookmark', {serviceId, titleId, episodeId, contentIndex},  {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });
        
        if (res.data.code === 200 && res.data.key) {
            return res.data.key;
        } else {
            return null;
        };

    }catch(e){
        console.error(e)
        return null;
    };
};

/**
 * 
 * @param {{ serviceId: string, titleId: string, episodeId: string }[]} keys 
 * @returns 
 */
export const deleteBookmark = async (keys) => {

    try{
        
        let res = await axios.delete(`https://challenge-api.webtoon.today/interaction/bookmark?key=${keys.map(bookmark => `${bookmark.serviceId}:${bookmark.titleId}:${bookmark.episodeId}`).join(',')}`, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });
        
        if (res.data.code === 200 && res.data.key) {
            return res.data.key;
        } else {
            return null;
        };

    }catch(e){
        console.error(e)
        return null;
    };
};