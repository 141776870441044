import React from "react";
import { createImageWithS3, spreadZipOnS3 } from "../../../Data/Image";
import { waitImageLoaded } from "../../../Functions";
import { toastAlertType } from '@webtoontoday/toast';

export const uploadZipFiles = async ({ 
    files, setIsLoading, setFiles, toastAlert
}:{ 
    files: File[], 
    setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>,
    setFiles: React.Dispatch<React.SetStateAction<imageType[]>>,
    toastAlert: toastAlertType
}) => {
    if ( (files || []).length < 1) return;
    if ( setIsLoading ) setIsLoading(true);

    for ( let i=0; i<files.length; i++ ) {
        const zipFileName = (await createImageWithS3({name: files[i].name, files: [files[i]] }) || "") as `https://static.webtoon.today/${string}` ;
        const fileUrls = await spreadZipOnS3(zipFileName);
        const newFiles: imageType[] = [];

        if (!zipFileName || !fileUrls) {
            toastAlert('ZIP파일 업로드를 실패했습니다.')
    
            if ( setIsLoading ) setIsLoading(false);
            return;
        };

        for ( const url of fileUrls ) {
            newFiles.push({
                name: url.split('/').reverse().slice(0,2).reverse().join('_'),
                image: url,
                createdAt: files[i].lastModified,
            });
        }

        setFiles( prevState => [...prevState, ...newFiles] )
    }

    if ( setIsLoading ) setIsLoading(false);
}


export const uploadImages = async ({ 
    files, setLoadingFiles: setLoadingImages, setFiles
}:{
    files: File[],
    setLoadingFiles?: React.Dispatch<React.SetStateAction<imageType[]>>,
    setFiles: React.Dispatch<React.SetStateAction<imageType[]>>,
}) => {
    if ( (files || []).length < 1 ) return;
    const promises: Promise<string>[] = [];
    const fileNameToDataUrl: {[name: string]: string} = {};

    for ( let i=0; i<files.length; i++ ) {
        const file = files[i];
        const previewReader = new FileReader();

        previewReader.onloadend = async () => {
            const dataURL = previewReader.result as string;

            if (setLoadingImages) {
                setLoadingImages( prevState => [...prevState, {name: file.name, image: dataURL, createdAt: file.lastModified}] );
            };
            
            fileNameToDataUrl[file.name] = dataURL;
            setFiles( prevState => [...prevState, {name: file.name, image: dataURL, createdAt: file.lastModified}] );
        }

        previewReader.readAsDataURL(file);
    }
    
    for ( let i=0; i<files.length; i++ ) {
        const file = files[i];

        const promise = new Promise<string>( async (resolve, reject) => {

            const uploadedUrl = await createImageWithS3({name: file.name, files: [file]});
            if (uploadedUrl){
            
                await waitImageLoaded(uploadedUrl);
                setFiles( prevState => {
                    const newState = [...prevState];
                    const fixingIndex = prevState.findIndex(({name, image}) => name === file.name && image === fileNameToDataUrl[file.name]);

                    newState[fixingIndex] = {name: file.name, image: uploadedUrl, createdAt: file.lastModified}
                    
                    return newState;
                });
                
                if (setLoadingImages) {
                    setLoadingImages( prevState => [...prevState].filter( ({name}) => name !== file.name ) );
                };

                resolve(uploadedUrl);
            }
        })
        promises.push(promise);
        
        try {
            // 모든 작업 완료까지 기다리기
            await Promise.all(promises);
        } catch (error) {
            // 오류 처리
            console.error(error);
        }
    }
}
