import React, { ReactNode, useEffect } from "react";

import { Target, TargetAndTransition, Transition, Variants, motion, useAnimation } from "framer-motion";

import { useInView } from "react-intersection-observer";


// ----------------------------------------------------------------------

const AnimateDiv = ({ children, className, variants, transition, threshold, ...other }:{
    children: ReactNode, className?: string, variants: Variants, transition?: Transition, threshold?: number | number[],
}) => {




    const controls = useAnimation();

    const [ref, inView] = useInView({
        threshold,
        triggerOnce: true
    });

    
    const initialObject = Object.values(variants)[0] as TargetAndTransition
    const animateObject = Object.values(variants)[1] as TargetAndTransition

    useEffect(() => {

        if(inView){
            controls.start(animateObject);
        } else {
            controls.start(initialObject);
        }
    }, [animateObject, controls, inView, initialObject]);

    

    return (

        <motion.div

            ref={ref}
            animate={controls}
            initial={initialObject as Target}
            variants={variants}
            transition={transition}
            className={className}
            {...other}
        >
            {children}

        </motion.div>
        
    );
}

export default AnimateDiv;
