import { useState, useEffect } from "react";
import { ParentSize } from "@visx/responsive";
import SearchBar from "./Components/SearchBar";
import LineChart from "./Components/LineChart";
import { getPopularityResult } from "../../Data/PopularityResult";
import LoadingCircle from "../../Component/LoadingCircle";

const PopularitySearch = ({query} : {query: string}) => {
    const params = new URLSearchParams(query);
    const [serviceId, titleId] = [params.get('serviceId'), params.get('titleId')];

    const [ popularityData, setPopularityData ] = useState<{period: string, ratio: number}[]>([])
    const [ isLoading, setIsLoading ] = useState<boolean>(false);

    useEffect(()=>{
        (async() => {
            if(serviceId&&titleId){
                setIsLoading(true)
                let res: {period: string, ratio: number}[] = await getPopularityResult({serviceId, titleId})
            
                let incrementRes: {period: string, ratio: number}[] = res.map((row, index) => {
                    return {
                        period: row.period,
                        ratio: (res[index-1]!==undefined)?row.ratio-res[index-1].ratio:0
                    }
                })
                setPopularityData(incrementRes);
                setIsLoading(false)
            }
        })();
    },[serviceId, titleId])

    return (
        <div className={'PopularityResult'}>
            <div className={'SearchBarBox'}>
                <SearchBar
                    searchType="popularity"
                    style={{
                        padding: '10px 10px 10px 20px'
                    }}
                    iconStyle={{
                        fontSize: '2rem'
                    }}
                    placeholder={'궁금한 작품 이름을 입력해주세요.'}
                />
            </div>
            {(popularityData.length!==0)?<div className={'ChartSpace'} >
                <ParentSize>
                    {({width}) => 
                        <LineChart
                            searchType="popularity" width={width} height={500} data={popularityData} 
                        />
                    }
                </ParentSize>
            </div>:<></>}
            <LoadingCircle show={isLoading} />
        </div>
    )
}

export default PopularitySearch;