import { atom, atomFamily, useRecoilState } from 'recoil';
import { readSubscribedTitle, subscribeTitle, unsubscribedTitle  } from '../../Data/TitleSubscription';
import { getTitles, } from '../../Data/Title';
import { logBehavior } from '../../Data/Behavior';
import { useCallback, useEffect, useState } from 'react';
import { getFeedData } from '../../Data/Feed';
import { deepCompareObjects, unique } from '../../Functions';
import { useUsers } from '../User/Users';

const TitleKeyList = atom<string[]>({
    key: 'titleKeyList',
    default: [],
    dangerouslyAllowMutability: true
});
const TitleObject = atom<{[serviceIdAndtitleId: string]: TitleType & TitleAugmentedData}>({
    key: "titleObject",
    default: {},
    dangerouslyAllowMutability: true
});
const FeedObject = atom<(FeedTitleInfoType|null)[]>({
    key: 'feedObject',
    default: [],
    dangerouslyAllowMutability: true
});

const subscribedTitleKeyList = atom<string[] | null>({
    key: "subscribedTitleList",
    default: null,
    dangerouslyAllowMutability: true
});

export const useSubscribedTitleList = (): {
    titleList: (TitleType & TitleAugmentedData)[],
    fetch: () => void,
    subscribe: (serviceId: string, titleId: string) => Promise<boolean>,
    unsubscribe: (serviceId: string, titleId: string) => Promise<boolean>,
    isSubscribing: (serviceId: string, titleId: string) => boolean,
} => {
    const [titleKeyList, setKeyTitleList] = useRecoilState<string[] | null>(subscribedTitleKeyList);
    const [isFetching, setIsFetching] = useState<boolean>(false);

    const { titleObject, fetch: fetchTitles, refreshTitle } = useTitles();

    const fetch = useCallback(async (): Promise<boolean> => {
        if (isFetching){
            setIsFetching(false);
            return false;
        }

        if (titleKeyList && titleKeyList.length > 0){
            setIsFetching(false);
            return true;
        }

        const subscribedTitleKeys: string[] | null = await readSubscribedTitle();

        setKeyTitleList(subscribedTitleKeys);
        
        if (!subscribedTitleKeys || subscribedTitleKeys.length === 0){
            setIsFetching(false);
            return !!subscribedTitleKeys;
        }
        
        fetchTitles(subscribedTitleKeys.map(key => key.split(':')).map(([serviceId, titleId]) => ({serviceId, titleId})));

        setIsFetching(false);
        return true;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isFetching, setKeyTitleList]);

    const secureFetch = useCallback(()=> {
        if (isFetching){
            return;
        }

        fetch();
        setIsFetching(true);
    },[fetch, isFetching])

    const subscribe = useCallback(async (serviceId: string, titleId: string): Promise<boolean> => {

        const res = await subscribeTitle(serviceId, titleId);
        if (!res){
            return false;
        }else{
            if(titleKeyList === null){
                secureFetch();
            } else{
                setKeyTitleList([...titleKeyList, `${serviceId}:${titleId}`].filter(unique));
            }
            refreshTitle([{serviceId, titleId}]);
            logBehavior('add-subscriptiontitle', {serviceId, titleId});
            return true;
        }
    }, [refreshTitle, secureFetch, setKeyTitleList, titleKeyList]);

    const unsubscribe = useCallback(async (serviceId: string, titleId: string): Promise<boolean> => {

        const res = await unsubscribedTitle(serviceId, titleId)
        if (!res){
            return false;
        }else{
            if(titleKeyList === null){
                secureFetch();
            } else{
                setKeyTitleList(titleKeyList.filter(subscribedTitle => subscribedTitle !== `${serviceId}:${titleId}`));
            }
            refreshTitle([{serviceId, titleId}]);
            logBehavior('delete-subscriptiontitle', {serviceId, titleId});
            return true;
        }
    },[refreshTitle, secureFetch, setKeyTitleList, titleKeyList]);

    const isSubscribing = useCallback((serviceId: string, titleId: string) => {
        return titleKeyList?titleKeyList.filter(row => row === `${serviceId}:${titleId}`).length > 0:false; 
    },[titleKeyList])

    const [subscribedTitleList, setSubscribedTitleList] = useState<(TitleType&TitleAugmentedData)[]>([]);
    useEffect(()=>{
        setSubscribedTitleList(titleKeyList?.map((serviceIdAndTitleId) => titleObject[serviceIdAndTitleId] || null).filter(row => row) || []);
    },[titleKeyList, titleObject])
    return {titleList: subscribedTitleList, fetch: secureFetch, subscribe, unsubscribe, isSubscribing};
}


const countFamily = atomFamily<number, string>({
    key: "countFamily",
    default: 12
})
const booleanFamily = atomFamily<boolean, string>({
    key: 'booleanFamily',
    default: false
})

export const keyListFamily = atomFamily<TitleKeyType[] | null, string>({
    key: 'keyListFamily',
    default: null
})

const clickLogsAtom = atom<InteractionLogType[]>({
    key: 'clickLogs',
    default: []
})

const CELLS_PER_CALL = 12;
const BLANKS_PER_CALL = 2;

export const useFeedTitleList = (feedTabName: string) : {
    feedData: (FeedTitleInfoType | null)[] | null,
    secureFetch: (requiredTotalCount: number) => void,
    isExhausted: boolean
} => {
    const [feedKeyData, setFeedKeyData] = useRecoilState<TitleKeyType[] | null>(keyListFamily(feedTabName));
    const [expectedCount, setExpectedCount] = useRecoilState<number>(countFamily(feedTabName));
    const [isExhausted, setIsExhausted] = useRecoilState<boolean>(booleanFamily(feedTabName));

    const [feeds, setFeeds] = useRecoilState<(FeedTitleInfoType|null)[]>(FeedObject);

    const [clickLogs, ] = useRecoilState<InteractionLogType[]>(clickLogsAtom);
    
    const {isSubscribing, fetch: fetchSubscribed} = useSubscribedTitleList();
    const {titleObject, fetch: fetchTitles} = useTitles();
    const {userObject, fetch: fetchUsers} = useUsers();

    
    const fetch = useCallback(async (pageSize: number = CELLS_PER_CALL, blank: number = BLANKS_PER_CALL) => {
        const exposureLogs = feedKeyData?.filter(row=>row!==null).map(row => ({serviceId: row.serviceId, titleId: row.titleId, timestamp: 123})) || [];
        const newFeedData = await getFeedData(true, clickLogs, exposureLogs, feedTabName === 'global'?'global':"tags", feedTabName, feedKeyData?.length || 0, 2 * Math.floor((feedKeyData?.length || 0) / 12), pageSize, blank);
        
        if ( newFeedData ) {
            setFeeds( feed => ([...feed, ...newFeedData?.titleList.filter( row => row )]) )
        }
        
        if(!newFeedData){
            setIsExhausted(true);
            return;
        }

        if (feedKeyData
            && newFeedData.titleList.length + feedKeyData.length < expectedCount){
            setIsExhausted(true);
        }

        if (newFeedData && newFeedData.isEnd){
            setIsExhausted(true);
        }

        
        setFeedKeyData(currentFeedKeyData => {
            if ((currentFeedKeyData?.length || 0) > (feedKeyData?.length || 0)){
                // if concurrent call succeeded already, no update
                return currentFeedKeyData
            }

            const impressionData = newFeedData.titleList.filter(row=>row!==null).map(row=>({serviceId: row.serviceId, titleId: row.titleId, timestamp: Math.floor(new Date().getTime()/1000)}));
            logBehavior("feed-impression",{impressionLogs: impressionData});

            return [...(currentFeedKeyData||[]), ...newFeedData.titleList];
        })
        
        return;
    }, [clickLogs, expectedCount, feedKeyData, feedTabName, setFeedKeyData, setFeeds, setIsExhausted]);

    // TODO: TitleType <-> FeedTitleType <-> SubscribedTitleType&SubscribedTitleAugmentedData 간에 싱크 맞추기 V

    const [feedData, setFeedData] = useState<(FeedTitleInfoType | null)[] | null>(null);
    useEffect(()=>{
            if(!feedKeyData){
                setFeedData(null);
                return;
            }

            const titleKeys = [];
            const neededTitleKeySet = new Set<string>();
            for (const key of feedKeyData) {
                if(key === null){
                    continue;
                }
                const { serviceId, titleId } = key;
                titleKeys.push({serviceId, titleId});
                neededTitleKeySet.add(`${serviceId}:${titleId}`)
            };

            let nonExistUsers = Object.values(titleObject).filter(({titleId, serviceId, authorId}) => neededTitleKeySet.has(`${serviceId}:${titleId}`) && !(authorId in userObject)).map(row => row.authorId);

            fetchUsers(nonExistUsers);
            fetchSubscribed();
            fetchTitles(titleKeys);
    
            if (!feedKeyData){
                setFeedData(null);
                return;
            }
            
            let feedBodyData:{[key: string]: FeedTitleInfoType|null} = {};
            for ( const oneFeed of feeds ) {
                feedBodyData[`${oneFeed?.serviceId}:${oneFeed?.titleId}`] = oneFeed;
            }
            const newFeedData = feedKeyData
                .filter((row) => !row || `${row.serviceId}:${row.titleId}` in titleObject)
                .map(row => {
                    if(!row){
                        return null;
                    }
                    const {serviceId, titleId} = row;
                    const {
                        slides = [], thumbnail = "", href = "", title = "", description = "", tags = "", firstEpisodeId = "", titleViewCount: viewCount, episodeCount, subscriptionCount: favoriteCount, musicAddress, 
                    } = titleObject[`${serviceId}:${titleId}`] || {};
                    const {addressid, name, image, subscriptionCount, subscribe, otherTitle} = userObject[titleObject[`${serviceId}:${titleId}`]?.authorId] || {};
                    const {schoolCompetitionSubmittedYear=null, schoolCompetitionWinner=null} = feedBodyData[`${serviceId}:${titleId}`] || {};

                    return {
                        slides,
                        serviceId, titleId,
                        thumbnail, href,
                        title, description,
                        tags: tags?.split(',') || [],
                        viewCount, firstEpisodeId, episodeCount, favoriteCount,
                        lastEpisodeCreatedAt: Math.round(Date.now()/1000), schoolCompetitionSubmittedYear, schoolCompetitionWinner,
                        favorite: isSubscribing(serviceId, titleId),
                        musicAddress,
                        authorInfo: {
                            id: addressid, name, image, subscriptionCount, subscribe,
                            otherTitle: otherTitle?.map((row) => ({
                                ...row,
                                serviceId: row.serviceId || "", titleId: row.titleId || "",
                                href: titleObject[`${row.serviceId}:${row.titleId}`]?.href || "", thumbnail: titleObject[`${row.serviceId}:${row.titleId}`]?.thumbnail || "",
                            }))
                        }
                    }
                })
            setFeedData(newFeedData);
            
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [feedKeyData, isSubscribing, titleObject, userObject])

    const secureFetch = useCallback((n: number) => {
        setExpectedCount(expectedCount => Math.max(expectedCount,n));
    }, [setExpectedCount]);

    useEffect(()=> {
        if (!feedData || (feedData?.length < expectedCount && !isExhausted)) {
            fetch(CELLS_PER_CALL, BLANKS_PER_CALL);
        }
    }, [expectedCount, feedData, fetch, isExhausted])


    return {feedData, isExhausted, secureFetch};
}

export const useClickLogs = (): {
    handleClickLogs: (clickInteractionLogs: InteractionLogType[]) => void
} => {
    const [ ,setClickLogs ] = useRecoilState<InteractionLogType[]>(clickLogsAtom);

    const handleClickLogs = useCallback((clickInteractionLogs: InteractionLogType[]) => {
        setClickLogs(clickLogs => [...clickLogs, ...clickInteractionLogs].filter(unique));
    },[setClickLogs]);

    return {handleClickLogs};
}

// TODO: Nothing to do
export const useTitles = () => {
    const [titleKeyList, setTitleKeyList] = useRecoilState(TitleKeyList);
    const [titleObject, setTitleObject] = useRecoilState(TitleObject);

    const fetch = useCallback(async (keys:{serviceId: string,titleId: string}[]) => {
        
        const newTitleList: (TitleType & TitleAugmentedData)[] | null = await getTitles(keys.filter(key => key !== null));

        if (! newTitleList){
            return;
        }

        const newTitleObject = {} as {[key: string]: TitleType & TitleAugmentedData};

        for (let row of newTitleList){
            newTitleObject[`${row.serviceId}:${row.titleId}`] = row;
        }

        if (! deepCompareObjects(newTitleObject, titleObject)){
            setTitleObject(titleObject => ({...titleObject, ...newTitleObject}));
        }

    }, [setTitleObject, titleObject]);

    const refreshTitle = useCallback((keys:{serviceId: string,titleId: string}[]) => {
        setTitleKeyList(titleKeyList => [...titleKeyList, ...keys.map(({serviceId, titleId}) => `${serviceId}:${titleId}`)].filter(unique))

        fetch(keys)
    }, [fetch, setTitleKeyList]);

    const secureFetch = useCallback((keys:{serviceId: string,titleId: string}[])=>{
        let realNewList = keys.filter(row => !!row).filter(({serviceId, titleId}) => !titleKeyList.includes(`${serviceId}:${titleId}`));
        
        setTitleKeyList(titleKeyList => [...titleKeyList, ...realNewList.map(({serviceId, titleId}) => `${serviceId}:${titleId}`)].filter(unique))
        if (realNewList.length > 0){
            fetch(realNewList);
        }

    },[fetch, setTitleKeyList, titleKeyList])

    const isNotExist = useCallback((serviceId: string, titleId: string)=> {
        return !(`${serviceId}:${titleId}` in titleObject) && titleKeyList.includes(`${serviceId}:${titleId}`)
    },[titleKeyList, titleObject])

    return {fetch: secureFetch, refreshTitle, isNotExist, titleObject};
}

export const lastSeenTitleKey = atom<string>({
    key: "lastSeenTitleKey",
    default: "",
});
