import React from 'react';
import {ArrowBackIos, } from '@material-ui/icons';

import './CollapseCell.scss';
import { IconButton } from '@material-ui/core';

const CollapseCell = ({
    style={}, className='', bodyStyle={}, title="", defaultIsOpen=true,
    titleHeader, children, forcedProps={}
}) => {

    const currRef = React.useRef();

    const [isOpen, setIsOpen] = React.useState(defaultIsOpen);
    const [showEvent, setShowEvent] = React.useState(true);
    React.useEffect(()=>{ setShowEvent(false); },[])

    return (
        <div ref={currRef} className={`CollapseCell ${className}`} style={style}
            {...forcedProps}
        >
            <div className={"CollapseHeader"}>
                <div className={"CollapseTitle"}>
                    <div style={{display:'flex', justifyContent:'flex-start', alignItems:'flex-end'}}>
                        {titleHeader}
                        <div style={{width: '100%', paddingRight: 5}}>{title}</div>
                    </div>
                </div>
                <IconButton style={{padding: 0}} onClick={()=>{setIsOpen(!isOpen)}}>
                    <ArrowBackIos fontSize='small' style={{transform: isOpen?'rotate(90deg) translate(5px, 0)':'rotate(-90deg) translate(5px, 0)'}}/>
                </IconButton>
            </div>
            <div className={"CollapseBody"} style={{maxHeight: (isOpen&&!showEvent)?500:0, ...bodyStyle}}>
                {children}
            </div>
        </div>);
}

export default CollapseCell;