import React from 'react';
import './NoticeList.scss';
import moment from 'moment-timezone';
import { fn } from '../../Functions';
import { Button, FormControl, IconButton, MenuItem, Select, TextField } from '@material-ui/core';
import { PostType, readPost } from '../../Data/Post';
import { ArrowUpward, Search } from '@material-ui/icons';
import { useSession } from '../../Recoil/Session/Session';
import LoadingCircle from '../../Component/LoadingCircleAnimated';


const NoticeList = () => {
    const { isManager } = useSession();

    const [posts, setPosts] = React.useState<PostType[]>([]);
    const [typing, setTyping] = React.useState<string>('');
    const [managerAuthority, setManagerAuthority] = React.useState<boolean>(false);

    // 서버에서 페이지와 count 수를 받았을 때 사용할 state들 입니다.
    // const [currentPage, setCurrentPage] = React.useState(1);
    // const [listCount, setListCount] = React.useState(15);

    // 서버에서 페이지, count, searchWord 를 받아서 사용할 때 쓸 코드입니다.
    // let queryString = window.location.search.replace('?','');
    // let queries = Object.fromEntries(queryString.split('&').map(pair => pair.split("=").map(uri => decodeURIComponent(uri))))

    const [isLoading, setIsLoading] = React.useState(false);
    
    React.useEffect(()=>{
        
        (async()=>{
            setIsLoading(true);
            
            const result = await readPost();
            setPosts(result)

            // setCurrentPage(queries.page) 여기에 쿼리로 받은 페이지
            // setListCount(queries.listCount) 여기에 한 페이지당 작품 개수

            setIsLoading(false);
        })();
        (async()=>{    
            const authority = await isManager();
            if(authority){
                setManagerAuthority(true);
            }
        })();

    },[isManager])


    const isSignificant = (significantFrom: number, significantTo: number) => {
        const currentTime = new Date().getTime();
        if (significantFrom * 1000 < currentTime && significantTo * 1000 > currentTime) {
            return true;
        }
        return false;
    }
    
    const [maximumPosts, setMaximumPosts] = React.useState<number>(15);

    const significantPosts = posts.filter(post => isSignificant(post.significantFrom, post.significantTo))
    const normalPosts = posts.filter(post => !isSignificant(post.significantFrom, post.significantTo))
    

    return (
        <div className={"NoticeList"}>
            <div className={'ListContainer'}>
                <div className={'ListInner'}>
                    <div className={'Title'}>
                        {'공지사항'}
                        {/* 숨김 */}
                        <div className={'MaximumPostSelector'} style={{display: 'none'}}>
                            <FormControl>
                                <Select
                                    value={maximumPosts} 
                                    // 서버에 변한 listPosts 값 보내기
                                    onChange={(event) => setMaximumPosts(event.target.value as number)}
                                >
                                    <MenuItem value={15}>{"15개 보기"}</MenuItem>
                                    <MenuItem value={25}>{"25개 보기"}</MenuItem>
                                    <MenuItem value={35}>{"35개 보기"}</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    {managerAuthority &&
                    <div className={'ButtonArea'}>
                        <Button variant={'contained'} className={'PostButton'} onClick={()=>fn.goto('/notice/write')} >
                            {'글쓰기'}
                        </Button>
                    </div>}
                    <div>
                        <table className={'PostTable'}>
                            <tbody className={'PostTableBody'}>
                                <tr className={'StandardRow'}>
                                    <td className={'PostTitle'} >
                                        {'제목'}
                                    </td>
                                    <td className={'PostCreatedTime'}>
                                        {'등록일'}
                                    </td>
                                </tr>
                                {/* 서버에 page, listCount를 보내 해당 게시물(posts)을 받기 전까지 임시로 비슷하게 보이도록 만들었습니다. */}
                                {[...significantPosts,...normalPosts]
                                .map( post => (
                                    <tr className={`PostRow ${isSignificant(post.significantFrom,post.significantTo)?'Significant':''}`} 
                                        key={`${post.title}:${post.postId}`} 
                                        onClick={ () => fn.goto(`/notice/${post.postId}`)}>
                                        <td className={'PostTitle'}>
                                            <div>{post.title}</div>
                                        </td>
                                        <td className={'PostCreatedTime'} >
                                            {moment(post.createdAt * 1000).format('YY. MM. DD')}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        {/* 숨김 */}
                        <div className={'ListPagination'} style={{display: 'none'}}>
                            {'pageNation'}
                        </div>
                        {/* 숨김 */}
                        <div className={'SearchArea'} style={{display: 'none'}}>
                            <div className={'SearchRow'}>
                                <TextField className={'SearchPost'} placeholder={'검색하기'} value={typing} 
                                    onChange={ (event) => {
                                        setTyping(event.target.value)
                                    }} 
                                />
                            </div>
                            {/* typing의 키워드를 서버로 보낼는 곳 */}
                            <IconButton className={"SearchIcon"} onClick={ () => {} }>
                                <Search />
                            </IconButton>
                        </div>
                    </div>
                    <div
                        className={'ScrollToTopButton'}
                        onClick={ () => window.scrollTo({ top: 0 }) } 
                    >
                        <ArrowUpward />
                    </div>
                </div>
            </div>
            <LoadingCircle show={isLoading}/>
        </div>
    );
}

export default NoticeList;