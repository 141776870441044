
import { Pagination } from 'swiper';
import './PrizeSlider.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Button } from '@material-ui/core';
import { fn, randomString } from '../../../../../Functions';
import { useRef } from 'react';


const PrizeSlider = ({ slides }:{ slides: {image: string, url: string}[] }) => {
    const uniqueId = useRef<string>(randomString(17));
    
    return(<div className={'PrizeSlideArea'} >
        <div className={'SlideContainer'} >
            <Swiper
                className={'PrizeSwiper'} 
                slidesPerView={'auto'}
                modules={[ Pagination ]} spaceBetween={10}
                pagination={{
                    el: `.my-custom-unvi2024-reader-pagination-${uniqueId.current}`,
                    clickable: true,
                    renderBullet: (index, className) => {
                        return `<span key=${index} class=${className} style="width: 17px; height: 17px;" > </span>`
                    },
                }}
            >
                {slides.map( ({image, url}) => 
                    <SwiperSlide key={`${image}:${uniqueId}`} className={'PrizeSlideInner'} >
                        <img src={image} alt={'prizeSlide'} />
                        {url && <div className={'ButtonBox'}>
                            <Button className={'SlideButton'} href={url} onClick={fn.gotoByAnchor} >{'작품 보러 가기'}</Button>
                        </div>}
                    </SwiperSlide>    
                )}
            </Swiper>
        </div>
        <div className={'Pagination'}  >
            <div className={'HoverBackground'} />
            <div className={`my-custom-unvi2024-reader-pagination-${uniqueId.current}`} />
        </div>
    </div>)
}

const PosterSlider = ({ slides }:{ slides: {image: string}[] }) => {
    const uniqueId = useRef<string>(randomString(17));
    
    return(<div className={'PrizeSlideArea'} >
        <div className={'PosterSlideContainer'} >
            <Swiper
                slidesPerView={1}
                modules={[ Pagination ]}
                pagination={{
                    el: `.my-custom-unvi2024-reader-pagination-${uniqueId.current}`,
                    clickable: true,
                    renderBullet: (index, className) => {
                        return `<span key=${index} class=${className} style="width: 17px; height: 17px;" > </span>`
                    },
                }}
            >
                {slides.map( ({image}) => 
                    <SwiperSlide key={`${image}:${uniqueId}`} >
                        <img src={image} alt={'prizeSlide'} />
                    </SwiperSlide>    
                )}
            </Swiper>
        </div>
        <div className={'Pagination'}  >
            <div className={'HoverBackground'} />
            <div className={`my-custom-unvi2024-reader-pagination-${uniqueId.current}`} />
        </div>
    </div>)
}

export {
    PrizeSlider,
    PosterSlider
};