import React, { ReactNode } from 'react';

import './Styles/RadioButton.scss';

interface RadioButtonProps<T> {
    value: T, onChange: () => void,
    checked: boolean,
    label: ReactNode, labelProps?: React.HTMLAttributes<HTMLLabelElement>
}
const RadioButton = <T,>({ 
    value, onChange, checked, label, labelProps = {}
}:RadioButtonProps<T>) => (
    <div 
        className={`RadioBox ${checked?'Checked':''}`} 
        onClick={() => onChange() }
        tabIndex={0} onKeyDown={(e) => (e.key === ' ' || e.key === 'Enter')?onChange():null}
    >
        <label className={`RadioLabel`} {...labelProps} >
            {label}
        </label>
        <input className={'RadioButton'} type={"radio"} value={`${value}`} checked={checked} onChange={() => onChange()} />
    </div>
);

export default RadioButton;