import React from 'react';

import './TemplateEdit.scss';
import CollapeCell from './CollapeCell';
import {themeColors} from '../../Data/Theme';

const ColorButton = ({color, className='', isSelected, setIsSelected,}) => {
    return (
    <div
        className={`ColorButtonContainer ${isSelected?"Selected":""} `+className}
        onClick={()=>setIsSelected(true)}
    >
        <div className={'ColorButton'} style={{backgroundColor: color}}></div>
    </div>
    )
}

const TemplateEdit = ({className="", style={}, givenTemplate={color: 'rgb(255,255,255)'}, setTemplate=()=>{},}) => {

    const [selectedColor, setSelectedColor] = React.useState(givenTemplate.color || 'rgb(255,255,255)');

    const [colors , setColors] = React.useState([]);
    React.useEffect(()=>{
        (async()=>{
            setColors(await themeColors());
        })();
    },[])

    React.useEffect(()=>{
        setTemplate({
            ...givenTemplate,
            color: selectedColor
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[selectedColor]);

    return (
    <div className={`TemplateEdit ${className}`} style={style} >
        <CollapeCell
            title='컬러'
            bodyStyle={{
                display:'flex', flexDirection: 'row', flexWrap: 'wrap',
                justifyContent: 'center', alignItems: 'flex-start'
            }}
        >
            {colors.map(color => 
                <ColorButton
                    key={color}
                    color={color}
                    className={'Button'}
                    isSelected={color === selectedColor}
                    setIsSelected={(value)=>{
                        if(value){
                            setSelectedColor(color)
                        }
                    }}
                />
            )}
        </CollapeCell>
    </div>
    )
}

export default TemplateEdit;