import React, { DragEventHandler, ReactNode, SetStateAction, useEffect, useState } from "react";
import './Styles/SingleUploadImageDisplayed.scss';
import UploadScreen from "./UploadScreen";
import Cropper, { ReactCropperElement } from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { createImageWithBase64 } from "../../../Data/Image";
import { useToastAlert } from '@webtoontoday/toast';
import LoadingCircle from "../../../Component/LoadingCircleOnSmallArea";

const SingleUploadImageDisplayed = ({
    width = 110, height = 110, gap = 8, screenTitle = "", screenSubtitle = [""], children = <></>,
    files, setFiles
}:{ 
    width: number,
    height: number,
    gap?: number | undefined,
    screenTitle?: string, 
    screenSubtitle?: string[],
    children?: ReactNode
    files: {name: string, image: string, createdAt: number}[],
    setFiles: (files: {name: string, image: string, createdAt: number}[]) => void,
}) => {
    const { toastAlert } = useToastAlert();
    
    const [ loadingFile, setLoadingFile ] = useState<{name: string, image: string, createdAt: number}[]>([]);
    const [ uploadedFile, setUploadedFile ] = useState<{name: string, image: string, createdAt: number}[]>(files || []);
    const [ onUploadScreen, setOnUploadScreen ] = useState<boolean>(false);

    const [ cropModalOpen, setCropModalOpen ] = useState<boolean>(false);

    const [ isFileRecognizing, setIsFileRecognizing ] = useState<boolean>(false);

    useEffect( () => {
        if (JSON.stringify(files) !== JSON.stringify(uploadedFile) ) {
            setUploadedFile(files);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[files])
    useEffect( () => {
        if (JSON.stringify(files) !== JSON.stringify(uploadedFile) ) {
            setFiles(uploadedFile);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[uploadedFile])

    const hasUploadedFile = uploadedFile.length > 0;

    const fileUploadFunction = async (FileList: FileList) => {
        setIsFileRecognizing(true);
        if ( !FileList ) {
            setIsFileRecognizing(false);
            return
        };

        let files = FileList;
        const fileTypes = Object.values(FileList).map( ({type}) => type);

        const imageTypeFiles = fileTypes.filter( type => !( type.includes('image')) )
        if ( imageTypeFiles.length > 0 ) {
            toastAlert('image파일만 업로드 할 수 있습니다.', 3000);
            setIsFileRecognizing(false);
            return;
        } 
        
        if ( imageTypeFiles.length > 0 ) {
            toastAlert('첫 번째 image파일이 업로드 됩니다.', 3000);
        }

        const file = files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            const base64String = `${reader.result}`;

            setLoadingFile([{name: file.name, image: base64String, createdAt: file.lastModified}]);
            setCropModalOpen(true);
        }

        reader.readAsDataURL(files[0]);
        setIsFileRecognizing(false);
    }
    
    // 업로드 핸들러
    const uploadHandler = async () => {
        const input = document.createElement('input');
        input.setAttribute('type','file');
        input.setAttribute('accept','image/*');
        input.click();

        input.addEventListener('change', async (e) => {
            if ( !input.files ) return;
            await fileUploadFunction(input.files);
        })
    }

    // 콘테이너 핸들러
    const dragOverHandler: DragEventHandler<HTMLDivElement> = (e) => {
        e.preventDefault();
        const types = e.dataTransfer.types;
        if ( types.length > 0 && types.filter( type => type !== 'Files').length < 1 ) {
            setOnUploadScreen(true);
        }
    }

    // 스크린 핸들러
    const onDropHandler: DragEventHandler<HTMLDivElement> = (e) => {
        e.preventDefault();
        e.stopPropagation();
        
        (async () => {
            setOnUploadScreen(false);
            await fileUploadFunction(e.dataTransfer?.files);
        })()
    }
    const mouseLeaveScreenHandler: DragEventHandler<HTMLDivElement> = (e) => {
        setOnUploadScreen(false);
    }

    return(
        <div className={'MultiUploadFilenameDisplayedArea'} >
            <SingleUploadControlBox 
                uploadHandler = {uploadHandler}
            />
            <div className={'MultiUploadFilenameBody'} >
                <div className={'MultiUploadContainer'}
                    onClick={uploadedFile.length > 0?()=>{}:uploadHandler}
                    onDragOver={dragOverHandler}
                    style={{ width, height }}
                >
                    {uploadedFile.length > 0
                        ?<div style={{position: 'relative', overflow: "hidden"}} >
                            <img 
                                src={uploadedFile[0].image} alt={uploadedFile[0].name} 
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                }}
                            />
                            <img
                                src={"https://static.webtoon.today/ddah/icon/icon_delete.svg"}
                                alt={'delete_Icon'}
                                onClick={() => {
                                    if ( !onUploadScreen ) {
                                        setUploadedFile([]);
                                    }
                                }}
                                style={{
                                    position: 'absolute',
                                    right: 5, top: 5,
                                    width: 12, height: 12,
                                    padding: 2, backgroundColor: 'black',
                                    borderRadius: '50%', cursor: 'pointer'
                                }}
                            />
                        </div>:<></>}
                    {onUploadScreen || !hasUploadedFile
                        ?<UploadScreen 
                            mouseLeaveHandler={mouseLeaveScreenHandler} 
                            onDropHandler={onDropHandler}
                            title={screenTitle}
                            subTitles={screenSubtitle}
                        />
                        :<></>
                    }
                </div>
            </div>
            {children}
            <CropperDialog 
                file={loadingFile} setFile={setUploadedFile} 
                cropModalOpen={cropModalOpen} setCropModalOpen={setCropModalOpen}  
                ratio={width/height}
            />
            <LoadingCircle show={isFileRecognizing} />
        </div>
    )
}

export default SingleUploadImageDisplayed;

const SingleUploadControlBox = ({
    uploadHandler
}:{
    uploadHandler: () => void,
}) => {
    
    return(
        <div className={'SingleUploadControlBox'} >
            <button className={'SingleUploadeButton'} onClick={uploadHandler} >
                {'첨부'}
            </button>
        </div>
    )
}

const CropperDialog = ({
    file, setFile, cropModalOpen, setCropModalOpen, ratio
}:{
    file: {name: string, image: string, createdAt: number}[], ratio: number,
    setFile: React.Dispatch<SetStateAction<{name: string, image: string, createdAt: number}[]>>,
    cropModalOpen: boolean, setCropModalOpen: React.Dispatch<SetStateAction<boolean>>
}) => {
    const { toastAlert } = useToastAlert();

    const [ cropBoxSize, setCropBoxSize ] = useState<{width: number, height: number, naturalWidth: number, naturalHeight: number}>({width: 110, height: 110, naturalWidth: 110, naturalHeight: 110});

    const cropperRef = React.useRef<ReactCropperElement | null>(null);

    const onCrop = () => {
        const imageElement = cropperRef?.current;
        const cropper = imageElement?.cropper;
        if (!cropper) return;
        
        const {width, height, naturalWidth, naturalHeight} = cropper.getCanvasData();
        setCropBoxSize({width, height, naturalWidth, naturalHeight})
    }

    const cropModalClose = () => {
        setCropModalOpen(false);
    }
    const {naturalWidth, width } = cropBoxSize
    const imageRatio = naturalWidth / width;
    
    return(<>
        <Dialog onClose={cropModalClose} open={cropModalOpen} >
            <DialogTitle >
                <div style={{fontSize: '0.9rem', padding: '16px 0 10px'}}>
                    <span style={{color: 'rgb(0,176,240)', fontWeight: 'bold', marginRight: 16}}>
                        {'TIP!'}
                    </span>
                    <span>
                        {`600 x 600 px 이미지를 선택해주세요.`}
                    </span>
                </div>
            </DialogTitle>
            <DialogContent>
                <div style={{display: 'block', padding: '0 30px'}} >
                    <Cropper 
                        crop={onCrop} src={file[0]?.image} ref={cropperRef} 
                        aspectRatio={ratio} initialAspectRatio={220/220} 
                        viewMode={2} checkOrientation={true}
                        background={false}
                        minCropBoxWidth={cropBoxSize.width/imageRatio}
                        minCropBoxHeight={cropBoxSize.height/imageRatio}
                    />
                </div>
            </DialogContent>
            <DialogActions style={{display: 'flex', justifyContent: 'center', padding: '16px 24px'}}>
                <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                    <Button onClick={ () => cropModalClose() } style={{color: 'rgb(0,176,240)', border: '1px solid rgb(0,176,240)', width: 'calc(50% - 20px)'}} >
                        {'취소'}
                    </Button>
                    <Button variant={'contained'} color={"primary"} style={{width: 'calc(50% - 20px)'}} disableElevation
                        onClick={ async () => {
                            (async () => {
                                const imageElement = cropperRef?.current;
                                const cropper = imageElement?.cropper;
                                if (!cropper) return;
                                const cropViewBox = cropper.getCroppedCanvas({width: 600, height: 600});
                                
                                const croppedImage = cropViewBox.toDataURL();
                                const cropImage = await createImageWithBase64(croppedImage);
                                if (cropImage) {
                                    setFile([{...file[0], image: cropImage}]);
                                } else {
                                    toastAlert('썸네일 업로드가 실패했습니다.');
                                }
                                cropModalClose()
                            })()
                        }} >
                        {'확인'}
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    </>)
}