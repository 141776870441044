import axios from 'axios';
import ss from './Session';

export const getProductInfo = async (productId: string): Promise<{name: string, detail: string, amount: number, finalAmount: number, image: string} | null> => {
    
    try{
        let res = await axios.get(`https://challenge-api.webtoon.today/webtoon/product?productId=${encodeURIComponent(productId)}`, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });
        
        if (res.data && res.data.code === 200){
            return res.data.data;
        } else {
            return null;
        };
    }catch(e){
        console.error(e)
        return null;
    };
};