export const currentTermsofuseVersion = "https://webtoon.today/termsofuse/kr";

export const consentList = [
    "독자 반응 수집을 위한 SNS 퍼포먼스 마케팅(광고 게재)에 동의합니다.",
    "접수된 작품의 저작권이 본인의 소유임을 확인합니다.",
    "데이터 분석 결과 및 피드백의 저작권은 오늘의웹툰에 있음을 확인합니다.",
    "접수 시 오늘의웹툰 자유연재플랫폼에 작품이 게재됨을 확인합니다.",
];

export const advertisingExample = {
    serviceId: "today",
    title: "약육강식 로맨스",
    slides: [
        {
            name: '0000-0000',
            image:"https://static.webtoon.today/contents/rbEKayFKKpnywuTXmIGPhOUHpVzfymum/0000-0000.jpg",
            createdAt: Math.round(new Date().getTime() / 1000)
        },
        {
            name: '0001-0000',
            image: "https://static.webtoon.today/contents/rbEKayFKKpnywuTXmIGPhOUHpVzfymum/0001-0000.jpg",
            createdAt: Math.round(new Date().getTime() / 1000)
        },
        {
            name: '0002-0000',
            image: "https://static.webtoon.today/contents/rbEKayFKKpnywuTXmIGPhOUHpVzfymum/0002-0000.jpg",
            createdAt: Math.round(new Date().getTime() / 1000)
        },
        {
            name: '0003-0000',
            image: "https://static.webtoon.today/contents/rbEKayFKKpnywuTXmIGPhOUHpVzfymum/0003-0000.jpg",
            createdAt: Math.round(new Date().getTime() / 1000)
        },
        {
            name: '0004-0000',
            image: "https://static.webtoon.today/contents/rbEKayFKKpnywuTXmIGPhOUHpVzfymum/0004-0000.jpg",
            createdAt: Math.round(new Date().getTime() / 1000)
        },
        {
            name: '0005-0000',
            image: "https://static.webtoon.today/contents/rbEKayFKKpnywuTXmIGPhOUHpVzfymum/0005-0000.jpg",
            createdAt: Math.round(new Date().getTime() / 1000)
        },
        {
            name: '0006-0000',
            image: "https://static.webtoon.today/contents/rbEKayFKKpnywuTXmIGPhOUHpVzfymum/0006-0000.jpg",
            createdAt: Math.round(new Date().getTime() / 1000)
        }
    ],
}
