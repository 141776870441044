import { useEffect } from 'react';
import { pageHeaderOptions } from '../../../../Recoil/PageHeader/PageHeader';
import { useSetRecoilState } from 'recoil';
import ApplicationForm from './Components/Univ2023ApplicationForm';

import './Application.scss';

const Application = () => {
    const setPageHeader = useSetRecoilState(pageHeaderOptions);

    useEffect(() => {
        setPageHeader({
            isHiddenForPC: true,
            isHiddenForMobile: true,
            isAppbarHidden: true,
        })
    }, [setPageHeader])

    return(<div className={'AnalysisApplyPage'}>
        <ApplicationForm />
    </div>)
}

export default Application;