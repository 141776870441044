import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import React from "react";

import './RangeChoice.scss';

/**
 * 
 * @param {{
 *   response: number,
 *   setResponse: (number) => void,
 *   range?: [from_included: number, to_included: number] ,
 *   title: string, subtitle?: string, isRequired?: boolean,
 *   caption_max?: string, caption_min?: string
 * }} param 
 */
const RangeChoice = ({
    response = null, setResponse,
    range = [0,10], caption_max="아주 만족스러움", caption_min="아주 불만족스러움",
    title = "제목", subtitle= "",
    isRequired = false,
}) => {

    let [from_included, to_included] = range;

    return (<div className="RangeChoice QuestionBox">
        <div className={"Title"}>{title}{isRequired?<span className={"RedAsterisk"}>{" *"}</span>:<></>}</div>
        {subtitle?<div className={"Subtitle"}>{subtitle}</div>:<></>}
        <RadioGroup className={"HorizontalRadioGroup"}
            value={response}
            onChange={(event, value)=>{
                setResponse(Number(value))
            }}
        >
            <div className={"OptionText"}>{caption_min}</div>
            {Array(to_included - from_included + 1).fill(0).map( (v,i)=> 
                <FormControlLabel
                    key={`Radio${i+from_included}`}
                    className={"RadioCell"}
                    control={<Radio className={"Radio"}/>}
                    label={`${i+from_included}`}
                    labelPlacement={"top"}
                    value={i+from_included}
                />)}
            <div className={"OptionText"}>{caption_max}</div>
        </RadioGroup>
    </div>);
}

export default RangeChoice;