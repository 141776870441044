import axios from "axios";
import ss from './Session';




export async function verifyEmail (email) {
    try{
        let res = await axios.post('https://challenge-api.webtoon.today/user/email-otp-validation', {email}, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        })

        if (res.data && res.data.code === 200){
            return res.data.data;

        } else {
            return null;

        }
    }catch(e){
        return null;
    }
}


export async function verifyOtp (email, otp) {
    try{
        let res = await axios.post(`https://challenge-api.webtoon.today/user/email-otp-validation`, {email, otp}, {
            headers: {  
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        })

        if (res.data && res.data.code === 200){
            return true;

        } else {
            return false;

        }
    }catch(e){
        return false;
    }
}
