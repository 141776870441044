import axios from 'axios';
import ss from './Session';


export type PostType = {
    postId: number,
    type: string,
    exposure: string,
    significantFrom: number,
    significantTo: number,
    title: string,
    createdAt: number,
    updatedAt: number,
    body: string,
}


export async function readPost (postId: number | null = null): Promise<PostType[]> {
    try{
        let res = await axios.post('https://challenge-api.webtoon.today/post', {postId}, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        })

        if (res.data && res.data.code === 200){

            return res.data.data;
        } else {
            return [];
        }

    } catch(e) {
        return [];
    }
}


export async function createPost (post: Object): Promise<PostType[] | undefined> {
    try{
        let res = await axios.put('https://challenge-api.webtoon.today/post', post, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        })

        if (res.data && res.data.code === 200){

            return res.data.key;
        } else {
            return ;
        }

    } catch(e) {
        return ;
    }
}


export async function updatePost (post: Object): Promise<string | undefined> {
    try{
        let res = await axios.patch('https://challenge-api.webtoon.today/post', post, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        })

        if (res.data && res.data.code === 200){

            return res.data.key;
        } else {
            return ;
        }

    } catch(e) {
        return ;
    }
}


export async function deletePost (postId: number): Promise<string | undefined> {
    try{
        let res = await axios.delete(`https://challenge-api.webtoon.today/post?postId=${postId}`,{
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        })

        if (res.data && res.data.code === 200){

            return res.data.key;
        } else {
            return ;
        }

    } catch(e) {
        return ;
    }
}