import React from 'react';
import './Slideup.scss';

const percentRegex = RegExp('([0-9]*(.[0-9]*))?%')

/**
 * 
 * @param {{
 * style: CSSStyleDeclaration,
 * bodyStyle: CSSStyleDeclaration,
 * headStyle: CSSStyleDeclaration,
 * handleChild: import('react').ReactChild,
 * children: import('react').ReactChildren,
 * defaultTopMargin: number | `${number}%`, defaultBottomClose: number | `${number}%`,
 * open: boolean, setOpen: (boolean) => void,
 * }} param0 
 * @returns 
 */
const Slideup = ({
    className='', style={}, bodyStyle={}, headStyle: handleStyle={}, handleChild=<></>,
    open=false, setOpen=()=>{},
    exposureHeight = 0,
    defaultTopMargin: givenDefaultTopMargin=200,
    defaultBottomClose: givenDefaultBottomClose= 300,
    children
}) => {
    const [defaultTopMargin, setDefaultTopMargin] = React.useState( ((ptn) => ptn?(Number(ptn[1]) / 100.0 * window.innerHeight):givenDefaultTopMargin)(percentRegex.exec(givenDefaultTopMargin)) );
    React.useEffect(()=>{
        setDefaultTopMargin( ((ptn) => ptn?(Number(ptn[1]) / 100.0 * window.innerHeight):givenDefaultTopMargin)(percentRegex.exec(givenDefaultTopMargin)) );
    },[givenDefaultTopMargin])
    const [defaultBottomClose, setDefaultBottomClose] = React.useState( ((ptn) => ptn?(Number(ptn[1]) / 100.0 * window.innerHeight):givenDefaultBottomClose)(percentRegex.exec(givenDefaultBottomClose)) );
    React.useEffect(()=>{
        setDefaultBottomClose( ((ptn) => ptn?(Number(ptn[1]) / 100.0 * window.innerHeight):givenDefaultBottomClose)(percentRegex.exec(givenDefaultBottomClose)) );
    },[givenDefaultBottomClose])

    const [innerHeight, setInnerHeight] = React.useState(window.innerHeight);
    React.useEffect(()=>{
        const resizeListener = (event) => {
            setInnerHeight(window.innerHeight);
        }
        window.addEventListener('resize', resizeListener)

        return ()=>{
            window.removeEventListener('resize', resizeListener);
        }
    },[])
    
    const [pointerY, setPointerY] = React.useState(open?defaultTopMargin:window.innerHeight);
    const [isAnimated, setIsAnimated] = React.useState(false);
    const [pointerDown, setPointerDown] = React.useState(false);

    React.useEffect(()=>{
        if (!pointerDown){
            setIsAnimated(true);
            let timer = null;
            if (pointerY < innerHeight - defaultBottomClose){
                setPointerY(defaultTopMargin)
                timer = setTimeout(()=>{setIsAnimated(false)}, 200);
            }else{
                setPointerY(innerHeight - exposureHeight);
                timer = setTimeout(()=>setOpen(false), 200);
            }
            
            return ()=>{
                if (timer){
                    clearTimeout(timer);
                }
            }
        }
    },[defaultBottomClose, defaultTopMargin, innerHeight, pointerDown, pointerY, setOpen, exposureHeight])

    React.useEffect(()=>{
        if(open){
            setIsAnimated(true);
            let timer, timer2;
            timer = setTimeout(()=>{
                setPointerY(defaultTopMargin)
                timer2 = setTimeout(()=>{setIsAnimated(false)}, 200);
                setIsAnimated(false);
            },20);
            return ()=>{
                if (timer){
                    clearTimeout(timer);
                }
                if (timer2){
                    clearTimeout(timer2);
                }
            }
        }
    },[defaultTopMargin, open])

    if (!open) {
        return (<></>);
    }
    return (<div
        className={className}
        style={{
            display: open?"block":"none",
            position: 'fixed', width: '100%', height: '100vh', top: 0,
            backgroundColor: `rgba(111,111,111,${(innerHeight - pointerY)*1.0/innerHeight})`,
            ...style
        }}
        onMouseMove={(event)=>{
            if (pointerDown){
                setPointerY(event.clientY)

                if (event.buttons === 0){
                    setPointerDown(false);
                }
            }
        }}
        onTouchMove={(event)=>{
            if (pointerDown){
                if (event.touches){
                    setPointerY(event.touches[0].clientY)
                }
            }
        }}
        onMouseUp={()=>setPointerDown(false)}
        onTouchEnd={()=>setPointerDown(false)}

    >
        <div
            className={'bg'} style={{position:'absolute', width: '100%', height: '100%'}}
            onClick={()=>{
                let timer, timer2;
                setIsAnimated(true);
                timer = setTimeout(()=>{
                    setPointerY(innerHeight);
                    timer2 = setTimeout(()=>setOpen(false), 200);
                    setIsAnimated(false);
                },20);
                return ()=>{
                    if (timer){
                        clearTimeout(timer);
                    }
                    if(timer2){
                        clearTimeout(timer2);
                    }
                }
            }}
        ></div>
        <div className='body' style={{position:'absolute', width: '100%', bottom: 0, height: `calc(100% - ${pointerY}px + 15px)`, backgroundColor: 'white', overflow: 'auto', ...(isAnimated?{transition: '200ms'}:{})}}>
            <div
                className='handle' style={{width: '100%', height: '30px', display: 'flex', flexDirection:'column', justifyContent: 'center', alignItems: 'center', ...handleStyle}}
                onMouseDown={()=>setPointerDown(true)}
                onTouchStart={()=>setPointerDown(true)}
            >
                {handleChild}
            </div>
            {children}
        </div>
    </div>);
}

export default Slideup;