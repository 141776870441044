import React, { MouseEventHandler } from 'react';

import './MenuBar.scss';

import { fn, getOffsetInScreen } from '../../../Functions';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons';

const MenuBar = () => {

    const [isBarColorChange, setIsBarColorChange] = React.useState(true);

    React.useEffect(()=>{
        const barColorChanger = ()=>{
            let homeDOM = document.getElementById('Home')
            if (!homeDOM){
                return;
            }

            let offset = getOffsetInScreen(homeDOM);
            if (! ('top' in offset)) {
                return;
            }

            let currentTopPos = offset.top;
            
            let currentBottomPos = offset.height + currentTopPos;
            if( window.innerHeight > 0 && currentBottomPos - 75 > 0){
                if(!isBarColorChange){
                    setIsBarColorChange(true);
                }
            } else {
                if(isBarColorChange){
                    setIsBarColorChange(false);
                }   
            }
        }

        window.addEventListener('scroll', barColorChanger);
        return () => {
            window.removeEventListener('scroll', barColorChanger);
        }
    },[isBarColorChange]);
    
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const handleClick = (event: Event) => {
        if (!event.currentTarget){
            return;
        }
        setAnchorEl(event.currentTarget as HTMLElement);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <div className={'MenuBar'}>
            <div className={`SubmitHeader ${isBarColorChange?"ColorOn":""}`}>
                <div className={"HeadInner"}>
                    <div className={"BarContentArea"}>
                        <a onClick={fn.gotoByAnchor} href={"/"} className={"HashMover"}>
                            <img src={"https://static.webtoon.today/ddah/logo-01.png"} alt={"logo"} />
                        </a>
                        <div className={"Informations"}>
                            <div className={"Information"} >
                                <a href="https://webtoon.today/notice" className={"HashMover"} onClick={fn.gotoByAnchor}>{"공지사항"}</a>
                            </div>
                            <div className={"Information"} >
                                <a href="#Home" className={"HashMover"}>{"Webtoon Analytics"}</a>
                            </div>
                            <div className={"Information"} >
                                <a href="#PostScript" className={"HashMover"}>{"고객 사례"}</a>
                            </div>
                            <div className={"Information"} >
                                <a href="#Member" className={"HashMover"}>{"팀 소개"}</a>
                            </div>
                            <div className={"Information"} >
                                <a href="#Submit" className={"HashMover"}>{"데이터 분석 신청"}</a>
                            </div>
                            <div className={"Information"} >
                                <a href="#QnA" className={"HashMover"}>{"자주 묻는 질문"}</a>
                            </div>
                        </div>
                        <div className={"InformationMenu"}>
                            <IconButton onClick={handleClick as unknown as MouseEventHandler} className={"HashMover"}>
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="simple-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                <MenuItem className={"Information"} onClick={()=>{
                                    fn.goto('https://webtoon.today/notice')
                                }}>
                                    <span className={"HashMover"}>{"공지사항"}</span>
                                </MenuItem>
                                <MenuItem className={"Information"} onClick={()=>{
                                    handleClose();
                                    window.location.hash = "#Home";
                                }}>
                                    <span className={"HashMover"}>{"Webtoon Analytics"}</span>
                                </MenuItem>
                                <MenuItem className={"Information"} onClick={()=>{
                                    handleClose();
                                    window.location.hash = "#PostScript";
                                }}>
                                    <span className={"HashMover"} >{"고객 사례"}</span>
                                </MenuItem>
                                <MenuItem className={"Information"} onClick={()=>{
                                    handleClose();
                                    window.location.hash = "#Member";
                                }}>
                                    <span className={"HashMover"} >{"팀 소개"}</span>
                                </MenuItem>
                                <MenuItem className={"Information"} onClick={()=>{
                                    handleClose();
                                    window.location.hash = "#Submit";
                                }}>
                                    <span className={"HashMover"}>{"데이터 분석 신청"}</span>
                                </MenuItem>
                                <MenuItem className={"Information"} onClick={()=>{
                                    handleClose();
                                    window.location.hash = "#QnA";
                                }}>
                                    <span className={"HashMover"} >{"자주 묻는 질문"}</span>
                                </MenuItem>
                            </Menu>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default MenuBar;


