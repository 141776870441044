import React from 'react';
import {AddCircleRounded} from '@material-ui/icons';
import {fn} from '../../Functions';

import './ArtCard.scss';

const ArtCard = () => {
    return (<div
        className={"ArtCard Button New"}
        style={{
            borderStyle: 'dotted',
        }}
        onClick={()=>{
            fn.goto('/enroll/');
        }}
    >
        <div className={"ButtonDetail"}>
            <AddCircleRounded style={{margin:10}}/>
            {"새 작품 등록하기"}
        </div>
    </div>)
}

export default ArtCard;