import {useRef } from 'react';
import { appearRight, appearUp } from "../../SettingValues";
import AnimateDiv from '../../Component/AnimateDiv'

import './Apply.scss'

import {useOnScreen} from '../../../../Hooks';
import {logBehavior} from '../../../../Data/Behavior';
import { Button } from '@material-ui/core';
import { fn } from '../../../../Functions';
let didSeen = false;

const BenefitCell = ({icon, name, summary}: {icon: string, name: string, summary: string}) => {
    return (
        <AnimateDiv variants={appearRight} className={"BenefitCell"}>
            <div className={"Icon"}><img src={icon} alt={name}/></div>
            <div className={"Benefit"}>
                <span className={"Name"}>{name}</span>
                <span className='Summary'>{summary}</span>
            </div>
        </AnimateDiv>
    )
}

const Apply = () => {

    const ref = useRef(null);
    const onScreen = useOnScreen(ref);
    if (!didSeen && onScreen){
        didSeen = onScreen;
        logBehavior('scroll-apply', {});
    }

    return (

        <div ref={ref} className={"ApplySubRoot LandingSection"} id={"Submit"}>
            
            <div className={"TeamTitle"}>
                <AnimateDiv variants={appearUp} >
                    <div className={"ApplyTitle"}>
                        {"데이터 분석 신청하기"}
                    </div>
                </AnimateDiv>

                <AnimateDiv variants={appearUp}>
                    <div className={"TitleFlex"}>
                        <span>{"데이터 분석을 하면 많은 혜택이 제공됩니다."}</span>
                    
                    </div>
                </AnimateDiv>
            </div>

            <div className={"Section"}>
                    <BenefitCell
                        name='작품 분석 보고서'
                        icon={"https://static.webtoon.today/ddah/report.png"}
                        summary={"독자들의 감상 이력을 확인해보세요. 작품을 체계적으로 발전시켜보세요."}
                    />
                    <BenefitCell
                        name='디렉터의 상세한 피드백'
                        icon={"https://static.webtoon.today/ddah/feedback.png"}
                        summary={"전문 디렉터의 작품에 대한 상세한 피드백을 받을 수 있어요."}
                    />
                    <BenefitCell
                        name='50만원의 창작 지원금'
                        icon={"https://static.webtoon.today/ddah/money.png"}
                        summary={"매달 정해진 인원 없이 기준에 따라 창작 지원금을 드려요."}
                    />
                    <BenefitCell
                        name='작품 마케팅 지원'
                        icon={"https://static.webtoon.today/ddah/marketing.png"}
                        summary={"5,000~7,000 명의 독자들에게 작품을 홍보해 드려요."}
                    />
                </div>

            <Button className={"Button"} onClick={()=>{
                window.scrollTo({top:0});
                fn.goto('/analysis/application');
            }}>{"신청서 작성하기"}</Button>
        </div>

    );

}

export default Apply;