import axios from 'axios';
import ss from './Session';

/**
 * @typedef {{
 *  userid: string,
 *  addressid: string,
 *  name: string | null,
 *  email: string | null,
 *  image: string | null,
 * }} profileType
 */

/**
 * 
 * @param {import('./Session').sessionType} session 
 * @param {{
 *  name?: string,
 *  email?: string,
 *  addressid?: string,
 *  image?: string
 * }} newProfileData
 * @returns {Promise<import('./Session').sessionType|Error>}
 */
export const updateProfile = async (newProfileData,) => {
    let res = await axios.patch('https://challenge-api.webtoon.today/user/profile', newProfileData, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    });

    if (res.data && res.data.code === 200){
        return res.data.data;
    } else {
        return new Error( res.data.message );
    };
};

/**
 * @param {string} targetid
 */

export const readProfile = async (targetid) => {
    
    try{
        
        let res = await readProfileList([targetid]);
        
        if (res?.length > 0) {
            let profile = res[0];
            if (!profile.image){
                profile.image = "https://static.webtoon.today/noprofile.jpg";
            }
            return profile;
        } else {
            return {
                userid: null,
                email: null,
                image: null,
                addressid: null,
                memberState: null,
                name: null,
                gernder: null,
            };
        };
    }catch(e){
        if (e.response && e.response.status === 404) {
            console.log(e);
            return {
                userid: null,
                email: null,
                image: null,
                addressid: null,
                memberState: null,
                name: null,
                gernder: null,
            };
        };
        console.error(e);
        return {
            userid: null,
            email: null,
            image: null,
            addressid: null,
            memberState: null,
            name: null,
            gernder: null,
        };
    }
};

/**
 * @param {string} email
 */


export const readProfileByEmail = async (email) => {
    
    try{
        
        let res = await readProfileList([email]);
        
        if (res?.length > 0) {
            let profile = res[0];
            if (!profile.image){
                profile.image = "https://static.webtoon.today/noprofile.jpg";
            }
            return profile;
        } else {
            return {
                userid: null,
                email: null,
                image: null,
                addressid: null,
                memberState: null,
                name: null,
                gernder: null,
            };
        };
    }catch(e){
        if (e.response && e.response.status === 404) {
            console.log(e);
            return {
                userid: null,
                email: null,
                image: null,
                addressid: null,
                memberState: null,
                name: null,
                gernder: null,
            };
        };
        console.error(e);
        return {
            userid: null,
            email: null,
            image: null,
            addressid: null,
            memberState: null,
            name: null,
            gernder: null,
        };
    }
};


/**
 * @param {string[]} targetids
 */
export const readProfileList = async (targetids) => {
    let res = await axios.post(`https://challenge-api.webtoon.today/user/profile`, {targetid: targetids.join(',')}, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    });
    
    if (res.data.data && res.data.code === 200) {

        return res.data.data;

    } else {
        return null;
    };
};

export const removeProfile = async (userid, ) => {

    let res = await axios.delete(`https://challenge-api.webtoon.today/user/profile?userid=${userid}`, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    });
    
    if (res.data.code === 200) {

        return true;

    } else {
        return false;
    };
};