import axios from 'axios';
import ss from './Session';

export const readTitlesByQuery = async (query: string, type: string, limit: number = 999): Promise<TitleKeyType[] | null> => {
    
    try{
        let res = await axios.get(`https://challenge-api.webtoon.today/webtoon/search?type=${encodeURIComponent(type)}&query=${encodeURIComponent(query)}&limit=${encodeURIComponent(limit)}`, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });
        
        if (res.data && res.data.code === 200){
            return res.data.data;
        } else {
            return null;
        };
    }catch(e){
        console.error(e)
        return null;
    };
};