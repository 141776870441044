import { Button, TextField } from '@material-ui/core';
import React from 'react';
import './SignIn.scss';
import { fn, checkKorean, validateEmailForm } from '../../Functions';
import { readLoginInfo } from '../../Data/Account';
import LoadingCircle from '../../Component/LoadingCircle';
import { logBehavior } from '../../Data/Behavior';
import ToastAutoClose from '../../Component/ToastAutoClose';
import SNSLoginButtons from './SNSLoginButtons';
import moment from 'moment-timezone';
import { useSetRecoilState } from 'recoil';
import { pageHeaderOptions } from '../../Recoil/PageHeader/PageHeader';

const SNS = {
    naver: '네이버',
    google: '구글',
    kakao: '카카오',
    facebook: '페이스북',
}

const EntryPoint = ({email, setPassword, setName, setGender, setBirthday, setEmail}) => {

    const setPageHeader = useSetRecoilState(pageHeaderOptions);
    React.useEffect(()=>{
        setPageHeader({
            titleText: '로그인 / 회원가입',
            headerStyle: {borderBottom: '1px rgb(231,231,231) solid'},
            mobileShortcuts:<></>
        })
    },[setPageHeader])

    React.useEffect(() => {
        const lastLoginMethod = JSON.parse(localStorage.getItem('lastLogin'))
        if (lastLoginMethod){
            setInfoMessage(`${lastLoginMethod}로 로그인하셨었네요!`)
        }
    },[]);

    const FrequencyEmails = [
        '@naver.com',
        '@gmail.com',
        '@daum.net',
        '@hanmail.net',
        '@yahoo.com',
        '@outlook.com',
        '@nate.com',
        '@kakao.com',
    ];

    const [ currentExposedSNS, setCurrentExposedSNS] = React.useState('naver');
    const [ snsListOpen, setSnsListOpen ] = React.useState(false);

    const [ isWrongEmail, setIsWrongEmail ] = React.useState(false);

    const [ isLoading, setIsLoading ] = React.useState(false);
    
    const [ hasKoreanLetters, setHasKoreanLetters ] = React.useState(false);

    const [ infoMessage, setInfoMessage ] = React.useState();
    
    const [ emailList, setEmailList ] = React.useState(FrequencyEmails); 
    const [ selected, setSelected ] = React.useState(-1);
    const [ isDropBox, setIsDropbox ] = React.useState(false);
    const inputRef = React.useRef();

    const SNSCallback = (SNSSignin, type) => async (ssoInfo) => {
        setIsLoading(true);
        
        let newSession = await SNSSignin(ssoInfo);
        
        if (newSession.memberState === 'regular') {
            localStorage.setItem('lastLogin', JSON.stringify(type));
            logBehavior('login(success)');
            fn.goto('/');
        } else if (newSession.memberState === 'associate') {
            logBehavior('signin(start)');
            const response = {
                email: newSession.email,
                image: newSession.image,
                name: newSession.name,
                birthday: moment(newSession.birthday || '').format('YYYY-MM-DD'),
                gender: newSession.gender,
            }

            setEmail(response.email)
            setPassword(response.password)
    
            setName(response.name || null);
            setGender(response.gender || null);
            setBirthday(response.birthday || null);
            
            fn.goto('/signin/personalsetting');
        }
        setIsLoading(false);
    };

    React.useEffect(() => {
        const handleClickOutside = (e) => {
            if (inputRef.current && !inputRef.current.contains(e.target) ) {
                setIsDropbox(false);
            }
        };
    
        document.addEventListener('mousedown', handleClickOutside);
    }, [inputRef]);

    const handleDropDownClick = (email, address) => {
        setEmail(`${email.split('@')[0]}${address}`);
        setIsDropbox(false);
        setSelected(-1);
    };

    return(
        <div className={'MainArea'}>
            <div className={'BodyContainer'}>
                <div className={'TitleBox'}>
                    <div className={'LogoImage'}>
                        <img src={'https://static.webtoon.today/ddah/logo-01.png'} alt={'logo-01'} style={{width: '70px', height: '55px', objectFit: 'cover', objectPosition:'0%'}} />
                    </div>
                    <div className={'TitleText'} >
                        {`오늘의웹툰에
                        오신 것을 환영합니다 :)`}
                    </div>
                    <div className={'SubText'} >
                        {'독자의 마음을 읽는 오늘의웹툰과 함께해요!'}
                    </div>
                </div>
                <div className={'EmailContainer'} ref={inputRef} >
                    <div className={'Label'} >{'이메일'}</div>
                    <TextField value={email || ''} fullWidth onChange={(e)=>{
                        const hasKorean = checkKorean(e.target.value)
                        if (hasKorean) {
                            setHasKoreanLetters(true)
                        } else {
                            setHasKoreanLetters(false)
                        };

                        if (isWrongEmail) {
                            setIsWrongEmail(false);
                        };
                        
                        setEmail(e.target.value);

                        if (e.target.value.includes('@')) {
                            setIsDropbox(true);
                            setEmailList(
                                FrequencyEmails.filter((site) =>
                                    site.includes(e.target.value.split('@')[1]) 
                                    && site !== ('@' + e.target.value.split('@')[1]),
                                ),
                            );
                        } else {
                            setIsDropbox(false);
                            setSelected(-1);
                        }
                    }}
                    onKeyDown = {(e) => {
                        if (isDropBox) {
                            if (e.key === 'ArrowDown') {
                                e.preventDefault()
                                setSelected((selected + 1) % emailList.length);
                            }
                      
                            if (e.key === 'ArrowUp') {
                                e.preventDefault()
                                setSelected(selected - 1 < 0
                                    ?selected - 1 + emailList.length
                                    :selected - 1
                                );
                            }
                            if (e.key === 'Enter' && selected >= 0) {
                                handleDropDownClick(email, emailList[selected]);
                            } else if (e.key === 'Enter') {
                                document.getElementById('ContinueWithEmail')?.click();
                            }
                            if (e.key === 'Escape') {
                                setIsDropbox(false);
                                setSelected(-1);
                            }
                        }
                    }}
                    style={{position: 'relative'}} />
                    {hasKoreanLetters?
                    <div style={{color: 'red', fontSize:'0.75rem'}}>
                        {'한글은 입력할 수 없어요.'}
                    </div>
                    :isWrongEmail?
                    <div style={{color: 'red', fontSize:'0.75rem'}}>
                        {'올바른 이메일을 입력해주세요.'}
                    </div>
                    :<></>}
                    {isDropBox
                        ?<div className={'EmailList'} >
                            {emailList.map( (site, idx) => (
                                <div
                                    style={{ 
                                        backgroundColor:`${selected === idx?'#f5f5f5':'unset'}`, 
                                        color:`${selected === idx?'rgb(0,176,240)':'rgb(51,51,51)'}`, 
                                        cursor: 'pointer', padding: '5px 10px',
                                    }}
                                    key={`${site}`}
                                    onMouseOver={() => setSelected(idx)}
                                    onClick={() => handleDropDownClick(email, site) }
                                >
                                    {site}
                                </div>
                        ))}</div>
                        :<></>}
                </div>
                <div className={'ButtonArea'} >
                    <Button id={'ContinueWithEmail'} className={`Button`} disabled={hasKoreanLetters} style={hasKoreanLetters?{backgroundColor: 'rgb(192,192,192)'}:{}} onClick={()=>{
                        (async ()=>{
                            setInfoMessage('');
                            const res = validateEmailForm(email)

                            if (!email || !res) {
                                setIsWrongEmail(true);
                                return;
                            } else{
                                setIsWrongEmail(false);
                                setIsLoading(true);

                                const {isEmailLoginable, isProfileExist, socialLoginPath} = await readLoginInfo(email)
                                
                                if(isEmailLoginable){
                                    fn.goto(`/login`);
                                } else if (!isEmailLoginable && isProfileExist) {
                                    
                                    // socialPath 가 없으면 비밀번호 변경 페이지로 쓩
                                    if (socialLoginPath === 'none') {
                                        fn.goto('/account/start-reset-password/'+email);
                                    } else if (socialLoginPath.indexOf(Object.keys(SNS))){
                                        // 하나라도 있다면 팝업 띄워주고 버튼 변경
                                        setCurrentExposedSNS(socialLoginPath);
                                        setInfoMessage(`${socialLoginPath}로 계속하기를 눌러주세요.`);
                                    } else {
                                        alert('로그인을 실패했습니다.');
                                        logBehavior('login(fail)', {email, isEmailLoginable, isProfileExist, socialLoginPath});
                                    };

                                } else if (!isEmailLoginable && !isProfileExist) {
                                    fn.goto('/signin/accountsetting')
                                }

                                setIsLoading(false);
                            }
                        })()
                    }} >
                        {'이메일로 계속하기'}
                    </Button>
                    <SNSLoginButtons SelectButtons={[currentExposedSNS]} setIsLoading={setIsLoading} SNSCallback={SNSCallback} />
                    {!snsListOpen
                        ?<Button className={'Button'} style={{backgroundColor: 'white', color:'black', height: '45px', boxSizing: 'border-box'}} onClick={()=> setSnsListOpen(true)} >
                            {'다른 옵션 더 보기'}
                        </Button>
                        :<SNSLoginButtons SelectButtons={Object.keys(SNS).filter(sns => sns !== currentExposedSNS)} setIsLoading={setIsLoading} SNSCallback={SNSCallback} />}
                </div>
                <ToastAutoClose className='MailAlarm' message={infoMessage} timeout={3000} />
            </div>
            <LoadingCircle show={isLoading} />
        </div>
    )
}

export default EntryPoint;