import React, { useCallback } from 'react';
import {BranchFunction, fn, flags} from './Functions';
import {Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText, Button,} from '@material-ui/core';

import {logBehavior} from './Data/Behavior';
import TitleHome from './Page/Title/TitleHome';

import NotFound from './Page/NotFound';
import User from './Page/Account/User';

import SignIn from './Page/Sign/SignIn';
import SigninComplete from './Page/Sign/Complete';
import ResetGuide from './Page/Sign/ResetGuide';

import ResetPassword from './Page/Sign/ResetPassword';
import QnA from './Page/Inquiry/QnA';
import ApplyingAnalysis from './Page/DataAnalysis/ApplyingAnalysis';
import ThemeDecoration from './Page/Title/ThemeDecoration';
import EpisodeHome from './Page/Episode/EpisodeHome';
import NaverChild from './Page/Sign/NaverChild';
import PortfolioEdit from './Page/Portfolio';

import MyPage from './Page/Account/MyPage';
import Unregister from './Page/Account/Unregister';
import UnregisterComplete from './Page/Account/UnregisterComplete';
import UserPage from './Page/Account/User';
import Search from './Route/Search';

import './VirtualRouter.scss';
import Survey from './Route/Survey';
import RealnameAuthentication from './Page/RealnameAuthentication';
import TrendSearch from './Route/Market/TrendSearchPage';

import loadable from '@loadable/component';
import PopularitySearch from './Route/Market/PopularitySearchPage';
import Home from './Route/Home';
import { useSession } from './Recoil/Session/Session';
import { useSetRecoilState } from 'recoil';
import { pageHeaderOptions } from './Recoil/PageHeader/PageHeader';
import { useRouter } from './Recoil/Router/Router';
import Landing from './Route/Landing/Landing';

import ContentsPayment from './Route/Payment/ContentsPayment';
import PostTitle from './Route/Enroll/PostTitle';
import PostEpisode from './Route/Enroll/PostEpisode';
import NoticeList from './Route/Notice/NoticeList';
import NoticeDetail from './Route/Notice/NoticeDetail';
import NoticeWrite from './Route/Notice/NoticeWrite';
import AnalysisComplete from './Page/DataAnalysis/AnalysisComplete';

import University from './Page/Contest/2022/University';
import Univ2023 from './Route/Analysis/Univ2023';

import AnalysisApplyPage2024 from './Route/Analysis/Univ2024/AnalysisApplyPage2024';
import Applying2024University from './Route/Analysis/Univ2024/Applying2024University';
import { isOnSubmission } from './Route/Analysis/Univ2024/Data/Variables';
import Univ2024 from './Route/Analysis/Univ2024';

const Report = loadable(() => import('./Page/Report/Report'));
const SurveyView = loadable(() => import('./Route/Survey/SurveyView'));
const DataAnalyticsDashBoard = loadable(() => import('./Page/MarketAnalysis/MarketAnalysis'));
const Slicer = loadable(() => import('./Route/Slicer'));
// 이후에 사용할 시장 분석 랜딩페이지, 접수페이지, 결제페이지 입니다.
// import MarketLanding from './Page/MarketPages/MarketLanding';
// import MarketApply from './Page/MarketPages/MarketApply';
// import MarketReceipt from './Page/MarketPages/MarketReceipt';
// import PaymentComplete from './Page/MarketPages/PaymentComplete';

/** @typedef {{width: number, height: number}} size */
/** @typedef {{x: number, y: number}} point */
/**
 * @template T
 * @typedef {React.Dispatch<React.SetStateAction<T>>} set<T>
 */

 /**
  * @typedef {import('./App').globalDataType & { 
  *   goto: (urlOrPath: string) => void,
  *   path: string,
  * }} routingDataType
  * */

 /**
  * 
  * @param {string} path 
  * @param {routingDataType} newProps 
  * @returns {() => JSX.Element}
  */
const jsxBranch = (path, query, hash, newProps) => BranchFunction(
    path,
    [
        {
            validator: (path) => path.toLowerCase().startsWith("/contents"),
            sublogic: [
                {
                    validator: (path) => /contents\/([^/]+)\/([^/]+)\/([^/]+)/.exec(path.toLowerCase()),
                    defaultValue: () => {
                        let [,serviceId, titleId, episodeId, snapshotId] = /contents\/([^/]+)\/([^/]+)\/([^/]+)\/?([^/#?]+)?/.exec(path);
                        return <EpisodeHome serviceId={serviceId} titleId={titleId} episodeId={episodeId} snapshotId={snapshotId} query = {query} hash={hash} isClient={newProps.isClient} />
                    }
                },
                {
                    validator: (path) => /contents\/([^/]+)\/([^/]+)/.exec(path.toLowerCase()),
                    defaultValue: () => {
                        let [,serviceId, titleId] = /contents\/([^/]+)\/([^/]+)/.exec(path);
                        return <TitleHome serviceId={serviceId} titleId={titleId} />
                    }
                },
            ],
            defaultValue: () => <TitleHome />
        },
        {
            validator: (path) => path.startsWith('/themedecoration'),
            sublogic: [
                {
                    validator: (path) => /themedecoration\/([^/]+)/.exec(path.toLowerCase()),
                    defaultValue: () => {
                        let [,titleId] = /themedecoration\/([^/]+)/.exec(path);
                        return <ThemeDecoration titleId={titleId}/>
                    }
                }
            ],
            defaultValue: () => <ThemeDecoration />
        },
        {
            validator: (path) => path.toLowerCase().startsWith("/signin") || path.toLowerCase().startsWith("/login"),
            sublogic:[
                {
                    validator: (path) => path.toLowerCase().startsWith("/signin/complete"),
                    defaultValue: () => <SigninComplete />
                },
                {
                    validator: (path) => path.toLowerCase().startsWith("/signin/accountsetting"),
                    defaultValue: () => <SignIn mode={'accountsetting'} />
                },
                {
                    validator: (path) => path.toLowerCase().startsWith("/signin/personalsetting"),
                    defaultValue: () => <SignIn mode={'personalsetting'} />
                },
                {
                    validator: (path) => path.toLowerCase().startsWith("/login"),
                    defaultValue: () => <SignIn mode={'login'} />
                },
            ],
            defaultValue: () => <SignIn />
        },
        {
            validator: (path) => path.toLowerCase().startsWith("/account"),
            sublogic: [
                {
                    validator: (path) => path.toLowerCase().startsWith("/account/start-reset-password"),
                    defaultValue: () => {
                        let [,email] = /account\/start-reset-password\/?([^/?]+)?/.exec(path);
                        return <ResetGuide email={email} />
                    }
                },
                {
                    validator: (path) => path.toLowerCase().startsWith("/account/resetpassword"),
                    defaultValue: () => <ResetPassword />
                },
                {
                    validator: (path) => path.toLowerCase().startsWith("/account/unregister"),
                    sublogic: [
                        {
                            validator: (path) => path.toLowerCase().startsWith("/account/unregister/complete"),
                            defaultValue: () => <UnregisterComplete />
                        },
                    ],
                    defaultValue: () => <Unregister />
                },
            ],
            defaultValue: () => <UserPage />
        },
        {
            validator: (path) => path.toLowerCase().startsWith("/navercallback"),
            defaultValue: () => <NaverChild />
        },
        {
            validator: (path) => path.toLowerCase().startsWith("/enroll"),
            sublogic:[
                {
                    validator: (path) => /^\/enroll\/([^/]+)\/([^/]+)\/?(edit)?$/.exec(path.toLowerCase()),
                    defaultValue: () => {
                        let edit;
                        if ( /enroll\/([^/]+)\/([^/]+)\/edit/.exec(path.toLowerCase()) ) {
                            edit = true;
                        } else {
                            edit = false;
                        };

                        let [,serviceId, titleId] = /enroll\/([^/]+)\/([^/]+)/.exec(path);
                        return <PostTitle serviceId={serviceId} titleId={titleId} edit={edit} />
                    }
                },
                {
                    validator: (path) => /^\/enroll\/([^/]+)\/([^/]+)\/([^/]+)\/?(edit)?$/.exec(path.toLowerCase()),
                    defaultValue: () => {
                        let edit;
                        if ( /enroll\/([^/]+)\/([^/]+)\/([^/]+)\/edit/.exec(path.toLowerCase()) ) {
                            edit = true;
                        } else {
                            edit = false;
                        };

                        let [, serviceId, titleId, episodeId] = /enroll\/([^/]+)\/([^/]+)\/([^/]+)/.exec(path)
                        
                        return <PostEpisode serviceId={serviceId} titleId={titleId} episodeId={episodeId} edit={edit} />
                    }
                }
            ],
            defaultValue: () => <PostTitle />
        },
        {
            validator: (path) => /^\/report\/([^/]+)\/([^/]+)\/([^/]+)\/([^/]+)/.exec(path.toLowerCase()),
            defaultValue: () => {
                let [, serviceId, titleId, episodeId, snapshotId=null] = /report\/([^/]+)\/([^/]+)\/([^/]+)\/?([^/]+)?/.exec(path);
                return <Report serviceId={serviceId} titleId={titleId} episodeId={episodeId} snapshotId={snapshotId}/>
            }
        },
        {
            validator: (path) => path.toLowerCase().startsWith("/user"),
            defaultValue: () => <User />
        },
        {
            validator: (path) => path.toLowerCase().startsWith("/mypage"),
            defaultValue: () => <MyPage />
        },
        {
            validator: (path) => path.toLowerCase().startsWith("/analysis") || path.toLowerCase().startsWith("/application/analysis"),
            sublogic: [
                {
                    validator: (path) => (/analysis\/apply\/([^/]+)\/([^/]+)/.exec(path.toLowerCase())),
                    defaultValue: () => {
                        let [ ,serviceId, titleId ] = /analysis\/apply\/([^/]+)\/([^/]+)/.exec(path);
                        return <ApplyingAnalysis serviceId={serviceId} titleId={titleId} />
                    }
                },
                {
                    validator: (path) => path.toLowerCase().startsWith("/analysis/apply"),
                    defaultValue: () => <AnalysisApplyPage2024 />
                },
                {
                    validator: (path) => (/analysis\/univapply\/([^/]+)\/([^/]+)/.exec(path.toLowerCase())) && isOnSubmission,
                    defaultValue: () => {
                        let [ ,serviceId, titleId ] = /analysis\/univapply\/([^/]+)\/([^/]+)/.exec(path);
                        return <Applying2024University serviceId={serviceId} titleId={titleId} />
                    }
                },
                {
                    validator: (path) => (path.startsWith('/analysis/landing')),
                    defaultValue: () => <Landing />
                },
                {
                    validator: (path) => (/analysis\/complete\/([^/]+)\/([^/]+)/.exec(path.toLowerCase())),
                    defaultValue: () => {
                        let [ ,serviceId, titleId ] = /analysis\/complete\/([^/]+)\/([^/]+)/.exec(path);
                        return <AnalysisComplete serviceId={serviceId} titleId={titleId} />
                    }
                },
                {
                    validator: (path) => /analysis\/univ2024/i.test(path),
                    defaultValue: () => <Univ2024 path={path.replace(/^\/analysis/i,'')} query={query} key={'/analysis/univ2024'} />
                },
                {
                    validator: (path) => path.toLowerCase().startsWith("/analysis/application") || path.toLowerCase().startsWith("/application/analysis"),
                    defaultValue: () => <AnalysisApplyPage2024 />
                },
            ],
            defaultValue: () => <Landing />
        },
        {
            validator: (path) => path.toLowerCase().startsWith("/univ2024"),
            defaultValue: () => <Univ2024 path={path} query={query} key={'/univ2024'} />
        },
        {
            validator: (path) => path.toLowerCase().startsWith("/univ2023"),
            defaultValue: () => <Univ2023 path={path} query={query} key={'/univ2023'} />
        },
        {
            validator: (path) => path.toLowerCase().startsWith("/univ2022"),
            defaultValue: () => <University path={path} query={query} />
        },
        {
            validator: (path) => path.toLowerCase().startsWith("/help"),
            defaultValue: () => <QnA />
        },
        {
            validator: (path) => path.toLowerCase().startsWith('/notfound'),
            defaultValue: () => <NotFound />
        },
        {
            validator: (path) => path.toLowerCase().startsWith('/portfolio'),
            sublogic: [{
                validator: (path) => ((/\/portfolio\/([^/]+)/.exec(path)) || [null, null])[1] === newProps.session.addressid,
                defaultValue: () => {
                    return <PortfolioEdit addressid={newProps.session.addressid}/>
                }
            }],
            defaultValue: () => {
                let [ ,addressid ] = (/\/portfolio\/([^/]+)/.exec(path) || [null, null]);

                return <PortfolioEdit addressid={addressid}/>
            },
        },
        {
            validator: (path) => path.toLowerCase().startsWith('/dashboard'),
            sublogic: [
                {
                    validator: (path) => (/dashboard\/([^/]+)\/([^/]+)/.exec(path.toLowerCase())),
                    defaultValue: () => {
                        let [ ,serviceId, titleId ] = /dashboard\/([^/]+)\/([^/]+)/.exec(path)
                        return <DataAnalyticsDashBoard serviceId={serviceId} titleId={titleId} />
                    }
                },
            ],
            defaultValue: () => <NotFound />
        },
        {
            validator: (path) => path.toLowerCase().startsWith('/search'),
            defaultValue: () => <Search query={query} />
        },
        {
            validator: (path) => path.toLowerCase().startsWith('/survey'),
            sublogic: [
                {
                    validator: (path) => (/survey\/([^/]+)\/result/.exec(path.toLowerCase())),
                    defaultValue: () => {
                        let [ ,surveyId ] = (/\/survey\/([^/]+)\/result/.exec(path) || [null, null]);
                        return <SurveyView {...{surveyId}} />;
                    }
                },
            ],
            defaultValue: () => {
                let [ ,surveyId ] = (/\/survey\/([^/]+)/.exec(path.split('?')[0]) || [null, null]);
                return <Survey {...{surveyId}} />;
            }
        },
        {
            validator: (path) => path.toLowerCase().startsWith('/authenticate'),
            defaultValue: () => <RealnameAuthentication  />
        },
        // 이후에 사용할 시장 분석 랜딩페이지, 접수페이지, 결제페이지 입니다.
        // {
        //     validator: (path) => path.toLowerCase().startsWith('/market'),
        //     sublogic: [
        //         {
        //             validator: (path) => path.toLowerCase().startsWith('/market/apply'),
        //             defaultValue: () => <MarketApply />
        //         },
        //         {
        //             validator: (path) => (/market\/receipt\/([^/]+)\/([^/]+)/.exec(path.toLowerCase())),
        //             defaultValue: () => {
        //                 let [, serviceId, titleId] = /market\/receipt\/([^/]+)\/([^/]+)/.exec(path);
        //                 return <MarketReceipt serviceId={serviceId} titleId={titleId} hash={hash} />
        //             }
        //         },
        //         {
        //             validator: (path) => (/market\/paymentcompleted\/([^/]+)\/([^/]+)/.exec(path.toLowerCase())),
        //             defaultValue: () => {
        //                 let [, serviceId, titleId] = /market\/paymentcompleted\/([^/]+)\/([^/]+)/.exec(path);
        //                 return <PaymentComplete serviceId={serviceId} titleId={titleId} />
        //             }
        //         },
        //     ],
        //     defaultValue: () => <MarketLanding />
        // },
        {
            validator: (path) => path.toLowerCase().startsWith('/payment'),
            sublogic: [
                {
                    validator: (path) => (/payment\/contents\/([^/]+)\/([^/]+)/.exec(path.toLowerCase())),
                    defaultValue: () => {
                        let [,serviceId, titleId, episodeId] = /payment\/contents\/([^/]+)\/([^/]+)\/?([^/]+)?/.exec(path);
                        return <ContentsPayment serviceId={serviceId} titleId={titleId} episodeId={episodeId} hash={hash} />
                    }
                },
            ],
            defaultValue: () => <ContentsPayment />
        },
        {
            validator: (path) => path.startsWith("/notice"),
            sublogic: [
                {
                    validator: (path) => path.startsWith('/notice/write'),
                    sublogic: [
                        {
                            validator: (path) => /notice\/write\/([^/]+)\/*/.exec(path),
                            defaultValue: () => {
                                let [, updatePostId] = /notice\/write\/([^/]+)\/*/.exec(path)
                                return <NoticeWrite {...{updatePostId}}/>
                            },
                        },
                    ],
                    defaultValue: () => <NoticeWrite/>,
                },
                {
                    validator: (path) => /notice\/([^/]+)\/*/.exec(path),
                    defaultValue: () => {
                        let [, postId] = /notice\/([^/]+)\/*/.exec(path)
                        return <NoticeDetail {...{postId}}/>
                    }
                },
            ],
            defaultValue: () => <NoticeList />,
        },
        {
            validator: (path) => path.toLowerCase().startsWith('/slicer') || path.toLowerCase().startsWith('/imageslicer') || path.toLowerCase().startsWith('/webtoonslicer'),
            defaultValue: () => (flags.isClient && <Slicer />)
        },
        {
            validator: (path) => path.toLowerCase().startsWith('/market') || path.toLowerCase().startsWith('/trendsearch'),
            sublogic: [
                {
                    validator: (path) => path.toLowerCase().startsWith('/market/trendsearch') || path.toLowerCase().startsWith('/trendsearch'),
                    defaultValue: () => <TrendSearch query={query} />
                },
                {
                    validator: (path) => path.toLowerCase().startsWith('/market/popularitysearch'),
                    defaultValue: () => <PopularitySearch query={query} />
                },
            ],
            defaultValue: () => <NotFound />
        },
        {
            validator: (path) => path.toLowerCase().startsWith('/'),
            defaultValue: () => <Home query={query} />
        },
    ],
    () => <NotFound />,
)

/**
 * 
 * @param {import('./App').globalDataType} props 
 */
const VirtualRouter = (props) => {
    const { session } = useSession();
    const setPageHeader = useSetRecoilState(pageHeaderOptions);
    const {pushRedirectTo, popRedirectTo} = useRouter();
    
    /**
     * @type {[
     *      [string, set<string>],
     *      [size, set<size>],
     *      [number, set<number>],
     * ]}
     * */
    const [path, setPath] = React.useState((props.injectedLocation || window.location.pathname).replace(window.location.origin));
    const [query, setQuery] = React.useState('?' + (props.injectedLocation?.split('?')[1]?.split('#')[0] || window.location.search.slice(1)));
    const [hash, setHash] = React.useState((props.injectedLocation || '').split('#')[1] || window.location.hash);

    React.useEffect(()=>{
        const historyListener = ()=>{
            setPath(window.location.pathname);
            setQuery(window.location.search);
            setHash(window.location.hash);
        };
        window.addEventListener('popstate', historyListener);

        const searchParams = new URL(window.location.href).searchParams;
        if (searchParams.has('redirectTo')){
            pushRedirectTo(searchParams.get('redirectTo'));
        }

        return ()=> {
            window.removeEventListener('popstate', historyListener);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const handleUrlOrPath = useCallback((stateHandling) => (urlOrPath) => {
        if (urlOrPath === window.location.pathname){
            return;
        }
        setPageHeader({});
        logBehavior('goto', {to: urlOrPath});

        if (urlOrPath === undefined || urlOrPath === null) {
            window.history.back();
        }else if (urlOrPath.startsWith("/")){
            if (window.gtag){
                window.gtag('config', "G-8PRR5WLG9M", {'page_path': urlOrPath});
            }            
            
            const path = urlOrPath.split('#')[0];
            const hash = urlOrPath.split('#')[1];
            
            if (path.startsWith("/") || path === ""){
                setPath(path);
                setQuery('?' + (path.split('?')[1] || ''));
                setHash(hash);

                let query = new URL('https://'+window.location.host + path).searchParams;

                if (query.has('redirectTo')){
                    pushRedirectTo(query.get('redirectTo'));
                }

                if(stateHandling === 'push'){
                    window.history.pushState({}, document.title, window.location.origin + path + (hash?`#${hash}`:``));
                } else{
                    window.history.replaceState({}, document.title, window.location.origin + path + (hash?`#${hash}`:``));
                }
            }
        }else {
            window.open(urlOrPath);
        }
    }, [pushRedirectTo, setPageHeader]);

    const goto = handleUrlOrPath('push');
    const replaceWith = handleUrlOrPath('replace');

    React.useEffect(()=>{
        fn.goto = goto;
        fn.replaceWith = replaceWith;
        fn.redirectIfExists = ()=> {
            let newPath = popRedirectTo();

            if (newPath){
                fn.goto(newPath);
            }
        }
    },[popRedirectTo, goto, replaceWith]);

    /**
     * @type {[
     *      [boolean, set<boolean>],
     *      [string, set<string>],
     *      [string, set<string>],
     *      [(boolean)=>void, set<(boolean)=>void>],
     *      [string, set<string>],
     *      [string, set<string>],
     * ]}
     */
    const [
        [warningShown, setWarningShown],
        [warningTitle, setWarningTitle],
        [warningMessage, setWarningMessage],
        [warningShownCallback, setWarningShownCallback],
        [warningOKText, setWarningOkText],
        [warningCancelText, setWarningCancelText],
    ] = [
        React.useState(false),
        React.useState(""),
        React.useState(""),
        React.useState((isOkay) => {}),
        React.useState(""),
        React.useState(""),
    ];
    

    /**
     * 
     * @param {string} warningTitle 
     * @param {string} warningMessage 
     * @param {(boolean) => void} warningShownCallback 
     * @param {string?} warningOKText 
     * @param {string?} warningCancelText 
     */
    const [showWarning,] = React.useState(()=>(warningTitle, warningMessage, warningShownCallback, warningOKText, warningCancelText) => {
        setWarningTitle(warningTitle);
        setWarningMessage(warningMessage);
        setWarningShownCallback(warningShownCallback);
        setWarningOkText(warningOKText);
        setWarningCancelText(warningCancelText);
    });

    /** @type {routingDataType} */
    const newProps = {
        isClient: props.isClient,
        goto, path,
        showWarning,
        session: session
    };
    
    return (
    <>
        <div className={"Router"}>
            <div style={{width:'100%'}}>
                {jsxBranch(path, query, hash, newProps)()}
            </div>
        </div>
        <Dialog
            open={warningShown}
        >
            <DialogTitle>{warningTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>{warningMessage}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>{
                    setWarningShown(false)
                    warningShownCallback(false)
                }} color="default">
                    {warningCancelText || "Close"}
                </Button>
                <Button onClick={()=>{
                    setWarningShown(true)
                    warningShownCallback(true)
                }} color="primary">
                {warningOKText || "OK"}
                </Button>
            </DialogActions>
        </Dialog>
    </>
    );
}

export default VirtualRouter