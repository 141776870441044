import { TextField } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { CSSProperties, useEffect, useRef, useState } from "react";
import { computeFuzzyDistance, fn } from "../../../Functions";

import './Styles/SearchBar.scss';
import { getKeywordToTopic, getMarketTitleList, keywordToTopicType, marketTitleListType } from "../../../Data/MarketKeyword";

const SearchBar = ({ 
    searchType, searchText = '', placeholder, style, iconStyle
}:{
    searchType: 'trend' | 'popularity'
    searchText?: string,
    placeholder?: string,
    style?: CSSProperties,
    iconStyle?: CSSProperties,
}) => {
    const [ isDropbox, setIsDropbox ] = useState(false);
    const [ matchList, setMatchList ] = useState<string[]>([]);
    const [ selected, setSelected ] = useState<number>(-1);
    const [ text, setText ] = useState<string>('')
    const [ keywordList, setKeywordList ] = useState<marketTitleListType | keywordToTopicType | null>(null);

    const inputRef = useRef<HTMLDivElement>(null);
    const focusRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if(keywordList){
            return;
        }

        (async() => {
            let keywords;
            switch(searchType){
                case "trend":{
                    keywords = await getKeywordToTopic();
                    break;
                }
                case "popularity":{
                    keywords = await getMarketTitleList();
                    break;
                }
            }
            if(!keywords){
                return;
            }
            setKeywordList(keywords);
        })();
    })

    useEffect( () => {
        const handleClickOutside = (e: any) => {
            if (   inputRef 
                && inputRef.current 
                && !inputRef.current.contains(e?.target) ) {
                setIsDropbox(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
    },[inputRef])
    
    useEffect( () => {
        setText(searchText);
    },[searchText])

    const handleDropDownClick = (keyword: string) => {
        setIsDropbox(false);
        switch(searchType){
            case "trend":
                fn.goto(`/market/trendsearch?topic=${keyword}`);
                break;
            case "popularity":{
                if(!keywordList){
                    break;
                }
                const {serviceId, titleId} = keywordList[keyword] as { serviceId: string, titleId: string};
                fn.goto(`/market/popularitysearch?serviceId=${serviceId}&titleId=${titleId}`);
                break;
            }
        }
    };
    
    return <div className={'SearchBarContainer'} style={{...style}} ref={inputRef} >
        <TextField
            value={text}
            onChange={(e) => {
                setText(e.target.value);

                if(!keywordList){
                    return;
                }
                const keywords = Object.keys(keywordList).map(keyword => ([keyword, computeFuzzyDistance(e.target.value.toLowerCase(), keyword || "NEVEREXISTINGSTRING") ] as [string, number]))
                                                    .filter( ([keyword, distance]) => distance >= 0 )
                                                    .sort( ([keywordA, distanceA], [keywordB, distanceB]) => distanceA - distanceB )
                                                    .map( ([keyword, distance]) => keyword);

                setMatchList(keywords);

                if ( keywords.length > 0 ) {
                    setIsDropbox(true);
                } else {
                    setIsDropbox(false);
                }
                setSelected(-1);
                
                if (e.target.value.length < 1) {
                    setIsDropbox(false);
                }
            }}
            placeholder={placeholder}
            onKeyDown = {(e) => {
                if (isDropbox) {
                    if (e.key === 'ArrowDown') {
                        e.preventDefault()
                        const newSelected = (selected + 1) % matchList.length
                        setSelected(newSelected);
                        if ( focusRef && focusRef.current ) {
                            (focusRef.current.children[newSelected] as HTMLElement).scrollIntoView({ behavior: 'auto', block: 'nearest'})
                        }
                    } else if (e.key === 'ArrowUp') {
                        e.preventDefault()
                        const newSelected = selected - 1 < 0
                                                ?selected - 1 + matchList.length
                                                :selected - 1
                        setSelected(newSelected);
                        if ( focusRef && focusRef.current ) {
                            (focusRef.current.children[newSelected] as HTMLElement).scrollIntoView({ behavior: 'auto', block: 'nearest'})
                        }
                    } else if (e.key === 'Enter')  {
                        if ( selected < 0 ) {
                            handleDropDownClick(text);
                        } else {
                            handleDropDownClick(matchList[selected]);
                        }

                    } else if (e.key === 'Escape') {
                        setIsDropbox(false);
                        setSelected(-1);
                    }
                } else {
                    if (e.key === 'Enter' && selected < 0) {
                        handleDropDownClick(text);
                    }
                }
            }}
        />
        <div className={'SearchIconBox'}>
            <Search 
                className={'SearchIcon'}
                style={{...iconStyle}}
                onClick={ () => {
                    handleDropDownClick(text);
                }}
            />
        </div>
        {isDropbox
            ?<div className={`KeywordList ${matchList.length < 7?'Responsive':'Fullsize'}`} ref={focusRef} >
                {matchList.map( (keyword, idx) => (
                    <div 
                        className={`KeywordRow ${selected === idx?'SelectedRow':''}`}
                        key={`SearchBar:${keyword}`}
                        onMouseOver={() => setSelected(idx)}
                        onClick={() => {
                            handleDropDownClick(keyword)
                        }}
                    >
                        <div className={'SearchIconBox'}>
                            <Search fontSize={'small'} />
                        </div>
                        <div className={'Keyword'} >
                            {keyword}
                        </div>
                    </div>
            ))}</div>
            :<></>}
    </div>
}

export default SearchBar;