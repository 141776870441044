import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from "@material-ui/core";
import { Add, AddPhotoAlternate, DragIndicator, HighlightOff } from "@material-ui/icons";
import { logBehavior } from "../Data/Behavior";
import { createImageWithS3, spreadZipOnS3 } from "../Data/Image";
import React from 'react';
import './MultiManuscriptUpload.scss';
import ChannelService from "./ChannelService";
import { useToastAlert } from '@webtoontoday/toast';


const MultiManuscriptUpload = ({manuscript, setManuscript, setIsLoading = ()=>{}, setIsImagesLoading = ()=>{}}) => {
    const { toastAlert } = useToastAlert();

    const [isDraggingOrder, setIsDraggingOrder] = React.useState(false);
    const [draggingElement, setDraggingElement] = React.useState(null);
    const [errorPopupOpen, setErrorPopupOpen] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');

    const uploadFunction = async (input) => {
        
        let newFiles;
        let orgFiles = [...manuscript];
        
        const fileTypeArray = Object.values(input.files).map(file => file.type)

        const imageTypeArray = fileTypeArray.filter(type => type.split('/')[0] === 'image')
        const zipTypeArray = fileTypeArray.filter(type => type === 'application/x-zip-compressed')
        
        const addZipImages = async (i) => {
            setIsLoading(true);

            const zipFileName = await createImageWithS3(input);
            const fileUrls = await spreadZipOnS3(zipFileName);
            newFiles = [];

            if (!zipFileName || !fileUrls) {
                setErrorMessage('ZIP파일 업로드를 실패했습니다.')
                setErrorPopupOpen(true);

                setIsLoading(false);
                return;
            };

            for ( const fileUrl of fileUrls ) {
                newFiles.push([
                    fileUrl.split('/').reverse().slice(0,2).reverse().join('_'),
                    fileUrl
                ]);
            };

            setManuscript([...orgFiles, ...newFiles]);
            logBehavior('upload-manuscript',{type: 'zip', manuscript: [...orgFiles, ...newFiles]})
            setIsLoading(false);
        };

        if (   ( imageTypeArray.length > 0 && imageTypeArray.length !== fileTypeArray.length  ) ) {
            toastAlert('이미지 또는 ZIP 중 한 종류의 파일만 선택해주세요.', 5000)
            return;
        }else if ( zipTypeArray.length > 1) {
            toastAlert('ZIP파일은 한 개만 선택할 수 있어요.', 5000)
            return;
        }else if (zipTypeArray.length === 1) {
            addZipImages();
            return;
        }else if (imageTypeArray.length > 0) {

            let newFiles = [];
            setIsImagesLoading(true);
            for (let i = 0; i < input.files.length; i++){
                let filename = await createImageWithS3({name: input.files[i].name, files:[input.files[i]]});

                if (!filename) {
                    setErrorMessage("이미지 업로드를 실패했습니다.")
                    setErrorPopupOpen(true);

                    setIsImagesLoading(false);
                    return;
                }

                newFiles.push([
                    input.files[i].name,
                    filename
                ]);
            };
            setManuscript([...orgFiles, ...newFiles]);
            logBehavior('upload-manuscript',{type: 'image', manuscript: [...orgFiles, ...newFiles]})
            setIsImagesLoading(false);
            
            return;
        };
    }

    const fileUploadHandler = (e) => {
        const input = document.createElement('input');
        input.setAttribute('type','file');
        input.setAttribute('multiple','');
        input.click();

        input.addEventListener('change', async(e)=>{
            uploadFunction(input);
        });
    };

    const [ isDraggingOnManuscript, setIsDraggingOnManuscript ] = React.useState(false);
    const [ onManuscriptItemDragging, setOnManuscriptItemDragging ] = React.useState(false);

    return (
        
    <div className={`ManuscriptBox`} 
        onDragOver={(e)=>{
            if (onManuscriptItemDragging) return;
            setIsDraggingOnManuscript(true);
        }}
        onClick={fileUploadHandler}
    >
        <div className={`ScriptBoxInner ${(manuscript || []).length === 0?'Center':''}`}>
            {(manuscript || []).length > 0
                ?<div className={'Rows'} >
                    <div className={'RowBox'} >
                        <div className={'Files'} >
                            {manuscript.map(([name, file], index) => (
                                <div 
                                    className={'ProductRow'} key={`ThirdRow:${file}`}
                                    onDragOver={ (e) => {
                                        if (!onManuscriptItemDragging) {
                                            setIsDraggingOnManuscript(true);
                                            return;
                                        };
                                        const draggedOverItem = manuscript[index];
                                        if ( !isDraggingOrder ) {
                                            return;
                                        };

                                        if ( draggedOverItem === draggingElement ) {
                                            return;
                                        }
                                        let newManuscripts = manuscript.filter((manuscript) => manuscript !== draggingElement);
                                        newManuscripts.splice(index, 0, draggingElement);

                                        setManuscript(newManuscripts);
                                    }}
                                >
                                    <div 
                                        className={'NameCell'} draggable
                                        onDragStart={ (e) => {
                                            setOnManuscriptItemDragging(true);
                                            setDraggingElement(manuscript[index]);
                                            e.dataTransfer.effectAllowed = "move";
                                            e.dataTransfer.setData('text/html', draggingElement);
                                            e.dataTransfer.setDragImage(e.currentTarget.parentNode, 20, 20);
                                            setIsDraggingOrder(true);
                                        }}
                                        onDragEnd={ (e) => {
                                            setOnManuscriptItemDragging(false);
                                            setDraggingElement(null);
                                            setIsDraggingOrder(false);
                                        }}
                                    >
                                        <DragIndicator style={{color: 'rgb(196,196,196)'}} />
                                        <div className={'FileName'} >{name}</div>
                                        <IconButton style={{padding: 0}} onClick={(e)=>{
                                            e.stopPropagation();
                                            setManuscript(manuscript.filter((script,idx)=> script.join('') !== [name, file].join('')))
                                        }}>
                                            <HighlightOff />
                                        </IconButton>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className={'AddImage'} ><AddPhotoAlternate fontSize={'large'} style={{color: 'lightgray'}} /> </div>
                </div>
                :<>
                    <AddPhotoAlternate fontSize={'large'} style={{color: 'lightgray'}} />
                </>}
        </div>
        <div 
            className={`ManuscriptsDropZone ${isDraggingOnManuscript && !onManuscriptItemDragging?'hover':''}`}
            onDragOver={(e)=>{
                if (onManuscriptItemDragging) return;
                e.preventDefault();
                setIsDraggingOnManuscript(true);
            }} onDragLeave={(e)=>{
                e.preventDefault();
                setIsDraggingOnManuscript(false);
            }} onDrop={(e)=>{
                if (onManuscriptItemDragging) return;
                e.preventDefault();
                
                const input = document.createElement('input');
                input.setAttribute('type', 'file');
                input.setAttribute('multiple', '');
                input.files = e.dataTransfer.files;
                
                uploadFunction(input);
                setIsDraggingOnManuscript(false);
            }}
        >
            <Add style={{color: 'rgb(0,176,240)', pointerEvents: 'none'}} />
        </div>
        <Dialog className={"FailDialog"} open={errorPopupOpen}>
            <DialogTitle>
                {"오류가 발생했어요."}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <div style={{wordBreak: 'keep-all'}}>{errorMessage}</div>
                    <div>{'같은 문제가 반복되면 문의해 주세요.'}</div>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button disableElevation onClick={()=>{
                    setErrorPopupOpen(false);
                }} >
                    {'다시 해보기'}
                </Button>
                <Button variant={"contained"} disableElevation className={"PopupButton"}
                    onClick={(e)=>{
                        e.stopPropagation();
                        ChannelService.ChannelIO()('openChat');
                        setErrorPopupOpen(false)
                    }} color={"primary"} >
                    {"문의하기"}
                </Button>
            </DialogActions>
        </Dialog>
    </div>
    )
}

export default MultiManuscriptUpload;