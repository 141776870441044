import React from 'react';
import { verifyEmail, verifyOtp } from '../../Data/Email';
import { Check, ErrorOutline } from '@material-ui/icons';
import { Button, TextField, Checkbox, FormControlLabel } from '@material-ui/core';
import './SignIn.scss';
import { validateEmailForm, checkKorean, fn } from '../../Functions';
import LoadingCircle from '../../Component/LoadingCircle';

import ToastAutoClose from '../../Component/ToastAutoClose';

import {AskMDLToken, AskCertificate, FetchCertificationResult} from '../../Data/PhoneCertification';
import moment from 'moment-timezone';
import { useSetRecoilState } from 'recoil';
import { pageHeaderOptions } from '../../Recoil/PageHeader/PageHeader';

const AccountSetting = ({
    email, setBirthday, otp, setOtp, password, setPassword, setGender, setName,
    certificatedName, certificatedBirthday, certificatedPhoneNumber,
    setCertificatedName, setCertificatedBirthday, setCertificatedPhoneNumber
}) => {

    if (!email){
        fn.goto('/signin');
    }

    const setPageHeader = useSetRecoilState(pageHeaderOptions);
    React.useEffect(()=>{
        setPageHeader({
            titleText: '회원가입',
            headerStyle: {borderBottom: '1px rgb(231,231,231) solid'},
            mobileShortcuts:<></>
        })
    },[setPageHeader])
    

    const checkEnglish = (password) => {
        const regx = /[a-zA-Z]/;
        return regx.test(password)
    }

    const checkNumber = (password) => {
        const regx = /[0-9]/;
        return regx.test(password)
    }

    const [ onVerifying, setOnVerifying ] = React.useState(false);
    const [ correspondedPassword, setCorrespondedPassword ] = React.useState(null);
    const [ isCorrespondPasswordVisible, setIsCorrespondPasswordVisible ] = React.useState(false);
    
    const [ isEmailVerified, setIsEmailVerified ] = React.useState(false);

    const [ disabled, setDisabled ] = React.useState(false);
    const [ hasKoreanLetters, setHasKoreanLetters ] = React.useState(false);

    const [ isAuthenticated, setIsAuthenticated ] = React.useState(false);
    const [ isPasswordVisible , setIsPasswordVisible ] = React.useState(false);

    const [ infoMessage, setInfoMessage ] = React.useState();
    const [ isLoading, setIsLoading ] = React.useState();

    React.useEffect( () => {
        
        if (certificatedName && certificatedBirthday && certificatedPhoneNumber) {
            setIsAuthenticated(true);
        };

    },[certificatedName, certificatedBirthday, certificatedPhoneNumber]);

    return(
        <div className={'MainArea'}>
            <div className={'BodyContainer'}>
                <div className={'InnerInputBox'}>
                    <div className={'BoxLabel'}>
                        {'이메일'}
                    </div>
                    <div style={{display: 'flex'}}>
                        <TextField disabled fullWidth value={email || ''} />
                        {!isEmailVerified
                        ?<Button disableElevation onClick={ () => {
                            setDisabled(true);
                            
                            ( async () => {
                                setIsLoading(true);

                                const result = validateEmailForm(email)
                                if (result) {
                                    const res = await verifyEmail(email)
                                    if (res) {
                                        setOnVerifying(true);
                                        setInfoMessage("메일함을 확인해주세요!")
                                        
                                        setIsLoading(false);
                                        setDisabled(false);
                                        return res;
                                    } else {
                                        alert('메일 발송에 실패했습니다.')
                                    }

                                } else {
                                    alert('메일 주소를 확인해주세요.')
                                }
                                
                                setIsLoading(false);
                                setDisabled(false);
                            })();

                        }}
                            disabled={isEmailVerified}
                            className={`EmailStateButton`}
                        > 
                            {'인증번호 받기'}
                        </Button>:
                        <div className={'EmailStateButton'}>
                            {'인증 완료'}<Check style={{fontSize: '0.8rem', paddingLeft: '1px'}} />
                        </div>}
                    </div>
                    {!isEmailVerified
                    ?<div className={'BelowField'}>
                        <span className={'NeedOfCertification'} >
                            <ErrorOutline style={{fontSize: '1rem', backgroundColor: 'white', color:'rgb(0,176,240)'}} />
                            {'이메일 인증이 필요해요'}
                        </span>
                    </div>:<></>}
                    {onVerifying
                    ?<div className={'BoxLabel'} style={{marginTop: '16px'}}>
                        {'인증 번호'}
                    </div>
                    :<></>}
                    {onVerifying
                    ?<div style={{display: 'flex'}}>
                        <TextField fullWidth value={otp || ''} onChange={(event) => {
                            setOtp(event.target.value)
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter'){
                                document.getElementById('EmailOTPCheck').click();
                            }
                        }}
                        />
                        <Button id={"EmailOTPCheck"} disableElevation className={'EmailStateButton'} onClick={ () => {
                            setDisabled(true);

                            ( async()=>{
                                const result = await verifyOtp(email, otp)
                                if (result) {
                                    setIsEmailVerified(true);
                                    setOnVerifying(false);
                                    document.getElementById('PasswordInput').focus();
                                } else {
                                    alert('인증에 실패했습니다. 다시 인증을 진행해주세요')
                                    setOtp(null);
                                }

                                setDisabled(false);
                            })();
                        }}>
                            {"인증하기"}
                        </Button>
                    </div>
                    :<></>}
                </div>
                <div className={'InnerInputBox'}>
                    <div className={'BoxLabel'}>
                        {'비밀번호'}
                    </div>
                    <form>
                        <TextField 
                            id={"PasswordInput"} fullWidth value={password || ''} type={`${isPasswordVisible?'text':'password'}`} error={hasKoreanLetters}
                            onChange={(e)=>{
                                const hasKorean = checkKorean(e.target.value)
                                if (hasKorean) {
                                    setHasKoreanLetters(true)
                                } else {
                                    setHasKoreanLetters(false)
                                }
                                setPassword(e.target.value)
                            }}
                            onKeyDown={(e)=>{
                                if (e.key === 'Enter'){
                                    e.preventDefault();
                                    document.getElementById('PasswordCheckInput').focus();
                                }
                            }} 
                        />
                    </form>
                    <div className={'BelowField'} >
                        {!hasKoreanLetters
                        ?<div className={'PasswordRuleCheck'} >
                            <div className={`CheckText ${checkEnglish(password) && password?'Checked':''}`} >
                                {'영문'}<Check className={`CheckIcon`} />
                            </div>
                            <div className={`CheckText ${checkNumber(password) && password?'Checked':''}`} >
                                {'숫자'}<Check className={`CheckIcon`} />
                            </div>
                            <div className={`CheckText ${password && password.length>=6 && password.length<=20?'Checked':''}`} >
                                {'6-20 자리'}<Check className={`CheckIcon`} />
                            </div>
                        </div>
                        :<div>
                            <div style={{color: 'red', fontSize: '0.75rem'}}>
                                {'영문, 숫자, 특수문자만 입력할 수 있어요.'}
                            </div>
                        </div>}
                        <div className={'ShowingPassword'} >
                            <FormControlLabel control={
                                <Checkbox tabIndex={-1} className={'VisibleCheckBox'} color={'primary'}
                                    checked={isPasswordVisible} onChange={(e)=>setIsPasswordVisible(e.target.checked)}
                                />
                            } label={'비밀번호 표시'} />
                        </div>
                    </div>
                </div>
                <div className={'InnerInputBox'}>
                    <div className={'BoxLabel'}>
                        {'비밀번호 확인'}
                    </div>
                    <form>
                        <TextField id={"PasswordCheckInput"} fullWidth value={correspondedPassword || ''} type={`${isCorrespondPasswordVisible?'text':'password'}`}
                            onChange={(e)=>{
                                setCorrespondedPassword(e.target.value)
                            }}
                            onKeyDown={(e)=>{
                                if (e.key === 'Enter'){
                                    e.preventDefault();
                                    document.getElementById('AccountSettingDone').click();
                                }
                            }} 
                            error={(correspondedPassword && password !== correspondedPassword)?true:false}
                        />
                    </form>
                    <div className={'BelowField'}>
                        <div className={'CorrespondState'}>
                            { correspondedPassword && password !== correspondedPassword?'비밀번호가 달라요! 확인해보시겠어요?':''}
                        </div>
                        <div className={'ShowingPassword'} >
                            <FormControlLabel control={
                                <Checkbox tabIndex={-1} className={'VisibleCheckBox'} color={'primary'}
                                    checked={isCorrespondPasswordVisible} onChange={ (e) => setIsCorrespondPasswordVisible(e.target.checked) }
                                />
                            } label={'비밀번호 표시'} />
                        </div>
                    </div>
                </div>
                { <div className={'InnerInputBox'} >
                    <div className={'BoxLabel'} style={{lineHeight: '24px', whiteSpace: 'pre-line'}}>
                        {`실명 확인(선택사항)
                        도전만화에 작품을 올리시려면 실명확인이 필요해요`}
                    </div>
                    {!isAuthenticated
                    ?<Button className={'Identification'} fullWidth
                        onClick={()=>{
                            
                            (async()=>{
                                setIsLoading(true);

                                let mdl_tkn = await AskMDLToken();
                                console.log({mdl_tkn});
                            
                                try{
                                    await AskCertificate(mdl_tkn);
                                }catch(e){
                                    alert("팝업 차단을 해제해 주세요.");
                                    return;
                                }
                        
                                const result = await FetchCertificationResult(mdl_tkn);
                                
                                if (result && result.code === 200){
                                    setIsAuthenticated(true);
                                    
                                    setCertificatedName(result.user.RSLT_NAME)
                                    setCertificatedBirthday(result.user.RSLT_BIRTHDAY)
                                    setCertificatedPhoneNumber(result.user.TEL_NO)

                                    setName(result.user.RSLT_NAME);
                                    setBirthday( moment(result.user.RSLT_BIRTHDAY).format('YYYY-MM-DD')  );
                                    setGender(result.user.RSLT_SEX_CD)
                                } else {
                                    console.error('정보 로딩에 실패했습니다.')
                                }
                                
                                setIsLoading(false);
                            })();
                        }}>
                        {'실명확인'}
                    </Button>
                    :<Button className={'IdentifiedButton'} fullWidth disabled>
                        {'실명 확인 완료 '}<Check style={{fontSize: '0.75rem', paddingLeft: '1px'}} />
                    </Button>
                    }
                </div> }
                <div className={'BottomGroup'}>
                    <Button id={'AccountSettingDone'} variant={'contained'} color={'primary'} fullWidth
                        disabled={ !isEmailVerified || !password || password !== correspondedPassword || hasKoreanLetters || !checkNumber(password) || !checkEnglish(password) ||
                            password.length<6 || password.length>20 } 
                        onClick={()=>{
                            fn.goto('/signin/personalsetting')
                        }} 
                    >{'다음'}</Button>
                    <ToastAutoClose message={infoMessage} timeout={1000} style={{marginBottom: 20}}/>
                </div>
            </div>
            <LoadingCircle show={disabled || isLoading} />
        </div>
    );
}

export default AccountSetting;