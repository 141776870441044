import React from "react";

import './Subjective.scss';

const Notice = ({
    title = "제목", subtitle= "",
    isRequired = false,
}) => {

    return (<div className="Notice QuestionBox">
        <div className={"Title"}>{title}{isRequired?<span className={"RedAsterisk"}>{" *"}</span>:<></>}</div>
        {subtitle?<div className={"Subtitle"}>{subtitle}</div>:<></>}
    </div>);
}

export default Notice;