
/**
 * @returns {object}
 */
export const platformList = () => {
    return {
        naver: '네이버',
        series: '네이버시리즈',
        lezhin: '레진',
        ridi: '리디북스',
        bomtoon: '봄툰',
        daum: '카카오웹툰',
        kakao: '카카오페이지',
        comico: '코미코',
        toomics: '투믹스',
        toptoon: '탑툰',
    };
};

/**
 * 
 * @param {string} key 
 * @returns {string || null}
 */
export const keyToPlatformIcon = ( key ) => {
    const keyAndIconAddress = {
        naver: 'https://static.webtoon.today/ddah/naver_icon.svg',
        series: 'https://static.webtoon.today/ddah/naverSeries_icon.svg',
        kakao: 'https://static.webtoon.today/ddah/kakao_icon.svg',
        daum: 'https://static.webtoon.today/ddah/kakaoWebtoon_icon.svg',
        lezhin: 'https://static.webtoon.today/ddah/lezhine_icon.svg',
        ridi: 'https://static.webtoon.today/ddah/ridiBooks_icon.svg',
        toomics: 'https://static.webtoon.today/ddah/toomics_icon.svg',
        toptoon: 'https://static.webtoon.today/ddah/toptoon_icon.svg',
        comico: 'https://static.webtoon.today/ddah/comico_icon.svg',
        bomtoon: 'https://static.webtoon.today/ddah/bomtoon_icon.svg',
    }

    return keyAndIconAddress[key] || null
};


/**
 * 
 * @param {string} key 
 * @returns {string || null}
 */

export const keyToPlatformColor = ( key ) => {
    const keyAndColor = {
        naver: 'rgb(0, 213, 100)',
        series: 'rgb(0, 224, 99)',
        kakao: 'rgb(255, 187, 1)',
        lezhin: 'rgb(235, 28, 34)',
        ridi: 'rgb(0, 158, 255)',
        bomtoon: 'rgb(255, 44, 173)',
        daum: 'rgb(0,0,0)',
        comico: 'rgb(244, 0, 9)',
        toptoon: 'rgb(220, 64, 78)',
        toomics: 'rgb(238, 45, 47)',
    }

    return keyAndColor[key] || null
}


/**
 * 
 * @param {string} type 
 * @param {object[]} informations 
 * @returns {string || null}
 */

export const titleGenerator = ( type, data, rowKey ) => {
    
    let title = null;
    switch (type) {
        // 제외
        // case 'fullRead':
        //     title = `완독률이 상위 ${data.rate}%인 작품이에요.`
        //     break;
        case 'trendAnalytics':
            title = `최근 화제성이 30% 오른 소재예요.`
            break;
        case 'tweetsCountAnalytics':
            title = `이 작품과 유사도 85% 이상인 작품들, 성공했어요.`
            break;
        case 'platformGenreAnalytics':
            title = `카카오페이지의 집중 장르와 ${Math.round(data.platformGenreRatio * 100)}% 일치해요.`
            break;
        // 제외
        // case 'satisfyingAgeRange':  
        //     title = `${data.comparisonData
        //         .map( (ele) => Object.entries(ele.data)
        //         .reduce( (acc, cur) => acc[1]>cur[1]?acc:cur , []))
        //         .reduce( (acc, cur) => acc[0] === cur[0]?acc[0]:cur[0])
        //     }세 독자를 만족시킬 수 있어요.`
        //     break;
        case 'platformTopicAnalytics':
            title = `'${(data && data.keyword) || ''}' 포트폴리오를 채워줄 수 있어요.`
            break;
        case 'viralAnalytics':
            title = `${data.tweets.count}개보다 더 많은 바이럴을 얻을 수 있어요.`
            break;
        case 'platformTopicAccordanceAnalytics':
            title = `카카오페이지의 키워드가 100% 일치해요.`
            break;
        case 'platformDrawingStyleAnalytics':
            title = `카카오페이지에 어울리는 작화예요.`
            break;
        case 'naverViralAnalytics':
            title = `이 작품과 유사한 작품, 흥행 성공했어요.`
            break;
        case 'tweetInfoAnalytics':
            title = `이 작품과 유사한 작품, 바이럴이 좋아요.`
            break;
        case 'imminentTitleAnalytics':
            title = `완결 임박한 작품의 팬을 이어 받을 수 있어요.`
            break;
        case 'similarDrawingStyleTitleAnayltics':
            title = `열띈 반응을 얻을 수 있는 작화예요.`
            break;
        case 'platformTopicalityAnalytics':
            if (rowKey === 'profitability') {
                title = `최근 플랫폼이 많이 찾는 소재예요.`
            } else if (rowKey === 'compatibility') {
                title = `최근 카카오페이지가 많이 찾는 소재예요.`
            }
            break;
        case 'platformGenreRatioAnalytics':
            title = '카카오페이지가 집중하고 있는 장르예요.'
            break;

        // 테스트 차트 입니다.
        
        case 'StackedPlatformGenreRatioAnalytics':
            title = '카카오페이지가 집중하고 있는 장르예요.'
            break;

        default:
    }

    return title || null;
}

export const proxy = ( url, mw ) => {
    return `https://cached-api.webtoon.today/thumb?u=${encodeURIComponent(url)}${mw?`&mw=${mw}`:''}`;
};

export const boldMatchCharacters = ( sentence, characters ) => {
    let newSentence = sentence.slice();

    for (const character of characters) { 
        const regEx = new RegExp(character, 'g');
        newSentence = newSentence.replace(regEx, '<strong>$&</strong>')
    }

    return newSentence;
};

export const quarterDataKeyExtractor = ( key, count = 4 ) => {
    if (!key) return [];
    const [ year , quarter ] = key.replace(/q/,'').split('-');
    
    let keyArray = [key];

    for (let i = 1; i < count; i++) {
        if ( i >= quarter ) {
            keyArray.push(`${year - (Math.floor( (4 - Number(quarter) + i) / 4 ))}-${(Number(quarter) + 4 - (i % 4)) > 4?(Number(quarter) + 4 - (i % 4))%4:(Number(quarter) + 4 - (i % 4))}q` );
        } else {
            keyArray.push(`${year}-${Number(quarter) - i}q`)
        }
    }

    return keyArray;
}