import { Button, TextField } from '@material-ui/core';
import React from 'react';
import './SignIn.scss';
import {fn, validateEmailForm} from '../../Functions';
import ToastAutoClose from '../../Component/ToastAutoClose';
import LoadingCircle from '../../Component/LoadingCircle';
import { readLoginInfo } from '../../Data/Account';
import { verifyEmail, verifyOtp } from '../../Data/Email';
import { pageHeaderOptions } from '../../Recoil/PageHeader/PageHeader';
import { useSetRecoilState } from 'recoil';
import { useSession } from '../../Recoil/Session/Session';

const ResetGuide = ({ email: givenEmail }) => {
    const alert = fn.alert;
    const { session } = useSession();

    const [isLoading, setIsLoading] = React.useState(false);
    const [email, setEmail] = React.useState( givenEmail || session.email || null );
    const [isNoPasswordUser, setIsNoPasswordUser] = React.useState(false);
    const [otp, setOtp] = React.useState('');
    const [emailOnVerifying, setEmailOnVerifying] = React.useState(false);
    const [isEmailVerified, setIsEmailVerified] = React.useState(false);
    const [isVerifiedFail, setIsVerifiedFail] = React.useState(false);
    
    React.useEffect(()=>{
        let queryString = window.location.search.replace('?','');
        let queries = queryString?Object.fromEntries(queryString.split('&').map(pair => pair.split("=").map(uri => decodeURIComponent(uri)))):{};
        if ('email' in queries){
            setEmail(queries.email);
        };
    },[]);

    const setPageHeader = useSetRecoilState(pageHeaderOptions);
    React.useEffect(()=>{
        setPageHeader({titleText: '비밀번호 찾기', mobileShortcuts:<></>})
    },[setPageHeader])

    React.useEffect(() => {
        ( async () => {
            setIsLoading(true);

            const {isEmailLoginable, isProfileExist,} = await readLoginInfo(email);
            
            if (!isEmailLoginable && isProfileExist) {
                setIsNoPasswordUser(true);
            };

            setIsLoading(false);
        })()
    },[email])

    const [ infoMessage, setInfoMessage ] = React.useState();

    return(
        <div className={'MainArea'} >
            <div className={'BodyContainer'}>
                <div className={'ResetMailTitle'}>
                    {!isNoPasswordUser?'비밀번호를 찾으시나요?':'비밀번호를 설정해주세요!'}
                </div>
                {isNoPasswordUser
                    ?<div className={'ResetMailDescription'}>
                        {'회원님은 아직 비밀번호 정보가 없어요.'}
                    </div>:<></>}
                <div className={'ResetMailDescription'}>
                    {'가입할 때 사용하신 메일로 비밀번호 설정 링크를 보낼게요.'}
                </div>
                <div className={'InnerInputBox'}>
                    <div className={'BoxLabel'}>
                        {'이메일'}
                    </div>
                    <div style={{display: 'flex', fontSize: '0.75rem'}}>
                        <TextField
                            disabled={ !!givenEmail || !!session.email || email}
                            value={email || ''} onChange={(event)=>setEmail(event.target.value)}
                            style={{flex: 1}}
                            inputProps={{
                                style:{
                                    fontFamily: 'inherit'
                                }
                            }}
                        />
                        <Button
                            disabled={emailOnVerifying || isEmailVerified}
                            style={{fontSize: '0.75rem', fontFamily: 'inherit', width: 90, padding: 0, borderRadius: 0, marginLeft: 10,
                                color: `${emailOnVerifying || isEmailVerified ?'lightgray':'rgb(0,176,240)'}`, border: `${emailOnVerifying || isEmailVerified?'1px solid lightgray':'1px solid rgb(0,176,240)'}`}}
                            onClick={ async () => {
                                setIsLoading(true);

                                const result = validateEmailForm(email)
                                if (result) {
                                    const res = await verifyEmail(email)
                                    if (res) {
                                        setEmailOnVerifying(true);
                                        setInfoMessage("메일함을 확인해주세요!", 3000)

                                        setIsLoading(false);
                                        return res;
                                    } else {
                                        alert('메일 발송에 실패했습니다.')
                                    }

                                } else {
                                    alert('메일 주소를 확인해주세요.')
                                }
                                
                                setIsLoading(false);
                            }}
                        >
                            {'인증번호 받기'}
                        </Button>
                    </div>
                </div>
                {emailOnVerifying
                    ?<div className={'InnerInputBox'} >
                        <div className={'BoxLabel'}>
                            {'인증번호'}
                        </div>
                        <div style={{display: 'flex', fontSize: '0.75rem'}}>
                            <TextField
                                value={otp || ''} onChange={(event)=>setOtp(event.target.value)}
                                style={{flex: 1}}
                                inputProps={{
                                    style:{
                                        fontFamily: 'inherit'
                                    }
                                }}
                            />
                            <Button
                                style={{fontSize: '0.75rem', fontFamily: 'inherit', width: 90, padding: 0, borderRadius: 0, marginLeft: 10,
                                    color: 'rgb(0,176,240)', border: '1px solid rgb(0,176,240)'}}
                                onClick={() => {
                                    ( async()=>{
                                        setIsLoading(true);
                                        setIsVerifiedFail(false);
                                        
                                        const result = await verifyOtp(email, otp)
                                        if (result) {
                                            setIsEmailVerified(true);
                                            setEmailOnVerifying(false);

                                            const newQueries = new URLSearchParams(`otp=${otp}&email=${email}`);
                                            window.history.replaceState({}, '', `${window.location.pathname}?${newQueries}`);
                                            
                                            fn.goto(`/account/resetpassword?otp=${otp}&email=${email}`)
                                            
                                        } else {
                                            alert('인증에 실패했습니다. 다시 인증을 진행해주세요')
                                            setOtp(null);
                                            setIsVerifiedFail(true);
                                        }

                                        setIsLoading(false);
                                    })();
                                    
                                }}
                            >
                                {'인증하기'}
                            </Button>
                        </div>
                        {isVerifiedFail
                            ?<div style={{fontSize: '0.75rem', color: 'red', marginTop: 5}}>
                                {'인증번호를 다시 확인해주세요'}
                            </div>:<></>}
                    </div>:<></>}
                <ToastAutoClose className={'MailAlarm'} message={infoMessage} timeout={1000} />
            </div>
            <LoadingCircle show={isLoading} />
        </div>
    )
}
export default ResetGuide;