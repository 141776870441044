import React from 'react';

import './Toggle.scss';

/**
 * 
 * @param {{
 *  size: 'small'|'medium'|'large',
 *  labelOn: string, labelOff: string,
 *  style: React.StyleHTMLAttributes
 * }} param0 
 * @returns 
 */
const ToggleWithLabel = ({
    size="small",
    labelOn=null,labelOff=null, style={},
    defaultValue=true,
    onClick=(event)=>{},
    onChange=(value)=>{},
}) => {

    const [value, setValue] = React.useState(defaultValue);
    React.useEffect(()=>{
        onChange(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[value])

    return (
    <div
        style={style}
        className={`ToggleWithLabel ${size.toUpperCase()} ${value?"ON":"OFF"}`}
        onClick={(event)=>{
            let newValue = !value;
            setValue(newValue);
            onClick({
                ...event,
                target: {
                    ...(event.target),
                    value: newValue
                }
            })
        }}
    >
        <div className={"Label"}>{(value?labelOn:labelOff)||""}</div>
        <div className={"Togglewheel"}></div>
    </div>);
}

export default ToggleWithLabel;