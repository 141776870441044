
import axios from 'axios'
import ss from './Session';

export const requestVignetteConfirm = async ({serviceId, titleId, episodeId}:{serviceId: string, titleId: string, episodeId: string}) => {
    let res = await axios.put('https://challenge-api.webtoon.today/webtoon/ad', {
        serviceId, titleId, episodeId
    }, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    });

    return res.data?.code === 200;
}