import React from "react";
import "./Copyright.scss";

import { Container, Typography } from "@material-ui/core";
import {fn} from '../../../../Functions';



const Copyright = () => {


    return (
        <div className={"CopyrightSubRoot"}>
            <Container maxWidth="lg" className={"Copyrights"}>
                <div>
                    
                    <img
                        alt="logo"
                        src="https://static.webtoon.today/ddah/logo-01.png"
                        height={55}
                        className={"Img"}
                    />

                </div>

                <Typography variant="caption" className={"Address"}>
                    {"주식회사 오늘의웹툰"}
                    <br/>
                    {"765-81-02007 | 대표자 진수글 | "}<a href={"/privacypolicy/kr"} onClick={fn.gotoByAnchor}>{"개인정보처리방침"}</a>
                    <br/>
                    {"서울특별시 강남구 테헤란로 2길 27, 1131호 (역삼동, 패스트파이브 빌딩)"}
                </Typography>
            </Container>
        </div>
    );
}

export default Copyright;