import React from 'react';

import QnA from './Component/QnA';
import Copyright from './Component/Copyright';

import './Landing.scss';

const LandingFooter = () => {

    return (
        <div className={"LandingPage"}>
            <QnA />
            <Copyright />
            
        </div>
    );

}

export default LandingFooter;


