import ss from './Session';
import axios from 'axios';

/**
 * @typedef {{
 *  title: string,
 *  description: string,
 *  thumbnail: string,
 *  tags: string[],
 * }} SiteMetaType
 */

/**
 * 
 * @param {string} url 
 * @return {Promise<SiteMetaType | null>}
 * 
 */
 export const readSiteMeta = async (url) => {
    
    try{
        let res = await axios.post('https://challenge-api.webtoon.today/site-meta', {url}, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredential: true
        });
        
        if (res.data && res.data.code === 200){
            return res.data.data;
        }else{
            return null;
        };

    }catch(e){
        return null;
    };
}

