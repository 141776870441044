import axios from 'axios';
import ss from './Session';

export const getPopularityResult = async ({serviceId, titleId}: {serviceId: string, titleId: string}) => {
    try {
        
        let res = await axios.get(`https://cached-challenge-api.webtoon.today/popularity-result?serviceId=${serviceId}&titleId=${titleId}`, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });
        
        if (res.data && res.data.code === 200){
            return res.data.data;
        } else {
            return null;
        }
        
    } catch (e: any) {
        console.error(e)
        
        if (e.response && e.response.status === 404) {
            return e.response.data.message;
        } else if (e.response && e.response.status === 406) {
            return e.response.data.message;
        }

        return null;
    }
}

