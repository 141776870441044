import axios from "axios";
import ss from './Session';


export const updateCommentReaction = async ({ commentId, reaction }) => {

    try {
        let res = await axios.put('https://challenge-api.webtoon.today/interaction/comment-reaction', { commentId, reaction }, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });
        
        if (res.data && res.data.code === 200){
            return res.data.data;
        } else {
            return null;
        };

    } catch (e) {

        console.error(e)
        return null;
    };
};


export const deleteCommentReaction = async ({commentId}) => {
    
    try {
        let res = await axios.delete(`https://challenge-api.webtoon.today/interaction/comment-reaction?commentId=${commentId}`, {
            headers : {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        } )
        
        if (res.data && res.data.code === 200) {
            return res.data.key;
        } else {
            return null;
        };

    } catch (e) {
        console.error(e)
        return null;
    };
};