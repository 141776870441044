
import { Button } from '@material-ui/core';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import { fn } from '../../../Functions';
import { shuffleArray } from '../Util/Functions';
import './Styles/WebtoonVirals.scss';

type blogType = {
    title: string,
    link: string,
    description: string,
    bloggername: string,
    bloggerlink: string,
    postdate: string,
}[]
type cafeType ={
    title: string,
    link: string,
    description: string,
    cafename: string,
    cafeurl: string,
}[]
const WebtoonVirals = ({ 
    topic, data
}:{
    topic: string,
    data: {
        searchResCount: number,
        naverBlogSearchData: blogType,
        naverCafeSearchData: cafeType
    }
}) => {

    const { searchResCount, naverBlogSearchData, naverCafeSearchData } = data
    const [ page, setPage ] = useState<number>(0);
    const [ viralData, setViralData ] = useState<blogType | cafeType>([])

    useEffect( () => {
        const shuffledArray = shuffleArray( [...naverBlogSearchData, ...naverCafeSearchData] )
        setViralData(shuffledArray)
    },[naverBlogSearchData, naverCafeSearchData])
    
    useEffect(() => {
        setPage(0)
    },[topic])

    return <div className={'WebtoonViralsArea'}>
        <div className={'Title'} >
            <span className={'SearchText'} >{topic}</span>
            {' 웹툰에 대한 바이럴'}
        </div>
        <div className={'SubTitle'}>
            {'NAVER 누적 게시글'}
            <span className={'AccumulatedCounts'}>{(searchResCount || 0) + '개'}</span>
        </div>
        <div>
            {viralData.slice(0, 5 * page + 5).map( (ele) => 
                <div key={ele.link} className={'ViralBox'} onClick={() => fn.goto(ele.link)}  >
                    <div className={'TitleBox'}>
                        <div className={'ViralTitle'} 
                            dangerouslySetInnerHTML={{__html: `웹툰 ${ele.title}`}}
                        />
                        {"postdate" in ele
                            ?<div className={'Source'} >
                                {'블로그'}
                                <span className={'CreatedTime'} >{moment(ele.postdate).format('YYYY.MM.DD')}</span>
                            </div>
                            :<div className={'Source'}>
                                {'카페'}    
                            </div>}
                    </div>
                    <div className={'DescriptionsBox'} 
                        dangerouslySetInnerHTML={{
                            __html: ele.description
                        }}
                    />
                </div>
            )}
        </div>
        {viralData.length > (5 * page + 5)?
            <div className={'MoreBlogButtonBox'}>
                <Button
                    variant={'outlined'} 
                    onClick={() => {
                        setPage( (cur) => cur + 1 )
                    }} 
                >
                    {'더 보기'}
                </Button>
            </div>:<></>}
    </div>
}

export default WebtoonVirals;