export const themeColors = async () => {

    const colorPalette = [
        "rgb(255,255,255)", "rgb(255,219,216)", 
        "rgb(255,200,160)", "rgb(255,233,156)", 
        "rgb(197,221,193)", "rgb(197,224,216)", 
        "rgb(214,234,251)", "rgb(231,213,237)",
        "rgb(245,236,228)", "rgb(175,175,175)",
    ]

    return colorPalette;
}