import axios from 'axios';
import ss from './Session';

/**
 * @typedef {{
 *     type: null,      cardType: null,          title: null,        show: false,
 * }|{ type: 'link',    cardType: 'icon'|'card', title: string|null, show: boolean, link: string, images: string[],
 * }|{ type: 'wtComic', cardType: 'icon'|'card', title: string|null, show: boolean, link: string, images: string[],
 * }|{ type: 'youtube', cardType: 'card',        title: string|null, show: boolean, link: string, images: string[],
 * }|{ type: 'image',   cardType: 'card',        title: string|null, show: boolean, images: string[], viewerType: 'slide'|'board'|null,
 * }|{ type: 'text'     cardType: 'card',        title: string|null, show: boolean, description: string, textAlign: 'left'|'middle'|'right'|null,
 * }} PortfolioContentType
 * 
 * @typedef {{
 *  addressid: string,
 *  profile: {
 *    title: string,
 *    subtitle: string,
 *    image: string
 *  },
 *  contents: PortfolioContentType[],
 *  template: {
 *    color: string,
 *    [key: string]: any,
 *  }
 * }} PortfolioType
 */


/**
 * 
 * @param {string} addressid 
 * @return {Promise<PortfolioType | null>}
 * 
 */
export const readPortfolio = async (addressid) => {
    
    try{
        let res = await axios.post('https://challenge-api.webtoon.today/ugc/portfolio', {addressid}, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredential: true
        });
        
        if (res.data && res.data.code === 200){
            return res.data.data;
        }else{
            return null;
        };

    }catch(e){
        if (e.response && e.response.status === 404) {
            return null;
        }
        return null;
    };
};


/**
 * 
 * @param {PortfolioType} portfolio
 * @returns {Promise<string | null>}
 */
export const createOrUpdatePortfolio = async (portfolio) => {
    
    try{
        let res = await axios.put('https://challenge-api.webtoon.today/ugc/portfolio', portfolio, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredential: true
        });
        
        if (res.data && res.data.code === 200){
            return res.data.key;
        }else{
            return null;
        };

    }catch(e){
        return null;
    };
};