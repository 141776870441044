import React, { useEffect, useLayoutEffect, useState } from 'react';

import './Mypage.scss';
import { capitalize, fn } from '../../../../Functions';
import moment from 'moment-timezone';
import { Button } from '@material-ui/core';
import { useSetRecoilState } from 'recoil';
import { pageHeaderOptions } from '../../../../Recoil/PageHeader/PageHeader';
import { TitleCardType, readUnivReportList } from '../../../../Data/Report';
import { useSession } from '../../../../Recoil/Session/Session';
import { isOnSubmission, isUnderReview } from '../Data/Variables';


const Mypage = () => {
    const { session } = useSession();
    const setPageHeader = useSetRecoilState(pageHeaderOptions);
    const [ titleCardData, setTitleCardData ] = useState<TitleCardType[]>([]);

    useLayoutEffect(() => {
        if(session.memberState !== 'regular'){
            setTimeout(() => {
                fn.goto('/signin?redirectTo=/analysis/univ2023/mypage');
            }, 0);
        }
    }, [session.memberState])

    useLayoutEffect(() => {
        setPageHeader({
            isAppbarHidden: true,
            isHiddenForPC: true,
            isHiddenForMobile: true
        })
    }, [setPageHeader])

    useEffect(() => {
        (async () => {
            const newTitleCardData = await readUnivReportList({year: "2023"});
            if(newTitleCardData){
                setTitleCardData(newTitleCardData);
            }
        })();
    }, [])

    return (
        <div className={`Univ2023Mypage${isUnderReview ? " End" : ""}${titleCardData.length === 0 ? " Empty" : ""}`}>
            <div className="MypageHeader">
                {'내 작품'}
            </div>
            <div className="MyTitleList">
                {(isUnderReview && titleCardData.length === 0) ?
                <span>{'공모전에 접수 완료된 작품이 없어요'}</span>:
                titleCardData.map(({serviceId, titleId, title, author, thumbnail, isValid, rejectReason, createdAt}) =>
                    <TitleCard
                        key={`${serviceId}:${titleId}`}
                        serviceId={serviceId}
                        titleId={titleId}
                        title={title}
                        author={author}
                        thumbnail={thumbnail}
                        isValid={isValid}
                        rejectReason={rejectReason}
                        createdAt={createdAt}
                    />
                )}
                {isOnSubmission &&
                <Button
                    className="AddMoreTitle"
                    href={"/analysis/univ2023/application"}
                    onClick={fn.gotoByAnchor}
                >
                    <img src="https://static.webtoon.today/ddah/icon/plus.svg" alt="추가" width={'64px'} height={'64px'} />
                </Button>}
            </div>
            <div className={`OtherTitles${isUnderReview ? " End" : ""}${titleCardData.length === 0 ? " Empty" : ""}`}>
                <Button
                    className="OtherTitlesButton"
                    href={"/"}
                    onClick={fn.gotoByAnchor}
                >
                    {'다른 참여작 보러가기'}
                </Button>
            </div>
        </div>
    );
};


const statusToLabel = {
    'underReview': '심의 중',
    'complete': '접수완료',
    'rejected': '반려'
} as const;

const TitleCard = ({
    serviceId, titleId,
    title, author, thumbnail,
    isValid, rejectReason,
    createdAt
} : TitleCardType) => {
    let status: keyof typeof statusToLabel;
    if(rejectReason){
        status = 'rejected';
    } else{
        if(isValid){
            status = 'complete';
        } else{
            status = 'underReview';
        }
    }

    return (
        <Button className="TitleCard" disableElevation onClick={fn.gotoByAnchor} href={`/contents/${serviceId}/${titleId}`}>
            <img src={thumbnail} alt="작품 썸네일" width={'120px'} height={'120px'}/>
            <div className="TitleCardInfo">
                <div className="TitleCardStatus">
                    <div className={`StatusCircle ${capitalize(status)}`}></div>
                    <span>{statusToLabel[status]}</span>
                </div>
                <div className="TitleCardDetail">
                    <span>{title}</span>
                    <span>{author}</span>
                </div>
                <div className="TitleCardDate">
                    <span>{`접수: ${moment(createdAt * 1000).format('YYYY. MM. DD')}`}</span>
                </div>
            </div>
        </Button>
    )
}

export default Mypage;