import React from 'react';

import WebtoonScore from './Component/WebtoonScore';
import PostScript from './Component/PostScript';
import Member from './Component/Member';

import './Landing.scss';

const Landing = () => {

    return (
        <div className={"LandingPage"}>
            <WebtoonScore />
            <PostScript />
            <Member />
        </div>
    );

}

export default Landing;


