import React from 'react';
import { MoreHoriz } from '@material-ui/icons';
import { Button, FormControl, FormControlLabel, Radio, RadioGroup, Tab, Tabs } from '@material-ui/core';
import './ThemeDecoration.scss';
import Slideup from '../../Component/Slideup';
import TabPanel from '../../Component/TabPanel';

const colorPalette = [
    "rgb(255,255,255)", "rgb(255,219,216)",
    "rgb(255,200,160)", "rgb(255,233,156)",
    "rgb(197,221,193)", "rgb(197,224,216)",
    "rgb(214,234,251)", "rgb(231,213,237)",
    "rgb(245,236,228)", "rgb(175,175,175)",
]
    
const tabNames = {
    template: '템플릿', 
    themeColor: '색상', 
};


const ThemeDecoration = ({
    themeTemplate, setThemeTemplate, themeColor, setThemeColor,
    saveTheme=()=>{}, resetTheme=()=>{},
    open=false, setOpen=()=>{},
}) => {

    const [ tabPage, setTabPage ] = React.useState(0)
    React.useEffect(() => {
        if(open){
            document.body.classList.add('PreventingScrollInThemeDecoration')
            document.body.classList.remove('ScrollableThemeDecoration')
        } else {
            document.body.classList.add('ScrollableThemeDecoration')
            document.body.classList.remove('PreventingScrollInThemeDecoration')
        }
    }, [open]);

    return(
    <Slideup
        className={'ThemeEditSlideup'}
        open={open} setOpen={setOpen} 
        handleChild={<MoreHoriz/>}
        exposureHeight={70}
        bodyStyle={{borderRadius: '20px 20px 0 0'}}
    >
        
        <div className={'ThemeDecorationArea'}>
            <div className={'BodyContainer'} >
                <div className={`MobileDecorations`} >
                    <div className={`DecorationSlide`} >
                        <Tabs
                            TabIndicatorProps={{ style: {backgroundColor: 'transparent'} }}
                            value={tabPage} indicatorColor={'primary'}
                            onChange={ (e, newValue) => setTabPage(newValue) } centered
                        >
                            {Object.entries(tabNames).map( ([key, label], i) => (
                                <Tab
                                    key={`DecorationTab:${key}`} disableRipple={true} label={
                                        <span className={`${tabPage === i?'SelectedTab':'Tab'}`}>
                                            <span>{label}</span>
                                        </span>
                                    } onClick = { () => setTabPage(i) }
                                />
                            ))}
                        </Tabs>
                        <TabPanel value={tabPage} index={0}>
                            <FormControl style={{width: '100%'}} component="fieldset">
                                <RadioGroup
                                    className={'TypeSelection'}
                                    value={themeTemplate} onChange={(event) => { setThemeTemplate(event.target.value)}}
                                >
                                    {["Basic", "Circle", "Square"].map( type => (
                                        <div key={`themeDecoration:${type}`} className={'TypeBox'} >
                                                <img
                                                    src={`https://static.webtoon.today/ddah/${type}.png`} alt={type}
                                                    className={'TemplateExample'}
                                                    onClick={()=>setThemeTemplate(type.toLowerCase())}
                                                />
                                                <FormControlLabel
                                                    style={{margin: 0}}
                                                    value={type.toLowerCase()}
                                                    control={ <Radio color={'primary'} style={{margin: 0}} />} 
                                                />
                                        </div>
                                    ) )}
                                </RadioGroup>
                            </FormControl>
                            <div className={'ButtonGroup'} >
                                <Button 
                                    className={'Initialization'} 
                                    onClick={resetTheme}
                                >
                                    {'취소'}
                                </Button>
                                <Button 
                                    className={'Save'} 
                                    onClick={saveTheme}
                                >
                                    {'저장'}
                                </Button>
                            </div>
                        </TabPanel>
                        <TabPanel value={tabPage} index={1}>
                            <div className={'ColorSelection'} >
                                {colorPalette.map(color => (
                                    <div className={`${themeColor === color?'FocusedOutline':'Outline'}`} key={`ColorSelection:${color}`} >
                                        <div 
                                            className={'Color'}
                                            style={{background: `${color}`, border: `${color === 'rgb(255,255,255)'?'1px solid rgb(238,238,238)':''}`}} 
                                            onClick={ () => {
                                                setThemeColor(color);
                                            }}
                                        />
                                    </div>
                                ))}
                            </div>
                            <div className={'ButtonGroup'} >
                                <Button 
                                    className={'Initialization'} 
                                    onClick={resetTheme}
                                >
                                    {'취소'}
                                </Button>
                                <Button 
                                    className={'Save'} 
                                    onClick={saveTheme}
                                >
                                    {'저장'}
                                </Button>
                            </div>
                        </TabPanel>
                    </div>
                </div>
            </div>
        </div>
    </Slideup>
    )
}

export default ThemeDecoration;