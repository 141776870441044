
import { Button } from '@material-ui/core';
import React from 'react';
import './SignIn.scss';
import FacebookLogin from '@greatsumini/react-facebook-login';
import KakaoLogin from 'react-kakao-login';
import NaverLogin from './NaverLogin';
import { GoogleOAuthProvider } from '@react-oauth/google';
import GoogleLogin from './GoogleLogin';
import { useSignIn } from '../../Recoil/Session/Session';

const Kakao = 'https://static.webtoon.today/challenge/KakaoIcon.png';
const Facebook = 'https://static.webtoon.today/challenge/facebook-1.png';

const FACEBOOK_CID="1115243009106744";

const KAKAO_CID="f8c19bb2397699a819edfddd8b03e402"

const GOOGLE_ID=window.location.host.endsWith("webtoon.today")
?"1093253062710-dh2542i4vf7soiqjji199vcr0i02d7vh.apps.googleusercontent.com"
:"646791605002-asu7bku1jtui8puu0nsuu47ef3pt83nb.apps.googleusercontent.com";

const SNSLoginButtons = ({setIsLoading, SNSCallback, SelectButtons = []}) => {
    const { GoogleSignin, NaverSignin, KakaoSignin, FacebookSignin } = useSignIn();

    return(
        <div>
            {SelectButtons.map(sns => 
                sns === 'google'
                    ?<GoogleOAuthProvider clientId={GOOGLE_ID} key={"GoogleOAuthProvider"} >
                        <div className={'GoogleContainer'} style={{ marginTop: 10 }}>
                            <GoogleLogin onSuccess={SNSCallback(GoogleSignin, "구글")} />
                        </div>
                    </GoogleOAuthProvider>
                :sns === 'naver'
                    ?<NaverLogin
                        key={"NaverLogin"}
                        onSuccess={SNSCallback(NaverSignin, "네이버")}
                    />
                :sns === 'kakao'
                    ?<KakaoLogin
                        key={"KakaoLogin"}
                        token = {KAKAO_CID}
                        buttonText = "kakao"
                        onSuccess={SNSCallback(KakaoSignin, "카카오")}
                        onFail={console.error}
                        onLogout={console.info}
                        render={({onClick}) => {
                            return(
                            <Button onClick={(e)=>{
                                    e.preventDefault();
                                    onClick();
                                }} 
                                className={'Button'} style={{backgroundColor: 'rgb(254,229,0)', color:'black', height: '45px'}} >
                                <img src={Kakao} alt={'KakaoLogo'} style={{maxHeight: '20px', position: 'absolute', left: '15px'}} />
                                {'Kakao로 계속하기'}
                            </Button>
                            )
                        }}
                    />
                :sns === 'facebook'
                    ?<FacebookLogin 
                        key={"FacebookLogin"}
                        appId={FACEBOOK_CID}
                        scope={"public_profile,email,user_gender,user_birthday"}
                        onSuccess={SNSCallback(FacebookSignin, "페이스북")}
                        onFail={ async (error) => {
                            console.error(error)
                        }}
                        render={(renderProps) => (
                            <Button onClick={renderProps.onClick} disabled={renderProps.disabled} 
                                className={'Button'} style={{backgroundColor: 'rgb(24, 119, 242)', color:'white', height: '45px'}} >
                                <img src={Facebook} alt={'FacebookLogo'} style={{maxHeight: '20px', position: 'absolute', left: '15px', display: 'flex', alignItems: 'baseline'}} />
                                {'Facebook으로 계속하기'}
                            </Button>
                        )}
                    />
                    :<></>
                )}
        </div>
    )
}

export default SNSLoginButtons;