import React, { CSSProperties, ReactNode, useEffect } from "react";
import RadioButton from "./RadioButton";

import './Styles/RadioGroup.scss';

interface RadioGroupProps<T extends (string|number|undefined)> {
    className?: string,
    options: T[] | {value: T, label: ReactNode}[],
    value: T | undefined,
    onChange: (value: T) => void,
    gap?: number,
    labelProps?: React.HTMLAttributes<HTMLLabelElement>,
    fullWidth?: boolean,
    style?: CSSProperties, 
}
interface ValueLabelType<T> {
    value: T,
    label: ReactNode
}
const RadioGroup = <T extends (string|number|undefined)>( { 
    className, options, value, onChange, gap = 8, 
    labelProps, fullWidth = false, style={}
}:RadioGroupProps<T> ) => {
    function isValueLabelType<T>(obj: T | { value: T, label: ReactNode }): obj is ValueLabelType<T> {
        if (typeof obj !== 'object' || obj === null){
            return false;
        }

        return (obj as ValueLabelType<T>).value !== undefined && (obj as ValueLabelType<T>).label !== undefined;
    }

    useEffect(()=>{
        if ( value || typeof value === 'undefined' ) return;

        const normalizedOptions = options.map(option => {
            if ( !isValueLabelType(option) ) {
                return {value: option, label: `${option}`};
            }else {
                return option;
            }
        })
        if (normalizedOptions.length === 0) {
            return;
        }

        onChange(normalizedOptions[0].value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[options])

    const normalizedOptions = options.map(option => {
        if ( !isValueLabelType(option) ) {
            return {value: option, label: `${option}`};
        }else {
            return option;
        }
    })

    return(
    <div className={`RadioGroup ${className || ''}`} style={{gap, width: `${fullWidth?'100%':'auto'}`, ...style}} >
        {normalizedOptions.map(({value: optionValue, label}) => (
            <RadioButton
                key={`${optionValue}`}
                value={optionValue}
                checked={optionValue === value}
                onChange={() => onChange(optionValue)}
                label={label}
                labelProps={labelProps}
            />
        ))}
    </div>
)};

export default RadioGroup;