
import { CSSProperties, ChangeEvent, useState } from 'react';
import './Styles/TextArea.scss';

type guideTextType = 'initial' | 'normal' | 'success' | 'fail' | 'required';

const TextArea = ({ 
    text='', rows=3, maxLength, 
    isResizable=false, fullWidth=false, 
    onChange, onBlur, style,
    guideTexts, isRequired, forcedGuideTextType,
}:{ 
    text: string, rows?: number, maxLength?: number,
    isResizable?: boolean, fullWidth?: boolean, style?: CSSProperties,
    onChange: (value: string) => void, onBlur?: () => void,
    guideTexts: {[key in guideTextType]: string},
    isRequired: boolean,
    forcedGuideTextType?: guideTextType
}) => {

    const [hasClicked, setHasClicked] = useState<boolean>(false);

    let guideTextType: guideTextType = 'normal';
    if(isRequired && hasClicked && text.length === 0){
        guideTextType = 'required';
    }
    if(forcedGuideTextType){
        guideTextType = forcedGuideTextType;
    }

    return(<div className={'TextAreaComponent'}>
        <textarea
            className={'TextArea'}
            value={text}
            rows={rows}
            maxLength={maxLength}
            style={{
                resize: `${isResizable?'both':'none'}`,
                width: `${fullWidth?'100%':'auto'}`,
                ...style
            }}
            onChange={ (e: ChangeEvent) => onChange((e.target as HTMLInputElement).value) }
            onBlur={(e) => {
                setHasClicked(true);
                if ( onBlur ) {      
                    onBlur();
                }
            }}
        />
        { maxLength && <div className={'NumberOfTexts'} >
            <span className={"guideTextType"}>{guideTexts[guideTextType]}</span>
            {maxLength >= 0 && `${text.length}/${maxLength}`}
        </div>}
    </div>)
}

export default TextArea;