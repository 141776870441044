export const sendTwitterConversion = (event_name, userid, email) => {
    
    const event_id = eventIdDictionary[event_name];
    
    if (event_id) {
        if (!window.twq) {
            return;
        }
        window.twq('event', event_id, {
            conversion_id: userid ||null,
            email_address: email ||null,
        });
    }
};

const eventIdDictionary = {
    "click-winnerUniv2022": "click-winnerUniv2022",
    "click-notionGuideUniv2023": "2023 공모전 운영 정책 버튼 클릭",
    "click-openPromotion": "2023 공모전 오픈 알림 받기",
    "click-applicationDetailsUniv2023": "2023 내 작품 확인 버튼",
    "click-applyUniv2023": "2023 공모전 참여 버튼",

    "input-emailUniv2023": "2023 공모전 이메일 입력",
    "input-nameUniv2023": "2023 공모전 이름 입력",
    "input-engNameUniv2023": "2023 공모전 영문명(addressid) 입력",
    "input-schoolUniv2023": "2023 공모전 학교명 입력",
    "input-phoneNumberUniv2023": "2023 공모전 전화번호 입력",
    "input-titleUniv2023": "2023 공모전 작품 제목 입력",
    "input-titleIdUniv2023": "2023 공모전 작품 제목 영문명 입력",
    "input-descriptionUniv2023": "2023 공모전 작품 줄거리 입력",
    "input-referrerUniv2023": "2023 공모전 작품 추천인 입력",
    "submit-applicationUniv2023": "2023 공모전 접수 버튼",
}