import React, { ChangeEventHandler } from 'react';
import './TextCheckBox.scss';

const TextCheckBox = ({text, isChecked, disabled=false, onChange, fullWidth, style = {}, className = '', color = 'rgb(61, 106, 255)'}: {text: string, isChecked: boolean, disabled?: boolean, onChange: ChangeEventHandler<HTMLInputElement>, fullWidth?: boolean, style?: React.CSSProperties, className?: string, color?: string}) => {
    return (
        <div className={`TextCheckBox ${isChecked ? 'Checked' : ''} ${disabled?"Disabled":""} ${fullWidth ? 'FullWidth' : ''} ${className}`} style={style}>
            <span>{text}</span>
            <label className={'CustomCheckBox'} >
                <input type='checkbox' checked={isChecked} onChange={onChange}/>
                <span className={'CheckMark'} style={isChecked?{backgroundColor: color}:{}} />
            </label>
            {disabled && <div style={disabled?{position:'absolute', width: '100%', height: '100%', top: 0, left: 0}:{}}></div>}
        </div>
    );
};

export default TextCheckBox;