import { Button, Dialog } from '@material-ui/core';
import React from 'react';
import { AskCertificate, AskMDLToken, FetchCertificationResult } from '../Data/PhoneCertification';
import { fn, isAgeAdult } from '../Functions';
import { readProfile, updateProfile } from '../Data/Profile';
import LoadingCircle from '../Component/LoadingCircle';
import { useSession } from '../Recoil/Session/Session';
import { useSetRecoilState } from 'recoil';
import { pageHeaderOptions } from '../Recoil/PageHeader/PageHeader';
import { useToastAlert } from '@webtoontoday/toast';


const RealnameAuthentication = () => {
    const { session } = useSession();
    
    const [ isLoading, setIsLoading ] = React.useState(false);
    const [ openMessage, setOpenMessage ] = React.useState(null);
    
    const { toastAlert } = useToastAlert();
    const [ profile, setProfile ] = React.useState({});

    const setPageHeader = useSetRecoilState(pageHeaderOptions);

    React.useEffect(()=>{
        setPageHeader({
            titleText: '실명인증',
            headerStyle: {borderBottom: '1px rgb(231,231,231) solid'},
            mobileShortcuts:<></>
        })
    },[setPageHeader])

    React.useEffect( () => {
        ( async () => {
            const { userid, memberState } = session;
            if (memberState !== 'regular') {
                toastAlert('로그인을 한 후 실명인증이 가능해요.', 3000);
                fn.goto('/signin?redirectTo=/authenticate');
                return;
            };
            
            const res = await readProfile(userid)
            
            if (res && res.memberState === 'regular' && res.email) {

                setProfile(res);

            } else {
                toastAlert('오류가 발생했어요.\n문의하기를 이용해주세요.', 2000);
            }
        })()
    },[session, toastAlert]);

    React.useEffect(()=>{
        const certificated = !!profile.certificatedBirthday;

        if (certificated){
            fn.goto("/");
        }

    },[profile])

    const modalHandler = () => {
        setOpenMessage(null);
        fn.goto("/");
    };
    
    return (
        <div style={{display: 'flex', justifyContent: 'center'}}>
            <div style={{maxWidth: 400, width: '100%', display: 'flex', justifyContent: 'center'}}>
                <div style={{width: 'calc(100% - 32px)'}}>
                    <div style={{margin: '30px 0', fontWeight: 600}}>
                        {'실명 인증을 해주세요.'}
                    </div>
                    <div style={{ marginBottom: 60, lineHeight: '30px', fontWeight: 500, fontSize: '0.875rem'}}>
                        <div style={{marginBottom: 20}}>
                            {'실명 확인을 통한 성인 인증 후에 성인 작품을 감상하실 수 있습니다.'}
                        </div>
                        <div>
                            {'성인 인증은 1년간 유효하며, 기간 만료시 재인증이 필요합니다.'}
                        </div>
                    </div>
                    <div>
                        <Button 
                            fullWidth variant={'container'} 
                            style={{
                                padding: '10px 0',
                                backgroundColor: 'rgb(0,176,240)',
                                color: 'rgb(255,255,255)',
                            }}
                            onClick={ () => {
                                (async()=>{
                                    setIsLoading(true);

                                    let mdl_tkn = await AskMDLToken();
                                    console.log({mdl_tkn});

                                    setIsLoading(false);
                                
                                    try{
                                        await AskCertificate(mdl_tkn);
                                    }catch(e){
                                        alert("팝업 차단을 해제해 주세요.");
                                        return;
                                    }
                            
                                    const result = await FetchCertificationResult(mdl_tkn);

                                    if (result && result.code === 200){
                                        const modifiedProfile = { 
                                            ...profile,
                                            certificatedName: result.user.RSLT_NAME || null,
                                            certificatedBirthday: result.user.RSLT_BIRTHDAY || null, 
                                            certificatedPhoneNumber: result.user.TEL_NO || null,
                                        };
                                        
                                        const updateResponse = await updateProfile(modifiedProfile);
                                        
                                        if (updateResponse) {
                                            if ( isAgeAdult(result.user.RSLT_BIRTHDAY) ) {
                                                setOpenMessage('실명 인증을 완료했어요!')
                                            } else {
                                                setOpenMessage('미성년자로 실명 확인을 완료했어요.')
                                            };
                                            
                                        } else {
                                            toastAlert('프로필 업데이트를 실패했어요.\n문의하기를 이용해 주세요.', 3000);
                                        };

                                    } else {
                                        toastAlert('정보 로딩에 실패했어요.\n문의하기를 이용해 주세요.', 3000);
                                    };

                                })();
                            }}
                        >
                            {'휴대폰으로 실명 인증하기'}
                        </Button>
                    </div>
                    <Dialog 
                        open={(openMessage || '').length > 0} 
                        onClose={modalHandler}
                    >
                        <div 
                            style={{
                                display: 'flex', flexDirection: 'column',
                                minWidth: 300, fontFamily: 'inherit' 
                            }}
                        >
                            <div 
                                style={{ 
                                    padding: '15px 20px', marginBottom: 30 
                                }} 
                            >
                                <div>
                                    {openMessage}
                                </div>
                            </div>
                            <div 
                                style={{
                                    padding: '15px 0', display: 'flex', 
                                    justifyContent: 'center', alignItems: 'center'
                                }}
                            >
                                <Button
                                    onClick={modalHandler} 
                                    style={{ color: 'rgb(0,176,240)'}}>
                                    {'확인'}
                                </Button>
                            </div>
                        </div>
                    </Dialog>
                </div>
            </div>
            <LoadingCircle show={isLoading} />
        </div>
    )
}

export default RealnameAuthentication;