import { atom, useRecoilState, useSetRecoilState } from "recoil";
import * as amplitude from '@amplitude/analytics-browser';
import { logBehavior } from "../../Data/Behavior";
import ss from '../../Data/Session';
import { useCallback } from "react";

const localStorageEffect = (key: string) => ({setSelf, onSet}: {setSelf: Function, onSet: Function}) => {
    const savedValue = localStorage.getItem(key);
    if (savedValue) {
        setSelf(JSON.parse(savedValue));
    }

    onSet((newSession: sessionType) => {
        localStorage.setItem(key, JSON.stringify(newSession));
    });
};

const amplitudeEffect = ({onSet} : {onSet: Function}) => {
    onSet((newSession: sessionType) => {
        amplitude.init(
            '5798f36dd6023148cbe106c0e94fdde1',
            newSession.memberState === 'regular' ? ( newSession.userid || undefined ) : undefined,
            {
                serverUrl: 'https://amplitude.webtoon.today/2/httpapi',
            }
        );
        if (newSession.email){
            let identify = new amplitude.Identify();
            identify.setOnce('email', newSession.email);
            amplitude.identify(identify);
        }
    })
}

const logBehaviorEffect = (what: any, detail: any) => ({onSet}: {onSet: Function}) => {
    onSet((newSession: sessionType) => {
        if (newSession.userid){
            logBehavior(what, detail);
        }
    })
}

const sessionState = atom<sessionType>({
    key: 'sessionState',
    default: {
        userid: null,
        addressid: null,
        token: null,
        email: null,
        jwt: '',
        memberState: null,
        image: null,
        gender: null,
        birthday: null,
        name: null
    },
    effects: [
        localStorageEffect('session'),
        amplitudeEffect,
        logBehaviorEffect("login(success)", {})
    ]
});

export const useSession = () : {
    session: sessionType,
    sessionRefresh: Function,
    isManager: Function
} => {
    const [session, setSession] = useRecoilState(sessionState);

    const sessionRefresh = useCallback(async () => {
        const newSession = await ss.sessionRefresh();
        setSession(newSession);
        if(newSession.userid){
            return true;
        } else{
            return false;
        }
    }, [setSession]);

    const isManager = useCallback(async () => {
        const isManager = await ss.isManager();
        setSession(prevSession => ({...prevSession, isManager}));
        return isManager;
    }, [setSession])

    return {
        session,
        sessionRefresh,
        isManager
    }
}

export const useSignIn = () : {
    GoogleSignin: Function,
    FacebookSignin: Function,
    NaverSignin: Function,
    KakaoSignin: Function,
    EmailLogin: Function,
    EmailSignin: Function,
    EmailTokenSignin: Function,
    EmailSignupWithOtp: Function,
    logout: Function,
} => {
    const setSessionState = useSetRecoilState(sessionState);

    const GoogleSignin = useCallback(async (response: any) => {
        const newSession = await ss.GoogleSignin(response);
        setSessionState(newSession);
        if(newSession.userid){
            return newSession;
        } else{
            return false;
        }
    }, [setSessionState]);

    const FacebookSignin = useCallback(async (response: any) => {
        const newSession = await ss.FacebookSignin(response);
        setSessionState(newSession);
        if(newSession.userid){
            return newSession;
        } else{
            return false;
        }
    }, [setSessionState]);

    const NaverSignin = useCallback(async (response: any) => {
        const newSession = await ss.NaverSignin(response);
        setSessionState(newSession);
        if(newSession.userid){
            return newSession;
        } else{
            return false;
        }
    }, [setSessionState]);

    const KakaoSignin = useCallback(async (response: any) => {
        const newSession = await ss.KakaoSignin(response);
        setSessionState(newSession);
        if(newSession.userid){
            return newSession;
        } else{
            return false;
        }
    }, [setSessionState]);

    const EmailLogin = useCallback(async (email: any, password: any) => {
        const newSession = await ss.EmailLogin(email, password);
        setSessionState(newSession);
        if(newSession.userid){
            return newSession;
        } else{
            return false;
        }
    }, [setSessionState]);

    const EmailSignin = useCallback(async ({email, otp, password, addressid, name, gender, birthday}: {email: any, otp: any, password: any, addressid: any, name: any, gender: any, birthday: any}) => {
        const newSession = await ss.EmailSignin({email, otp, password, addressid, name, gender, birthday});
        setSessionState(newSession);
        if(newSession.userid){
            return newSession;
        } else{
            return false;
        }
    }, [setSessionState]);

    const EmailTokenSignin = useCallback(async (email: any, token: any) => {
        const newSession = await ss.EmailTokenSignin(email, token);
        setSessionState(newSession);
        if(newSession.userid){
            return newSession;
        } else{
            return false;
        }
    }, [setSessionState]);

    const EmailSignupWithOtp = useCallback(async (email: any, otp: string, name: string) => {
        const newSession = await ss.EmailSignupWithOtp(email, otp, name);
        setSessionState(newSession);
        if(newSession.userid){
            return newSession;
        } else{
            return false;
        }
    }, [setSessionState]);

    const logout = useCallback(async () => {
        const newSession = await ss.logout();
        setSessionState(newSession);
    }, [setSessionState]);

    return {
        GoogleSignin,
        FacebookSignin,
        NaverSignin,
        KakaoSignin,
        EmailLogin,
        EmailSignin,
        EmailTokenSignin,
        EmailSignupWithOtp,
        logout
    };
}