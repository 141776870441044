import axios from 'axios';


export type marketTitleListType = {[title: string]: {serviceId: string, titleId: string}};
export type keywordToTopicType = {[key: string]: string};


export const getMarketTitleList = async (): Promise<marketTitleListType | null> => {
    try {
        
        let res = await axios.get('https://static.webtoon.today/ddah/marketTitleList.json');
        
        if (res.data && res.status === 200){
            return res.data
        }else{
            return null;
        }
        
    } catch (e) {
        console.error(e)
        return null;
    }
}

export const getKeywordToTopic = async (): Promise<keywordToTopicType | null> => {
    try {
        
        let res = await axios.get('https://static.webtoon.today/ddah/keywordToTopic.json');
        
        if (res.data && res.status === 200){
            return res.data
        }else{
            return null;
        }
        
    } catch (e) {
        console.error(e)
        return null;
    }
}

export const getTopicList = async (): Promise<string[] | null> => {
    try {
        
        let res = await axios.get('https://static.webtoon.today/ddah/topicList.json');
        
        if (res.data && res.status === 200){
            return res.data
        }else{
            return null;
        }
        
    } catch (e) {
        console.error(e)
        return null;
    }
}