

import { ParentSize } from '@visx/responsive';
import { quarterDataKeyExtractor } from '../Util/Functions';
import BarChart from './BarChart';
import './Styles/SerializationTrend.scss';

const SerializationTrend = ({ 
    topic, data
}: {
    topic: string
    data: {
        [date: string]: number
    }
}) => {
    
    const latestQuartersArray: string[] = quarterDataKeyExtractor(Object.keys(data).sort().reverse()[0] + 'q', 4 * 3 + 1)
    
    const BarData: {name: string, matchCount: number}[] = []

    for ( const quarter of latestQuartersArray ) {
        const key = quarter.replace(/q/g, '');
        BarData.push({
            name: quarter,
            matchCount: data[key] || 0
        })
    }
    
    BarData.sort().reverse()

    return <div className={'SerializationTrendArea'}>
        <div className={'Title'}>
            <span className={'SearchText'}>{topic}</span>
            {' 웹툰 연재 추이'}
        </div>
        <div className={'ChartBox'} >
            <ParentSize>
                {({width, height}: {width: number, height: number})  => 
                    <BarChart
                        width={width} height={height} data={BarData} subtitle={'대상: 주요 10개 플랫폼 전체'}
                    />}
            </ParentSize>
        </div>
    </div>
}

export default SerializationTrend;