import React, { useMemo } from 'react';
import Header from './Components/Header';
import Tabs from './Components/Tabs';
import { BranchFunction } from '../../../Functions';
import Landing from './Landing/Landing';
import Mypage from './Mypage/Mypage';
import Application from './Application/Application';
import Complete from './Complete/Complete';
import { isOnSubmission, isUnderReview } from './Data/Variables';

let tabs: {[page: string]: {label: string, destination: string}} = {
    'landing': {label: '과제부활전', destination: '/analysis/univ2023'},
}
if(isOnSubmission || isUnderReview){
    tabs['mypage'] = {label: '참여한 작품', destination: '/analysis/univ2023/mypage'};
}

const Univ2023 = ({path, query}:{path: string, query: string}) => {
    const currentBranch = useMemo(() => BranchFunction(
        path,[
            {
                validator: (path) => /^\/univ2023\/landing/i.test(path),
                defaultValue: 'landing',
            },
            {
                validator: (path) => /^\/univ2023\/mypage/i.test(path) && (isOnSubmission || isUnderReview),
                defaultValue: 'mypage',
            },
            {
                validator: (path) => /^\/univ2023\/application/i.test(path) && isOnSubmission,
                defaultValue: 'application',
            },
            {
                validator: (path) => /^\/univ2023\/complete/i.test(path),
                defaultValue: 'complete',
            },
        ],
        'landing'
    ), [path]);

    const body = useMemo(()=>{
        if(currentBranch === 'landing'){
            return <Landing query={query} />;
        } else if(currentBranch === 'mypage') {
            return <Mypage />;
        } else if(currentBranch === 'application'){
            return <Application />;
        } else{
            let [ , ,serviceId, titleId ] = /^\/univ2023\/complete(\/([^/]+)\/([^/]+))?/.exec(path) as RegExpExecArray;
            return <Complete serviceId={serviceId} titleId={titleId} />;
        }
    },[currentBranch, path, query]);

    const isTabBar = currentBranch === 'landing' || currentBranch === 'mypage';
    
    return (<>
        <Header />
        {isTabBar && <Tabs tabs={tabs} currentTab={currentBranch}/>}
        {body}
    </>);
};

export default Univ2023;