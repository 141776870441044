import React, {useRef, useState} from 'react';
import { appearUp } from "../../SettingValues";
import AnimateDiv from '../../Component/AnimateDiv'
import { Card, Dialog, DialogContent } from "@material-ui/core";

import './Member.scss'

import {useOnScreen} from '../../../../Hooks';
import {logBehavior} from '../../../../Data/Behavior';
import { ReactNode } from 'react-markdown';
let didSeen = false;


const MemberCard = ({position, name, story, profileImg,}: {position: string, name: string, story?: {duration: string, title: string, subContext?: ReactNode}[], profileImg?: string}) => {

    const [isActive, setIsActive] = useState(false);

    return (
        <div className={"CardBox"}>
            <AnimateDiv variants={appearUp}>
                <Card className={"Cards"} elevation={0} onClick={()=>setIsActive(!!story && story.length > 0 && true)}>
                    {profileImg && <div className={"Characters"}>
                        <img
                            src={profileImg}
                            alt={name}
                            className={"CardIcon"}
                        />
                    </div>}
                    <div className={"Introduction"}>
                        <div className={"JobPositions"}>
                            <div className={"Position"}>
                                {`${position} `}
                            </div>
                            <div className={"Name"}>
                                {name}
                            </div>
                            <div className={"Position Hidden"}>
                            {`${position} `}
                            </div>
                        </div>
                    </div>
                </Card>
            </AnimateDiv>
            <Dialog open={isActive} onClose={()=>setIsActive(!!story && false)}>
                <DialogContent>
                    <Card className={"ModalCards"} elevation={0}>
                        <div className={"Characters"}>
                            <img
                                src={profileImg}
                                alt={name}
                                className={"CardIcon"}
                            />
                        </div>
                        <div className={"Introduction"}>
                            <div className={"JobPositions"}>
                                <div className={"Position"}>
                                    {`${position} `}
                                </div>
                                <div className={"Name"}>
                                    {name}
                                </div>
                                <div className={"Position Hidden"}>
                                {`${position} `}
                                </div>
                            </div>
                            <div>
                                <ul className={"UlStyles"}>
                                    {story?.map( ({duration, title, subContext}) =>
                                    <li key={duration}>
                                        <span
                                            className={"SubText"}
                                        >
                                            {duration}
                                        </span>
                                        <br />
                                        <div className={"Context"}>
                                            {title}
                                            {subContext && <div className={"SubContext"}>
                                                {subContext}
                                            </div>}
                                        </div>
                                    </li>)}
                                </ul>
                            </div>
                        </div>
                    </Card>
                </DialogContent>
            </Dialog>
        </div>
    )
}


const Member = () => {

    const ref = useRef(null);
    const onScreen = useOnScreen(ref);
    if (!didSeen && onScreen){
        didSeen = onScreen;
        logBehavior('scroll-member', {});
    }

    return (

        <div ref={ref} className={"MemberSubRoot LandingSection"} id={"Member"}>
            
            <div className={"MemberTitle"}>
                <AnimateDiv variants={appearUp} >
                    <div className={"TeamTitle"}>
                        {"팀 오늘의웹툰"}
                    </div>
                </AnimateDiv>

                <AnimateDiv variants={appearUp}>
                    <div className={"TitleFlex"}>
                        <span className={"Blue"}>
                            {"데이터"}
                        </span>
                        {"를 알고 "}
                        <span className={"Blue"} >
                            {"웹툰"}
                        </span>
                        {"을 이해하는 팀이 "}
                        <span style={{whiteSpace:'nowrap'}}>{"함께 합니다."}</span>
                    
                    </div>
                </AnimateDiv>
            </div>
            <div>
                <div className={"Section"} >
                    <MemberCard
                        position={"디렉터"} name={"서영호"} profileImg={"https://static.webtoon.today/ddah/yeongho.png"}
                        story={[
                            {duration: "'14.~`20.", title: "카툰팝 만화학원 강의"},
                            {duration: "'21.", title: "제주 웹툰 캠퍼스 출강"},
                            {duration: "'13.~'20. ", title: "웹툰 연재", subContext: <>
                                {"- 카카오페이지 '귀신잡는 말뚝이'"}<br />
                                {"- 코미코 '이매용주전'"}<br />
                                {"- 만화경 '교육생','페어플레이'"}</>},
                            {duration: "'05.~'12.", title: "공주대학교 만화 애니메이션 전공"},
                        ]}
                    />
                    <MemberCard
                        position={"디렉터"} name={"김서정"} profileImg={"https://static.webtoon.today/ddah/seojung.png"}
                        story={[
                            {duration: "'19.~", title: "유튜브 '두미두미의 웹툰강좌' 운영"},
                            {duration: "'18.~", title: "웹툰 연재", subContext: "- 피너툰 '낭만주의자와 쾌락주의자가 만났을 때'"},
                            {duration: "'09.~'17.", title: "윤지운 작가 어시스턴트"},
                            {duration: "'99.~'01.", title: "치요다 공과예술 전문학교 만화과"},
                        ]}
                    />
                    <MemberCard
                        position={"디렉터"} name={"심우인"} profileImg={"https://static.webtoon.today/ddah/wooin.png"}
                        story={[
                        ]}
                    />
                    <MemberCard
                        position={"디렉터"} name={"김은총"} profileImg={"https://static.webtoon.today/ddah/david.png"}
                        story={[
                            {duration: "'22.~", title: "카카오웹툰 <VR히어로>"},
                            {duration: "'21.~'22.", title: "더그림엔터테인먼트"},
                            {duration: "~'14.", title: "뉴욕 주립대학교(Stonybrook) Asian studies 졸업"}
                        ]}
                    />
                </div>
                
                <div className={"Section"} >
                    <MemberCard
                        position={"프론트"} name={"김동영"} profileImg={"https://static.webtoon.today/ddah/dongyeong.png"}
                        story={[
                        ]}
                    />
                    <MemberCard
                        position={"마케터"} name={"안수현"} profileImg={"https://static.webtoon.today/ddah/suhyun.png"}
                        story={[
                            {duration: "'22.", title: "TBS 전략기획실 유튜브 콘텐츠 제작"},
                            {duration: "'21.", title: "서울시청 조경과 홍보콘텐츠 제작 담당"},
                            {duration: "'20.", title: "나스미디어 광고팀 인턴"},
                            {duration: "'16.~'21.", title: "한양대학교 ERICA 신문방송학과"}
                        ]}
                    />
                    <MemberCard
                        position={"백엔드"} name={"손세환"} profileImg={"https://static.webtoon.today/ddah/sehwan.png"}
                        story={[
                            {duration: "'19.~", title: '서울대학교 전기정보공학부'},
                        ]}
                    />
                    <MemberCard
                        position={"프론트"} name={"박영진"} profileImg={"https://static.webtoon.today/ddah/youngjin.png"}
                        story={[
                            {duration: "'22.", title: '삼성전자'},
                            {duration: "'16.~'21.", title: '연세대학교 컴퓨터과학과'},
                        ]}
                    />
                    <MemberCard
                        position={"UX 설계"} name={"권기현"} profileImg={"https://static.webtoon.today/ddah/kihyun.png"}
                        story={[
                            {duration: "'13.~'20.", title: '금오공과대학교 기계공학과'},
                        ]}
                    />
                    <MemberCard
                        position={"사업기획"} name={"김새영"} profileImg={"https://static.webtoon.today/ddah/saeyoung.png"}
                        story={[
                            {duration: "'21.~22.", title: '그립 서비스 기획/운영'},
                            {duration: "'20.", title: '티켓몬스터 MD'},
                            {duration: "'15.~'19.", title: '고려대학교 국어교육과'},
                        ]}
                    />
                </div>
                <div className={"Section"} >
                    <MemberCard
                        position={"경영지원"} name={"윤수선"} profileImg={"https://static.webtoon.today/ddah/suseon.png"}
                        story={[
                            {duration: "'19.~'21.", title: '포잉 컬쳐유닛'},
                            {duration: "'18.~'19.", title: '프레시지 경영지원실'},
                            {duration: "'15.~'18.", title: '새한세무회계사무소'},
                            {duration: "'09.~'14.", title: '청운대학교 호텔경영컨벤션학과'},
                        ]}
                    />
                    <MemberCard
                        position={"대표"} name={"진수글"} profileImg={"https://static.webtoon.today/ddah/sugeul.png"}
                        story={[
                            {duration: "'19.~'20.", title: "브이로거(Vlogr) CTO"},
                            {duration: "'16.~'19.", title: "네이버 검색센터 추천연구팀(AiRS) Modeler"},
                            {duration: "'15.~'15.", title: "Bain&Company Research Assistant"},
                            {duration: "'08.~'13.", title: "카이스트 전산학과"},
                        ]}
                    />
                </div>
            </div>

        </div>

    );

}

export default Member;