
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import React from 'react';
import { fn } from '../Functions';
import { useSession, useSignIn } from '../Recoil/Session/Session';

const CheckLogout = ({ open, setOpen }) => {
    const {session } = useSession();
    const { logout } = useSignIn();

    return(
        <Dialog onClose={ () => setOpen(false) } open={open}>
            <DialogTitle >
                {'로그아웃 하시겠어요?'}
            </DialogTitle>
            <DialogContent style={{padding: '0 24px 24px 24px', fontSize: '0.9rem', lineHeight: '24px'}} >
                <div>
                    {`나중에 이 계정으로 다시 로그인할 수 있어요.`}
                </div>
                <div style={{marginTop: 6}}>
                    {session.email}
                </div>
            </DialogContent>
            <DialogActions >
                <Button onClick={ () => setOpen(false)}>
                    {'취소'}
                </Button>
                <Button variant={'contained'} color={"primary"} disableElevation={true} onClick={() => {
                    setOpen(false);
                    fn.goto(`/`);
                    logout();
                }} >
                    {'로그아웃'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default CheckLogout;