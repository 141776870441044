import axios from 'axios';
import ss from './Session';

export type ContentsType = {
    code: number;
    id: number;
    data: {
        images: string[];
        siblings: number[];
        episodeId?: string;
        freezeAt?: number;
        nextEpisodeId?: string;
        nextSnapshotId?: string;
        serviceId?: string;
        snapshotId?: string;
        titleId?: string;
        updatedAt?: number;
    };
};


type ContentsParamsType = {
    serviceId: string,
    titleId: string,
    episodeId: string,
    snapshotId?: string,
    data: any,
}

export const readContents = async (serviceId?: string, titleId?: string, episodeId?: string, snapshotId?: string):Promise<ContentsType | null> =>{
    try{
        let res = await axios.post('https://challenge-api.webtoon.today/webtoon/contents', {serviceId, titleId, episodeId, snapshotId,}, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });

        if (res.data){
            if (res.data.code === 200 && res.data.siblings){
                res.data.siblings = (res.data.siblings as number[]).map(num => `${num}`)
            }
            return res.data;

        }else {
            return null;
        }
    }catch(e){
        return null;
    }
}

export const createContents = async ({ contents }:{ contents: ContentsParamsType }): Promise<string | null> => {
    try{
        const res = await axios.put('https://challenge-api.webtoon.today/webtoon/contents', contents,{
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            }
        });

        if (res.data && res.data.code === 200){
            
            return res.data.key;

        }else{
            return null;
        }
    }catch(e){
        return null;
    }
}

/**
 * @description **unused function**
 * @param param0 
 * @returns 
 */
export const updateContents = async ({ contents }:{ contents: ContentsParamsType }): Promise<string | null> => {
    try{
        const res = await axios.patch('https://challenge-api.webtoon.today/webtoon/contents', contents,{
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            }
        });

        if (res.data && res.data.code === 200){
            
            return res.data.key;

        }else{
            return null;
        }
    }catch(e){
        return null;
    }
}

/**
 * @description **unused function**
 * @param param0 
 * @returns 
 */
export const deleteContents = async ({ contents }:{ contents: ContentsParamsType }): Promise<string | null> =>{
    try{
        const res = await axios.delete(`https://challenge-api.webtoon.today/webtoon/contents?serviceId=${contents.serviceId}&titleId=${contents.titleId}&episodeId=${contents.episodeId}&snapshotId=${contents.snapshotId}`,{
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            }
        });

        if (res.data && res.data.code === 200){
            
            return res.data.key;

        }else{
            return null;
        }
    }catch(e){
        return null;
    }
}

/**
 * @description **unused function**
 * @param param0 
 * @returns 
 */
export const createContentsFromChallenge = async ({serviceId, titleId, episodeId, source, ...others}: {serviceId: string, titleId: string, episodeId: string, source: string,}):Promise<string | null> => {
    try{
        const res = await axios.put('https://challenge-api.webtoon.today/create-episode-from-challenge', {
            serviceId, titleId, episodeId, source, ...others
        }, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });

        if (res.data && res.data.code === 200){
            
            return res.data.zipFileLocation;

        }else{
            return null;
        }
    }catch(e){
        return null;
    }
}