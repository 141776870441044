import { atomFamily, selectorFamily, GetRecoilValue, useRecoilState, useRecoilValueLoadable, Loadable, atom } from "recoil";
import { readProfileList } from "../../Data/Profile";
import { readSubscribedAuthors, subscribeAuthors, unsubscribeAuthors } from "../../Data/AuthorSubscription";
import { logBehavior } from "../../Data/Behavior";
import { useCallback, useEffect, useState } from "react";
import { useTitles } from "../Title/Titles";
import { deepCompareObjects, unique } from "../../Functions";

export const AUTHOR_SUBSCRIPTION = "author-subscription";

const UserKeyList = atom<string[]>({
    key: 'userKeyList',
    default: [],
    dangerouslyAllowMutability: true
});
const UserObject = atom<{[accountid: string]: ProfileType}>({
    key: 'userObject',
    default: {},
    dangerouslyAllowMutability: true
})

// param: "<API's endpoint>"
const userListFamily = atomFamily<{authorId: string}[] | null, string>({
    key: "userList",
    default: null
});

export const usersProfileListFamily = selectorFamily<ProfileType[] | null, string>({
    key: "usersProfileList",
    get: (key: string) => async ({get}: {get: GetRecoilValue}) => {
        const userList: {authorId: string}[] | null = get(userListFamily(key));
        if(userList === null){
            return null;
        } else if(userList.length === 0){
            return [];
        }
        const profileList = await readProfileList(userList.map(author => author.authorId));
        if (profileList?.length > 0){
            return profileList;
        } else {
            throw Error();
        }
    }
});

export const useSubscribedAuthorList = (): {
    userList: {authorId: string}[] | null,
    profileLoadable: Loadable<ProfileType[] | null>,
    isLoading: boolean,
    isFetchingError: boolean,
    fetch: () => Promise<boolean>,
    subscribe: (authorId: string) => Promise<boolean>,
    unsubscribe: (authorId: string) => Promise<boolean>
} => {
    const [userList, setUserList] = useRecoilState<{authorId: string}[] | null>(userListFamily(AUTHOR_SUBSCRIPTION));
    const profileLoadable = useRecoilValueLoadable(usersProfileListFamily(AUTHOR_SUBSCRIPTION));
    const { refreshUser } = useUsers();

    const [isFetchingUserList, setIsFetchingUserList] = useState<boolean>(false);
    const [isFetchingProfile, setIsFetchingProfile] = useState<boolean>(false);
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const isLoading = isFetchingUserList || isFetchingProfile || isUpdating;
    
    const [isFetchingError, setIsFetchingError] = useState<boolean>(false);

    useEffect(() => {
        setIsFetchingProfile(true);
        if(profileLoadable.state === 'hasValue' && profileLoadable.contents !== null ){
            setIsFetchingProfile(false);
        } else if(profileLoadable.state === 'hasError'){
            setIsFetchingError(true);
            setIsFetchingProfile(false);
        }
    }, [profileLoadable])

    const fetch = useCallback(async (): Promise<boolean> => {
        setIsFetchingUserList(true);
        setIsFetchingError(false);

        if(userList !== null){
            setIsFetchingUserList(false);
            return true;
        }

        const newSubscribedAuthors = await readSubscribedAuthors();
        if(newSubscribedAuthors === null){
            setIsFetchingUserList(false);
            setIsFetchingError(true);
            return false;
        };
        setUserList(newSubscribedAuthors);
        setIsFetchingUserList(false);
        return true;
    }, [setUserList, userList]);

    const subscribe = useCallback(async (authorId: string): Promise<boolean> => {
        setIsUpdating(true);

        const res = await subscribeAuthors(authorId);
                                
        if (res) {
            if(userList === null){
                await fetch();
            } else{
                setUserList([...userList, {authorId}]);
            }
            refreshUser([authorId]);
            logBehavior('add-subscriptionauthor', {authorId});
            setIsUpdating(false);
            return true;
        } else {
            setIsUpdating(false);
            return false;
        };
    }, [fetch, refreshUser, setUserList, userList]);

    const unsubscribe = useCallback(async (authorId: string): Promise<boolean> => {
        setIsUpdating(true);

        const res = await unsubscribeAuthors(authorId);
                                
        if (res) {
            if(userList === null){
                await fetch();
            } else{
                setUserList(userList.filter(subscribedAuthor => !(subscribedAuthor.authorId === authorId)));
            }
            refreshUser([authorId]);
            logBehavior('delete-subscriptionauthor', {authorId});
            setIsUpdating(false);
            return true;
        } else {
            setIsUpdating(false);
            return false;
        };
    }, [fetch, refreshUser, setUserList, userList]);

    return {userList, profileLoadable, isLoading, isFetchingError, fetch, subscribe, unsubscribe};
}

export const useUsers = () => {
    const [userList, setUserList] = useRecoilState(UserKeyList);
    const [userObject, setUserObject] = useRecoilState(UserObject);
    const {fetch: fetchTitles, titleObject} = useTitles();
    
    const fetch = useCallback(async (accountidlist: string[]) => {
        let newUserArray: ProfileType[] = [];

        // TODO: fetch newUserArray from server
        newUserArray = await readProfileList(accountidlist);

        let newUserObject = Object.fromEntries(newUserArray.map(row => ([row.addressid, row])));

        if (! deepCompareObjects(newUserObject, userObject)){
            setUserObject(userObject => ({...userObject, ...newUserObject}));
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setUserObject, userObject]);
    
    const refreshUser = useCallback((accountidlist: string[] )=>{
        setUserList(userList => [...userList, ...(accountidlist.filter(id => !!id))].filter(unique))

        fetch(accountidlist);
    },[fetch, setUserList]);

    const secureFetch = useCallback((accountidlist: string[] )=>{
        let realNewList = accountidlist.filter(id => !!id).filter(id => !userList.includes(id));
        
        setUserList(userList => [...userList, ...(accountidlist.filter(id => !!id))].filter(unique))
        if (realNewList.length > 0){
            fetch(realNewList);
        };

    },[fetch, setUserList, userList])

    const [userWithOtherTitles, setUserWithOtherTitles] = useState<{[key: string]: ProfileType}>({});
    useEffect(()=> {
        
        let neededTitles = Object.values(userObject).map(row => row.otherTitle).flat();

        if (neededTitles.length > 0){
            fetchTitles(neededTitles);
        }

        setUserWithOtherTitles(
            Object.fromEntries(Object.entries(userObject).map(([key, row]) => ([key, {
                ...row,
                otherTitle: row.otherTitle
                                .filter(title => `${title.serviceId}:${title.titleId}` in titleObject)
                                .map(title => ({...title, ...(titleObject[`${title.serviceId}:${title.titleId}`])}))
            }])))
        )
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [titleObject, userObject])

    return {fetch: secureFetch, refreshUser, userObject: userWithOtherTitles};
}

export default userListFamily;