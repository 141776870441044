import {
    AnimatedAxis,
    AnimatedGrid,
    AnimatedLineSeries,
    XYChart
} from "@visx/xychart";
import moment from 'moment-timezone';
import { curveBasis } from '@visx/curve';

const LineChart = ({
    searchType, width, height, data, 
}:{
    searchType: 'trend' | 'popularity', width: number, height: number, data: {period:string, ratio:number}[], 
}) => {
    
    const accessors = {
        xAccessor: (d: {period: string, ratio: number}) => new Date(`${d.period}T00:00:00`),
        yAccessor: (d: {period: string, ratio: number}) => Math.floor(d.ratio),
    };

    const margin = {
        left: searchType === 'trend' ? 30 : 50, top: 20, bottom: 20, right: 30
    }
    
    return(
    <div >
        <XYChart
            height={height}
            width={ width }
            xScale={{ type: 'time' }}
            yScale={{ type: 'linear', zero: false, nice: true }}
            margin={margin}
        >
            <AnimatedGrid
                columns={false}
                numTicks={0}
            />
            <AnimatedAxis
                orientation="bottom"
                numTicks={6}
                strokeWidth={1}
                tickFormat={(d: string) => moment(d).format(searchType === 'trend' ? 'YY.MM' : 'YY.MM.DD')}
                hideZero={false}
                hideTicks={true}
                stroke={'rgb(117,117,117)'}
                tickLabelProps={ () => ({
                    style: {
                        fontSize: '0.625rem',
                        fontWeight: 'bold',
                        fontFamily: 'inherit'
                    }
                })}
            />
            <AnimatedAxis
                orientation="left"
                numTicks={4}
                strokeWidth={1}
                labelClassName={'YLabel'}
                hideZero={false}
                hideTicks={true}
                stroke={'rgb(117,117,117)'}
                tickLabelProps={ () => ({
                    style: {
                        fontSize: '0.625rem',
                        fontWeight: 'bold',
                        fontFamily: 'inherit'
                    }
                })}
            />
            <AnimatedLineSeries
                stroke="#BBD0FF"
                strokeWidth={5}
                dataKey="primary_line"
                data={data}
                curve={curveBasis}
                {...accessors}
            />
        </XYChart>
    </div>
    )
}

export default LineChart;