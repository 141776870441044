import { atom, useRecoilState } from "recoil";
import { logBehavior } from "../../Data/Behavior";
import { createBookmark, deleteBookmark, readBookmark } from "../../Data/Bookmark";
import { useCallback, useState } from "react";


const BookmarkListState = atom<(BookmarkType[] | null)>({
    key: "bookmarkList",
    default: null,
    dangerouslyAllowMutability: true
});

export const useBookmarkList = ():{
    bookmarkList: BookmarkType[] | null,
    isLoading: boolean,
    fetchBookmarkList: () => Promise<boolean>,
    deleteBookmarkFromList: (bookmarksToDelete: {serviceId: string, titleId: string, episodeId: string}[]) => Promise<boolean>,
    createBookmarkToList: (createdBookmark: BookmarkType ) => Promise<boolean>
} => {
    const [ bookmarkList, setBookmarkList ] = useRecoilState<(BookmarkType[] | null)>(BookmarkListState);
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const isLoading = isFetching || isUpdating;

    const fetchBookmarkList = useCallback(async (): Promise<boolean> => {
        setIsFetching(true);

        if ( bookmarkList !== null ) {
            setIsFetching(false);
            return true;
        }

        const bookmarkResponse = await readBookmark();

        if ( bookmarkResponse !== null ) {
            setBookmarkList(bookmarkResponse);
            setIsFetching(false);
            return true;
        } else {
            setIsFetching(false);
            return false;
        }
    }, [bookmarkList, setBookmarkList]);

    const deleteBookmarkFromList = useCallback(async (bookmarksToDelete: { serviceId: string, titleId: string, episodeId: string }[]): Promise<boolean> => {
        setIsUpdating(true);

        const deletedBookmarkKeys = await deleteBookmark(bookmarksToDelete);
        
        if (deletedBookmarkKeys !== null) {
            if (bookmarkList === null){
                await fetchBookmarkList();
            } else {
                let deletedBookmarksSet = new Set(bookmarksToDelete.map( ({serviceId, titleId, episodeId}) => `${serviceId}:${titleId}:${episodeId}` ));
                const newBookmarkList = bookmarkList.filter( ({serviceId, titleId, episodeId}) => !deletedBookmarksSet.has(`${serviceId}:${titleId}:${episodeId}`) );
                setBookmarkList(newBookmarkList);       
            }
            logBehavior('delete-bookmark', {bookmarksToDelete});
            setIsUpdating(false);
            return true;
        } else {
            setIsUpdating(false);
            return false;
        };
    }, [bookmarkList, fetchBookmarkList, setBookmarkList]);

    const createBookmarkToList = useCallback(async (createdBookmark: BookmarkType) => {
        setIsUpdating(true);

        const { serviceId, titleId, episodeId, contentIndex } = createdBookmark
        const createBookmarkKeys = await createBookmark({serviceId, titleId, episodeId, contentIndex});
        
        if (createBookmarkKeys !== null) {
            if (bookmarkList === null) {
                await fetchBookmarkList();
            } else {
                const newBookmarketList = [...bookmarkList, createdBookmark]
                setBookmarkList(newBookmarketList);
            }
            logBehavior('create-bookmark', {createdBookmark});
            setIsUpdating(false);
            return true;
        } else {
            setIsUpdating(false);
            return false;
        };
    }, [bookmarkList, fetchBookmarkList, setBookmarkList]);

    return ({ bookmarkList, isLoading, fetchBookmarkList, deleteBookmarkFromList, createBookmarkToList })
}

