
import axios from 'axios';
import ss from './Session';

export type announcementType = {
    prizeAnnouncementTitle: string,
    winnerPrizes: {image: string, url: string}[],
    schoolPrizes: {image: string, urls: string[]}[],
    standardAndStatistics: string[],
    readerStatistics: {image: string, url: string}[],
    interactionStatistics: {image: string, url: string}[],
    ceremonyStatistics: string,
}

export const readSchoolCompetition =  async (): Promise<announcementType |null>  =>  {

    let res = await axios.get('https://challenge-api.webtoon.today/static/school-competition', {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    });
    
    try {

        if (res.data && res.data.code === 200){
            return res.data.data;
        }else {
            return null;
        }
    } catch (e) {
        return null;
    }
}