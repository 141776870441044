import React from 'react';
import { Button, TextField } from '@material-ui/core';

const NameChange = ({name: givenName, callback=(property)=>{}, }) => {
    
    let [name, setName] = React.useState(givenName);

    return(
        <div className={'UserPageArea'}>
            <div className={'BodyContainer'} >
                <div className={'InnerInputBox'}>
                    <div className={'BoxLabel'}>
                        {'이름'}
                    </div>
                    <TextField fullWidth value={name || ''} onChange={(event)=>{
                        setName(event.target.value)
                    }} inputProps={{style:{fontSize: '0.9rem'}}} style={{marginTop: '10px'}} />
                </div>
            </div>
            <div className={'Footer'} >
                <Button fullWidth className={'SaveButton'} variant={'contained'} color={'primary'} onClick={()=>{
                    if (givenName !== name) {
                        callback(name);
                    }
                }}>
                    {'저장'}
                </Button>
            </div>
        </div>
    );
}

export default NameChange;