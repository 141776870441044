import React, { useEffect, useState } from 'react';
import { TextField, TextFieldProps } from "@material-ui/core";
import { randomString } from '../Functions';
import './AutoCompletableTextField.scss';

const constructChangeEvent = (): React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> => {
    return {
        target: new EventTarget() as EventTarget & (HTMLTextAreaElement | HTMLInputElement),
        nativeEvent: new Event('native'),
        currentTarget: new EventTarget() as EventTarget & (HTMLTextAreaElement | HTMLInputElement),
        bubbles: false,
        cancelable: false,
        defaultPrevented: false,
        eventPhase: 0,
        isTrusted: false,
        preventDefault: ()=>{},
        isDefaultPrevented: ()=>false,
        stopPropagation: ()=>{},
        isPropagationStopped: ()=>false,
        persist: ()=>{},
        timeStamp: 0,
        type: ''
    };
}

const AutocompletableTextField = ({
        value, 
        onChange=()=>{}, 
        onKeyUp=()=>{}, 
        candidates=[], 
        antiPattern=null,
        ...others
    }: {
        value:string;
        onChange?: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
        onKeyUp?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
        candidates?: {
            pattern: RegExp; prefix: string; postfix: string;
        }[];
        antiPattern?: RegExp | null;
    } & TextFieldProps) => {

    const [uniqueId, ] = useState('autocomplete_'+randomString(16));

    const [currentFocusedIdx, setCurrentFocusedIdx] = useState<number>(-1);
    const [shownCandidates, setShownCandidates] = useState<string[]>([]);
    

    useEffect(()=>{
        if (!value || candidates.length === 0){
            setCurrentFocusedIdx(-1);
            setShownCandidates([]);
            return;
        }
        if (antiPattern && antiPattern.test(value)){
            setCurrentFocusedIdx(-1);
            setShownCandidates([]);
            return;
        }

        const availableCandidates: string[] = [];

        for(const {pattern, prefix, postfix} of candidates){
            if (pattern.test(value)){
                availableCandidates.push(prefix+value+postfix);
            }
        }
        if (availableCandidates.length > 0){
            setCurrentFocusedIdx(0);
            setShownCandidates(availableCandidates);
            return;
        }

        setCurrentFocusedIdx(-1);
        setShownCandidates([]);
    },[antiPattern, candidates, value]);

    return(
        <div className={'AutoCompletableTextFieldContainer'} id={uniqueId}>
            <TextField
                value={value}
                onKeyUp={(event)=>{
                    if (event.key === 'ArrowUp' && shownCandidates.length > 0 && currentFocusedIdx > 0){
                        event.stopPropagation();
                        setCurrentFocusedIdx(currentFocusedIdx - 1);
                        return;
                    }
                    if (event.key === 'ArrowDown' && shownCandidates.length > 0 && currentFocusedIdx < shownCandidates.length - 1){
                        event.stopPropagation();
                        setCurrentFocusedIdx(currentFocusedIdx + 1);
                        return;
                    }
                    if (event.key === 'Enter' && shownCandidates.length > 0){
                        const e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> = constructChangeEvent();
                        e.target.value = shownCandidates[currentFocusedIdx];
                        onChange(e);
                    }
                    onKeyUp(event);
                }}
                onChange={(event) => {
                    onChange(event)
                }} 
                {...others}
            />
            {shownCandidates.length > 0 && <div className={'CandidateList'}>
                {shownCandidates.map((candidate,index) =>
                <div
                    key={candidate}
                    className={`CandidateItem${index===currentFocusedIdx ? " Focused": ""}`}
                    onClick={()=>{
                        const target = document.querySelector(`#${uniqueId} input`) as HTMLInputElement | null;
                        if (!target){
                            return;
                        }
                        const e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> = constructChangeEvent();
                        e.target.value = candidate;
                        onChange(e);
                    }}
                >
                    {candidate}
                </div>)}
            </div>}
        </div>
    );
}

export default AutocompletableTextField;