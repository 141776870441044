import React, { useCallback, useEffect, useState } from 'react';
import CarouselCard from './CarouselCard';

import './Styles/Feed.scss';
import { shuffleArray } from '../../Market/Util/Functions';
import { ArrowUpward } from '@material-ui/icons';

const Feed = ({
    data, selectedTab
}:{
    data: (FeedTitleInfoType | null)[], selectedTab: string
}) => {

    const [ keyOrder, setKeyOrder ] = useState<string[]>([]);
    const [ autoplayFeedKey, setAutoplayFeedKey ] = useState<string>('');

    const finishCallback = useCallback(() => {
        const currentIndex = keyOrder.indexOf(autoplayFeedKey);
        setAutoplayFeedKey(keyOrder[(currentIndex + 1) % keyOrder.length]);
    },[autoplayFeedKey, keyOrder]);

    useEffect(() => {
        if (!data) return;
        const shuffledKeys = shuffleArray(data
                                .filter( data => data && data !== null )
                                .map( data => `${data?.serviceId}:${data?.titleId}` ));
        
        if (( !autoplayFeedKey && (shuffledKeys || []).length > 0 )
           || JSON.stringify((keyOrder || []).sort()) !== JSON.stringify(shuffledKeys.sort())
        ) {
            setKeyOrder(shuffledKeys);
            setAutoplayFeedKey(shuffledKeys[0]);
        };
    },[autoplayFeedKey, data, keyOrder, selectedTab])
    
    let twoFeedKey: string[] = [];
    for ( let i = 1; i < data.length; i++ ) {
        if ( data[i] === null && (i+1 >= data.length || data[i+1] !== null) ) {
            twoFeedKey.push(`${data[i-1]?.serviceId}:${data[i-1]?.titleId}`)
        };
    };
  
    const feedTypeSelector = ( key: string ): 'doubleSet' | 'single' => {
        if ( twoFeedKey.indexOf(key) >= 0) {
            return 'doubleSet'
        } else {
            return 'single'
        };
    };

    return (
        <div className={'HomeFeedArea'} >
            <div className={'HomeFeedInner'}>
                {data?.map(( title, idx ) => 
                    title && <div className={`FeedItem ${feedTypeSelector(`${title.serviceId}:${title.titleId}`)}`} key={`Feed:${title.serviceId}:${title.titleId}`} >
                        <CarouselCard 
                            data={title}
                            type={feedTypeSelector(`${title.serviceId}:${title.titleId}`)} 
                            finishCallback={finishCallback}
                            play={autoplayFeedKey === `${title.serviceId}:${title.titleId}`} 
                        />
                    </div>)}
            </div>
            <div
                className={'ScrollToTopButton'}
                onClick={ () => window.scrollTo({ top: 0 }) } 
            >
                <ArrowUpward />
            </div>
        </div>
    );
};

export default Feed;