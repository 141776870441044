import { ArrowForwardIos } from '@material-ui/icons';
import React from 'react';
import Slider from 'react-slick';


import './RectSlide.scss';

const RectSlide = ({
    className="",  style={}, 
    isDraggable=true,
    beforeChange=(oldIndex, newIndex)=>{},
    children
}) => {

    
    const slider = React.useRef();
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    return (
    <div className={'RectSlide '+className} style={style}>
        <Slider
            ref={slider}
            {...settings}
            nextArrow={<div><ArrowForwardIos/></div>}
            prevArrow={<div><ArrowForwardIos style={{transform: 'rotate(180deg)'}}/></div>}
        >
            {children}
        </Slider>
    </div>
    )
}

export default RectSlide;