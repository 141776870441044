import React from "react";
import "./Styles/MoreButton.scss"

const MoreButton = ({handleMoreButton}: {handleMoreButton: Function}) => {

    return (
        <div className={'MoreButton'} onClick={() => handleMoreButton()}>
            <img src={'https://static.webtoon.today/ddah/arrow_forward.svg'} alt={'더보기'}/>
        </div>
    )
}

export default MoreButton;