import { fn } from '../../Functions';
import React from 'react';
import FloatingCard from '../../Component/FloatingCard';

import './PortfolioView.scss';
import RectSlide from '../../Component/RectSlide';
import { Share as ShareIcon } from '@material-ui/icons';
import Share from '../../Component/Share';

const DummyDataForLoading = {
    addressid: "today",
    profile: {
        title: "오늘의웹툰",
        subtitle: "포트폴리오를 로딩중입니다 :)",
    },
    contents: [
        {
            key: "cZAsQIagOaeX",
            type: "link",
            title: "...",
            cardType: "icon",
            show: true,
            images: [
            {
                name: "thumbnail",
                image: "https://static.webtoon.today/noimage.jpg",
                lastModified: 1663831861515
            }
            ],
            link: "#"
        }
    ],
    template: {
        color: "rgb(214,234,251)"
    }
}

/**
 * 
 * @param {{portfolio: import('../../Data/Portfolio').PortfolioType, isLoading: boolean}} param0 
 * @returns 
 */
const PortfolioView = ({portfolio={profile:{}, contents:[], template:{}}, isPortfolioLoading=false}) => {
    const {profile, contents,} = isPortfolioLoading?DummyDataForLoading:portfolio;

    const [largeImage, setLargeImage] = React.useState(null);
    
    return (
    <div className={"PortfolioView"}>
        <Share contents={
                <ShareIcon className={'ShareButton'} />
            } 
            log={{ what: 'share-portfolio', detail: {} }} 
        />
        <div className={"ProfileArea"}>
            <div className={"Image"}>
                <img src={profile.image || 'https://static.webtoon.today/noprofile.jpg'} alt={profile.title}/>
            </div>
            <div className={"Title"}>{profile.title}</div>
            <div className={"Subtitle"}>{profile.subtitle}</div>
        </div>
        <div className={"ContentsArea"}>
        {contents
        .filter(content => content.show)
        .map(content => (
            content.type === 'image' && content.viewerType === 'board' && (content.images || []).length > 0
            ?<div
                key={content.key}
                className={"Gallery "+(content.viewerType==="slide"?"Slide":"Board")}
                onClick={()=>{}}
            >
                {content.viewerType==="board" && (content.images || []).length > 0
                ?content.images.map((image,idx) => 
                    <div
                        key={`img_${idx}`}
                        className={"GridCell ContentsCell"}
                        onClick={()=>{setLargeImage(image.image)}}
                        style={idx%2===0?{marginRight:10}:{}}
                    >
                        <img key={image.image} src={image.image} alt={image.name}
                            onError={(event) => {
                                if (event.target.src === "https://static.webtoon.today/noimage.jpg"){
                                    return;
                                }
                                event.target.src="https://static.webtoon.today/noimage.jpg";
                                event.target.style.backgroundColor = 'rgb(192,192,192)';
                            }}
                        />
                    </div>
                ):<></>}
            </div>
            :content.type==="text"
            ?<div
                key={content.key}
                className={"Text "+(content.textAlign || 'left').toUpperCase()}
            >
                <h3>{content.title}</h3>
                <div>{content.description
                    ?content.description.split('\n').map(row => <div key={row}>{row}</div>)
                    :<></>
                }</div>
            </div>
            :content.type==="link" && content.cardType==="icon" && content.link && content.link.length > 0
            ?<FloatingCard key={content.key} className={"ContentsCell"}>
                <div
                    className={"Link Icon"}
                    onClick={()=>{fn.goto(content.link)}}
                >
                    <div className={"Image"}>
                    {(content.images || []).length > 0
                    ?content.images.map((image,idx) => 
                        <img key={image.image} className={idx===0?"FirstImage":"OtherImage"} src={image.image} alt={image.name}
                            onError={(event) => {
                                if (event.target.src === "https://static.webtoon.today/noimage.jpg"){
                                    return;
                                }
                                event.target.src="https://static.webtoon.today/noimage.jpg";
                                event.target.style.backgroundColor = 'rgb(192,192,192)';
                            }}
                        />
                    ):<></>}
                    </div>
                    <div className={"Text"}>
                        <div className={"TextInner"}>
                            {content.title}
                        </div>
                    </div>
                </div>
            </FloatingCard>
            :content.type==="link" && content.cardType==="card" && content.link && content.link.length > 0
            ?<FloatingCard key={content.key} className={"ContentsCell"}>
                <div
                    className={"Link Card"}
                    onClick={()=>{fn.goto(content.link)}}
                >
                    {(content.images || []).length>1
                    ?<RectSlide className={"Gallery Slide"}>
                    {content.images.map((image,idx) => 
                        <img key={image.image} src={image.image} alt={image.name} style={{width: '100%', height: '100%'}}
                            onError={(event) => {
                                if (event.target.src === "https://static.webtoon.today/noimage.jpg"){
                                    return;
                                }
                                event.target.src="https://static.webtoon.today/noimage.jpg";
                                event.target.style.backgroundColor = 'rgb(192,192,192)';
                            }}
                        />
                    )}
                    </RectSlide>
                    :(content.images || []).length === 1
                    ?<div className='Image'>
                        <img key={(content.images[0] || {}).image} src={(content.images[0] || {}).image} alt={(content.images[0] || {}).name}
                            onError={(event) => {
                                if (event.target.src === "https://static.webtoon.today/noimage.jpg"){
                                    return;
                                }
                                event.target.src="https://static.webtoon.today/noimage.jpg";
                                event.target.style.backgroundColor = 'rgb(192,192,192)';
                            }}
                        />
                    </div>
                    :<></>}
                    <div className={"Text"}>
                        <div className={"TextInner"}>
                            {content.title}
                        </div>
                    </div>
                </div>
            </FloatingCard>
            :content.type==="image" && content.viewerType === "slide" && (content.images || []).length>1
            ?<FloatingCard key={content.key} className={"ContentsCell"}>
                <RectSlide className={"Gallery Slide"}>
                    {content.images.map((image,idx) =>
                    <img key={image.image} src={image.image} alt={image.name} style={{width: '100%', height: '100%'}}
                        onError={(event) => {
                            if (event.target.src === "https://static.webtoon.today/noimage.jpg"){
                                return;
                            }
                            event.target.src="https://static.webtoon.today/noimage.jpg";
                            event.target.style.backgroundColor = 'rgb(192,192,192)';
                        }}
                    />)}
                </RectSlide>
            </FloatingCard>
            :content.type==="image" && content.viewerType === "slide" && (content.images || []).length === 1
            ?<FloatingCard key={content.key} className={"ContentsCell"}>
                <img className={"Gallery Slide Oneimage"} key={(content.images[0] || {}).image} src={(content.images[0] || {}).image} alt={(content.images[0] || {}).name}
                    onError={(event) => {
                        if (event.target.src === "https://static.webtoon.today/noimage.jpg"){
                            return;
                        }
                        event.target.src="https://static.webtoon.today/noimage.jpg";
                        event.target.style.backgroundColor = 'rgb(192,192,192)';
                    }}
                />
                </FloatingCard>
            :content.type==="youtube" && content.link
            ?<FloatingCard key={content.key} className={"ContentsCell"}>
                <div
                    className={"Youtube"}
                >
                    {/* eslint-disable-next-line jsx-a11y/iframe-has-title*/}
                    <iframe
                        src={content.link.replace(/watch\?v=/,'embed/')}
                        frameBorder="0" allow='accelerometer; autoplay; encripted-media; gyroscope; picture-in-picture'
                        allowFullScreen
                    ></iframe>
                </div>
            </FloatingCard>
            :<></>
            ))}
        </div>
        <div
            style={{
                ...(largeImage?{opacity: 1, zIndex:1}:{opacity: 0, zIndex: -1}),
                position:'fixed', left:0, top:0,
                width: '100vw', height: '100vh',
                backgroundColor: 'rgba(0,0,0,0.2)',
                display:'flex', flexDirection:'row', justifyContent:'center',alignItems:'center'
            }}
            onClick={()=>{setLargeImage(null)}}
        >
            <div style={{maxWidth:'100%', maxHeight:'100%'}}>
                <img style={{maxWidth:'100%', maxHeight:'100%', objectFit:'contain'}} src={largeImage} alt={"large one"}/>
            </div>
        </div>
    </div>);
}

export default PortfolioView;