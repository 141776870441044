import axios from 'axios';
/**
 * @typedef {{
 *  isEmailLoginable: string, 
 *  isProfileExist: string, 
 *  socialpath: string,
 * }} loginInfoType
 */

/**
 * 
 * @param {string} email 
 * @returns {Promise<loginInfoType>}
 */
export const readLoginInfo = async ( email ) => {

    try {
        let res = await axios.post(`https://challenge-api.webtoon.today/user/is-email-loginable`, {email},);
        
        if (res.data.code === 200 && res.data.accountData) {
            
            return res.data.accountData;
        } else {

            return {}
        }
    }catch(e){
        console.error(e);
        return {};
    }
}
