import React, { useEffect, useState } from 'react';
import { fn, randomString } from '../../../../Functions';

import './Tabs.scss';

const Tabs = ({ tabs, currentTab }: { tabs: { [tab: string]: {label: string, destination: string} }; currentTab: string; }) => {
    const [uniqueId,] = useState(randomString(16));

    const tabsEntries = Object.entries(tabs);

    const [biStyle, setBiStyle] = useState<{width: string|number, left: string|number}>({ width: 0, left: 0 });

    useEffect(() => {
        const moveBottomIndicator = () => {
            const parentRect = document.querySelector(`#${uniqueId}.TabMenu`)?.getBoundingClientRect();
            const rect = document.querySelector(`#${uniqueId}.TabMenu .Active`)?.getBoundingClientRect();
    
            if (!parentRect || !rect) {
                return;
            }
    
            setBiStyle({
                width: rect.width,
                left: `calc(${parentRect.left}px + ${(rect.left - parentRect.left)/parentRect.width*100}%)`,
            });
        }

        window.addEventListener('resize', moveBottomIndicator);
        moveBottomIndicator();

        return () => {
            window.removeEventListener('resize', moveBottomIndicator);
        }
    }, [currentTab, uniqueId]);

    return (
        <div className={`TabMenu`} id={uniqueId}>
            {tabsEntries.map(([key, {label, destination}]) => (
                <div
                    key={key}
                    className={`TabItem${key === currentTab ? ' Active' : ''}`}
                    onClick={() => {
                        fn.goto(destination);
                    }}
                >
                    {label}
                </div>
            ))}
            <div className='BottomIndicator' style={biStyle} />
        </div>
    );
};

export default Tabs;