import './NoticeDetail.scss';
import 'react-quill/dist/quill.bubble.css';
import React from 'react';
import moment from 'moment-timezone';
import { fn } from '../../Functions';
import { Button } from '@material-ui/core';
import { PostType, deletePost, readPost } from '../../Data/Post';
import LoadingCircle from '../../Component/LoadingCircle';
import { useSession } from '../../Recoil/Session/Session';
import { ArrowUpward } from '@material-ui/icons';

const ReactQuill = (global.Node && global.HTMLElement) ? require('react-quill') : () => false;

const NoticeDetail = ({postId}: {postId: number}) => {
    const { isManager } = useSession();

    //let queryString = window.location.search.replace('?','');
    //let queries = Object.fromEntries(queryString.split('&').map(pair => pair.split("=").map(uri => decodeURIComponent(uri))))

    const [currentReadPost, setCurrentReadPost] = React.useState<PostType>({
        postId,
        type: "",
        exposure: "",
        significantFrom: 0,
        significantTo: 0,
        title: "",
        createdAt: 0,
        updatedAt: 0,
        body: ""
    });
    const [managerAuthority, setManagerAuthority] = React.useState(false);

    const [isLoading, setIsLoading] = React.useState(false);

    React.useEffect(()=>{
        
        (async()=>{
            setIsLoading(true);
            const result = await readPost(postId)

            if (result && result.length === 1) {
                setCurrentReadPost(result[0])
            } else {
                alert('게시글 로딩에 실패했습니다')
            }
            setIsLoading(false);
        })();
        (async()=>{
            const authority = await isManager();
            if(authority){
                setManagerAuthority(true);
            }
        })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const DeleteCurrentPost = async () => {

        const resultKey = await deletePost(postId)

        if (resultKey){
            fn.goto('/notice')
        }
    }
    
    return (
        <div className={'NoticeDetail'}>
            <div className={'DetailContainer'}>
                <div className={'DetailInner'}>
                    <div className={'Title'}>
                        {'공지사항'}
                    </div>
                    <div className='NoticeDetailButtons'>
                        {managerAuthority &&
                        <>
                            <Button className={'DetailButton Edit'}
                                variant={'contained'} 
                                onClick={()=> fn.goto(`/notice/write/${currentReadPost.postId}`)}
                            >
                                {'수정'}
                            </Button>
                            <Button className={'DetailButton Delete'}
                                variant={'contained'}
                                onClick={()=> DeleteCurrentPost() }
                            >
                                {'삭제'}
                            </Button>
                        </>}
                    </div>
                    <div className={'PostTitle'}>
                        {currentReadPost.title}
                    </div>
                    <div className='PostDate'>
                        {moment(currentReadPost.createdAt * 1000).format('YY. MM. DD')}
                    </div>
                    <div className={'CurrentPostDescription'}>
                        <ReactQuill
                            value={currentReadPost.body}
                            readOnly={true}
                            theme={'bubble'}
                        />
                    </div>
                    <div className={'DetailFooter'}>
                        <Button className={'DetailButton'}
                            variant={'contained'}
                            onClick={()=>fn.goto('/notice')} 
                        >
                            {'목록으로'}
                        </Button>
                    </div>
                    <div
                        className={'ScrollToTopButton'}
                        onClick={ () => window.scrollTo({ top: 0 }) } 
                    >
                        <ArrowUpward />
                    </div>
                </div>
            </div>
            <LoadingCircle show={isLoading}/>
        </div>
    );
}

export default NoticeDetail;