import React, { useEffect, useState } from 'react';
import HomePC from './Components/HomePC';
import HomeMobile from './Components/HomeMobile';
import ChannelService from '../../Component/ChannelService';
import { pageHeaderOptions } from '../../Recoil/PageHeader/PageHeader';
import { useSetRecoilState } from 'recoil';
import { fn } from '../../Functions';

const Home = ({query}: {query: string}) => {
    const selectedTab = new URLSearchParams(query).get('tab') || 'global';

    const setPageHeader = useSetRecoilState(pageHeaderOptions);
    
    const [ isMobileMode,   setIsMobileMode ] = useState<boolean>(window.innerWidth < 700);

    useEffect(() => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);

        ChannelService.ChannelIO()('hideChannelButton');

        setTimeout(()=>fn.redirectIfExists(),0);
    },[])

    useEffect(() => {

        const decidePcOrMobile = ( ) => {
            if ( (window.innerWidth >= 700)) {
                setPageHeader({
                    titleText: '오늘의웹툰',
                    isAppbarHidden: true,
                    isStickyTop: true,
                });
                setIsMobileMode(false);

            } else if ( (window.innerWidth < 700)) {
                setPageHeader({
                    titleText: '오늘의웹툰',
                    isHiddenForMobile: true,
                    isHiddenForPC: true,
                })
                setIsMobileMode(true);
            };
        };
        decidePcOrMobile();
        window.addEventListener('resize', decidePcOrMobile);

        return () => {
            window.removeEventListener('resize', decidePcOrMobile);
        }
    },[setPageHeader])

    return (
        <>
            {isMobileMode ? <HomeMobile /> : <HomePC selectedTab={selectedTab}/>}
        </>
    );  
};

export default Home;