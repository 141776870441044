import React from 'react';
import './Comment.scss';
import { deleteComment } from '../../Data/Comment';
import { deleteCommentReaction, updateCommentReaction } from '../../Data/CommentReaction';
import { timeLapsConversion } from '../../Functions';
import ChannelService from '../../Component/ChannelService';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { useSession } from '../../Recoil/Session/Session';
import { useToastAlert } from '@webtoontoday/toast';

/**
 * 
 * @param {{
 *   comment:{
 *     type?: 'Author',
 *     isBest: boolean,
 *     content: string,
 *     reaction: 'like'|'dislike'|'sadness'|null,
 *     likeCount: number, dislikeCount: number, sadnessCount: number,
 *   }, serviceId: string, titleId: string, episodeId: string,
 *   deleteCallback:()=>void,
 * }} param0 
 * @returns 
 */


const Comment = ({
    comment = {
        isBest: false, content: '', reaction: null,
        likeCount: 0, dislikeCount: 0, sadnessCount: 0
    }, setComment = (newComment)=>{},
    serviceId, titleId, episodeId, 
    deleteCallback
}) => {
    const { toastAlert } = useToastAlert();
    const { session } = useSession();
    
    const [ commentReaction, setCommentReaction ] = React.useState(comment.reaction);
    const [ openDeleteCofirmation, setOpenDeleteConfirmation ] = React.useState(false);

    React.useEffect(()=>{
        setCommentReaction(comment.reaction);
    },[comment])
    
    const reactionUpdate = async (newReaction) => {
        
        if (newReaction === commentReaction) {
            const res = await deleteCommentReaction({commentId: comment.id});
            
            if (res) {
                const newComment = {...comment, reaction: null};

                newComment[`${comment.reaction}Count`] = newComment[`${comment.reaction}Count`] - 1

                setComment(newComment);
                setCommentReaction(null);
                return;
            } else {
                toastAlert('댓글 업데이트가 실패했습니다.')
                return;
            }
        } else {
            
            const res = await updateCommentReaction({commentId: comment.id, reaction: newReaction });
            
            if (res) {
                const newComment = {...comment, reaction: newReaction};

                newComment[`${newReaction}Count`] = newComment[`${newReaction}Count`] + 1
                newComment[`${comment.reaction}Count`] = newComment[`${comment.reaction}Count`] - 1
                
                setComment(newComment);
                setCommentReaction(newReaction)
                return;
            } else {
                toastAlert('댓글 업데이트가 실패했습니다.')
                return;
            }
        };
    };
    
    return(
        <div className={`CommentBox`}>
            <div>
                <div className={'CommentStateLine'} >
                    <div className={'AuthorAndTitle'}>
                        {comment.type === 'Author' || comment.isBest
                            ?<span className={'Title'} style={{ backgroundColor: `${comment.type === 'Author'?'rgb(0,176,240)':'rgb(219,0,0)'}` }} >
                                {`${comment.type === 'Author'?'작가':'BEST'}`}
                            </span>
                            :<></>
                        }
                        <span className={'Author'} >
                            {comment.name}
                        </span>
                    </div>
                    <div className={'CreatedDate'} >
                        {timeLapsConversion(comment.createdAt)}
                    </div>
                </div>
                <div className={'ContentLine'}>
                    {comment.content}
                </div>
                <div className={'ReactionButtonLine'}  >
                    <span className={`Reaction ${commentReaction === 'like'?'FocusedReaction':''}`} style={{marginRight: 16}} onClick={() => reactionUpdate('like')} >
                        <span className={'ReactionIcon'}>{`😃`}</span>
                        {` 좋아요 ${comment.likeCount}`}
                    </span>
                    <span className={`Reaction ${commentReaction === 'dislike'?'FocusedReaction':''}`} style={{marginRight: 16}} onClick={() => reactionUpdate('dislike')} >
                        <span className={'ReactionIcon'}>{`🙁`}</span>
                        {` 싫어요 ${comment.dislikeCount}`}
                    </span>
                    <span className={`Reaction ${commentReaction === 'sadness'?'FocusedReaction':''}`} onClick={() => reactionUpdate('sadness')} >
                        <span className={'ReactionIcon'}>{`😢`}</span>
                        {` 슬퍼요 ${comment.sadnessCount}`}
                    </span>
                    {session.userid === comment.authorId
                        ?<div 
                            className={`DeleteButton`} 
                            onClick = { () => setOpenDeleteConfirmation(true) }
                        >
                            {'삭제'}
                        </div>
                        :<div className={'CommentReportButton'} onClick={()=>{
                            ChannelService.ChannelIO()('openChat');
                        }}>
                            {`신고`}
                        </div>
                        }
                </div>
            </div>
            <div className={'CommentEndLine'} />
            <Dialog open={openDeleteCofirmation} onClose={()=>setOpenDeleteConfirmation(false)} >
                <DialogTitle>
                    {'댓글을 삭제하시겠어요?'}
                </DialogTitle>
                <DialogContent>
                    {'한번 삭제한 댓글은 복구할 수 없어요.'}
                </DialogContent>
                <DialogActions>
                    <Button
                        color={'primary'} disableElevation
                        onClick={ async () => {
                            const res = await deleteComment({id: comment.id, serviceId, titleId, episodeId, authorId: comment.authorId});
                            
                            if (res) {
                                deleteCallback();
                                return;
                            } else {
                                toastAlert('댓글 삭제를 실패했어요.\n채널톡으로 문의해주세요.');
                                console.error('fail to delete comment in Comment.js');
                                return;
                            }
                        }}
                    >
                        {'확인'}
                    </Button>
                    <Button 
                        disableElevation 
                        onClick={()=>setOpenDeleteConfirmation(false)}
                    >
                        {'취소'}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}


export default Comment;
