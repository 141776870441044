export const platformAndTopicRank: {[platform: string]: string[]} = {
    "naver": [
        "일상",
        "SF",
        "학원로맨스",
        "빙의",
        "서스펜스"
    ],
    "bomtoon": [
        "BL",
        "달달물",
        "학원",
        "일상",
        "성장",
    ],
    "daum": [
        "회귀",
        "성장",
        "학원",
        "빙의",
        "레벨업"
    ],
    "kakao": [
        "성장",
        "빙의",
        "계약관계",
        "능력녀",
        "연예계"
    ],
    "toomics": [
        "학원",
        "이능",
        "재벌남",
        "복수/배신",
        "성장",
    ],
    "toptoon": [
        "소년물",
        "복수/배신",
        "성장",
        "학원",
        "인외/초월적존재"
    ],
    "ridi": [
        "성인",
        "다정남",
        "순진녀",
        "SF",
        "오피스로맨스"
    ]
}