import { Button, Chip } from '@material-ui/core';
import { Favorite, FavoriteBorderOutlined } from '@material-ui/icons';
import React from 'react';
import LoadingCircle from '../../Component/LoadingCircle';
import { fn } from '../../Functions';

import { readOwnerships } from '../../Data/Ownership';
import {readProfileList} from '../../Data/Profile';

import './TitleDetail.scss';
import { useSubscribedAuthorList } from '../../Recoil/User/Users';
import { useSession } from '../../Recoil/Session/Session';

const TitleDetail = ({title}) => {
    const { session } = useSession();

    const [ isLoading, setIsLoading ] = React.useState(false);
    const [authors, setAuthors] = React.useState([]);
    const {userList: subscribedAuthors, fetch, subscribe, unsubscribe} = useSubscribedAuthorList();

    const alert = fn.alert;

    React.useEffect(()=>{
        (async()=>{
            setIsLoading(true);

            let ownershipList = await readOwnerships(title.serviceId, title.titleId);

            if (!ownershipList || ownershipList.length === 0){
                ownershipList = [{addressid: title.serviceId}]
            }
            const profiles = await readProfileList(ownershipList.map(row => row.userid));

            if(profiles){
                setAuthors(profiles);
            }

            await fetch();
            
            setIsLoading(false);
        })();
    },[fetch, title])
    
    return (
    <div className='TitleDetail'>
        <div className='Section'>
            <h2>{title.title}</h2>
        </div>
        <div className='Section'>
            <h3>{"작가"}</h3>
            {authors.map(author => 
                <div className='AuthorRow' key={`author:${author.addressid}`}>
                    {author.name}
                    <Button variant={"outlined"} onClick={ async () => {
                        if (!session.memberState || session.memberState !== 'regular'){
                            alert('로그인이 필요한 기능입니다.');
                            return;
                        };

                        // subscribedAuthors wouldn't be null at this point, because it's fetched before clicking this button
                        const isMyFavoriteAuthor = subscribedAuthors.findIndex(favoriteAuthor => favoriteAuthor.authorId === author.addressid) >= 0

                        if (isMyFavoriteAuthor) {
                            const res = await unsubscribe(author.addressid);
                            
                            if (!res) {
                                alert('관심 작가 취소에 실패했습니다.')
                            };
                        } else {
                            const res = await subscribe(author.addressid);
                            
                            if (!res) {
                                alert('관심 작가 등록에 실패했습니다.')
                            };
                        };
                    }}>
                        {subscribedAuthors?.findIndex(favoriteAuthor => favoriteAuthor.authorId === author.addressid) >= 0
                            ?<Favorite/>
                            :<FavoriteBorderOutlined/>
                        }
                        {"관심 작가"}
                    </Button>
                </div>
            )}
        </div>
        <div className='Section'>
            <h3>{"줄거리"}</h3>
            {title.description}
        </div>
        <div className='Section'>
            <h3>{"장르"}</h3>
            {(title.tags || '').split(',').map(tag => 
                <Chip label={tag} key={`titleDetail:${tag}`} />
            )}
        </div>
        <div className='Section'>
            <h3>{"작가의 다른 작품"}</h3>
            {}
        </div>
        <div className='Section'>
            <h3>{"이 작품을 본 사람들이 관심있게 보는 작품"}</h3>
        </div>
        <LoadingCircle show={isLoading} />
    </div>
    );
}

export default TitleDetail;