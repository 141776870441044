import React from 'react';
import { FormControl, FormControlLabel, Radio, RadioGroup, } from '@material-ui/core';
import './ThemeDecorationCol.scss';

const colorPalette = [
    "rgb(255,255,255)", "rgb(255,219,216)",
    "rgb(255,200,160)", "rgb(255,233,156)",
    "rgb(197,221,193)", "rgb(197,224,216)",
    "rgb(214,234,251)", "rgb(231,213,237)",
    "rgb(245,236,228)", "rgb(175,175,175)",
]
const ThemeDecoration = ({
    themeTemplate, setThemeTemplate, themeColor, setThemeColor,
    saveTheme=()=>{}, resetTheme=()=>{},
}) => {
    
    return(
    <div className={'ThemeDecorationArea'}>
        
        <div className={'HalfCol'}>
            <h3>{"템플릿"}</h3>
            <FormControl style={{width: '100%'}} component="fieldset">
                <RadioGroup
                    className={'TypeSelection'}
                    value={themeTemplate} onChange={(event) => { setThemeTemplate(event.target.value)}}
                >
                    {["Circle", "Basic", "Square"].map( type => (
                        <div key={`themeDecorationSlide:${type}`} className={'TypeBox'} >
                                <img 
                                    src={`https://static.webtoon.today/ddah/${type}.png`} alt={type} 
                                    onClick={ () => setThemeTemplate(type.toLowerCase()) } 
                                    className={'TemplatePreview'}
                                />
                                <FormControlLabel
                                    style={{margin: 0}}
                                    value={type.toLowerCase()}
                                    control={ <Radio color={'primary'} style={{margin: 0}} />} 
                                />
                        </div>
                    ) )}
                </RadioGroup>
            </FormControl>
        </div>
        <div className={'HalfCol'}>
            <h3>{"색상"}</h3>
            <div className={'ColorSelection'} >
                {colorPalette.map(color => (
                    <div className={`${themeColor === color?'FocusedOutline':'Outline'}`} key={`ColorSelection:${color}`} >
                        <div 
                            className={'Color'}
                            style={{background: `${color}`, border: `${color === 'rgb(255,255,255)'?'1px solid rgb(238,238,238)':''}`}} 
                            onClick={ () => {
                                setThemeColor(color);
                            }}
                        />
                    </div>
                ))}
            </div>
        </div>
    </div>
    )
}

export default ThemeDecoration;