import { atom, useRecoilState } from "recoil";
import { useCallback } from "react";

const redirectToStackState = atom<string[]>({
    key: 'redirectToStack',
    default: [],
});

export const useRouter = ():{
    popRedirectTo: () => string|null,
    pushRedirectTo: (newPath: string) => void,
} => {
    const [redirectToStack, setRedirectToStack] = useRecoilState(redirectToStackState);

    const popRedirectTo = useCallback(()=>{
        if (redirectToStack.length > 0){
            let ret = redirectToStack[redirectToStack.length-1];
            setRedirectToStack(redirectToStack.slice(0, redirectToStack.length-1));
            return ret;
        }else {
            return null;
        }
    }, [redirectToStack, setRedirectToStack]);

    const pushRedirectTo = useCallback((newPath: string)=>{
        setRedirectToStack(redirectToStack => [...redirectToStack, newPath]);
    }, [setRedirectToStack]);

    return {
        popRedirectTo,
        pushRedirectTo,
    };
};