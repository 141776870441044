import { Button, TextField, Checkbox, FormControlLabel, } from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';
import React from 'react';
import './SignIn.scss';
import { fn, checkKorean } from '../../Functions';
import { useSignIn } from '../../Recoil/Session/Session';
import { useSetRecoilState } from 'recoil';
import { pageHeaderOptions } from '../../Recoil/PageHeader/PageHeader';

const LoginWithPassword = ({email, setEmail, password, setPassword,})=> {
    const { EmailLogin } = useSignIn();
    
    if(!email){
        fn.goto('/signin');
    }

    const setPageHeader = useSetRecoilState(pageHeaderOptions);
    React.useEffect(()=>{
        setPageHeader({titleText: '로그인', mobileShortcuts:<></>})
    },[setPageHeader])

    const [ isPasswordVisible , setIsPasswordVisible ] = React.useState(false);
    const [ isWrongPassword, setIsWrongPassword ] = React.useState(false);
    
    const [ hasKoreanLetters, setHasKoreanLetters ] = React.useState(false);

    return (
        <div className={'MainArea'} >
            <div className={'BodyContainer'}>
                <div className={'InnerInputBox'}>
                    <div className={'BoxLabel'}>
                        {'이메일'}
                    </div>
                    <div style={{display: 'flex'}}>
                        <TextField fullWidth value={email || ''} onChange={(e)=>{
                            setEmail(e.target.value)
                        }} />
                    </div>
                </div>
                <div className={'InnerInputBox'}>
                    <div className={'BoxLabel'}>
                        {'비밀번호'}
                    </div>
                    <TextField fullWidth value={password || ''} type={`${isPasswordVisible?'text':'password'}`} error={isWrongPassword || hasKoreanLetters} onChange={(e)=>{
                        const hasKorean = checkKorean(e.target.value)
                        if (hasKorean) {
                            setHasKoreanLetters(true);
                        } else {
                            setHasKoreanLetters(false);
                        }
                        setPassword(e.target.value)

                        if(isWrongPassword){
                            setIsWrongPassword(false)
                        }
                    }} 
                    onKeyDown={(e)=>{
                        if(e.key === 'Enter'){
                            document.getElementById('LoginButton').click();
                        }
                    }}
                    />
                    {hasKoreanLetters
                    ?<div style={{color: 'red', fontSize: '0.75rem'}}>
                        {'영문, 숫자, 특수문자만 입력할 수 있어요.'}
                    </div>
                    :isWrongPassword
                        ?<div style={{color: 'red', fontSize: '0.75rem'}}>
                            {'비밀번호를 다시 확인해주세요.'}
                        </div>
                    :<></>}
                    <div className={'BelowField'} >
                        <div style={{display: 'flex', alignItems: 'center'}} onClick={()=> fn.goto('/account/start-reset-password?email='+email)} >
                            <HelpOutline style={{fontSize: '1rem', color: 'rgb(0,176,240)', marginRight: 3, cursor: 'pointer'}} />
                            <span className={'UnknownPassword'} >
                                {'비밀번호 찾기'}
                            </span>
                        </div>
                        <div className={'ShowingPassword'} >
                            <FormControlLabel tabIndex={-1} control={
                                <Checkbox className={'VisibleCheckBox'} color={'primary'}
                                    checked={isPasswordVisible} onChange={(e)=>setIsPasswordVisible(e.target.checked)}
                                />
                            } label={'비밀번호 표시'} />
                        </div>
                    </div>
                </div>
                <Button id={"LoginButton"} variant={'contained'} color={'primary'} className={`NextModeButton`}
                    disabled={ !email || (password || "").length < 6 || (password || "").length > 20 || hasKoreanLetters } 
                    onClick={()=> {

                        ( async () => {
                            const res = await EmailLogin(email, password);
                            if (res){
                                localStorage.setItem('lastLogin', JSON.stringify('이메일'));
                                setEmail(null);
                                fn.goto('/');
                            } else {
                                setIsWrongPassword(true);
                            }
                        })()

                    }}
                >{'로그인하기'}</Button>
            </div>
        </div>
    )
}

export default LoginWithPassword;