import axios from 'axios';
import ss from './Session';


export const readTrendAnalytics = async ( topic: string ) => {
    try {
        
        let res = await axios.post('https://challenge-api.webtoon.today/market/trend-analytics', {topic: [topic]}, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        })
        
        if (res.data && res.data.code === 200){
            return res.data.data;
        }else{
            return null;
        }
        
    } catch (e) {
        console.error(e)
        return null;
    }
}

type PlatformDataType = {
    code: string,
    data: {
        "platformPopularityAnalyticsByMonth": {
            "code": number,
            "data": {
                [date: string]: {
                    [topic: string]: number
                }
            } 
        },
        "platformPopularityTransitionAnalytics": {
            "code": number,
            "data": {
                [date: string]: {
                    [title: string]: number
                }
            } 
        },
        "platformNewTitlePopularityAnalytics": {
            "code": 200,
            "data": {
                "rank": string,
                "platform": string,
                "serviceId": string,
                "titleId": string,
                "title": string,
                "thumbnail": string,
                "description": string,
                "topic": string[],
                "href": string,
            }[],     
        },
        "getPlatformRecentTopicTrendAnalyticsFromS3": {
            "code": number,
            "data": {
                "increasedTopic": {
                    [topic: string]: number
                },
                "decreasedTopic": {
                    [topic: string]: number
                }
            }
        },
        "platformTitlePopularityAnalytics": {
            "code": 200,
            "data": {
                "rank": string,
                "platform": string,
                "serviceId": string,
                "titleId": string,
                "title": string,
                "thumbnail": string,
                "description": string,
                "topic": string[],
                "href": string,
            }[],     
        }
    }
}

export const readPlatformTrendAnalytics = async ( platform: string ): Promise<{code: number, data: PlatformDataType} | null> => {
    try {
        
        let res = await axios.post('https://challenge-api.webtoon.today/market/platform-trend-analytics', {platform}, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        })
        
        if (res.data && res.data.code === 200){
            return res.data.data;
        }else{
            return null;
        }
        
    } catch (e) {
        console.error(e)
        return null;
    }
}